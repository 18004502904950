export const dateToLocalInputString = (date: Date = new Date()) =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -8);

export const localInputToIsoString = (date: string) => {
    const fakeUtcTime = new Date(`${date}Z`);

    return new Date(
        fakeUtcTime.getTime() + fakeUtcTime.getTimezoneOffset() * 60000
    );
};
