import classes from "./Button.module.css";

const Button = (props) => (
    <div
        className={props.disabled ? classes.disabled : classes.button}
        onClick={props.onClick}
    >
        <span>{props.text}</span>
    </div>
);

export default Button;
