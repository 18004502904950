import classNames from "classnames/bind";
import { createRef, useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Slider from "react-slick";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNavigate } from "react-router-dom";
import {
    MONTHS,
    convertDate,
    formatNumber,
    getMonday,
    getWeekNumber,
    isMobile,
    returnWithZero,
} from "../reservationUtilities";
import { responseMessage } from "../../../utility";
import { post } from "../../../config/fetch";
import reservationConfig from "../reservationConfig.json";
import classes from "./ReservationCalendar.module.css";
import Week from "./Week/Week";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cx = classNames.bind(classes);

const Loader = () => (
    <div className={cx("loaderContainer")}>
        <CircularProgress color="primary" />
        <p>Naptár betöltése...</p>
    </div>
);

const ReservationCalendar = (props) => {
    const navigate = useNavigate();
    const sliderRef = useRef();
    const sliderRefs = [];
    const [activeWeek, setActiveWeek] = useState(0);
    const [sliderInTransaction, setSliderTransaction] = useState(false);
    const [appointments, setAppointments] = useState(null);

    const getAppointmentsFromBackend = () =>
        post(
            "api/v1/reservation/appointments",
            {
                specializationId: props.serviceId,
                doctorId: props.doctorId,
                institutionId: props.institutionId,
                appointmentId: props.appointmentId,
            },
            false
        ).then((response) => {
            const weeks = {};
            for (let i = 0; i <= reservationConfig.weekCount; i++) {
                weeks[i] = [];
            }

            if (response.data.data?.length) {
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                let thisWeeksMonday = getMonday(today);
                for (let d of response.data.data) {
                    let dateData = new Date(d.date);

                    dateData.setHours(0, 0, 0, 0);
                    let dateWeeksMonday = getMonday(dateData);
                    let weekDifference = Math.round(
                        (dateWeeksMonday.getTime() -
                            thisWeeksMonday.getTime()) /
                            (1000 * 60 * 60 * 24) /
                            7
                    );
                    weeks[weekDifference].push(new Date(d.date));
                }
            }
            let now = new Date();
            now.setMinutes(now.getMinutes() + 5);
            let cacheData = {
                expiry: now.getTime(),
                data: weeks,
            };
            localStorage.setItem(
                `appointment${props.appointmentId}`,
                JSON.stringify(cacheData)
            );
            setAppointments(weeks);
        });

    const getAppointments = () => {
        const cachedData = localStorage.getItem(
            `appointment${props.appointmentId}`
        );
        if (cachedData && !props.noCache) {
            const data = JSON.parse(cachedData);
            if (new Date(data.expiry).getTime() > new Date().getTime()) {
                setAppointments(data.data);
            } else {
                localStorage.removeItem(`appointment${props.appointmentId}`);
                getAppointmentsFromBackend();
            }
        } else {
            getAppointmentsFromBackend();
        }
    };

    useEffect(() => {
        getAppointments();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSlideSwipe = (e) => {
        sliderRefs[activeWeek].current.closeWeek();
        if (e === "left") {
            setActiveWeek((prevState) => prevState + 1);
        } else if (e === "right") {
            setActiveWeek((prevState) => prevState - 1);
        }
        setSliderTransaction(true);
        setTimeout(() => setSliderTransaction(false), 600);
    };

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: () => {
            setSliderTransaction(true);
            setTimeout(() => setSliderTransaction(false), 600);
        },
        onSwipe: onSlideSwipe,
    };

    const slidePrev = () => {
        if (activeWeek > 0 && !sliderInTransaction) {
            sliderRefs[activeWeek].current.closeWeek();
            sliderRef.current.slickPrev();
            setActiveWeek((prevState) => prevState - 1);
        }
    };
    const slideNext = () => {
        if (
            activeWeek < Object.keys(appointments).length - 1 &&
            !sliderInTransaction
        ) {
            sliderRefs[activeWeek].current.closeWeek();
            sliderRef.current.slickNext();
            setActiveWeek((prevState) => prevState + 1);
        }
    };

    const renderWeekHeader = (offset) => {
        const weekStart = new Date(props.firstWeek);
        const weekEnd = new Date(props.firstWeek);
        weekStart.setDate(weekStart.getDate() + offset * 7);
        weekEnd.setDate(weekEnd.getDate() + offset * 7 + 6);
        if (!isMobile()) {
            return (
                MONTHS[weekStart.getMonth()] +
                " " +
                returnWithZero(weekStart.getDate()) +
                " - " +
                MONTHS[weekEnd.getMonth()] +
                " " +
                returnWithZero(weekEnd.getDate())
            );
        }

        return (
            weekStart.getFullYear() +
            " " +
            MONTHS[weekStart.getMonth()] +
            ", " +
            getWeekNumber(weekStart) +
            ". hét"
        );
    };

    const jumpToNextNotEmptyWeek = () => {
        for (let key in appointments) {
            if (appointments[key].length && key > activeWeek) {
                sliderRef.current.slickGoTo(key);
                setActiveWeek((prevState) => prevState + (key - prevState));

                return false;
            }
        }
        responseMessage(
            "A kiválasztott orvos jelenleg nem rendelkezik több szabad időponttal!"
        );
    };

    const onDateClick = (date) => {
        const hours = date.time.substr(0, 2);
        const minutes = date.time.substr(3, 2);
        const targetDate = getMonday();
        targetDate.setDate(
            targetDate.getDate() + date.weekOffset * 7 + date.dayIndex
        );
        targetDate.setHours(hours);
        targetDate.setMinutes(minutes);
        let urlParams;
        if (props.isVideo) {
            urlParams = `id=${props.appointmentId}&patientId=${
                props.selectedPatient.value
            }&patient=${props.selectedPatient.label}&patientHasCard=${
                props.patientHasCard
            }&selectedTime=${convertDate(targetDate, "-", true)}&doctor=${
                props.doctorName
            }&doctorId=${props.doctorId}&service=${props.service}&serviceId=${
                props.serviceId
            }&isVideo=${props.isVideo}&price=${props.price}&doctorImage=${
                props.profileImageUrl
            }`;
        } else {
            urlParams = `id=${props.appointmentId}&patientId=${
                props.selectedPatient.value
            }&patient=${props.selectedPatient.label}&patientHasCard=${
                props.patientHasCard
            }&selectedTime=${convertDate(targetDate, "-", true)}&doctor=${
                props.doctorName
            }&doctorId=${props.doctorId}&institution=${
                props.institution
            }&institutionAddress=${props.institutionAddress}&institutionId=${
                props.institutionId
            }&service=${props.service}&serviceId=${props.serviceId}&isVideo=${
                props.isVideo
            }&price=${props.price}&doctorImage=${props.profileImageUrl}`;
        }
        navigate(
            `${reservationConfig.summaryURL}?${encodeURIComponent(urlParams)}`
        );
    };

    const renderWeeks = () => {
        const start = new Date(props.firstWeek);
        const weeks = [];
        for (let key in appointments) {
            const currentRef = createRef(null);
            sliderRefs.push(currentRef);
            weeks.push(
                <Week
                    key={"weekKey" + key}
                    start={new Date(start)}
                    appointments={appointments[key]}
                    weekOffset={key}
                    active={activeWeek == key}
                    ref={currentRef}
                    jumpToNextNotEmptyWeek={jumpToNextNotEmptyWeek}
                    onDateClick={onDateClick}
                />
            );
            start.setDate(start.getDate() + 7);
        }

        return weeks;
    };

    return (
        <div className={cx("reservationCalendar")}>
            <div className={cx("header")}>
                <div className={classes.datas}>
                    <div className={classes.nameContainer}>
                        <div className={classes.nameDatas}>
                            <p className={classes.doctorName}>
                                {props.doctorName}
                            </p>
                            <p className={classes.data}>{props.service}</p>
                        </div>
                        <div className={classes.mobileImageContainer}>
                            <img
                                src={props.profileImageUrl}
                                className={classes.image}
                            />
                        </div>
                    </div>
                    <div className={classes.line}></div>
                    <p className={classes.dataHeader}>{props.institution}</p>
                    <p className={classes.data}>{props.institutionAddress}</p>
                    <div className={classes.line}></div>
                    <p className={classes.dataHeader}>Ár:</p>
                    <p className={classes.data}>
                        {formatNumber(props.price)} {props.currency || "Ft"}
                    </p>
                </div>
                <div className={classes.imageContainer}>
                    <img
                        src={props.profileImageUrl}
                        className={classes.image}
                    />
                </div>
            </div>
            <div className={cx("calendarContainer")}>
                <div className={classes.calendarOuter}>
                    <div className={classes.weekPicker}>
                        <div
                            className={classes.slideControl}
                            onClick={slidePrev}
                        >
                            <ChevronLeftIcon
                                classes={{ root: classes.slideIcon }}
                            />
                        </div>
                        <div className={classes.weekData}>
                            {renderWeekHeader(activeWeek)}
                        </div>
                        <div
                            className={classes.slideControl}
                            onClick={slideNext}
                        >
                            <ChevronRightIcon
                                classes={{ root: classes.slideIcon }}
                            />
                        </div>
                    </div>
                    <div className={classes.weeks}>
                        {appointments ? (
                            <Slider {...sliderSettings} ref={sliderRef}>
                                {renderWeeks()}
                            </Slider>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReservationCalendar;
