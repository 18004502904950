import { useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get } from "../../config/fetch";
import CSVUploader from "../../components/CSVUploader/CSVUploader";
import ImportSample from "../../assets/import_sample.csv";

const Users = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    const [isShowList, setIsShowList] = useState(false);
    const [isShowSearch, setIsShowSearch] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [checkedRows, setCheckedRows] = useState([]);

    const handleSelectAllChange = () => {
        if (selectAll) {
            setCheckedRows([]);
            setSelectAll(false);
        } else {
            setCheckedRows(users.map((user) => user.id));
            setSelectAll(true);
        }
    };

    const handleCheckboxChange = (id) => {
        if (checkedRows.includes(id)) {
            setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
        } else {
            setCheckedRows([...checkedRows, id]);
        }
    };

    let tableData = {
        columns: [
            {
                label: (
                    <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                ),
                field: "checkbox",
            },
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Vezetéknév",
                field: "last_name",
            },
            {
                label: "Keresztnév",
                field: "first_name",
            },
            {
                label: "Születési dátum",
                field: "birth_date",
            },
            {
                label: "Születési hely",
                field: "birth_place",
            },
            {
                label: "Anyja neve",
                field: "mothers_name",
            },
            {
                label: "Neme",
                field: "gender",
            },
            {
                label: "TAJ szám",
                field: "security_number",
            },
            {
                label: "Személyi ig. / Útlevél szám",
                field: "id_number",
            },
            {
                label: "Email",
                field: "email",
            },
            {
                label: "Telefonszám",
                field: "phone",
            },
            {
                label: "Regisztráció ideje",
                field: "created_at",
            },
            {
                label: "Alapértelmezett kártya ID",
                field: "default_card_id",
            },
            {
                label: "Előfizetés",
                field: "subscribed_package",
            },
            {
                label: "Munkáltató",
                field: "employer",
            },
            // {
            //   label: "Számlázási adatok",
            //   field: "billingInformations"
            // },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const fillTableData = (userData) => {
        tableData.rows = userData.map((user) => ({
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            birth_date: user.birth_date,
            birth_place: user.birth_place,
            mothers_name: user.mothers_name,
            gender: user.gender,
            security_number: user.security_number,
            id_number: user.id_number,
            email: user.email,
            billingInformations: (
                <div>
                    <p>{user.billingName}</p>
                    <p>{user.billingCountry}</p>
                    <p>{user.billingZip}</p>
                    <p>{user.billingSettlement}</p>
                    <p>
                        {user.billingStreet} {user.billingHouseNumber}
                    </p>
                    {user.billingFloorDoor ? (
                        <p>{user.billingFloorDoor}</p>
                    ) : null}
                    {user.billingTaxNumber ? (
                        <p>Adószám: {user.billingTaxNumber}</p>
                    ) : null}
                </div>
            ),
            created_at: user.created_at,
            default_card_id: user.default_card_id,
            subscribed_package: user.packageName,
            employer: user.employer,
            actions: <Link to={"/users/" + user.id}>Műveletek</Link>,
        }));
    };

    fillTableData(users);

    const exportUsers = () =>
        get("admin/export-users")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.open(response.data.link);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleLoadList = (search) => {
        const url = search ? `admin/users?search=${search}` : "admin/users";

        get(url)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const sortedUsers = response.data.users.sort(
                            (a, b) =>
                                new Date(b.created_at) - new Date(a.created_at)
                        );
                        setUsers(sortedUsers);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
                // console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleSearch = () => {
        const search = document.getElementById("search").value;
        handleLoadList(search);
        setIsShowSearch(false);
        setIsShowList(true);
    };

    const rowsWithCheckbox = tableData.rows.map((row) => ({
        ...row,
        checkbox: (
            <input
                type="checkbox"
                checked={checkedRows.includes(row.id)}
                onChange={() => handleCheckboxChange(row.id)}
            />
        ),
    }));

    return (
        <>
            {checkedRows && checkedRows.length > 0 && (
                <div className="container-fluid">
                    <Link
                        to="/users/add-nha-package-batch"
                        state={{ users: checkedRows }}
                        className="btn btn-primary"
                    >
                        Adatalapú csomag hozzáadása
                    </Link>
                    <Link
                        to="/users/add-extra-service-batch"
                        state={{ users: checkedRows }}
                        className="btn btn-primary"
                    >
                        Szolgáltatás hozzáadása
                    </Link>
                </div>
            )}
            <div className="container-fluid">
                {isShowSearch && (
                    <>
                        <h4>Felhasználó keresése</h4>
                        <div>
                            <Input id="search" style={{ width: "50%" }} />
                            <Button
                                className="btn btn-primary"
                                onClick={handleSearch}
                            >
                                Keresés
                            </Button>
                        </div>
                    </>
                )}
                {isShowList && (
                    <>
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">
                                        Felhasználók
                                    </h4>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="float-right">
                                    {(props.access.superAdmin == "1" ||
                                        props.access.callCenter == "1") && (
                                        <>
                                            <a
                                                href={ImportSample}
                                                target="_blank"
                                                style={{ marginRight: "1rem" }}
                                                rel="noreferrer"
                                            >
                                                Import minta letöltése
                                            </a>
                                            <CSVUploader
                                                style={{ marginRight: "1rem" }}
                                                onUpload={() => {
                                                    let currentPage =
                                                        location.pathname;
                                                    navigate({
                                                        pathname: "/empty",
                                                    });
                                                    navigate(
                                                        {
                                                            pathname:
                                                                currentPage,
                                                        },
                                                        { replace: true }
                                                    );
                                                }}
                                            />
                                            <button
                                                className="btn btn-primary"
                                                style={{ marginRight: "1rem" }}
                                                onClick={exportUsers}
                                            >
                                                Exportálás
                                            </button>
                                        </>
                                    )}
                                    <Link to={"/users/new"}>
                                        <button className="btn btn-primary">
                                            Új felhasználó
                                        </button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <MDBDataTable
                                            className="user-table"
                                            responsive
                                            striped
                                            noBottomColumns
                                            bordered
                                            data={{
                                                ...tableData,
                                                rows: rowsWithCheckbox,
                                            }}
                                            infoLabel={[
                                                "",
                                                "-",
                                                "a(z)",
                                                "elemből",
                                            ]}
                                            noRecordsFoundLabel="Nincs megjeleníthető elem"
                                            paginationLabel={[
                                                "Előző",
                                                "Következő",
                                            ]}
                                            searchLabel="Keresés"
                                            entriesLabel="Megjelenítendő elemek száma"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!isShowList && (
                    <Button
                        onClick={() => {
                            handleLoadList();
                            setIsShowSearch(false);
                            setIsShowList(true);
                        }}
                    >
                        Teljes lista betöltése
                    </Button>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    access: state.auth.access,
});

export default connect(mapStateToProps)(Users);
