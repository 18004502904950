import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import {
    ALERT_MESSAGES,
    PACKAGES,
    serviceNames,
} from "../../../config/variables";
import { convertDate, responseMessage } from "../../../utility";
import { getDate } from "../../../config/helpers";
import { endLoading, post } from "../../../config/fetch";
import NewUserCoupon from "./NewUserCoupon/NewUserCoupon";

const Coupons = (props) => {
    const [coupons, setCoupons] = useState(props.coupons);
    const [isOpen, setIsOpen] = useState(false);

    const { refreshCoupons } = props;

    const changeCouponValidation = (couponId, available) =>
        post("api/v1/coupon/change-user-coupon-validation", {
            couponId,
            available,
            userId: props.userId,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres módosítás");
                        let allCoupons = [...coupons];
                        let modifiedCoupon = coupons.find(
                            (c) => c.id === couponId
                        );
                        const index = coupons.indexOf(modifiedCoupon);
                        modifiedCoupon.available =
                            modifiedCoupon.available == "1" ? "0" : "1";
                        modifiedCoupon.invalidated_at =
                            modifiedCoupon.invalidated_at ? null : getDate();
                        allCoupons[index] = modifiedCoupon;
                        // console.log(modifiedCoupon);
                        // console.log(index);
                        setCoupons(allCoupons);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const userId = useParams().id ?? props.userId;

    const addUserCoupon = (coupon) => {
        let newCoupon = coupon;
        const oldCoupons = coupons ?? [];
        post("api/v1/coupon/create-user-coupon", {
            data: coupon,
            userId,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        setIsOpen(false);

                        newCoupon = {
                            ...newCoupon,
                            adminFirstName: response.data.adminFirstName,
                            adminLastName: response.data.adminLastName,
                            available: "1",
                            used: "0",
                            expires_at:
                                newCoupon.type == "1" || newCoupon.type == "4"
                                    ? response.data.expiresAt
                                    : newCoupon.expires_at,
                            created_at: convertDate(new Date(), "-", true),
                        };

                        if (newCoupon.type == "1") {
                            newCoupon = {
                                ...newCoupon,
                                used: "1",
                                used_at: convertDate(new Date(), "-", true),
                                available: "0",
                            };
                        }

                        setCoupons([newCoupon, ...oldCoupons]);
                        endLoading();
                        refreshCoupons();

                        break;
                    case "INVALID_PACKAGE":
                        endLoading();
                        responseMessage(
                            "Ez a kupon típus csak előfizetés nélkül adható!"
                        );

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    if (props.onlyAddCoupon) {
        return (
            <>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {isOpen ? "Mégsem" : "Kupon hozzáadása"}
                </button>
                {isOpen && (
                    <NewUserCoupon
                        onAddUserCoupon={(coupon) => addUserCoupon(coupon)}
                    />
                )}
            </>
        );
    }

    return (
        <Card>
            <CardBody>
                <h5>Kuponok</h5>
                <br></br>
                {coupons.map((c, index) => (
                    <Card
                        key={c.id + index}
                        style={{ backgroundColor: "#dadee1" }}
                    >
                        <CardBody>
                            <Row>
                                <Col sm={3}>
                                    <strong>{c.coupon_code}</strong>
                                </Col>
                                <Col sm={3}>
                                    <strong>
                                        {c.available == "1"
                                            ? "Elérhető"
                                            : "Nem elérhető"}
                                    </strong>
                                </Col>
                                <Col sm={3}>{c.typeName}</Col>
                                <Col sm={3}>
                                    Létrehozva: {c.adminLastName}{" "}
                                    {c.adminFirstName} - {c.created_at}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col sm={3}>
                                    Kedvezmény:{" "}
                                    {c.discount_amount
                                        ? `${c.discount_amount} Ft`
                                        : ""}
                                    {c.discount_interval_days
                                        ? `${c.discount_interval_days} nap`
                                        : ""}
                                    {c.discount_percentage
                                        ? `${c.discount_percentage} %`
                                        : ""}
                                </Col>
                                <Col sm={3}>
                                    Csomag:{" "}
                                    {PACKAGES[+c.package_id]
                                        ? PACKAGES[+c.package_id]
                                        : "-"}
                                </Col>
                                <Col sm={3}>
                                    Szolgáltatás:{" "}
                                    {serviceNames[c.service]
                                        ? serviceNames[c.service]
                                        : "-"}
                                </Col>
                                <Col>
                                    Min. kosárérték:{" "}
                                    {c.minimum_value ? c.minimum_value : "-"}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col sm={3}>
                                    Lejárat: {c.expires_at ? c.expires_at : "-"}
                                </Col>
                                <Col sm={3}>
                                    {c.used == "1"
                                        ? "Felhasználva"
                                        : "Nincs felhasználva"}
                                </Col>
                                <Col sm={3}>
                                    Utolsó felhasználás :{" "}
                                    {c.used_at ? c.used_at : "-"}
                                </Col>
                                <Col sm={3}>
                                    Inaktiválva:{" "}
                                    {c.invalidated_at ? c.invalidated_at : "-"}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col sm={6}>
                                    Publikus komment: {c.public_comment}
                                </Col>
                                <Col sm={4}>
                                    Privát komment: {c.private_comment}
                                </Col>
                                <Col sm={2}>
                                    {c.available == "1" ? (
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() =>
                                                changeCouponValidation(
                                                    c.id,
                                                    c.available
                                                )
                                            }
                                        >
                                            Inaktiválás
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                changeCouponValidation(
                                                    c.id,
                                                    c.available
                                                )
                                            }
                                        >
                                            Aktiválás
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                ))}
                <hr></hr>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {isOpen ? "Mégsem" : "Kupon hozzáadása"}
                </button>
                {isOpen && (
                    <NewUserCoupon
                        onAddUserCoupon={(coupon) => addUserCoupon(coupon)}
                    />
                )}
            </CardBody>
        </Card>
    );
};

export default Coupons;
