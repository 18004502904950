import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import BillingInfo from "../../components/AdminCards/UserDetails/BillingInfo/BillingInfo";
import PersonalDetails from "../../components/AdminCards/UserDetails/PersonalDetails/PersonalDetails";
import { endLoading, get, post } from "../../config/fetch";
import { convertDate, responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { getDate } from "../../config/helpers";
import * as actions from "../../store/ui/actions";
import Subscriptions from "../../components/AdminCards/UserDetails/Subscriptions/Subscriptions";
import ServicesAdded from "../../components/AdminCards/UserDetails/ServicesAdded/ServicesAdded";
import ServicesUsed from "../../components/AdminCards/UserDetails/ServicesUsed/ServicesUsed";
import Comments from "../../components/AdminCards/UserDetails/Comments/Comments";
import CasesActive from "../../components/AdminCards/UserDetails/CasesActive/CasesActive";
import CasesClosed from "../../components/AdminCards/UserDetails/CasesClosed/CasesClosed";
import Coupons from "../../components/AdminCards/UserDetails/Coupons/Coupons";
import PatientBrief from "../../components/CaseManager/PatientBrief/PatientBrief";

const UserDetails = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const emptyUserData = {
        lastName: null,
        firstName: null,
        gender: null,
        securityNumber: null,
        idNumber: null,
        email: null,
        phone: null,
        dateOfBirth: null,
        birthPlace: null,
        mothersName: null,
        subscription: null,
        defaultCardId: null,
        billingName: null,
        zipcode: null,
        country: null,
        settlement: null,
        street: null,
        houseNumber: null,
        floorDoor: null,
        taxNumber: null,
        comment: null,
        addressCountry: null,
        addressZip: null,
        addressSettlement: null,
        addressStreet: null,
        addressHouseNumber: null,
        addressFloorDoor: null,
    };

    const [userData, setUserData] = useState(emptyUserData);
    const [cards, setCards] = useState([]);
    const [comments, setComments] = useState([]);
    const [extraServices, setExtraServices] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [extraServiceData, setExtraServiceData] = useState({
        name: null,
        date: null,
        comment: null,
        quantity: null,
    });
    const [userEvents, setUserEvents] = useState(null);
    const [packageInfo, setPackageInfo] = useState(null);
    const [nhaSubscriptions, setNhaSubscriptions] = useState(null);
    const [coupons, setCoupons] = useState(null);
    const [cases, setCases] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [employers, setEmployers] = useState(null);

    const setUserDataByKey = (key, value) =>
        setUserData({ ...userData, [key]: value });

    const setExtraServiceDataByKey = (key, value) =>
        setExtraServiceData({ ...extraServiceData, [key]: value });

    const filterCasesByStatus = (status) =>
        cases.filter((c) => c.closed == status);

    const dateChangeHandler = (date, key, type) => {
        if (type === "user") {
            setUserDataByKey(key, convertDate(date));
        }
        if (type === "service") {
            setExtraServiceDataByKey(key, convertDate(date));
        }
    };

    useEffect(() => {
        get(`admin/user?id=${id}`)
            .then((response) => {
                const userData = response.data.user;
                const fetchedUser = {
                    lastName: userData.last_name,
                    firstName: userData.first_name,
                    gender: userData.gender ? userData.gender : "Férfi",
                    employer: userData.employerId ?? null,
                    employerName: userData.employerName,
                    securityNumber: userData.security_number,
                    idNumber: userData.id_number,
                    email: userData.email,
                    phone: userData.phone,
                    dateOfBirth: userData.birth_date,
                    birthPlace: userData.birth_place,
                    mothersName: userData.mothers_name,
                    subscription: userData.subscribed_package,
                    defaultCardId: userData.default_card_id,
                    billingName: userData.billingName,
                    zipcode: userData.billingZip,
                    country: userData.billingCountry,
                    settlement: userData.billingSettlement,
                    street: userData.billingStreet,
                    houseNumber: userData.billingHouseNumber,
                    floorDoor: userData.billingFloorDoor,
                    taxNumber: userData.billingTaxNumber,
                    addressCountry: userData.country,
                    addressZip: userData.zip,
                    addressSettlement: userData.settlement,
                    addressStreet: userData.street,
                    addressHouseNumber: userData.house_number,
                    addressFloorDoor: userData.floor_door,
                };
                setUserData(fetchedUser);
                setCards(response.data.paymentData);
                setComments(response.data.comments);
                setNhaSubscriptions(response.data.nhaSubscriptions);
                setExtraServices(response.data.extraServices);
                setUserEvents(response.data.events);
                setCoupons(response.data.coupons);
                setCases(response.data.cases);
                setDoctors(response.data.doctors);
                setEmployers(response.data.employers);

                const packageInfos = {
                    id: +userData.subscribed_package,
                    name: userData.packageName,
                    freeChatPerMonth: +userData.free_chat_per_month,
                    freeDutyPerMonth: +userData.free_duty_per_month,
                    paymentNextDate: userData.payment_next_date,
                    nextCharge: userData.next_charge,
                    nextMonthPackage: userData.next_month_package,
                };
                setPackageInfo(packageInfos);
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setCardToDefault = (cardId) =>
        post("admin/change-default-card", { cardId, userId: id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setUserDataByKey("defaultCardId", cardId);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleSubmit = () => {
        const userDatas = {
            lastName: userData.lastName,
            firstName: userData.firstName,
            gender: userData.gender,
            securityNumber: userData.securityNumber,
            idNumber: userData.idNumber,
            employer: userData.employer,
            email: userData.email,
            phone: userData.phone,
            dateOfBirth: userData.dateOfBirth,
            birthPlace: userData.birthPlace,
            mothersName: userData.mothersName,
            // subscription: userData.subscription,
            defaultCardId: userData.defaultCardId,
        };

        const billingData = {
            billingName: userData.billingName,
            zipcode: userData.zipcode,
            country: userData.country,
            settlement: userData.settlement,
            street: userData.street,
            houseNumber: userData.houseNumber,
            floorDoor: userData.floorDoor,
            taxNumber: userData.taxNumber,
        };

        const addressData = {
            country: userData.addressCountry,
            zip: userData.addressZip,
            settlement: userData.addressSettlement,
            street: userData.addressStreet,
            houseNumber: userData.addressHouseNumber,
            floorDoor: userData.addressFloorDoor,
        };

        post("admin/update-user", {
            id,
            userData: userDatas,
            billingData,
            addressData,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        endLoading();

                        break;
                    case "EMAIL_OR_PHONE_TAKEN":
                        endLoading();
                        responseMessage(
                            "Az email cím vagy telefonszám foglalt!"
                        );

                        break;
                    case "INVALID_SECURITY_NUMBER":
                        endLoading();
                        responseMessage("A megadott TAJ szám hibás!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const deleteUser = () =>
        post("admin/delete-user", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/users");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törli a felhasználót? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteUser,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleSubscriptionChange = (data) =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan megváltoztatja a felhasználó előfizetését?",
            confirmButton: true,
            confirmButtonText: "Igen",
            confirmButtonCallback: () => changeSubscription(data),
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const changeSubscription = (data) =>
        post("admin/change-user-subscription", { id, data })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres váltás!");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const createComment = (user_id) => {
        if (!newComment) {
            return;
        }
        post("admin/create-comment", { user_id, comment: newComment })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        endLoading();

                        const createdComment = {
                            id: response.data.newCommentId,
                            user_id,
                            first_name: response.data.adminFirstName,
                            last_name: response.data.adminLastName,
                            created_at: getDate(),
                            comment: newComment,
                        };
                        setComments([createdComment, ...comments]);
                        setNewComment("");

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleAddExtraService = () => {
        const service = extraServiceData.name;
        const date = extraServiceData.date;
        const comment = extraServiceData.comment;
        const quantity = extraServiceData.quantity;

        if (extraServiceData.name === null) {
            responseMessage("Válasszon szolgáltatást!");

            return;
        }
        post("admin/add-extra-service", {
            id,
            service,
            date,
            comment,
            quantity,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres hozzáadás!");
                        const createdExtraService = {
                            id: response.data.id,
                            service_name: extraServiceData.name,
                            created_at: getDate(),
                            comment: extraServiceData.comment,
                            expires_at: extraServiceData.date,
                            first_name: response.data.adminFirstName,
                            last_name: response.data.adminLastName,
                            quantity: extraServiceData.quantity,
                            available: "1",
                        };
                        setExtraServices([
                            createdExtraService,
                            ...extraServices,
                        ]);
                        setExtraServiceData({
                            name: "",
                            date: null,
                            comment: "",
                            quantity: null,
                        });

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleDeleteExtraService = (id) => {
        post("admin/delete-extra-service", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setExtraServices(
                            extraServices.filter((service) => service.id !== id)
                        );
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const propsBus = {
        props,
        userId: id,
        userData,
        cards,
        comments,
        extraServices,
        newComment,
        extraServiceData,
        userEvents,
        packageInfo,
        nhaSubscriptions,
        coupons,
        cases,
        doctors,
        employers,
        setNewComment,
        setUserDataByKey,
        setExtraServiceDataByKey,
        filterCasesByStatus,
        dateChangeHandler,
        setCardToDefault,
        handleSubmit,
        handleDelete,
        handleSubscriptionChange,
        createComment,
        handleAddExtraService,
        handleDeleteExtraService,
    };

    const refreshCoupons = () => {
        get(`admin/user?id=${id}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setCoupons(response.data.coupons);
                        endLoading();
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Felhasználói adatok
                            </h4>
                        </div>
                    </Col>
                </Row>
                <PatientBrief userId={id} />

                {Object.values(userData).every((value) => value === null) ? (
                    <></>
                ) : (
                    <>
                        <PersonalDetails propsBus={propsBus} />
                        <BillingInfo propsBus={propsBus} />
                        <Coupons
                            propsBus={propsBus}
                            refreshCoupons={refreshCoupons}
                        />
                        <Subscriptions propsBus={propsBus} />
                        <ServicesAdded propsBus={propsBus} />
                        <ServicesUsed propsBus={propsBus} />
                        <Comments propsBus={propsBus} />
                        <CasesActive propsBus={propsBus} />
                        <CasesClosed propsBus={propsBus} />
                    </>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
