import type { ComponentProps } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import {
    Controller,
    dateToLocalInputString,
    localInputToIsoString,
    useField,
} from "tdoc-common";

type Props = Omit<ComponentProps<typeof Input>, "type" | "pattern"> & {
    label?: string;
    name: string;
};

const FormDateTimeInput = (props: Props) => {
    const { control, isError, error } = useField(props.name);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{ required: props.required }}
            render={({ field }) => (
                <FormGroup>
                    {props.label && <Label>{props.label}</Label>}
                    <Input
                        type="datetime-local"
                        pattern="\d{4}-\d{2}-\d{2} \d{2}:\d{2}"
                        {...field}
                        value={
                            field.value
                                ? dateToLocalInputString(new Date(field.value))
                                : ""
                        }
                        onChange={(event) =>
                            field.onChange(
                                localInputToIsoString(
                                    event.target.value
                                ).toISOString()
                            )
                        }
                        invalid={isError}
                        {...props}
                    />
                    {error && <FormFeedback>{error}</FormFeedback>}
                </FormGroup>
            )}
        />
    );
};

export default FormDateTimeInput;
