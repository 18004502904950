import { ApiFormWrapper } from "tdoc-common";
import { Form } from "reactstrap";
import type { NextHealthSubscription } from "./model";
import { useUpdateSubscriptionMutation } from "./api";
import CaseNhaSubscriptionFormInner from "./CaseNhaSubscriptionFormInner";

interface Props {
    userId: number;
    subscription: NextHealthSubscription;
    onSuccess: () => void;
}

const CaseNhaSubscriptionEditForm = ({ subscription, onSuccess }: Props) => {
    const [update] = useUpdateSubscriptionMutation();

    return (
        <ApiFormWrapper
            defaultValues={subscription}
            mutation={update}
            onSuccess={onSuccess}
        >
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <CaseNhaSubscriptionFormInner />
                </Form>
            )}
        </ApiFormWrapper>
    );
};

export default CaseNhaSubscriptionEditForm;
