import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import { getEnv } from "tdoc-common";
import store from "../store/index";

let socket: Socket;

const connectSocket = () => {
    const token = store.getState().auth.token;
    if (!token) {
        return;
    }

    socket = io(`https://${getEnv("HOSTNAME_SOCKET")}`, {
        auth: { token },
        query: { isAdmin: true },
    });
};

export const getSocket = () => {
    if (!socket) {
        connectSocket();
    }

    return socket;
};
