import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get, post } from "../../config/fetch";
import * as actions from "../../store/ui/actions";

const Reservations = (props) => {
    const [reservations, setReservations] = useState([]);

    useEffect(() => {
        get("admin/reservations")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        // console.log(response.data.reservations);
                        setReservations(response.data.reservations);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    let tableData = {
        columns: [
            // {
            //   label: "ID",
            //   field: "id",
            // },
            {
                label: "Foglalás ID",
                field: "reservationId",
            },
            {
                label: "Név",
                field: "name",
            },
            {
                label: "Születési idő",
                field: "birthDate",
            },
            {
                label: "Telefon",
                field: "phone",
            },
            {
                label: "Időpont",
                field: "reservationTime",
            },
            // {
            //   label: "Típus",
            //   field: "type",
            // },
            {
                label: "Intézmény",
                field: "institution",
            },
            {
                label: "Vizsgálat",
                field: "specialization",
            },
            {
                label: "Orvos",
                field: "doctor",
            },
            {
                label: "Fizetett (Ft)",
                field: "payed",
            },
            {
                label: "Foglalva",
                field: "createdAt",
            },
            {
                label: "Lemondva",
                field: "deleted",
            },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const deleteReservation = (reservationId, userId) =>
        post("api/v1/reservation/admin-delete-reservation", {
            id: reservationId,
            userId,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        responseMessage("Sikeres lemondás!");
                        window.location.reload();

                        break;
                    case "ALREADY_DELETED":
                        endLoading();
                        responseMessage("Már lemondva!");

                        break;
                    case "ADMIN_DELETE_OVER_DEADLINE":
                        endLoading();
                        responseMessage("Sikeres lemondás, határidőn túl!");
                        window.location.reload();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleDelete = (reservationId, userId) =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan lemondja a foglalást? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Lemondás",
            confirmButtonCallback: () =>
                deleteReservation(reservationId, userId),
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const fillTableData = (reservationData) => {
        const modifiedData = reservationData.map((reservation) => ({
            id: reservation.id,
            name: `${reservation.last_name} ${reservation.first_name}`,
            birthDate: reservation.birth_date,
            phone: reservation.phone,
            reservationId: reservation.medio_reservation_id,
            reservationTime: reservation.reservation_time,
            type: reservation.type,
            institution: reservation.medio_institution_name,
            specialization: reservation.medio_specialization_name,
            doctor: reservation.medio_doctor_name,
            payed: reservation.payed_amount,
            createdAt: reservation.created_at,
            deleted: reservation.deleted == "1" ? "Igen" : "Nem",
            actions:
                reservation.deleted == "0" ? (
                    <Button
                        onClick={() =>
                            handleDelete(reservation.id, reservation.user_id)
                        }
                    >
                        Lemondás
                    </Button>
                ) : null,
        }));
        if (props.access.superAdmin != "1" && props.access.callCenter != "1") {
            tableData.columns = tableData.columns.filter(
                (el) => el.field !== "actions"
            );
        }
        tableData.rows = modifiedData;
    };
    fillTableData(reservations);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Időpontfoglalás</h4>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="float-right">
                            <Link to={"/reservation"}>
                                <button className="btn btn-primary">
                                    Új időpontfoglalás
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="reservation-table"
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
