import type { ComponentProps } from "react";
import { Button } from "reactstrap";
import { useFormContext } from "tdoc-common";

type Props = ComponentProps<typeof Button>;

const FormSubmitButton = ({ children, disabled, ...props }: Props) => {
    const {
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Button
            {...props}
            type="submit"
            disabled={isSubmitting || disabled}
            color="primary"
        >
            {children}
        </Button>
    );
};

export default FormSubmitButton;
