import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import { connect } from "react-redux";
import FileUploader from "../../components/FileUploader/FileUploader";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES, LANGUAGES } from "../../config/variables";
import TextEditor from "../../components/TextEditor/TextEditor";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import * as actions from "../../store/ui/actions";

let originalQualifications = [];
let originalInstitutions = [];
let originalLanguages = [];

const DoctorDetails = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [qualifications, setQualifications] = useState([]);
    const [institutions, setInstitutions] = useState([]);

    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [imageURL, setImageURL] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [introduction, setIntroduction] = useState(null);
    const [profileLink, setProfileLink] = useState("");

    const [doctorQualifications, setDoctorQualifications] = useState([]);
    const [doctorLanguages, setDoctorLanguages] = useState([]);
    const [doctorInstitutions, setDoctorInstitutions] = useState([]);

    const [hidden, setHidden] = useState(false);
    const [frontendHidden, setFrontendHidden] = useState(false);
    const [participateAppointment, setParticipateAppointment] = useState(null);
    const [participateChat, setParticipateChat] = useState(null);
    const [participateSecondOpinion, setParticipateSecondOpinion] =
        useState(null);
    const [participateDuty, setParticipateDuty] = useState(null);
    const [participateVideoConsultation, setParticipateVideoConsultation] =
        useState(null);
    const [participateConsilium, setParticipateConsilium] = useState(null);
    const [participatePrescription, setParticipatePrescription] =
        useState(null);
    const [medioId, setMedioId] = useState("");

    useEffect(() => {
        get(`admin/doctor?id=${id}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const fetchedDoctor = response.data.doctor;
                        setTitle(fetchedDoctor.title);
                        setFirstName(fetchedDoctor.first_name);
                        setLastName(fetchedDoctor.last_name);
                        setEmail(fetchedDoctor.email);
                        setImageURL(fetchedDoctor.image);
                        setIntroduction(fetchedDoctor.introduction);
                        setProfileLink(fetchedDoctor.profile_link);

                        setDoctorQualifications(response.data.qualifications);
                        setDoctorInstitutions(response.data.institutions);
                        setDoctorLanguages(response.data.languages);
                        originalQualifications = response.data.qualifications;
                        originalInstitutions = response.data.institutions;
                        originalLanguages = response.data.languages;

                        setHidden(fetchedDoctor.hidden == "1");
                        setParticipateAppointment(
                            fetchedDoctor.participate_appointment == "1"
                        );
                        setParticipateChat(
                            fetchedDoctor.participate_chat == "1"
                        );
                        setParticipateSecondOpinion(
                            fetchedDoctor.participate_second_opinion == "1"
                        );
                        setParticipateDuty(
                            fetchedDoctor.participate_duty == "1"
                        );
                        setParticipateVideoConsultation(
                            fetchedDoctor.participate_video_consultation == "1"
                        );
                        setParticipateConsilium(
                            fetchedDoctor.participate_consilium == "1"
                        );
                        setParticipatePrescription(
                            fetchedDoctor.participate_prescription == "1"
                        );
                        setMedioId(fetchedDoctor.medio_id);

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        get("admin/get-qualifications-institutions")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        setQualifications(response.data.qualifications);
                        setInstitutions(response.data.institutions);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("id", id);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("title", title);
        formData.append("email", email);
        formData.append("introduction", introduction);
        formData.append("profileLink", profileLink);

        if (
            JSON.stringify(originalQualifications) !==
            JSON.stringify(doctorQualifications)
        ) {
            formData.append(
                "qualifications",
                JSON.stringify(doctorQualifications)
            );
        }
        if (
            JSON.stringify(originalInstitutions) !==
            JSON.stringify(doctorInstitutions)
        ) {
            formData.append("institutions", JSON.stringify(doctorInstitutions));
        }
        if (
            JSON.stringify(originalLanguages) !==
            JSON.stringify(doctorLanguages)
        ) {
            formData.append("languages", JSON.stringify(doctorLanguages));
        }

        post("admin/update-doctor", formData)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        endLoading();

                        break;
                    case "EMAIL_OR_PHONE_TAKEN":
                        endLoading();
                        responseMessage(
                            "Az email cím vagy telefonszám foglalt!"
                        );

                        break;
                    case "PROFILE_LINK_TAKEN":
                        endLoading();
                        responseMessage("A profil link foglalt!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleUpdate = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan menti a változásokat?",
            confirmButton: true,
            confirmButtonText: "Igen",
            confirmButtonCallback: handleSubmit,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const deleteDoctor = () =>
        post("admin/delete-doctor", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/doctors");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan törli az orvost? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteDoctor,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleParticipations = () => {
        const data = {
            hidden: hidden ? "1" : "0",
            frontendHidden: frontendHidden ? "1" : "0",
            appointment: participateAppointment ? "1" : "0",
            chat: participateChat ? "1" : "0",
            secondOpinion: participateSecondOpinion ? "1" : "0",
            duty: participateDuty ? "1" : "0",
            videoConsultation: participateVideoConsultation ? "1" : "0",
            consilium: participateConsilium ? "1" : "0",
            prescription: participatePrescription ? "1" : "0",
            medioId,
        };

        post("admin/update-doctor-participations", { doctorId: id, data })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    const handleEditorChange = (content) => setIntroduction(content);

    const handleSelectChange = (e, type) => {
        let functionToUse;
        switch (type) {
            case "qualifications":
                functionToUse = setDoctorQualifications;

                break;
            case "languages":
                functionToUse = setDoctorLanguages;

                break;
            case "institutions":
                functionToUse = setDoctorInstitutions;

                break;
            default:
                break;
        }
        if (e === null) {
            functionToUse([]);

            return;
        }
        const modified = e.map((q) => ({ id: q.value, name: q.label }));
        functionToUse(modified);
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Orvos adatok</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="user-form">
                                <AvForm onValidSubmit={handleUpdate}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="title"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Titulus
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="title"
                                                errorMessage="Adja meg az orvos titulusát!"
                                                className="form-control"
                                                type="text"
                                                value={title}
                                                onChange={(event) =>
                                                    setTitle(event.target.value)
                                                }
                                                id="title"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="lastName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Vezetéknév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="lastName"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az orvos utónevév!"
                                                className="form-control"
                                                type="text"
                                                value={lastName}
                                                onChange={(event) =>
                                                    setLastName(
                                                        event.target.value
                                                    )
                                                }
                                                id="lastName"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="firstName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Keresztnév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="firstName"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az orvos keresztnevét!"
                                                className="form-control"
                                                type="text"
                                                value={firstName}
                                                onChange={(event) =>
                                                    setFirstName(
                                                        event.target.value
                                                    )
                                                }
                                                id="firstName"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="qualification"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Szakterületek
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                value={doctorQualifications.map(
                                                    (q) => ({
                                                        value: q.id,
                                                        label: q.name,
                                                    })
                                                )}
                                                isMulti={true}
                                                placeholder="Válasszon szakterületet..."
                                                options={qualifications.map(
                                                    (q) => ({
                                                        value: q.id,
                                                        label: q.name,
                                                    })
                                                )}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "qualifications"
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="institutions"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Intézmények
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                value={doctorInstitutions.map(
                                                    (i) => ({
                                                        value: i.id,
                                                        label: i.name,
                                                    })
                                                )}
                                                isMulti={true}
                                                placeholder="Válasszon intézményt..."
                                                options={institutions.map(
                                                    (i) => ({
                                                        value: i.id,
                                                        label: i.name,
                                                    })
                                                )}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "institutions"
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="languages"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Beszélt nyelvek
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                value={doctorLanguages.map(
                                                    (l) => ({
                                                        value: l.id,
                                                        label: l.name,
                                                    })
                                                )}
                                                isMulti={true}
                                                placeholder="Válasszon nyelvet..."
                                                options={LANGUAGES}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "languages"
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="email"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="email"
                                                validate={{
                                                    required: { value: false },
                                                    email: { value: false },
                                                }}
                                                errorMessage="Adjon meg valós email címet!"
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                                id="email"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="profileLink"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Profil link
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="profileLink"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a profil linket!"
                                                className="form-control"
                                                type="text"
                                                value={profileLink}
                                                onChange={(event) =>
                                                    setProfileLink(
                                                        event.target.value
                                                    )
                                                }
                                                id="profileLink"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="image"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kép
                                        </label>
                                        <Col sm={10}>
                                            <FileUploader
                                                handleFile={handleFile}
                                            />
                                            {imageURL ? (
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                    onClick={cancelImageUpload}
                                                >
                                                    Kép törlése
                                                </button>
                                            ) : null}
                                            {imageURL ? (
                                                <div className="image-preview">
                                                    <img
                                                        className="image"
                                                        src={imageURL}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    {introduction !== null ? (
                                        <Row className="form-group">
                                            <label
                                                htmlFor="introduction"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Bemutatkozás
                                            </label>
                                            <Col sm={10}>
                                                <TextEditor
                                                    value={introduction}
                                                    onEditorChange={
                                                        handleEditorChange
                                                    }
                                                ></TextEditor>
                                            </Col>
                                        </Row>
                                    ) : null}
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger float-right"
                                        onClick={handleDelete}
                                    >
                                        Orvos törlése
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="doctor-admin-form">
                                <h5>Admin beállítások</h5>
                                <br></br>
                                <AvForm onValidSubmit={handleParticipations}>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Rejtett
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_appointment">
                                                <AvCheckbox
                                                    checked={hidden}
                                                    onChange={(event) =>
                                                        setHidden(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Frontend rejtett
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_appointment">
                                                <AvCheckbox
                                                    checked={frontendHidden}
                                                    onChange={(event) =>
                                                        setFrontendHidden(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Időpontfoglalás
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_appointment">
                                                <AvCheckbox
                                                    checked={
                                                        participateAppointment
                                                    }
                                                    onChange={(event) =>
                                                        setParticipateAppointment(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">Chat</label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_chat">
                                                <AvCheckbox
                                                    checked={participateChat}
                                                    onChange={(event) =>
                                                        setParticipateChat(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Másodvélemény
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_second_opinion">
                                                <AvCheckbox
                                                    checked={
                                                        participateSecondOpinion
                                                    }
                                                    onChange={(event) =>
                                                        setParticipateSecondOpinion(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Ügyelet
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_duty">
                                                <AvCheckbox
                                                    checked={participateDuty}
                                                    onChange={(event) =>
                                                        setParticipateDuty(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Videó konzultáció
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_video_consultation">
                                                <AvCheckbox
                                                    checked={
                                                        participateVideoConsultation
                                                    }
                                                    onChange={(event) =>
                                                        setParticipateVideoConsultation(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Konzílium
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_consilium">
                                                <AvCheckbox
                                                    checked={
                                                        participateConsilium
                                                    }
                                                    onChange={(event) =>
                                                        setParticipateConsilium(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            E-recept
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="participate_prescription">
                                                <AvCheckbox
                                                    checked={
                                                        participatePrescription
                                                    }
                                                    onChange={(event) =>
                                                        setParticipatePrescription(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="medioId"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Medio Id
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="medioId"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                className="form-control"
                                                type="text"
                                                value={medioId}
                                                onChange={(event) =>
                                                    setMedioId(
                                                        event.target.value
                                                    )
                                                }
                                                id="medioId"
                                            />
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Módosítás
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(DoctorDetails);
