import { Fragment, useRef, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { getDate } from "../../../config/helpers";
import classes from "./PatientCard.module.css";

const PatientCard = (props) => {
    const userId = props.data.id;

    const [recommendation, setRecommendation] = useState(false);
    const [service, setService] = useState("");
    const [showComments, setShowComments] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState(null);

    const [selectedQualification, setSelectedQualification] = useState(null);
    const [selectedSymptom, setSelectedSymptom] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedSpecialization, setSelectedSpecialization] = useState(null);

    const [selectedStatus, setSelectedStatus] = useState(props.status);

    const select1 = useRef();
    const select2 = useRef();
    const select3 = useRef();

    const onChangeService = (service) => {
        setService(service);
        setSelectedQualification(null);
        setSelectedSymptom(null);
        setSelectedDoctor(null);
        setSelectedSpecialization(null);
        if (select1.current && select2.current && select3.current) {
            select1.current.state.value = "";
            select2.current.state.value = "";
            select3.current.state.value = "";
        }
    };

    const createComment = () =>
        post("admin/create-comment", { user_id: userId, comment: newComment })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        endLoading();

                        const createdComment = {
                            id: response.data.newCommentId,
                            user_id: userId,
                            last_name: response.data.adminLastName,
                            first_name: response.data.adminFirstName,
                            created_at: getDate(),
                            comment: newComment,
                        };

                        setComments([createdComment, ...props.data.comments]);

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const onSendServiceData = () =>
        post("admin/recommend-service", {
            userId,
            service,
            qualification: selectedQualification
                ? selectedQualification.qualification
                : null,
            qualificationId: selectedQualification
                ? selectedQualification.qualification_id
                : null,
            specialization: selectedSpecialization
                ? selectedSpecialization.specialization
                : null,
            specializationId: selectedSpecialization
                ? selectedSpecialization.specialization_id
                : null,
            symptom: selectedSymptom ? selectedSymptom.symptom : null,
            symptomId: selectedSymptom ? selectedSymptom.symptom_id : null,
            doctor: selectedDoctor ? selectedDoctor.doctor : null,
            doctorId: selectedDoctor ? selectedDoctor.doctor_id : null,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Ajánlott szolgáltatás elküldve!");
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleStatusChange = (status) =>
        post("admin/patient-status-change", { userId, status })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        setSelectedStatus(status);
                        props.onStatusChange(status, userId);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const commentEl = (index, comment) => (
        <Fragment key={index}>
            <Col sm={4}>
                <p
                    className={classes.text}
                >{`${comment.last_name} ${comment.first_name}`}</p>
            </Col>
            <Col sm={4}>
                <p className={classes.text}>{comment.created_at}</p>
            </Col>
            <Col sm={4} style={{ marginBottom: "1rem" }}>
                <p className={classes.text}>{comment.comment}</p>
            </Col>
        </Fragment>
    );

    return (
        <div
            className={classes.main}
            style={{
                border: selectedStatus
                    ? selectedStatus == "1"
                        ? "2px solid #FFD943"
                        : "2px solid #1FBA9C"
                    : props.status == "1"
                    ? "2px solid #FFD943"
                    : "2px solid #1FBA9C",
            }}
        >
            <Row>
                <Col sm={4}>
                    <p className={classes.text}>
                        <strong>Név:</strong>
                        {` ${props.data.last_name} ${props.data.first_name}`}
                    </p>
                </Col>
                <Col sm={4}>
                    <p className={classes.text}>
                        <strong>TAJ/Személyi ig. szám:</strong>
                        {props.data.security_number
                            ? ` ${props.data.security_number}`
                            : ` ${props.data.id_number}`}
                    </p>
                </Col>
                <Col sm={4}>
                    <p className={classes.text}>
                        <strong>Születési dátum:</strong>
                        {` ${props.data.birth_date}`}
                    </p>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col sm={4}>
                    <p className={classes.text}>
                        <strong>Műveletek:</strong>
                    </p>
                </Col>
                <Col sm={4}>
                    <Link to={"/users/" + userId}>Páciens adatlap</Link>
                </Col>
                <Col sm={4}>
                    <Link
                        to={"#"}
                        onClick={() => {
                            setService("");
                            setRecommendation((prev) => !prev);
                        }}
                    >
                        Szolgáltatás ajánlás
                    </Link>
                </Col>
            </Row>
            {recommendation ? (
                <div>
                    <hr></hr>
                    <Row>
                        <Col sm={4}>
                            <p className={classes.text}>
                                <strong>Válasszon szolgáltatást!</strong>
                            </p>
                        </Col>
                        <Col sm={4}>
                            <FormGroup style={{ marginBottom: "0.5rem" }}>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    onChange={(e) =>
                                        onChangeService(e.target.value)
                                    }
                                >
                                    <option value="" selected disabled>
                                        Válasszon szolgáltatást...
                                    </option>
                                    <option value="HOTLINE">
                                        Hotline hívása
                                    </option>
                                    <option value="DUTY">Ügyelet hívása</option>
                                    <option value="CHAT">Üzenetváltás</option>
                                    <option value="SECOND_OPINION">
                                        Másodvélemény
                                    </option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {service === "HOTLINE" ? (
                        <div>
                            <Row>
                                <Col>
                                    <Button
                                        color="primary"
                                        onClick={onSendServiceData}
                                    >
                                        Küldés
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => setRecommendation(false)}
                                    >
                                        Vissza
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {service === "DUTY" ? (
                        <div>
                            <Row>
                                <Col>
                                    <Button
                                        color="primary"
                                        onClick={onSendServiceData}
                                    >
                                        Küldés
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => setRecommendation(false)}
                                    >
                                        Vissza
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {service === "CHAT" || service === "SECOND_OPINION" ? (
                        <div>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Select
                                            ref={select1}
                                            placeholder="Válasszon szakterületet..."
                                            noOptionsMessage={() =>
                                                "Nincs találat"
                                            }
                                            options={props.qualifications.map(
                                                (q) => ({
                                                    value: q.id,
                                                    label: q.name,
                                                })
                                            )}
                                            onChange={(e) =>
                                                setSelectedQualification({
                                                    qualification_id: e.value,
                                                    qualification: e.label,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Select
                                            ref={select2}
                                            placeholder="Válasszon tünetet..."
                                            noOptionsMessage={() =>
                                                "Nincs találat"
                                            }
                                            options={props.symptoms.map(
                                                (s) => ({
                                                    value: s.id,
                                                    label: s.name,
                                                })
                                            )}
                                            onChange={(e) =>
                                                setSelectedSymptom({
                                                    symptom_id: e.value,
                                                    symptom: e.label,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Select
                                            ref={select3}
                                            placeholder="Válasszon orvost..."
                                            noOptionsMessage={() =>
                                                "Nincs találat"
                                            }
                                            options={props.doctors.map((d) => ({
                                                value: d.doctor_id,
                                                label: `${d.last_name} ${d.first_name}`,
                                            }))}
                                            onChange={(e) =>
                                                setSelectedDoctor({
                                                    doctor_id: e.value,
                                                    doctor: e.label,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Button
                                        color="primary"
                                        onClick={onSendServiceData}
                                    >
                                        Küldés
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => setRecommendation(false)}
                                    >
                                        Vissza
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                </div>
            ) : null}
            <hr></hr>
            <Row>
                <Col sm={4}>
                    <Link
                        to={"#"}
                        onClick={() => setShowComments((prev) => !prev)}
                    >
                        Kommentek
                    </Link>
                </Col>
                <Col sm={4}>
                    <Link to={`/reservation?type=both&patient=${userId}`}>
                        <button className="btn btn-primary">
                            Időpontfoglalás
                        </button>
                    </Link>
                </Col>
                <Col sm={4}>
                    <FormGroup style={{ marginBottom: "0.5rem" }}>
                        <Input
                            type="select"
                            name="select"
                            onChange={(e) => handleStatusChange(e.target.value)}
                            value={selectedStatus}
                        >
                            <option value="1">Van vele feladat</option>
                            <option value="0">Kész</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            {showComments ? (
                <>
                    <br></br>
                    {comments === null && props.data.comments && (
                        <Row>
                            {props.data.comments.map((comment, index) =>
                                commentEl(index, comment)
                            )}
                        </Row>
                    )}
                    {comments !== null && (
                        <Row>
                            {comments.map((comment, index) =>
                                commentEl(index, comment)
                            )}
                        </Row>
                    )}
                    {!props.data.comments.length ||
                        (!comments.length && (
                            <Row>
                                <Col>
                                    <p
                                        className={classes.text}
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        Nincs megjeleníthető komment.
                                    </p>
                                </Col>
                            </Row>
                        ))}

                    <Row>
                        <Col sm={2}>
                            <p className={classes.text}>
                                <strong>Komment írása:</strong>
                            </p>
                        </Col>
                        <Col sm={8}>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    value={newComment}
                                    onChange={(e) =>
                                        setNewComment(e.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Button
                                color="primary"
                                onClick={() => createComment()}
                            >
                                Küldés
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </div>
    );
};

export default PatientCard;
