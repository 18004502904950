import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { endLoading, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { logout } from "../../store/custom-auth/actions";

const Profile = (props) => {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [againPassword, setAgainPassword] = useState("");

    const handleSubmit = () => {
        // console.log(newPassword);
        // console.log(againPassword);
        if (newPassword !== againPassword) {
            responseMessage("A két jelszó nem egyezik!");
        } else {
            post("admin/change-password", {
                id: props.id,
                oldPassword,
                newPassword,
            })
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            endLoading();
                            responseMessage(
                                "Sikeres jelszó változtatás. Jelentkezzen be újra!"
                            );
                            props.logout();
                            navigate("/login");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Profil adatok</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <Row>
                                            <Col sm={4}>
                                                <p style={{ marginBottom: 0 }}>
                                                    <strong>Admin ID:</strong>{" "}
                                                    {props.id}
                                                </p>
                                            </Col>
                                            <Col sm={4}>
                                                <p style={{ marginBottom: 0 }}>
                                                    <strong>Név:</strong>{" "}
                                                    {props.name}
                                                </p>
                                            </Col>
                                            <Col sm={4}>
                                                <p style={{ marginBottom: 0 }}>
                                                    <strong>Email:</strong>{" "}
                                                    {props.email}
                                                </p>
                                            </Col>
                                        </Row>
                                    </li>
                                </ul>
                                <br></br>
                                <br></br>
                                <h5>Jelszó változtatás</h5>
                                <hr></hr>
                                <Row>
                                    <div className="col-12">
                                        <Card>
                                            <CardBody id="profile-form">
                                                <AvForm
                                                    onValidSubmit={handleSubmit}
                                                >
                                                    <Row className="form-group">
                                                        <label
                                                            htmlFor="actual-password"
                                                            className="col-sm-2 col-form-label"
                                                        >
                                                            Jelenlegi jelszó
                                                        </label>
                                                        <Col sm={4}>
                                                            <AvField
                                                                name="actual-password"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                    },
                                                                }}
                                                                errorMessage="Adja meg a jelenlegi jelszavát!"
                                                                className="form-control"
                                                                type="password"
                                                                id="actual-password"
                                                                value={
                                                                    oldPassword
                                                                }
                                                                onChange={(e) =>
                                                                    setOldPassword(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="form-group">
                                                        <label
                                                            htmlFor="new-password"
                                                            className="col-sm-2 col-form-label"
                                                        >
                                                            Új jelszó
                                                        </label>
                                                        <Col sm={4}>
                                                            <AvField
                                                                name="new-password"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                    },
                                                                }}
                                                                errorMessage="Adjon meg egy új jelszót!"
                                                                className="form-control"
                                                                type="password"
                                                                id="new-password"
                                                                value={
                                                                    newPassword
                                                                }
                                                                onChange={(e) =>
                                                                    setNewPassword(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="form-group">
                                                        <label
                                                            htmlFor="again-password"
                                                            className="col-sm-2 col-form-label"
                                                        >
                                                            Új jelszó mégegyszer
                                                        </label>
                                                        <Col sm={4}>
                                                            <AvField
                                                                name="again-password"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                    },
                                                                }}
                                                                errorMessage="Adja meg az új jelszót mégegyszer!"
                                                                className="form-control"
                                                                type="password"
                                                                id="again-password"
                                                                value={
                                                                    againPassword
                                                                }
                                                                onChange={(e) =>
                                                                    setAgainPassword(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Változtatás
                                                    </button>
                                                </AvForm>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    id: state.auth.userId,
    token: state.auth.token,
    name: state.auth.name,
    email: state.auth.email,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
