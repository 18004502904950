import { useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import classes from "./CustomCheckbox.module.css";

const CustomCheckbox = (props) => {
    const [checked, setChecked] = useState(props.default || false);

    // console.log(checked);

    return (
        <div className={classes.main} style={{ ...props.style }}>
            {!checked ? (
                <div
                    className={classes.unchecked}
                    onClick={() => {
                        setChecked(true);
                        props.onChange(true);
                    }}
                ></div>
            ) : (
                <div
                    className={classes.checked}
                    onClick={() => {
                        setChecked(false);
                        props.onChange(false);
                    }}
                >
                    <DoneIcon
                        style={{
                            color: "white",
                            height: "20px",
                            width: "20px",
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default CustomCheckbox;
