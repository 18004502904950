import { Route, Routes } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop } from "@material-ui/core";
import { NextHealthContent } from "@madmedical/sdk";
import { NextHealthWrapper } from "tdoc-common";
import ResponseModal from "./components/ResponseModal/ResponseModal";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import "./assets/scss/custom.scss";
import { useSelector } from "./store";
import RouteAccess from "./routes/RouteAccess";

const App = () => {
    const isLoading = useSelector((state) => state.auth.loading);
    const isAuthLoading = useSelector((state) => state.auth.isAuthLoading);

    return (
        <NextHealthWrapper
            isAuthLoading={isAuthLoading}
            nhaBackend={process.env.REACT_APP_NEXTHEALTH_HOSTNAME_BACKEND}
        >
            <Routes>
                {publicRoutes.map(({ path, component: Component }) => (
                    <Route
                        path={path}
                        key={path}
                        element={
                            <NonAuthLayout>
                                <Component />
                            </NonAuthLayout>
                        }
                    />
                ))}
                {authProtectedRoutes.map(
                    ({ path, component: Component, rights }) => (
                        <Route
                            path={path}
                            key={path}
                            element={
                                <RouteAccess isAuthProtected rights={rights}>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore TODO: convert to FC */}
                                    <VerticalLayout>
                                        <Component />
                                    </VerticalLayout>
                                </RouteAccess>
                            }
                        />
                    )
                )}

                <Route
                    path="/nha/*"
                    element={
                        <RouteAccess isAuthProtected rights={["doctor"]}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore TODO: convert to FC */}
                            <VerticalLayout>
                                <NextHealthContent />
                            </VerticalLayout>
                        </RouteAccess>
                    }
                />
            </Routes>
            <Backdrop open={isLoading} style={{ zIndex: 10000 }}>
                <CircularProgress color="primary" />
            </Backdrop>
            <ResponseModal />
        </NextHealthWrapper>
    );
};

export default App;
