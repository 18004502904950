import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvForm,
} from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { endLoading, get, post } from "../../config/fetch";
import { convertDate, responseMessage } from "../../utility";
import { ALERT_MESSAGES, serviceNames } from "../../config/variables";
import FormRow from "../../components/FormRow/FormRow";
import * as actions from "../../store/ui/actions";
import FileUploader from "../../components/FileUploader/FileUploader";
import ServicesAdded from "../../components/AdminCards/UserDetails/ServicesAdded/ServicesAdded";
import store from "../../store";
import { getDate } from "../../config/helpers";
import DatePicker from "../../components/DatePicker/DatePicker";

const EmployerDetails = (props) => {
    const access = store.getState().auth.access;

    const { id } = useParams();
    const navigate = useNavigate();
    const url = window.location.href.split("/");
    const type = url[url.length - 1];

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [addressInfo, setAddressInfo] = useState({
        country: null,
        zip: null,
        settlement: null,
        street: null,
        houseNumber: null,
        floorDoor: null,
    });
    const [delivery, setDelivery] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [extraServices, setExtraServices] = useState([]);
    const [extraServiceData, setExtraServiceData] = useState({
        name: null,
        date: null,
        comment: null,
        original_quantity: null,
        employerServiceId: null,
    });
    const [employerUserData, setEmployerUserData] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [packageStartDate, setPackageStartDate] = useState(new Date());
    const [packageEndDate, setPackageEndDate] = useState(new Date());
    const [selectAll, setSelectAll] = useState(false);

    const dateChangeHandler = (date, key) => {
        setExtraServiceDataByKey(key, convertDate(date));
    };

    const setExtraServiceDataByKey = (key, value) =>
        setExtraServiceData({ ...extraServiceData, [key]: value });

    const handleAddExtraService = () => {
        const service = extraServiceData.name;
        const date = extraServiceData.date;
        const comment = extraServiceData.comment;
        const quantity = extraServiceData.quantity;

        if (extraServiceData.name === null) {
            responseMessage("Válasszon szolgáltatást!");

            return;
        }
        post("admin/add-extra-service-employer", {
            id,
            service,
            date,
            comment,
            quantity,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres hozzáadás!");
                        const createdExtraService = {
                            id: response.data.id,
                            service_name: extraServiceData.name,
                            created_at: getDate(),
                            comment: extraServiceData.comment,
                            expires_at: extraServiceData.date,
                            first_name: response.data.adminFirstName,
                            last_name: response.data.adminLastName,
                            original_quantity:
                                extraServiceData.original_quantity,
                            available: "1",
                        };
                        setExtraServices([
                            createdExtraService,
                            ...extraServices,
                        ]);
                        setExtraServiceData({
                            name: "",
                            date: null,
                            comment: "",
                            original_quantity: null,
                        });

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleDeleteExtraService = (id) => {
        post("admin/delete-extra-service-employer", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres törlés!");
                        setExtraServices(
                            extraServices.filter(
                                (extraService) => extraService.id !== id
                            )
                        );
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const propsBus = {
        props: {
            access,
        },
        extraServices,
        extraServiceData,
        setExtraServiceDataByKey,
        dateChangeHandler,
        handleAddExtraService,
        handleDeleteExtraService,
    };

    useEffect(() => {
        if (id !== "new" && type !== "new") {
            get(`admin/employer?id=${id}`)
                .then((response) => {
                    console.log(response.data);
                    switch (response.data.responseCode) {
                        case "OK": {
                            const employer = response.data.employer;
                            const address = response.data.employerAddress;
                            setName(employer.name);
                            setEmail(employer.email);
                            setPhone(employer.phone);
                            setExtraServices(
                                response.data.employerExtraServices.map(
                                    (extraService) => ({
                                        ...extraService,
                                        id: extraService.service_employer_id,
                                        available: extraService.expires_at
                                            ? extraService.expires_at >
                                              new Date().toISOString()
                                                ? "1"
                                                : "0"
                                            : "1",
                                    })
                                )
                            );
                            setAddressInfo({
                                country: address.country,
                                zip: address.zip,
                                settlement: address.settlement,
                                street: address.street,
                                houseNumber: address.house_number,
                                floorDoor: address.floor_door,
                            });
                            setImageURL(employer.logo_url);
                            setDelivery(employer.delivery);
                            endLoading();

                            break;
                        }
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });

            get(`admin/users-by-employer?employerId=${id}`)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK": {
                            setEmployerUserData(response.data.users);

                            break;
                        }
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectAllChange = () => {
        if (selectAll) {
            setCheckedRows([]);
            setSelectAll(false);
        } else {
            setCheckedRows(employerUserData.map((user) => user.id));
            setSelectAll(true);
        }
    };

    let tableData = {
        columns: [
            {
                label: (
                    <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                ),
                field: "checkbox",
            },
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Vezetéknév",
                field: "lastName",
            },
            {
                label: "Keresztnév",
                field: "firstName",
            },
            {
                label: "Email",
                field: "email",
            },
            {
                label: "Előfizetés típusa",
                field: "subscribedPackage",
            },
            {
                label: "Felhasznált szolgáltatások száma",
                field: "servicesCount",
            },
            {
                label: "Felhasznált szolgáltatások típusa",
                field: "services",
            },
            {
                label: "Műveletek",
                field: "actions",
            },
        ],
        rows: [],
    };

    const fillTableData = () => {
        tableData.rows = employerUserData.map((user) => ({
            id: user.id,
            lastName: user.lastName,
            firstName: user.firstName,
            email: user.email,
            subscribedPackage: user.subscribedPackage,
            servicesCount: user.servicesCount,
            services: user.services
                .map((service) => serviceNames[service])
                .join(", "),
            actions: <Link to={"/users/" + user.id}>Adatok</Link>,
        }));
    };

    fillTableData();

    const handleSubmit = () => {
        let formData = new FormData();

        formData.append("file", imageData);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("delivery", delivery === true ? 1 : 0);
        formData.append("addressInfo", JSON.stringify(addressInfo));

        if (type === "new") {
            post("admin/new-employer", formData)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("Sikeres mentés!");
                            navigate("/employers");
                            endLoading();

                            break;
                        case "MISSING_DATA":
                            endLoading();
                            responseMessage("Hiányzó adatok!");

                            break;
                        case "INVALID_EMAIL":
                            endLoading();
                            responseMessage("Hibás email!");

                            break;
                        case "EMAIL_TAKEN":
                            endLoading();
                            responseMessage("Email foglalt!");

                            break;
                        case "PHONE_TAKEN":
                            endLoading();
                            responseMessage("Telefonszám foglalt!");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
        if (type !== "new") {
            formData.append("id", id);

            post("admin/update-employer", formData)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("Sikeres mentés!");
                            endLoading();

                            break;
                        case "MISSING_DATA":
                            endLoading();
                            responseMessage("Hiányzó adatok!");

                            break;
                        case "INVALID_EMAIL":
                            endLoading();
                            responseMessage("Hibás email!");

                            break;
                        case "EMAIL_TAKEN":
                            endLoading();
                            responseMessage("Email foglalt!");

                            break;
                        case "PHONE_TAKEN":
                            endLoading();
                            responseMessage("Telefonszám foglalt!");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const deleteEmployer = () => {
        if (id === "new") {
            return;
        }
        post("admin/delete-employer", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres törlés!");
                        navigate("/employers");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törli a munkáltatót? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteEmployer,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => {
        setImageURL(null);
        setImageData(null);
    };

    const handleCheckboxChange = (id) => {
        if (checkedRows.includes(id)) {
            setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
        } else {
            setCheckedRows([...checkedRows, id]);
        }
    };

    const handleBatchPackageChange = () => {
        const dateEq = (date1, date2) => {
            const date1Ymd = date1.toISOString().split("T")[0];
            const date2Ymd = date2.toISOString().split("T")[0];

            return date1Ymd === date2Ymd;
        };

        const dateGt = (date1, date2) => {
            const date1Ymd = date1.toISOString().split("T")[0];
            const date2Ymd = date2.toISOString().split("T")[0];

            return date1Ymd >= date2Ymd;
        };

        const dateIsPast = (date) => {
            const dateYmd = date.toISOString().split("T")[0];
            const todayYmd = new Date().toISOString().split("T")[0];

            return dateYmd < todayYmd;
        };

        console.log(packageStartDate, packageEndDate);

        if (checkedRows.length === 0) {
            responseMessage("Felhasználó kiválasztása kötelező!");

            return;
        }

        if (!selectedPackage) {
            responseMessage("Csomag kiválasztása kötelező!");

            return;
        }

        if (!packageStartDate) {
            responseMessage("Kezdő dátum kiválasztása kötelező!");

            return;
        }

        if (!packageEndDate) {
            responseMessage("Befejező dátum kiválasztása kötelező!");

            return;
        }

        if (dateIsPast(packageStartDate)) {
            responseMessage("Kezdő dátum nem lehet kisebb mint a mai dátum!");

            return;
        }

        if (dateIsPast(packageEndDate)) {
            responseMessage(
                "Befejező dátum nem lehet kisebb mint a mai dátum!"
            );

            return;
        }

        if (dateEq(packageStartDate, packageEndDate)) {
            responseMessage(
                "Kezdő dátum nem lehet egyenlő a befejező dátummal!"
            );

            return;
        }

        if (dateGt(packageStartDate, packageEndDate)) {
            responseMessage(
                "Kezdő dátum nem lehet nagyobb mint a befejező dátum!"
            );

            return;
        }

        post("admin/change-employee-user-subscription", {
            userIds: checkedRows,
            data: {
                package: selectedPackage,
                from: packageStartDate,
                to: packageEndDate,
            },
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        setSelectedPackage(null);
                        setSelectAll(false);
                        setPackageStartDate(new Date());
                        setPackageEndDate(new Date());
                        setCheckedRows([]);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const rowsWithCheckbox = tableData.rows.map((row) => ({
        ...row,
        checkbox: (
            <input
                type="checkbox"
                checked={checkedRows.includes(row.id)}
                onChange={() => handleCheckboxChange(row.id)}
            />
        ),
    }));

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                {type === "new"
                                    ? "Új munkáltató"
                                    : "Munkáltató adatok"}
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="institution-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <FormRow
                                        type="text"
                                        name="name"
                                        label="Név"
                                        validate={true}
                                        errorMessage="Adja meg a munkáltató nevét!"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                    <FormRow
                                        type="text"
                                        name="email"
                                        label="Email"
                                        validate={true}
                                        errorMessage="Adja meg a munkáltató email címét!"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <FormRow
                                        type="text"
                                        name="phone"
                                        label="Telefonszám"
                                        validate={true}
                                        errorMessage="Adja meg a munkáltató telefonszámát!"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />

                                    <hr></hr>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Szolgáltatások
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup
                                                inline
                                                name="access"
                                            >
                                                <AvCheckbox
                                                    label="Gyógyszer kiszállítás"
                                                    checked={delivery}
                                                    onChange={(event) => {
                                                        setDelivery(
                                                            event.target.checked
                                                        );

                                                        if (
                                                            event.target
                                                                .checked ===
                                                            false
                                                        ) {
                                                            setAddressInfo({
                                                                country: null,
                                                                zip: null,
                                                                settlement:
                                                                    null,
                                                                street: null,
                                                                houseNumber:
                                                                    null,
                                                                floorDoor: null,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <hr></hr>

                                    {type !== "new" &&
                                        employerUserData.length > 0 && (
                                            <ServicesAdded
                                                propsBus={propsBus}
                                            />
                                        )}

                                    {Boolean(delivery) && (
                                        <>
                                            <label className="col-sm-2 col-form-label">
                                                Szállítási cím
                                            </label>
                                            <FormRow
                                                type="text"
                                                name="employer_country"
                                                label="Ország"
                                                value={addressInfo.country}
                                                onChange={(e) =>
                                                    setAddressInfo({
                                                        ...addressInfo,
                                                        country: e.target.value,
                                                    })
                                                }
                                            />
                                            <FormRow
                                                type="text"
                                                name="employer_zip"
                                                label="Irányítószám"
                                                value={addressInfo.zip}
                                                onChange={(e) =>
                                                    setAddressInfo({
                                                        ...addressInfo,
                                                        zip: e.target.value,
                                                    })
                                                }
                                            />
                                            <FormRow
                                                type="text"
                                                name="employer_settlement"
                                                label="Település"
                                                value={addressInfo.settlement}
                                                onChange={(e) =>
                                                    setAddressInfo({
                                                        ...addressInfo,
                                                        settlement:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <FormRow
                                                type="text"
                                                name="employer_street"
                                                label="Utca"
                                                value={addressInfo.street}
                                                onChange={(e) =>
                                                    setAddressInfo({
                                                        ...addressInfo,
                                                        street: e.target.value,
                                                    })
                                                }
                                            />
                                            <FormRow
                                                type="text"
                                                name="employer_house_number"
                                                label="Házszám"
                                                value={addressInfo.houseNumber}
                                                onChange={(e) =>
                                                    setAddressInfo({
                                                        ...addressInfo,
                                                        houseNumber:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <FormRow
                                                type="text"
                                                name="employer_floor_door"
                                                label="Emelet/Ajtó"
                                                value={addressInfo.floorDoor}
                                                onChange={(e) =>
                                                    setAddressInfo({
                                                        ...addressInfo,
                                                        floorDoor:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <hr />
                                        </>
                                    )}

                                    <label
                                        htmlFor="image"
                                        className="col-sm-2 col-form-label"
                                    >
                                        Kép
                                    </label>
                                    <Row>
                                        <Col sm={10}>
                                            <FileUploader
                                                handleFile={handleFile}
                                            />
                                            {imageURL ? (
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                    onClick={cancelImageUpload}
                                                >
                                                    Mégsem
                                                </button>
                                            ) : null}
                                            {imageURL ? (
                                                <div className="image-preview">
                                                    <img
                                                        className="image"
                                                        src={imageURL}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                    {type !== "new" && (
                                        <button
                                            type="button"
                                            className="btn btn-danger float-right"
                                            onClick={handleDelete}
                                        >
                                            Munkáltató törlése
                                        </button>
                                    )}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
            {type !== "new" && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    {checkedRows.length > 0 && (
                                        <>
                                            <Row
                                                className="form-group"
                                                style={{ marginBottom: "2rem" }}
                                            >
                                                <label className="col-sm-2 col-form-label">
                                                    Csomag
                                                </label>
                                                <Col sm={10}>
                                                    <select
                                                        onChange={(event) =>
                                                            setSelectedPackage(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        className="form-control"
                                                    >
                                                        <option value="0">
                                                            Válasszon...
                                                        </option>
                                                        <option value="2">
                                                            Basic
                                                        </option>
                                                        <option value="3">
                                                            Optimum
                                                        </option>
                                                        <option value="4">
                                                            Premium
                                                        </option>
                                                    </select>
                                                </Col>
                                            </Row>

                                            <Row
                                                className="form-group"
                                                style={{ marginBottom: "2rem" }}
                                            >
                                                <label
                                                    htmlFor="paymentNextDate"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Időszak kezdete
                                                </label>
                                                <Col sm={10}>
                                                    <DatePicker
                                                        value={packageStartDate}
                                                        onChange={(date) =>
                                                            setPackageStartDate(
                                                                date
                                                            )
                                                        }
                                                        disableFuture={false}
                                                        disablePast={true}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row
                                                className="form-group"
                                                style={{ marginBottom: "2rem" }}
                                            >
                                                <label
                                                    htmlFor="paymentNextDate"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Időszak vége
                                                </label>
                                                <Col sm={10}>
                                                    <DatePicker
                                                        value={packageEndDate}
                                                        onChange={(date) =>
                                                            setPackageEndDate(
                                                                date
                                                            )
                                                        }
                                                        disableFuture={false}
                                                        disablePast={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    handleBatchPackageChange()
                                                }
                                                style={{ marginBottom: "2rem" }}
                                            >
                                                Változtatások mentése
                                            </Button>
                                        </>
                                    )}
                                    <MDBDataTable
                                        className="user-table"
                                        responsive
                                        striped
                                        noBottomColumns
                                        bordered
                                        data={{
                                            ...tableData,
                                            rows: rowsWithCheckbox,
                                        }}
                                        infoLabel={["", "-", "a(z)", "elemből"]}
                                        noRecordsFoundLabel="Nincs megjeleníthető elem"
                                        paginationLabel={["Előző", "Következő"]}
                                        searchLabel="Keresés"
                                        entriesLabel="Megjelenítendő elemek száma"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(EmployerDetails);
