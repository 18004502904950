import CaseTable from "../../../../pages/CaseManager/CaseTable/CaseTable";
import CollapseBox from "../CollapseBox/CollapseBox";
import styles from "./CasesClosed.module.css";

const CasesClosed = ({ propsBus }) => {
    const { cases, filterCasesByStatus } = propsBus;

    return (
        <CollapseBox title="Lezárt esetek" classInput={styles.scope}>
            {cases ? (
                <CaseTable
                    title="Lezárt esetek"
                    cases={filterCasesByStatus("1")}
                    defaultOpen={true}
                    hiddenTitle={true}
                />
            ) : (
                <></>
            )}
        </CollapseBox>
    );
};

export default CasesClosed;
