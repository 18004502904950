import type { ReactNode } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "../store";
import { ErrorBoundary } from "./sentry";

interface Props {
    children: ReactNode;
}

const Root = ({ children }: Props) => (
    <ErrorBoundary>
        <Provider store={store}>
            <BrowserRouter>{children}</BrowserRouter>
        </Provider>
    </ErrorBoundary>
);

export default Root;
