import { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "react-select";
import PatientCard from "../PatientCard/PatientCard";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES, DAYS } from "../../../config/variables";
import { getDate } from "../../../config/helpers";
import NotificationWarning from "../../../components/NotificationWarning/NotificationWarning";
import classes from "./DayContainer.module.css";

const SummaryBlock = (props) => (
    <div style={{ padding: "0 1rem" }}>
        <Grid container spacing={2}>
            <Grid item>
                <span
                    style={{ fontWeight: "bold" }}
                >{`${props.last_name} ${props.first_name}`}</span>
            </Grid>
            <Grid item>
                <span style={{ fontWeight: "bold" }}>{props.created_at}</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <p>{props.summary}</p>
            </Grid>
        </Grid>
        <hr></hr>
    </div>
);

const DayContainer = (props) => {
    const [summary, setSummary] = useState(null);
    const [newSummary, setNewSummary] = useState("");
    const [patientStatus, setPatientStatus] = useState(
        props.patientStatus || []
    );
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [unfinished, setUnfinished] = useState(false);
    // const [users, setUsers] = useState(props.users);

    const select1 = useRef();

    // const getDataForPatient = (id, users) => {
    //   const user = users.find(u => u.id === id);
    //   if (user) {
    //     return user
    //   } else {
    //     return null;
    //   }
    // };

    // const getPatientStatus = (id) => {
    //   const info = patientStatus.find(u => u.user_id === id);
    //   if (info) {
    //     return {status: info.status, id: info.id}
    //   } else {
    //     return null;
    //   }
    // }

    const createDailySummary = () =>
        post("admin/create-daily-summary", {
            day_date: props.date,
            summary: newSummary,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        endLoading();

                        const createdSummary = {
                            id: response.data.newSummaryId,
                            last_name: response.data.adminLastName,
                            first_name: response.data.adminFirstName,
                            created_at: getDate(),
                            summary: newSummary,
                        };

                        setSummary([createdSummary, ...props.summary]);

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const onAddPatientCard = () => {
        if (!selectedPatient) {
            return;
        }

        post("admin/create-patient-card", {
            day_date: props.date,
            userId: selectedPatient,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        endLoading();

                        const createdPatientCard = {
                            user_id: selectedPatient,
                            day_date: props.date,
                            status: "1",
                            last_name: response.data.last_name,
                            first_name: response.data.first_name,
                            created_at: getDate(),
                        };

                        setUnfinished(true);

                        setPatientStatus([
                            createdPatientCard,
                            ...props.patientStatus,
                        ]);

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    useEffect(() => {
        setUnfinished(props.patientStatus.some((p) => p.status == "1"));
    }, [props.patientStatus]);

    const onChangeStatus = (status, userId) => {
        let patientToChange = patientStatus.find((p) => p.user_id == userId);
        patientToChange.status = status;

        // props.onStatusChange();
        if (status == "1") {
            setUnfinished(true);
        }
        if (status == "0") {
            const actives = patientStatus.filter((p) => p.status == "1");
            if (actives.length === 0) {
                setUnfinished(false);
            }
        }
    };

    const generateDate = (date) => {
        const d = new Date(date.replace(/-/g, "/"));
        const day = d.getDay();

        return `${date.replace(/-/g, ".")} - ${DAYS[day]}`;
    };

    return (
        <Accordion
            className={classes.main}
            expanded={props.expanded == props.id}
            onChange={props.onChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className={classes.titleContainer}>
                    {unfinished && <NotificationWarning />}
                    <span>{generateDate(props.date)}</span>
                </div>
            </AccordionSummary>
            <Row style={{ padding: "0 1rem" }}>
                <Col sm={2}>
                    <p>
                        <strong>Összegzés írása:</strong>
                    </p>
                </Col>
                <Col sm={8}>
                    <FormGroup>
                        <Input
                            placeholder="Összegzés"
                            type="textarea"
                            rows={4}
                            value={newSummary}
                            onChange={(e) => setNewSummary(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <Button color="primary" onClick={createDailySummary}>
                        Küldés
                    </Button>
                </Col>
            </Row>
            {!summary &&
                props.summary.map((s, index) => (
                    <SummaryBlock
                        key={index}
                        last_name={s.last_name}
                        first_name={s.first_name}
                        summary={s.summary}
                        created_at={s.created_at}
                    />
                ))}
            {summary?.map((s, index) => (
                <SummaryBlock
                    key={index}
                    last_name={s.last_name}
                    first_name={s.first_name}
                    summary={s.summary}
                    created_at={s.created_at}
                />
            ))}
            <p className={classes.subTitle}>Páciensek</p>
            <Row style={{ margin: "1rem", marginLeft: "0.5rem" }}>
                <Col sm={6}>
                    <FormGroup>
                        <Select
                            ref={select1}
                            placeholder="Válasszon pácienst..."
                            noOptionsMessage={() => "Nincs találat"}
                            options={props.allUsers.map((u) => ({
                                value: u.id,
                                label: `${u.last_name} ${u.first_name}`,
                            }))}
                            onChange={(e) => setSelectedPatient(e.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <Button color="primary" onClick={onAddPatientCard}>
                        Páciens kártya hozzáadása
                    </Button>
                </Col>
            </Row>
            {patientStatus?.map((u, index) => (
                <PatientCard
                    key={index}
                    data={props.allUsers.find((x) => x.id == u.user_id)}
                    status={u.status}
                    qualifications={props.qualifications}
                    symptoms={props.symptoms}
                    doctors={props.doctors}
                    onStatusChange={(status, userId) =>
                        onChangeStatus(status, userId)
                    }
                />
            ))}
        </Accordion>
    );
};

export default DayContainer;
