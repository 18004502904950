import { forwardRef, useImperativeHandle, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import CustomSelect from "../../../../components/CustomSelect2/CustomSelect2";
import { convertDate } from "../../reservationUtilities";
import classes from "./PersonalForm.module.css";

const Title = (props) => (
    <p
        className={classes.textLabel}
        style={{
            color: !props.value && props.required ? "red" : null,
            ...props.style,
        }}
    >
        {props.text}
    </p>
);

const PersonalForm = forwardRef((props, ref) => {
    const personalData = props.data;

    const [data, setData] = useState({
        // lastName: personalData.last_name,
        // firstName: personalData.first_name,
        gender: personalData.gender,
        email: personalData.email,
        phone: personalData.phone,
        birthPlace: personalData.birth_place,
        birthDate: personalData.birth_date,
        mothersName: personalData.mothers_name,
        securityNumber: personalData.security_number,
        idNumber: personalData.id_number,
        noTaj: personalData.id_number && !personalData.security_number,
    });

    const [expanded, setExpanded] = useState(false);

    const onInputChange = (key, value) => {
        if (value) {
            setData({ ...data, [key]: value });
        } else {
            setData({ ...data, [key]: null });
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            data,
        }),
        [data]
    );

    const useStyles = makeStyles({
        accordion: {
            borderRadius: "4px",
            "&::before": {
                display: "none",
            },
            border: "1px solid #929ea7",
            // border: 'none',
            boxShadow: "none",
        },
        expanded: {
            // border: 'none'
            padding: "1.6rem",
        },
        summaryExpanded: {
            paddingLeft: "0",
            fontWeight: "bold",
        },
        accordionDetails: {
            display: "block",
            padding: "0",
        },
    });

    const styles = useStyles();

    return (
        <>
            <p className={classes.mainTitle}>{props.mainTitle}</p>

            {!personalData.email && (
                <>
                    <Title
                        value={data.email}
                        text="Email cím"
                        required={true}
                    />
                    <Input
                        defaultValue={data.email}
                        onChange={(e) => onInputChange("email", e.target.value)}
                    />
                </>
            )}

            {!personalData.phone && (
                <>
                    <Title
                        value={data.phone}
                        text="Telefonszám"
                        required={true}
                    />
                    <Input
                        type="phone"
                        value={data.phone}
                        onChange={(e) => onInputChange("phone", e)}
                    />
                </>
            )}

            {!personalData.birth_place && (
                <>
                    <Title
                        value={data.birthPlace}
                        text="Születési hely"
                        required={true}
                    />
                    <Input
                        defaultValue={data.birthPlace}
                        onChange={(e) =>
                            onInputChange("birthPlace", e.target.value)
                        }
                    />
                </>
            )}

            {!personalData.birth_date && (
                <>
                    <Title
                        value={data.birthDate}
                        text="Születési idő"
                        style={{ marginBottom: "0" }}
                        required={true}
                    />
                    <CustomDatePicker
                        style={{ marginBottom: "2rem" }}
                        value={data.birthDate}
                        onChange={(date) =>
                            setData({ ...data, birthDate: convertDate(date) })
                        }
                    />
                </>
            )}
            {!personalData.mothers_name && (
                <>
                    <Title
                        value={data.mothersName}
                        text="Anyja neve"
                        required={true}
                    />
                    <Input
                        defaultValue={data.mothersName}
                        onChange={(e) =>
                            onInputChange("mothersName", e.target.value)
                        }
                    />
                </>
            )}

            {!personalData.gender && (
                <>
                    <Title value={data.gender} text="Neme" required={true} />
                    <CustomSelect
                        options={[
                            {
                                name: "Férfi",
                                id: "Férfi",
                            },
                            {
                                name: "Nő",
                                id: "Nő",
                            },
                        ]}
                        controlStyle={{ width: "100%", marginBottom: "2rem" }}
                        value={data.gender}
                        optionKey="name"
                        optionValue="id"
                        onChange={(val) => onInputChange("gender", val)}
                        // label='Téma kiválasztása'
                    />
                </>
            )}

            {!personalData.security_number && !personalData.id_number && (
                <>
                    {data.noTaj && (
                        <>
                            <Title
                                value={data.idNumber}
                                text="Személyi igazolvány szám"
                                required={true}
                            />
                            <Input
                                defaultValue={data.idNumber}
                                style={{ marginBottom: "0" }}
                                onChange={(e) =>
                                    onInputChange("idNumber", e.target.value)
                                }
                            />
                        </>
                    )}

                    {!data.noTaj && (
                        <>
                            <Title
                                value={data.securityNumber}
                                text="TAJ szám"
                                required={true}
                            />
                            <Input
                                type="taj"
                                defaultValue={data.securityNumber}
                                style={{ marginBottom: "0" }}
                                onChange={(e) =>
                                    onInputChange(
                                        "securityNumber",
                                        e.target.value
                                    )
                                }
                            />
                        </>
                    )}

                    <Checkbox
                        label="Nincs TAJ kártyám"
                        checked={data.noTaj}
                        // onChange={(checked) => {setData({...data, noTaj: checked})}}
                        onChange={(checked) => onInputChange("noTaj", checked)}
                    />
                </>
            )}

            <Accordion
                classes={{ root: styles.accordion, expanded: styles.expanded }}
                expanded={expanded}
                onChange={() => setExpanded((prev) => !prev)}
            >
                <AccordionSummary
                    classes={{ expanded: styles.summaryExpanded }}
                    expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1bh-content"
                    // id="panel1bh-header"
                >
                    <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                        Személyes adatok
                    </p>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.accordionDetails }}>
                    {personalData.email && (
                        <>
                            <Title
                                value={data.email}
                                text="Email cím"
                                required={true}
                            />
                            <Input
                                defaultValue={data.email}
                                onChange={(e) =>
                                    onInputChange("email", e.target.value)
                                }
                            />
                        </>
                    )}

                    {personalData.phone && (
                        <>
                            <Title
                                value={data.phone}
                                text="Telefonszám"
                                required={true}
                            />
                            <Input
                                type="phone"
                                value={data.phone}
                                onChange={(e) => onInputChange("phone", e)}
                            />
                        </>
                    )}

                    {personalData.birth_place && (
                        <>
                            <Title
                                value={data.birthPlace}
                                text="Születési hely"
                                required={true}
                            />
                            <Input
                                defaultValue={data.birthPlace}
                                onChange={(e) =>
                                    onInputChange("birthPlace", e.target.value)
                                }
                            />
                        </>
                    )}

                    {personalData.birth_date && (
                        <>
                            <Title
                                value={data.birthDate}
                                text="Születési idő"
                                style={{ marginBottom: "0" }}
                                required={true}
                            />
                            <CustomDatePicker
                                style={{ marginBottom: "2rem" }}
                                value={data.birthDate}
                                onChange={(date) =>
                                    setData({
                                        ...data,
                                        birthDate: convertDate(date),
                                    })
                                }
                            />
                        </>
                    )}
                    {personalData.mothers_name && (
                        <>
                            <Title
                                value={data.mothersName}
                                text="Anyja neve"
                                required={true}
                            />
                            <Input
                                defaultValue={data.mothersName}
                                onChange={(e) =>
                                    onInputChange("mothersName", e.target.value)
                                }
                            />
                        </>
                    )}

                    {personalData.gender && (
                        <>
                            <Title
                                value={data.gender}
                                text="Neme"
                                required={true}
                            />
                            <CustomSelect
                                options={[
                                    {
                                        name: "Férfi",
                                        id: "Férfi",
                                    },
                                    {
                                        name: "Nő",
                                        id: "Nő",
                                    },
                                ]}
                                controlStyle={{
                                    width: "100%",
                                    marginBottom: "2rem",
                                }}
                                value={data.gender}
                                optionKey="name"
                                optionValue="id"
                                onChange={(val) => onInputChange("gender", val)}
                                // label='Téma kiválasztása'
                            />
                        </>
                    )}

                    {personalData.security_number && (
                        <>
                            <Title
                                value={data.securityNumber}
                                text="TAJ szám"
                                required={true}
                            />
                            <Input
                                type="taj"
                                defaultValue={data.securityNumber}
                                style={{ marginBottom: "0" }}
                                onChange={(e) =>
                                    onInputChange(
                                        "securityNumber",
                                        e.target.value
                                    )
                                }
                            />
                        </>
                    )}

                    {personalData.id_number && (
                        <>
                            <Title
                                value={data.idNumber}
                                text="Személyi igazolvány szám"
                                required={true}
                            />
                            <Input
                                defaultValue={data.idNumber}
                                style={{ marginBottom: "0" }}
                                onChange={(e) =>
                                    onInputChange("idNumber", e.target.value)
                                }
                            />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    );
});

PersonalForm.displayName = "PersonalForm";

export default PersonalForm;
