import { useEffect, useState } from "react";
import Delete from "@material-ui/icons/Delete";
import { Button, Card, CardBody, Input } from "reactstrap";
import { formatNumber, responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import InfoComponent from "../InfoComponent/InfoComponent";
import EditBtn from "../EditBtn/EditBtn";
import NewItemBtn from "../NewItemBtn/NewItemBtn";
import classes from "./PricesCard.module.css";

const PricesCard = (props) => {
    const originalData = props.casePriceData;
    const originalExtraPrices = props.casePriceData.extraPrice;

    const [editMode, setEditMode] = useState(false);
    const [casePriceData, setCasePriceData] = useState(props.casePriceData);
    const [extraPrices, setExtraPrices] = useState(
        props.casePriceData.extraPrice
    );
    const [sum, setSum] = useState(null);

    const handleUpdate = () => {
        const _data = {
            discount: casePriceData.discount,
            insurant: casePriceData.insurant,
            insurantName:
                casePriceData.insurant == "1"
                    ? casePriceData.insurantName
                    : null,
            insurantFinance:
                casePriceData.insurant == "1"
                    ? casePriceData.insurantFinance
                    : null,
            surgeryPrice: casePriceData.surgeryPrice,
        };

        post("admin/update-case-prices", {
            caseId: props.caseId,
            data: _data,
            extraPrices,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        props.refreshPage();
                        endLoading();
                        setEditMode(false);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const onChangeExtraPrice = (index, name, value) => {
        const extraPrice = {
            ...extraPrices[index],
            [name]: value,
        };
        const modifiedArr = [...extraPrices];
        modifiedArr[index] = extraPrice;
        setExtraPrices(modifiedArr);
    };

    const onDeleteExtraPrice = (i) => {
        const modifiedArr = extraPrices.filter((e, index) => index != i);
        setExtraPrices(modifiedArr);
    };

    const onAddExtraPrice = () => {
        const modifiedArr = [...extraPrices];
        modifiedArr.push({
            name: "",
            price: "",
        });
        setExtraPrices(modifiedArr);
    };

    useEffect(() => {
        let sum = 0;
        if (casePriceData.surgeryPrice) {
            sum += +casePriceData.surgeryPrice;
        }
        if (extraPrices.length > 0) {
            extraPrices.forEach((p) => {
                sum += +p.price;
            });
        }
        if (casePriceData.discount) {
            let discountedSurgeryPrice =
                +casePriceData.surgeryPrice * (casePriceData.discount / 100);
            sum -= discountedSurgeryPrice;
            // sum -= ((sum / 100) * casePriceData.discount);
        }
        setSum(sum);
    }, [casePriceData, extraPrices]);

    const [isInsured, setIsInsured] = useState(casePriceData.insurant == "1");

    return (
        <Card style={{ borderRadius: "10px" }}>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        <span>Árak</span>
                    </div>
                    {!editMode && (
                        <div>
                            <EditBtn onClick={() => setEditMode(true)} />
                        </div>
                    )}
                </div>
                <InfoComponent
                    title="Biztosítás"
                    info={isInsured ? "Igen" : "Nem"}
                    editMode={editMode}
                    notEditable={false}
                    editComponent={
                        <Input
                            type="select"
                            value={casePriceData.insurant}
                            onChange={(e) => {
                                setCasePriceData({
                                    ...casePriceData,
                                    insurant: e.target.value,
                                });
                                setIsInsured(e.target.value == "1");
                            }}
                        >
                            <option value="0">Nem</option>
                            <option value="1">Igen</option>
                        </Input>
                    }
                />
                {casePriceData.insurant == "1" && (
                    <>
                        <InfoComponent
                            title="Biztosító"
                            info={casePriceData.insurantName ?? "-"}
                            editMode={editMode}
                            notEditable={false}
                            editComponent={
                                <Input
                                    type="select"
                                    value={casePriceData.insurantName}
                                    onChange={(e) =>
                                        setCasePriceData({
                                            ...casePriceData,
                                            insurantName: e.target.value,
                                        })
                                    }
                                >
                                    {props.insurants.map(({ name }) => (
                                        <option value={name} key={name}>
                                            {name}
                                        </option>
                                    ))}
                                </Input>
                            }
                        />
                        <InfoComponent
                            title="Finanszírozás"
                            info={casePriceData.insurantFinance ?? "-"}
                            editMode={editMode}
                            notEditable={false}
                            editComponent={
                                <Input
                                    type="text"
                                    value={casePriceData.insurantFinance}
                                    onChange={(e) =>
                                        setCasePriceData({
                                            ...casePriceData,
                                            insurantFinance: e.target.value,
                                        })
                                    }
                                ></Input>
                            }
                        />
                    </>
                )}
                <InfoComponent
                    title="Műtét ár"
                    info={
                        casePriceData.surgeryPrice
                            ? `${formatNumber(casePriceData.surgeryPrice)} Ft`
                            : "-"
                    }
                    editMode={editMode}
                    notEditable={false}
                    editComponent={
                        <Input
                            type="number"
                            value={casePriceData.surgeryPrice}
                            onChange={(e) =>
                                setCasePriceData({
                                    ...casePriceData,
                                    surgeryPrice: e.target.value,
                                })
                            }
                        />
                    }
                />
                {extraPrices.length > 0 &&
                    extraPrices.map((p, index) => (
                        <InfoComponent
                            key={index}
                            title={p.name}
                            info={`${formatNumber(p.price)} Ft`}
                            editMode={editMode}
                            notEditable={false}
                            editComponent={
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <Input
                                            type="text"
                                            value={p.name}
                                            style={{ marginBottom: "8px" }}
                                            onChange={(e) =>
                                                onChangeExtraPrice(
                                                    index,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Input
                                            type="number"
                                            value={p.price}
                                            onChange={(e) =>
                                                onChangeExtraPrice(
                                                    index,
                                                    "price",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            onDeleteExtraPrice(index)
                                        }
                                    >
                                        <Delete />
                                    </div>
                                </div>
                            }
                        />
                    ))}
                {editMode && (
                    <NewItemBtn
                        text={`+ Tétel hozzáadása`}
                        onClick={() => onAddExtraPrice()}
                    />
                )}
                <InfoComponent
                    title="Kedvezmény (%)"
                    info={casePriceData.discount ? casePriceData.discount : "-"}
                    editMode={editMode}
                    notEditable={false}
                    editComponent={
                        <Input
                            type="number"
                            value={casePriceData.discount}
                            onChange={(e) =>
                                setCasePriceData({
                                    ...casePriceData,
                                    discount: e.target.value,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Összesen"
                    info={`${formatNumber(sum)} Ft`}
                    editMode={editMode}
                    notEditable={true}
                />
                <InfoComponent
                    title={isInsured ? "Előleg (30%)" : "Előleg (50%)"}
                    info={`${formatNumber(sum * (isInsured ? 0.3 : 0.5))} Ft`}
                    editMode={editMode}
                    notEditable={true}
                />
                {editMode && (
                    <div className={classes.btnWrapper}>
                        <Button color="primary" onClick={() => handleUpdate()}>
                            Változtatások mentése
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setEditMode(false);
                                setCasePriceData(originalData);
                                setExtraPrices(originalExtraPrices);
                            }}
                        >
                            Mégse
                        </Button>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default PricesCard;
