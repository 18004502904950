import { useEffect, useState } from "react";
import { Col, Media, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get } from "../../config/fetch";

const Duties = () => {
    const [duties, setDuties] = useState([]);

    useEffect(() => {
        get("admin/get-duties")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDuties(response.data.duties);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    let tableData = {
        columns: [
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Név",
                field: "name",
            },
            {
                label: "Cím",
                field: "address",
            },
            {
                label: "Típusok",
                field: "types",
            },
            {
                label: "Telefonszám",
                field: "phone",
            },
            {
                label: "Hírdetés",
                field: "is_ad",
            },
            {
                label: "Kép",
                field: "image",
            },
            {
                label: "Medio Id",
                field: "medio_id",
            },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const fillTableData = (dutyData) => {
        tableData.rows = dutyData.map((duty) => ({
            id: duty.id,
            name: duty.name,
            address: duty.address,
            types: duty.types.map((t) => t.name).join(", "),
            phone: duty.phone !== "null" ? duty.phone : "",
            is_ad: duty.is_ad == "1" ? "Igen" : "Nem",
            image: duty.image ? (
                <Media>
                    <Media
                        className="institutionPic"
                        object
                        src={duty.image}
                        alt="Kép"
                    />
                </Media>
            ) : null,
            medio_id: duty.medio_id,
            actions: <Link to={"/duties/" + duty.id}>Műveletek</Link>,
        }));
    };
    fillTableData(duties);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Ügyeletek</h4>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="float-right d-none d-md-block">
                            <Link to={"/duties/new"}>
                                <button className="btn btn-primary">
                                    Új ügyelet
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="institution-table"
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps)(Duties);
