import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ChatMessage from "../ChatMessage/ChatMessage";
import { MONTHS } from "../../../config/variables";
import { returnWithZero } from "../../../utility";
import classes from "./ChatWindow.module.css";

const ChatWindow = (props) => {
    const startRef = useRef();
    const endRef = useRef();
    const messagesRef = useRef();

    useEffect(() => {
        messagesRef.current.scrollTo({
            top: endRef.current.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    }, [props.messages]);

    const renderDate = (date) =>
        `${MONTHS[date.getMonth()]} ${date.getDate()}, ${returnWithZero(
            date.getHours()
        )}:${returnWithZero(date.getMinutes())}`;

    const renderName = (id) => {
        if (id.substr(0, 1) === "d" || id.substr(0, 1) === "a") {
            const author = props.participants.find((p) => p.id == id);
            if (author) {
                return author.name;
            }
        } else {
            return props.otherName;
        }
    };

    const getDirection = (access, author) => {
        if (author.substr(0, 1) === "a") {
            if (props.userId === author.substring(1, author.length)) {
                return "sent";
            }

            return "received";
        }
        if (access.doctorId === author) {
            return "sent";
        } else if (access.doctorId !== author) {
            return "received";
        } else if (author.substr(0, 1) !== "d") {
            return "received";
        }
    };

    const renderMessages = () => {
        let messages = [];
        props.messages.forEach((message, index) => {
            const withDate =
                index === 0 ||
                props.messages[index - 1].author !== message.author ||
                message.dateCreated.getTime() -
                    props.messages[index - 1].dateCreated.getTime() >
                    1000 * 60 * 30;
            const type =
                message.type === "text"
                    ? "text"
                    : message.media.contentType.startsWith("image/")
                    ? "image"
                    : "attachment";

            messages.push(
                <ChatMessage
                    key={message.sid}
                    direction={getDirection(props.access, message.author)}
                    type={type}
                    date={renderDate(message.dateCreated)}
                    withDate={withDate}
                    otherName={renderName(message.author)}
                    text={message.body}
                    media={message.type === "media" ? message.media : null}
                />
            );
        });

        return messages;
    };

    return (
        <div className={classes.ChatWindow} style={props.style}>
            <div className={classes.header}>
                <span className={classes.title}>E-recept</span>
                <span className={classes.identifier}>
                    Azonosító: {props.identifier}
                </span>
            </div>

            <Grid container>
                <Grid item>
                    <div className={classes.infos}>
                        <span>
                            {" "}
                            {props.isOpen ? (
                                <p className={classes.info}>Státusz: Nyitott</p>
                            ) : (
                                <p className={classes.info}>Státusz: Lezárt</p>
                            )}
                        </span>
                    </div>
                    <div className={classes.infos}>
                        <span>
                            <p className={classes.info}>
                                Gyógyszerek:{" "}
                                {props.medicines ?? "Nincs megadva"}
                            </p>
                        </span>
                    </div>
                    <div className={classes.infos}>
                        <span>
                            <p className={classes.info}>
                                Választott orvos:{" "}
                                {props.author ?? "Nincs megadva"}
                            </p>
                        </span>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.infos}>
                        <span>
                            <p className={classes.info}>
                                Páciens: {props.otherName ?? "Nincs megadva"}
                            </p>
                        </span>
                    </div>

                    <Link to={`/users/${props.patientId}`}>
                        <button
                            className="btn btn-primary"
                            style={{ marginBottom: "1rem" }}
                        >
                            Páciens adatlap
                        </button>
                    </Link>
                </Grid>
            </Grid>

            <div className={classes.inner} ref={messagesRef}>
                <div className={classes.startDummy} ref={startRef}></div>
                {renderMessages()}
                <div
                    className={classes.endDummy}
                    ref={endRef}
                    id="endRef"
                ></div>
            </div>
        </div>
    );
};

export default ChatWindow;
