import { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import classes from "./MessagesTable.module.css";

const MessagesTable = (props) => {
    const [tableData, setTableData] = useState(null);
    const [open, setOpen] = useState(props.defaultOpen);

    const fillTableData = (messageData) =>
        messageData.map((m) => {
            let modifiedMessage = {
                messageId: m.id,
                name: m.name,
                email: m.email,
                phone: m.phone,
                createdAt: m.created_at.replace(/-/g, "."),
                actions: !props.delete ? (
                    <Link to={`/qa/${m.id}`}>Válasz</Link>
                ) : (
                    <Link to={`/qa/${m.id}`}>Megnyitás</Link>
                ),
            };

            if (props.answered) {
                modifiedMessage.answeredAt = m.answered_at;
                modifiedMessage.answeredBy = m.admin_name;
            } else {
                modifiedMessage.messages = m.message.substring(0, 100) + "...";
            }

            return modifiedMessage;
        });

    const unansweredColumns = [
        {
            label: "ID",
            field: "messageId",
        },
        {
            label: "Név",
            field: "name",
        },
        {
            label: "Email",
            field: "email",
        },
        {
            label: "Telefonszám",
            field: "phone",
        },
        {
            label: "Üzenet",
            field: "messages",
        },
        {
            label: "Kérdezve",
            field: "createdAt",
        },
        {
            label: "Művelet",
            field: "actions",
        },
    ];

    const answeredColumns = [
        {
            label: "ID",
            field: "messageId",
        },
        {
            label: "Név",
            field: "name",
        },
        {
            label: "Email",
            field: "email",
        },
        {
            label: "Telefonszám",
            field: "phone",
        },
        {
            label: "Megválaszolta",
            field: "answeredBy",
        },
        {
            label: "Megválaszolva",
            field: "answeredAt",
        },
        {
            label: "Kérdezve",
            field: "createdAt",
        },
        {
            label: "Művelet",
            field: "actions",
        },
    ];

    useEffect(() => {
        let columns = props.answered ? answeredColumns : unansweredColumns;
        let rows = fillTableData(props.messages);
        let tableData = {
            columns,
            rows,
        };

        setTableData(tableData);
    }, [props.messages]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card">
            <div className="card-body">
                <h5
                    className={classes.title}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {props.title}
                </h5>
                {open && (
                    <>
                        <hr></hr>
                        {tableData && (
                            <MDBDataTable
                                responsive
                                striped
                                noBottomColumns
                                bordered
                                data={tableData}
                                infoLabel={["", "-", "a(z)", "elemből"]}
                                noRecordsFoundLabel="Nincs megjeleníthető elem"
                                paginationLabel={["Előző", "Következő"]}
                                searchLabel="Keresés"
                                entriesLabel="Megjelenítendő elemek száma"
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default MessagesTable;
