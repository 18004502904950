import { useEffect, useState } from "react";
import { Col, Media, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const Admins = () => {
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        get("admin/admin-users")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setAdmins(response.data.admins);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    let tableData = {
        columns: [
            {
                label: "ID",
                field: "id",
                sort: "asc",
            },
            // {
            //   label: "Név",
            //   field: "name",
            //   sort: "asc"
            // },
            {
                label: "Vezetéknév",
                field: "last_name",
                sort: "asc",
            },
            {
                label: "Keresztnév",
                field: "first_name",
                sort: "asc",
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
            },
            {
                label: "Telefonszám",
                field: "phone",
                sort: "asc",
            },
            {
                label: "Kép",
                field: "image",
                // sort: "asc"
            },
            {
                label: "Szuper Admin",
                field: "super_admin",
                sort: "asc",
            },
            {
                label: "Call Center",
                field: "call_center",
                sort: "asc",
            },
            {
                label: "Pénzügy",
                field: "finance",
                sort: "asc",
            },
            {
                label: "Esetmenedzser",
                field: "case_manager",
                sort: "asc",
            },
            {
                label: "Esetmenedzser minden szakterülethez",
                field: "full_case_manager",
                sort: "asc",
            },
            {
                label: "Orvos",
                field: "doctor",
                sort: "asc",
            },
            {
                label: "Orvos ID",
                field: "doctor_id",
                sort: "asc",
            },
            {
                label: "Gyógyszerész",
                field: "pharmacist",
                sort: "asc",
            },
            // {
            //   label: "Hotline hívások",
            //   field: "hotline_calls",
            // },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const fillTableData = (adminData) => {
        tableData.rows = adminData.map((admin) => ({
            id: admin.id,
            name: admin.name,
            last_name: admin.last_name,
            first_name: admin.first_name,
            email: admin.email,
            phone: admin.phone,
            image: admin.image ? (
                <Media>
                    <Media className="Pic" object src={admin.image} alt="Kép" />
                </Media>
            ) : null,
            super_admin: admin.super_admin != "0" ? "Igen" : "-",
            call_center: admin.call_center != "0" ? "Igen" : "-",
            finance: admin.finance != "0" ? "Igen" : "-",
            case_manager: admin.case_manager != "0" ? "Igen" : "-",
            pharmacist: admin.pharmacist != "0" ? "Igen" : "-",
            full_case_manager: admin.full_case_manager != "0" ? "Igen" : "-",
            doctor: admin.doctor != "0" ? "Igen" : "-",
            doctor_id: admin.doctor_id,
            actions: <Link to={"/admins/" + admin.id}>Műveletek</Link>,
        }));
    };

    fillTableData(admins);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Admin felhasználók</h4>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="float-right">
                            <Link to={"/admins/new"}>
                                <button className="btn btn-primary">
                                    Új admin felhasználó
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="admin-table"
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Admins;
