import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import styles from "./PersonalDetails.module.css";

const PersonalDetails = ({ propsBus }) => {
    const [isEditing, setIsEditing] = useState(false);
    const {
        props,
        userData,
        employers,
        setUserDataByKey,
        dateChangeHandler,
        handleSubmit,
        handleDelete,
    } = propsBus;

    const sortedData = {
        ["Vezetéknév"]: userData.lastName,
        ["Keresztnév"]: userData.firstName,
        ["Születési dátum"]: userData.dateOfBirth,
        ["Születési hely"]: userData.birthPlace,
        ["Anyja neve"]: userData.mothersName,
        ["TAJ szám"]: userData.securityNumber,
        ["Neme"]: userData.gender,
        ["Igazolvány szám"]: userData.idNumber,
        ["Email"]: userData.email,
        ["Telefonszám"]: userData.phone,
        // TODO: közterület jellegének input fieldjét pótolni
        ["Lakcím"]: userData.addressSettlement
            ? `${userData.addressZip ?? ""} ${
                  userData.addressSettlement ?? ""
              }, ${userData.addressStreet ?? ""} ${
                  userData.addressHouseNumber ?? ""
              }  ${userData.addressFloorDoor ?? ""}`
            : "-",
        ["Munkáltató"]: userData.employerName ?? "-",
    };

    return (
        <CollapseBox
            title="Személyes adatok"
            classInput={styles.scope}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
        >
            {isEditing ? (
                <>
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody id="user-form">
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="last-name"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Vezetéknév
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="last-name"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg a felhasználó utónevét!"
                                                    className="form-control"
                                                    type="text"
                                                    value={userData.lastName}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "lastName",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="last-name"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="first-name"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Keresztnév
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="first-name"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg a felhasználó keresztnevét!"
                                                    className="form-control"
                                                    type="text"
                                                    value={userData.firstName}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "firstName",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="first-name"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="dateOfBirth"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Születési dátum
                                            </label>
                                            <Col
                                                sm={10}
                                                style={{ marginBottom: "1rem" }}
                                            >
                                                <DatePicker
                                                    value={userData.dateOfBirth}
                                                    onChange={(date) =>
                                                        dateChangeHandler(
                                                            date,
                                                            "dateOfBirth",
                                                            "user"
                                                        )
                                                    }
                                                    disableFuture={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="birthPlace"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Születési hely
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="birthPlace"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg a születési helyét!"
                                                    className="form-control"
                                                    type="text"
                                                    value={userData.birthPlace}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "birthPlace",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="birthPlace"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="mothersName"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Anyja neve
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="mothersName"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg az anyja nevét!"
                                                    className="form-control"
                                                    type="text"
                                                    value={userData.mothersName}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "mothersName",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="mothersName"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Neme
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    type="select"
                                                    name="select"
                                                    value={userData.gender}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "gender",
                                                            event.target.value
                                                        )
                                                    }
                                                    className="form-control"
                                                >
                                                    <option value="Férfi">
                                                        Férfi
                                                    </option>
                                                    <option value="Nő">
                                                        Nő
                                                    </option>
                                                    <option value="Nem nyilatkozom">
                                                        Nem nyilatkozom
                                                    </option>
                                                </AvField>
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="security-number"
                                                className="col-sm-2 col-form-label"
                                            >
                                                TAJ szám
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="security-number"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg a TAJ számot!"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        userData.securityNumber
                                                    }
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "securityNumber",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="security-number"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="id-number"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Személyi ig. / Útlevél szám
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="id-number"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    errorMessage="Adjon meg egy azonoító számot!"
                                                    className="form-control"
                                                    type="text"
                                                    value={userData.idNumber}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "idNumber",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="id-number"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="email"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Email
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="email"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                        email: { value: true },
                                                    }}
                                                    errorMessage="Adjon meg valós email címet!"
                                                    className="form-control"
                                                    type="email"
                                                    value={userData.email}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "email",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="email"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label
                                                htmlFor="phone"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Telefonszám
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="phone"
                                                    className="form-control"
                                                    type="text"
                                                    value={userData.phone}
                                                    onChange={(event) =>
                                                        setUserDataByKey(
                                                            "phone",
                                                            event.target.value
                                                        )
                                                    }
                                                    id="phone"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Munkáltató
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    type="select"
                                                    name="select"
                                                    value={
                                                        userData.employer ?? 0
                                                    }
                                                    onChange={(event) => {
                                                        setUserDataByKey(
                                                            "employer",
                                                            event.target.value
                                                        );
                                                        console.log(userData);
                                                    }}
                                                    className="form-control"
                                                >
                                                    {employers && (
                                                        <>
                                                            <option
                                                                key="0"
                                                                value="0"
                                                            >
                                                                Nincs munkáltató
                                                            </option>
                                                            {employers.map(
                                                                (employer) => (
                                                                    <option
                                                                        key={
                                                                            employer.id
                                                                        }
                                                                        value={
                                                                            employer.id
                                                                        }
                                                                    >
                                                                        {
                                                                            employer.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </AvField>
                                            </Col>
                                        </Row>

                                        {(props.access.superAdmin == "1" ||
                                            props.access.callCenter == "1") && (
                                            <>
                                                <hr />
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Mentés
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn float-right"
                                                    onClick={handleDelete}
                                                >
                                                    <p className="delete-button-text">
                                                        Felhasználó törlése
                                                    </p>
                                                </button>
                                            </>
                                        )}
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </>
            ) : (
                <ReadOnlyFieldList data={sortedData} />
            )}
        </CollapseBox>
    );
};

export default PersonalDetails;
