import { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import CustomSelect from "../CustomSelect/CustomSelect";
import * as actions from "../../store/ui/actions";

const CSVUploader = (props) => {
    const hiddenFileInput = useRef(null);

    const [modal, setModal] = useState(false);
    const [employers, setEmployers] = useState(null);
    const [selectedEmployer, setSelectedEmployer] = useState({
        value: "0",
        label: "Nincs munkáltató",
    });

    const toggle = () => setModal((prev) => !prev);

    useEffect(() => {
        get("admin/employers")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        let employerData = response.data.employers;
                        employerData = [
                            { id: "0", name: "Nincs munkáltató" },
                            ...employerData,
                        ];
                        setEmployers(employerData);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    // const renderErrors = (errors) => {

    //   const errorNames = {
    //     'missingData': 'Hiányzó adat',
    //     'emailTaken': 'Email foglalt',
    //     'phoneTaken': 'Telefonszám foglalt',
    //     'systemError': 'Rendszer hiba'
    //   }

    //   let errorString = ""

    //   errors.forEach((e) => {
    //     errorString += `[${e.row}. sor; ${errorNames[e.error]}] `
    //   })
    //   return `
    //     A következő sorokat nem sikerült feltölteni az alábbi hibák miatt:
    //     ${errorString}
    //   `
    // }

    const ErrorComponent = (props) => (
        <>
            <p>Néhány sort nem sikerült feltölteni.</p>
            <p>A hibákat tartalmazó CSV fájlt ezen a linken töltheti le:</p>
            <a
                style={{ color: "#1FBA9C" }}
                href={props.link}
                target="_blank"
                rel="noreferrer"
            >
                Letöltés
            </a>
        </>
    );

    const uploadFile = (file) => {
        toggle();

        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("employer", selectedEmployer.value);

        post("admin/upload-csv", formData)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres feltöltés!");
                        props.onUpload();
                        endLoading();

                        break;
                    case "ERRORS":
                        responseMessage(
                            null,
                            null,
                            <ErrorComponent link={response.data.errorLink} />
                        );
                        props.onUpload();
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleClick = () => toggle();

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            props.toggleResponseModal(true, {
                title: `${fileUploaded.name}`,
                content:
                    "Biztosan feltölti a fájl tartalmát? Győzödjön meg róla, hogy a mellékelt mintának megfelelően töltött ki minden adatot!",
                confirmButton: true,
                confirmButtonText: "Feltöltés",
                confirmButtonCallback: () => uploadFile(fileUploaded),
                cancelButton: true,
                cancelButtonText: "Mégsem",
                cancelButtonCallback: null,
                disableBackdropClick: false,
            });
        }

        toggle();

        hiddenFileInput.current.value = "";
    };

    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-primary"
                type="button"
                style={{ ...props.style }}
            >
                Importálás
            </button>
            <input
                accept="text/csv"
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
            />
            {employers && (
                <Modal centered isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        Munkáltató kiválasztása
                    </ModalHeader>
                    <ModalBody>
                        <CustomSelect
                            value={selectedEmployer}
                            inModal={true}
                            isMulti={false}
                            placeholder="Válasszon munkáltatót..."
                            options={employers.map((e) => ({
                                value: e.id,
                                label: e.name,
                            }))}
                            onChange={(e) => setSelectedEmployer(e)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                toggle();
                                hiddenFileInput.current.click();
                            }}
                        >
                            Tovább
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Vissza
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(CSVUploader);
