import { useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import NewCase from "./NewCase/NewCase";
import CaseTable from "./CaseTable/CaseTable";

const CaseManager = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [users, setUsers] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [qualifications, setQualifications] = useState(null);
    const [institutions, setInstitutions] = useState(null);
    const [cases, setCases] = useState(null);
    const [surgeryTypes, setSurgeryTypes] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [surgeryStatuses, setSurgeryStatuses] = useState(null);
    const [caseManagers, setCaseManagers] = useState(null);
    const [isShowList, setIsShowList] = useState(false);

    const filterCasesByStatus = (status) =>
        cases.filter((c) => c.closed == status);

    const refreshPage = () => {
        let currentPage = location.pathname;
        navigate({ pathname: "/empty" });
        navigate({ pathname: currentPage }, { replace: true });
    };

    const exportCases = () =>
        get("admin/export-cases")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.open(response.data.link);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleSearch = () => {
        const search = document.getElementById("search").value;
        handleLoadList(search);
        setIsShowList(true);
    };

    const handleLoadList = (search) => {
        const url = search
            ? `admin/case-manager-data?search=${search}`
            : `admin/case-manager-data`;
        get(url)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        setUsers(response.data.users);
                        setDoctors(response.data.doctors);
                        setQualifications(response.data.qualifications);
                        setInstitutions(response.data.institutions);
                        setSurgeryTypes(response.data.surgeryTypes);
                        setStatuses(response.data.statuses);
                        setSurgeryStatuses(response.data.surgeryStatuses);
                        setCaseManagers(response.data.caseManagers);

                        const sortedCases = response.data.cases.sort(
                            (a, b) =>
                                new Date(b.created_at.replace(/-/g, "/")) -
                                new Date(a.created_at.replace(/-/g, "/"))
                        );
                        setCases(sortedCases);

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Esetmenedzsment</h4>
                        </div>
                    </Col>
                    {(props.access.superAdmin == "1" ||
                        props.access.callCenter == "1") &&
                        isShowList && (
                            <Col sm={6}>
                                <div className="float-right">
                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "1rem" }}
                                        onClick={exportCases}
                                    >
                                        Exportálás
                                    </button>
                                </div>
                            </Col>
                        )}
                </Row>
                {!isShowList && (
                    <>
                        <h4>Esetek keresése</h4>
                        <div>
                            <Input
                                id="search"
                                style={{ width: "60%" }}
                                placeholder="Keressen páciens/admin neve/telefonszáma/email címe alapján vagy szakterület/helyszín/eset státusz/műtét státusz megnevezése alapján!"
                            />
                            <Button
                                className="btn btn-primary"
                                onClick={handleSearch}
                            >
                                Keresés
                            </Button>
                        </div>
                        <div
                            style={{
                                marginTop: "1rem",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    handleLoadList();
                                    setIsShowList(true);
                                }}
                            >
                                Teljes lista betöltése
                            </Button>
                        </div>
                    </>
                )}
                {isShowList && (
                    <>
                        <Row>
                            <Col>
                                {users &&
                                    doctors &&
                                    qualifications &&
                                    institutions &&
                                    surgeryTypes &&
                                    statuses &&
                                    surgeryStatuses && (
                                        <NewCase
                                            users={users}
                                            doctors={doctors}
                                            qualifications={qualifications}
                                            caseManagers={caseManagers}
                                            institutions={institutions}
                                            newCaseAdded={refreshPage}
                                            surgeryTypes={surgeryTypes}
                                            statuses={statuses}
                                            surgeryStatuses={surgeryStatuses}
                                        />
                                    )}
                            </Col>
                        </Row>
                        {cases && (
                            <>
                                <Row>
                                    <Col>
                                        <CaseTable
                                            title="Aktív esetek"
                                            cases={filterCasesByStatus("0")}
                                            defaultOpen={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CaseTable
                                            title="Lezárt esetek"
                                            cases={filterCasesByStatus("1")}
                                            defaultOpen={false}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    access: state.auth.access,
});

export default connect(mapStateToProps)(CaseManager);
