import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get } from "../../config/fetch";
import * as actions from "../../store/ui/actions";

const Qualifications = () => {
    const [qualifications, setQualifications] = useState([]);
    const [tableData, setTableData] = useState({
        columns: [
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Szakterület",
                field: "name",
            },
            {
                label: "Vezetők",
                field: "leaders",
            },
            // {
            //   label: " ",
            //   field: "edit"
            // },
            // {
            //   label: " ",
            //   field: "delete"
            // },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    });

    // const [modal, setModal] = useState(false);

    // const toggle = () => setModal(!modal);

    useEffect(() => {
        get("admin/get-qualifications")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setQualifications(response.data.qualifications);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    useEffect(() => {
        fillTableData(qualifications);
    }, [qualifications]); // eslint-disable-line react-hooks/exhaustive-deps

    const fillTableData = (qualificationData) => {
        const modifiedData = qualificationData.map((q) => ({
            id: q.id,
            name: q.name,
            leaders: q.leaders
                .map((l) => `${l.title} ${l.last_name} ${l.first_name}`)
                .join(", "),
            actions: <Link to={"/qualifications/" + q.id}>Műveletek</Link>,
            // edit: !q.deleted ? <button onClick={() => {toggle(); setSelectedQual(q.id); setNewQual(false); setNewText(q.name)}} className="btn btn-warning">Szerkesztés</button> : null,
            // delete: !q.deleted ? <button onClick={() => onDelete(q.id)} className="btn btn-danger">Törlés</button> : null,
        }));
        setTableData({
            ...tableData,
            rows: modifiedData,
        });
    };

    // const onEdit = () => {
    //   if (newText) {
    //     const exist = qualifications.find(q => q.name === newText);
    //     if (exist) {
    //       responseMessage('A szakterület már létezik!');
    //       return;
    //     }
    //     let editedQual = qualifications.find(q => q.id === selectedQual);
    //     const index = qualifications.indexOf(editedQual);
    //     editedQual.original = editedQual.name
    //     editedQual.name = newText;
    //     let modifiedQualArr = [...qualifications];
    //     modifiedQualArr[index] = {...editedQual, edited: true};
    //     setQualifications(modifiedQualArr);
    //   }
    // }

    // const onCancelEdit = (id) => {
    //   let editedQual = qualifications.find(q => q.id === id);
    //     const index = qualifications.indexOf(editedQual);
    //     let modifiedQualArr = [...qualifications];
    //     editedQual.name = editedQual.original;
    //     modifiedQualArr[index] = {...editedQual, edited: false};
    //     setQualifications(modifiedQualArr);
    // }

    // const onDelete = (id) => {
    //   let deletedQual = qualifications.find(q => q.id === id);
    //     const index = qualifications.indexOf(deletedQual);
    //     let modifiedQualArr = [...qualifications];
    //     modifiedQualArr[index] = {...deletedQual, deleted: true};
    //     setQualifications(modifiedQualArr);
    // }

    // const onCancelDelete = (id, temporaryId=null) => {
    //   if (temporaryId) {
    //     let modifiedQualArr = [...qualifications];
    //     modifiedQualArr = modifiedQualArr.filter(q => q.temporaryId !== temporaryId);
    //     setQualifications(modifiedQualArr);
    //     return;
    //   }
    //   let deletedQual = qualifications.find(q => q.id === id);
    //     const index = qualifications.indexOf(deletedQual);
    //     let modifiedQualArr = [...qualifications];
    //     modifiedQualArr[index] = {...deletedQual, deleted: false};
    //     setQualifications(modifiedQualArr);
    // }

    // const onAddNew = () => {
    //   if (newText) {
    //     const exist = qualifications.find(q => q.name === newText);
    //     if (exist) {
    //       responseMessage('A szakterület már létezik!');
    //       return;
    //     }
    //     const newQual = {id: 'Új', name: newText, new: true, temporaryId: Math.random()};
    //     let modifiedQualArr = [newQual, ...qualifications];
    //     setQualifications(modifiedQualArr);
    //   }
    // }

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Szakterületek</h4>
                        </div>
                    </Col>

                    <Col sm="6">
                        <div className="float-right">
                            <Link to={"/qualifications/new"}>
                                <button className="btn btn-primary">
                                    Új szakterület
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="doctor-table"
                                    disableRetreatAfterSorting={true}
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(Qualifications);
