import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const PatientInfos = (props) => {
    const [patientData, setPatientData] = useState(null);

    useEffect(() => {
        setPatientData(props.users.find((u) => u.id == props.selected));
    }, [props.selected]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card">
            <div className="card-body">
                {patientData && (
                    <Row>
                        <Col sm={4} xs={12}>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>
                                            Születési dátum:
                                        </span>{" "}
                                        {patientData.birth_date
                                            ? patientData.birth_date.replace(
                                                  /-/g,
                                                  "."
                                              )
                                            : "Nincs megadva"}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>
                                            Születési hely:
                                        </span>{" "}
                                        {patientData.birth_place ??
                                            "Nincs megadva"}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>
                                            Anyja neve:
                                        </span>{" "}
                                        {patientData.mothers_name ??
                                            "Nincs megadva"}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>
                                            TAJ szám:
                                        </span>{" "}
                                        {patientData.security_number !== ""
                                            ? patientData.security_number
                                            : "Nincs megadva"}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>
                                            Telefonszám:
                                        </span>{" "}
                                        {patientData.phone ?? "Nincs megadva"}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>
                                            Email cím:
                                        </span>{" "}
                                        {patientData.email ?? "Nincs megadva"}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default PatientInfos;
