import { useEffect, useState } from "react";
import { Col, Media, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get } from "../../config/fetch";

const Institutions = (props) => {
    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        get("admin/institutions")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setInstitutions(response.data.institutions);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    let tableData = {
        columns: [
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Név",
                field: "name",
            },
            {
                label: "Város",
                field: "city",
            },
            {
                label: "Teljes cím",
                field: "full_address",
            },
            {
                label: "Szakterületek",
                field: "qualification",
            },
            {
                label: "Fekvőbeteg ellátás",
                field: "in_patient_available",
            },
            {
                label: "Járóbeteg ellátás",
                field: "out_patient_available",
            },
            {
                label: "Kép",
                field: "image",
            },
            // {
            //   label: "Bemutatkozás",
            //   field: "introduction",
            // },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const fillTableData = (institutionData) => {
        tableData.rows = institutionData.map((institution) => ({
            id: institution.id,
            name: institution.name,
            city: institution.city,
            full_address: institution.full_address,
            qualification: institution.qualifications
                .map((q) => q.name)
                .join(", "),
            in_patient_available:
                institution.in_patient_available == "1" ? "Igen" : "Nem",
            out_patient_available:
                institution.out_patient_available == "1" ? "Igen" : "Nem",
            image: institution.image ? (
                <Media>
                    <Media
                        className="institutionPic"
                        object
                        src={institution.image}
                        alt="Kép"
                    />
                </Media>
            ) : null,
            introduction: institution.introduction
                ? institution.introduction.substring(0, 100) + "..."
                : institution.introduction,
            actions: (
                <Link to={"/institutions/" + institution.id}>Műveletek</Link>
            ),
        }));
    };
    fillTableData(institutions);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Partner Intézmények
                            </h4>
                        </div>
                    </Col>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter == "1" ? (
                        <Col sm="6">
                            <div className="float-right">
                                <Link to={"/institutions/new"}>
                                    <button className="btn btn-primary">
                                        Új intézmény
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    ) : null}
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="institution-table"
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps)(Institutions);
