import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { endLoading, get, post } from "../../config/fetch";
import {
    dispatchPrescriptionRedeemStatusText,
    prescriptionRedeemStatuses,
    responseMessage,
} from "../../utility";
import { ALERT_MESSAGES, PriceApproval } from "../../config/variables";
import FormRow from "../../components/FormRow/FormRow";
import * as actions from "../../store/ui/actions";

const PrescriptionRedeemDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [redeemData, setRedeemData] = useState({
        isLoading: true,
        data: null,
    });
    const redeem = redeemData.data;

    const [redeemProducts, setRedeemProducts] = useState({
        isLoading: true,
        data: [],
    });

    const [piqProducts, setPiqProducts] = useState({
        isLoading: true,
        data: [],
    });

    const [statusOnLoad, setStatusOnLoad] = useState(null);

    const setRedeemDataField = (kkk, value) =>
        setRedeemData({
            isLoading: false,
            data: {
                ...redeemData.data,
                [kkk]: value,
            },
        });

    useEffect(() => {
        get("admin/get-piq-products")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const fetched = response.data.products;
                        setPiqProducts({
                            isLoading: false,
                            data: fetched,
                        });
                        endLoading();

                        break;
                    }
                    default:
                        setPiqProducts({
                            isLoading: false,
                            data: [],
                        });
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                setPiqProducts({
                    isLoading: false,
                    data: [],
                });
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        get(`admin/get-prescription-redeem?id=${id}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const fetchedRedeem = response.data.redeem;
                        setRedeemData({
                            isLoading: false,
                            data: fetchedRedeem,
                        });
                        setStatusOnLoad(fetchedRedeem.status);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        get(`admin/get-prescription-redeem-products?id=${id}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        setRedeemProducts({
                            isLoading: false,
                            data: response.data.products,
                        });
                        endLoading();

                        break;
                    }
                    default:
                        setRedeemProducts({
                            isLoading: false,
                            data: [],
                        });
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                setRedeemProducts({
                    isLoading: false,
                    data: [],
                });
                endLoading();
                //responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if (redeemData.data.status === "declined" && !redeemData.data.note) {
            responseMessage("Kérjük, adjon meg egy megjegyzést!");

            return;
        }
        const prods = redeemProducts.data
            .filter((p) => p.piq_product_id)
            .map((p) => ({
                piqProductId: p.piq_product_id,
                quantity: p.quantity,
                priceApproval: p.price_approval,
                price: p.price,
            }));
        post("admin/update-prescription-redeem", {
            prescription_redeem_id: id,
            status: redeemData.data.status,
            note: redeemData.data.note ?? "",
            products: prods,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/prescription-redeems");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const statusIsEditable = statusOnLoad
        ? ![
              "no_prescription",
              "completed",
              "failed",
              "declined",
              "closed",
          ].includes(statusOnLoad)
        : false;
    const productsAreEditable = statusOnLoad ? statusOnLoad === "new" : false;
    const productOptions =
        piqProducts.data?.map((product) => ({
            value: product.id,
            label: product.name,
        })) ?? [];

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Gyógyszerigény részletek
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="prescription-redeem-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <FormRow
                                        type="text"
                                        name="id"
                                        disabled={true}
                                        label="Gyógyszerigény azonosító"
                                        value={id}
                                    />
                                    <FormRow
                                        type="text"
                                        name="created_at"
                                        disabled={true}
                                        label="Beérkezés dátuma"
                                        value={redeem?.created_at ?? ""}
                                    />
                                    <FormRow
                                        type="text"
                                        name="name"
                                        disabled={true}
                                        label="Név"
                                        value={`${redeem?.last_name ?? ""} ${
                                            redeem?.first_name ?? ""
                                        }`}
                                    />
                                    <FormRow
                                        type="text"
                                        name="name"
                                        disabled={true}
                                        label="TAJ szám"
                                        value={redeem?.security_number ?? ""}
                                    />
                                    <FormRow
                                        type="customselect"
                                        disabled={!statusIsEditable}
                                        name="status"
                                        label="Státusz"
                                        isMulti={false}
                                        placeholder="Válasszon státuszt..."
                                        value={
                                            redeem
                                                ? {
                                                      value: redeem.status,
                                                      label: dispatchPrescriptionRedeemStatusText(
                                                          redeem.status ?? ""
                                                      ),
                                                  }
                                                : null
                                        }
                                        options={prescriptionRedeemStatuses.map(
                                            (status) => ({
                                                value: status,
                                                label: dispatchPrescriptionRedeemStatusText(
                                                    status
                                                ),
                                            })
                                        )}
                                        onChange={(e) => {
                                            setRedeemDataField(
                                                "status",
                                                e.value
                                            );
                                        }}
                                        style={{ marginBottom: 0 }}
                                    />
                                    <hr></hr>
                                    <div
                                        className="row"
                                        style={{
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <div className="col-6">
                                            <h4 className="font-size-18">
                                                Termékek
                                            </h4>
                                        </div>
                                        {productsAreEditable &&
                                            !redeemProducts.isLoading &&
                                            !piqProducts.isLoading && (
                                                <div className="col-6">
                                                    <div className="float-right">
                                                        <div
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                setRedeemProducts(
                                                                    {
                                                                        isLoading: false,
                                                                        data: [
                                                                            ...redeemProducts.data,
                                                                            {
                                                                                piq_product_id:
                                                                                    null,
                                                                                quantity: 0,
                                                                            },
                                                                        ],
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            Új termék hozzáadása
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    {redeemProducts.isLoading ||
                                    piqProducts.isLoading ? (
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginBottom: "20px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            Termékek betöltése...
                                        </span>
                                    ) : (
                                        redeemProducts.data?.map((p, idx) => (
                                            <div className="row" key={idx}>
                                                <div className="col-3">
                                                    <FormRow
                                                        type="customselect"
                                                        name={`product-name-${idx}`}
                                                        disabled={
                                                            !productsAreEditable
                                                        }
                                                        label={`#${
                                                            idx + 1
                                                        } Termék neve`}
                                                        isMulti={false}
                                                        placeholder="Válasszon terméket..."
                                                        value={
                                                            p.piq_product_id
                                                                ? {
                                                                      value: p.piq_product_id,
                                                                      label: piqProducts.data.find(
                                                                          (
                                                                              product
                                                                          ) =>
                                                                              product.id ===
                                                                              p.piq_product_id
                                                                      ).name,
                                                                  }
                                                                : null
                                                        }
                                                        options={productOptions}
                                                        onChange={(e) => {
                                                            const newProducts =
                                                                redeemProducts.data;
                                                            newProducts[
                                                                idx
                                                            ].piq_product_id =
                                                                e.value;
                                                            setRedeemProducts({
                                                                isLoading: false,
                                                                data: newProducts,
                                                            });
                                                        }}
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <FormRow
                                                        type="number"
                                                        disabled={
                                                            !productsAreEditable
                                                        }
                                                        name={`product-quantity-${idx}`}
                                                        label="Mennyiség"
                                                        value={p.quantity}
                                                        onChange={(e) => {
                                                            const newProducts =
                                                                redeemProducts.data;
                                                            newProducts[
                                                                idx
                                                            ].quantity =
                                                                e.target.value;
                                                            setRedeemProducts({
                                                                isLoading: false,
                                                                data: newProducts,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <FormRow
                                                        type="customselect"
                                                        options={
                                                            p.piq_product_id
                                                                ? Object.entries(
                                                                      piqProducts.data.find(
                                                                          (
                                                                              product
                                                                          ) =>
                                                                              product.id ===
                                                                              p.piq_product_id
                                                                      )
                                                                          .price_list
                                                                  )
                                                                      .filter(
                                                                          ([
                                                                              ,
                                                                              v,
                                                                          ]) =>
                                                                              v !==
                                                                              -1
                                                                      )
                                                                      .map(
                                                                          ([
                                                                              k,
                                                                          ]) => ({
                                                                              value: k.toLowerCase(),
                                                                              label: k,
                                                                          })
                                                                      )
                                                                : []
                                                        }
                                                        disabled={
                                                            !productsAreEditable ||
                                                            !p.piq_product_id
                                                        }
                                                        isMulti={false}
                                                        name={`product-priceapproval-${idx}`}
                                                        label="Árjogcím"
                                                        value={
                                                            p.price_approval
                                                                ? {
                                                                      value: PriceApproval[
                                                                          p
                                                                              .price_approval
                                                                      ],
                                                                      label: p.price_approval.toUpperCase(),
                                                                  }
                                                                : null
                                                        }
                                                        onChange={(e) => {
                                                            const newProducts =
                                                                redeemProducts.data;

                                                            const product =
                                                                newProducts[
                                                                    idx
                                                                ];

                                                            product.price_approval =
                                                                e.value;

                                                            const piqProduct =
                                                                piqProducts.data.find(
                                                                    (product) =>
                                                                        product.id ===
                                                                        p.piq_product_id
                                                                );

                                                            product.price =
                                                                piqProduct.price_list[
                                                                    e.value.toUpperCase()
                                                                ];

                                                            setRedeemProducts({
                                                                isLoading: false,
                                                                data: newProducts,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    Ár:{" "}
                                                    {p.price_approval ? (
                                                        <span>
                                                            {
                                                                piqProducts.data.find(
                                                                    (product) =>
                                                                        product.id ===
                                                                        p.piq_product_id
                                                                ).price_list[
                                                                    p.price_approval.toUpperCase()
                                                                ]
                                                            }{" "}
                                                            Ft
                                                        </span>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    )}
                                    <hr></hr>
                                    <FormRow
                                        type="textarea"
                                        name="note"
                                        label="Megjegyzés"
                                        disabled={!productsAreEditable}
                                        value={redeem?.note ?? ""}
                                        onChange={(content) => {
                                            console.log("content", content);
                                            setRedeemDataField(
                                                "note",
                                                content.target.value
                                            );
                                        }}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(PrescriptionRedeemDetails);
