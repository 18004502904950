import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import MessagesTable from "./MessagesTable/MessagesTable";

const QA = () => {
    const [messages, setMessages] = useState(null);

    useEffect(() => {
        get("api/v1/contact/get-messages")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setMessages(response.data.messages);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const filterMessagesByStatus = (status) =>
        messages.filter((c) => c.answered === status);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">{`Q&A Kérdések`}</h4>
                        </div>
                    </Col>
                </Row>
                {messages && (
                    <>
                        <Row>
                            <Col>
                                <MessagesTable
                                    title="Aktív kérdések"
                                    messages={filterMessagesByStatus(0)}
                                    delete={false}
                                    answered={false}
                                    defaultOpen={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MessagesTable
                                    title="Megválaszolt kérdések"
                                    messages={filterMessagesByStatus(1)}
                                    delete={true}
                                    answered={true}
                                    defaultOpen={false}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </>
    );
};

export default QA;
