import { useEffect, useRef } from "react";
import ChatMessage from "../ChatMessage/ChatMessage";
import { MONTHS } from "../../../config/variables";
import { returnWithZero } from "../../../utility";
import classes from "./ChatWindow.module.css";

const ChatWindow = (props) => {
    const startRef = useRef();
    const endRef = useRef();
    const messagesRef = useRef();

    useEffect(() => {
        messagesRef.current.scrollTo({
            top: endRef.current.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    }, [props.messages]);

    const renderDate = (date) =>
        `${MONTHS[date.getMonth()]} ${date.getDate()}, ${returnWithZero(
            date.getHours()
        )}:${returnWithZero(date.getMinutes())}`;
    const renderMessages = () => {
        let messages = [];
        props.messages.forEach((message, index) => {
            const withDate =
                index === 0 ||
                props.messages[index - 1].author !== message.author ||
                message.dateCreated.getTime() -
                    props.messages[index - 1].dateCreated.getTime() >
                    1000 * 60 * 30;
            const type =
                message.type === "text"
                    ? "text"
                    : message.media.contentType.startsWith("image/")
                    ? "image"
                    : "attachment";

            messages.push(
                <ChatMessage
                    key={message.sid}
                    direction={
                        message.author.substr(0, 1) === "d"
                            ? "sent"
                            : "received"
                    }
                    type={type}
                    date={renderDate(message.dateCreated)}
                    withDate={withDate}
                    otherName={props.otherName}
                    text={message.body}
                    media={message.type === "media" ? message.media : null}
                />
            );
        });

        return messages;
    };

    return (
        <div className={classes.ChatWindow} style={props.style}>
            <div className={classes.header}>
                <span className={classes.title}>Üzenetváltás</span>
                <span className={classes.identifier}>
                    Azonosító: {props.identifier}
                </span>
            </div>
            {props.status ? (
                <div className={classes.infos}>
                    <span>
                        {" "}
                        {props.isOpen ? (
                            <p className={classes.info}>Státusz: Nyitott</p>
                        ) : (
                            <p className={classes.info}>Státusz: Lezárt</p>
                        )}
                    </span>
                    <span>
                        {props.status == "0" ? (
                            <p className={classes.info}>Típus: Üzenetváltás</p>
                        ) : null}
                        {props.status == "2" ? (
                            <p className={classes.info}>Típus: Másodvélemény</p>
                        ) : null}
                        {props.status == "1" ? (
                            <p className={classes.info}>
                                Típus: Másodvéleménynek jelölve
                            </p>
                        ) : null}
                    </span>
                </div>
            ) : null}

            <div className={classes.inner} ref={messagesRef}>
                <div className={classes.startDummy} ref={startRef}></div>
                {renderMessages()}
                <div
                    className={classes.endDummy}
                    ref={endRef}
                    id="endRef"
                ></div>
            </div>
        </div>
    );
};

export default ChatWindow;
