// Should ideally use a PatientCase type when it becomes available
import { useSelector } from "../../store";

interface Param {
    userId: number | undefined;
    caseDoctorIds: string[];
}

// Returns null when current user has no access to patient user
// TODO: Extend with hotline and superAdmin logic when NHA supports it
export default ({ userId, caseDoctorIds }: Param) => {
    const selfDoctorId = useSelector((state) => state.auth.access?.doctorId);

    if (!userId || !selfDoctorId || !caseDoctorIds) {
        return null;
    }

    if (!caseDoctorIds.includes(selfDoctorId)) {
        return null;
    }

    return `u${userId}`;
};
