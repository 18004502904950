import { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import { FormControl, MenuItem } from "@material-ui/core";
import classes from "./CustomSelect.module.css";
import "./style.css";

const CustomSelect = (props) => {
    const [value, setValue] = useState(props.value || 0);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const renderOptions = () => {
        let options = [];
        for (let o of props.options) {
            if (!props.optionRender) {
                options.push(
                    <MenuItem
                        value={o[props.optionValue]}
                        key={o[props.optionValue]}
                    >
                        <span className={classes.item}>
                            {o[props.optionKey]}
                        </span>
                    </MenuItem>
                );
            } else {
                options.push(
                    <MenuItem
                        value={o[props.optionValue]}
                        key={o[props.optionValue]}
                    >
                        {props.optionRender(o)}
                    </MenuItem>
                );
            }
        }

        return options;
    };

    const onChangeHandler = (val) => {
        if (props.onChange) {
            props.onChange(val);
        }
        // setValue(val);
    };

    return (
        <div className={classes.container} style={props.contentContainerStyle}>
            <p className={classes.label}>{props.label}</p>
            <FormControl
                className={`${classes.outer} select-form-controll`}
                style={props.controlStyle}
                disabled={props.disabled}
            >
                <Select
                    classes={{ root: classes.select }}
                    value={value}
                    onChange={(event) => onChangeHandler(event.target.value)}
                >
                    {renderOptions()}
                </Select>
            </FormControl>
        </div>
    );
};

export default CustomSelect;
