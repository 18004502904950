import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { endLoading, get, post } from "../../config/fetch";
import { ALERT_MESSAGES } from "../../config/variables";
import { responseMessage } from "../../utility";
import FormRow from "../../components/FormRow/FormRow";

const NewDutyDoctor = () => {
    const navigate = useNavigate();

    const [dutyDoctor, setDutyDoctor] = useState({
        id: "",
        doctor_id: "",
        from: "",
        to: "",
    });

    const { id } = useParams();

    const url = window.location.href.split("/");
    const type = url[url.length - 1];

    const [doctors, setDoctors] = useState([]);

    const upsertDutyDoctor = () => {
        post("admin/duty-doctors", id ? { ...dutyDoctor, id } : dutyDoctor)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        navigate("/duty-doctors");

                        break;
                    }
                    default: {
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    }
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    const getDutyDoctor = () => {
        get(`admin/duty-doctor?id=${id}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        setDutyDoctor(response.data.dutyDoctor);

                        break;
                    }
                    default: {
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    }
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    const getDoctors = () => {
        get("admin/doctors")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        setDoctors(response.data.doctors);

                        break;
                    }
                    default: {
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    }
                }
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    const handleSubmit = (e) => {
        if (!dutyDoctor.doctor_id) {
            responseMessage("Válasszon orvost!");

            return;
        }

        if (!dutyDoctor.from || !dutyDoctor.to) {
            responseMessage("Adja meg az időpontot!");

            return;
        }

        const fromDate = new Date(dutyDoctor.from);
        const toDate = new Date(dutyDoctor.to);

        if (fromDate > toDate) {
            responseMessage("Kezdés nem lehet nagyobb mint a befejezés!");

            return;
        }

        e.preventDefault();
        upsertDutyDoctor();
    };

    useEffect(() => {
        getDoctors();
        if (type !== "new") {
            getDutyDoctor();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Új ügyeletes orvos hozzáadása
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="prescription-redeem-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <FormRow
                                        type="customselect"
                                        name="doctorId"
                                        disabled={type !== "new"}
                                        label="Orvos"
                                        isMulti={false}
                                        placeholder="Válasszon orvost..."
                                        value={
                                            dutyDoctor.doctor_id
                                                ? {
                                                      value: dutyDoctor.doctor_id,
                                                      label:
                                                          doctors.find(
                                                              (d) =>
                                                                  d.doctor_id ===
                                                                  dutyDoctor.doctor_id
                                                          )?.title +
                                                          " " +
                                                          doctors.find(
                                                              (d) =>
                                                                  d.doctor_id ===
                                                                  dutyDoctor.doctor_id
                                                          )?.last_name +
                                                          " " +
                                                          doctors.find(
                                                              (d) =>
                                                                  d.doctor_id ===
                                                                  dutyDoctor.doctor_id
                                                          )?.first_name,
                                                  }
                                                : null
                                        }
                                        onChange={(e) => {
                                            setDutyDoctor({
                                                ...dutyDoctor,
                                                doctor_id: e.value,
                                            });
                                        }}
                                        options={doctors
                                            .filter((d) => d.doctor_id)
                                            .map((d) => ({
                                                value: d.doctor_id,
                                                label: `${d.title} ${d.last_name} ${d.first_name}`,
                                            }))}
                                        style={{ marginBottom: 0 }}
                                    />
                                    <label
                                        htmlFor={"from"}
                                        className="form-label"
                                        style={{
                                            marginRight: "8rem",
                                        }}
                                    >
                                        {" "}
                                        -tól-ig
                                    </label>
                                    <input
                                        type="datetime-local"
                                        id="from"
                                        style={{
                                            padding: "12px 20px",
                                            margin: "8px 5px",
                                            display: "inline-block",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",
                                        }}
                                        name="from"
                                        value={formatDateString(
                                            dutyDoctor.from
                                        )}
                                        onChange={(e) => {
                                            setDutyDoctor({
                                                ...dutyDoctor,
                                                from: e.target.value,
                                            });
                                        }}
                                    />
                                    —
                                    <input
                                        type="datetime-local"
                                        id="to"
                                        style={{
                                            padding: "12px 20px",
                                            margin: "8px 5px",
                                            display: "inline-block",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",
                                        }}
                                        name="to"
                                        value={formatDateString(dutyDoctor.to)}
                                        onChange={(e) => {
                                            setDutyDoctor({
                                                ...dutyDoctor,
                                                to: e.target.value,
                                            });
                                        }}
                                    />
                                    <br />
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const formatDateString = (date) => {
    if (!date) {
        return "";
    }

    const datetime = new Date(date);
    datetime.setMinutes(datetime.getMinutes() - datetime.getTimezoneOffset());

    return datetime.toISOString().slice(0, 16);
};

export default NewDutyDoctor;
