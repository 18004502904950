import { Fragment } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import styles from "./Comments.module.css";

const Comments = ({ propsBus }) => {
    const { userId, comments, newComment, setNewComment, createComment } =
        propsBus;

    const sortedData = comments.length
        ? comments.map((comment) => ({
              ["Szerző"]:
                  comment.first_name || comment.last_name
                      ? `${comment.first_name ?? ""} ${comment.last_name ?? ""}`
                      : null,
              ["Létrehozva"]: comment.created_at,
          }))
        : null;

    return (
        <CollapseBox title="Kommentek" classInput={styles.scope}>
            {sortedData ? (
                comments.map((comment, i) => (
                    <Fragment key={"comment-list" + i}>
                        {i > 0 && <hr />}

                        <ReadOnlyFieldList data={sortedData[i]} />

                        <p>{comment.comment || "-"}</p>
                    </Fragment>
                ))
            ) : (
                <></>
            )}

            <hr />

            <Row>
                <Col sm={2}>
                    <p>
                        <strong>Komment írása:</strong>
                    </p>
                </Col>
                <Col sm={8}>
                    <FormGroup>
                        <Input
                            placeholder="Komment"
                            type="textarea"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <Button
                        color="primary"
                        onClick={() => createComment(userId)}
                    >
                        Küldés
                    </Button>
                </Col>
            </Row>
        </CollapseBox>
    );
};

export default Comments;
