import type { DateTimeString, Ulid } from "tdoc-common";

export enum NextHealthService {
    Pregnancy = "pregnancy",
    Manager = "manager",
    PrePostOp = "pre_post_op",
}

export interface NextHealthSubscription {
    id: Ulid;
    userId: number;
    service: NextHealthService;
    expiresAt: DateTimeString;
    maxDeviceCount: number | null;
    maxRepresentativeCount: number | null;
}

export interface CreateSubscriptionRequest {
    userId: number;
    service: NextHealthService;
    expiresAt: DateTimeString;
    maxDeviceCount: number | null;
    maxRepresentativeCount: number | null;
}

export interface UpdateSubscriptionRequest {
    id: Ulid;
    expiresAt: DateTimeString;
    maxDeviceCount: number | null;
    maxRepresentativeCount: number | null;
}
