import { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import classes from "./ConsiliumTable.module.css";

const ConsiliumTable = (props) => {
    const [tableData, setTableData] = useState(null);
    const [open, setOpen] = useState(props.defaultOpen);

    const generateCreator = (channel) => {
        if (channel.adminName) {
            return channel.adminName;
        }

        const doctor = channel.participants.find(
            (d) => channel.doctor_id === d.doctor_id
        );

        if (doctor) {
            return `${doctor.title ?? ""} ${doctor.last_name} ${
                doctor.first_name
            }`;
        }

        return "";
    };

    const generateParticipants = (channel) => {
        let participantsString = "";
        channel.participants.forEach((p, index) => {
            if (index !== channel.participants.length - 1) {
                participantsString += `${p.title ?? ""} ${p.last_name} ${
                    p.first_name
                }, `;
            } else {
                participantsString += `${p.title ?? ""} ${p.last_name} ${
                    p.first_name
                }`;
            }
        });

        return participantsString;
    };

    const fillTableData = (channels) =>
        channels.map((c) => ({
            id: c.identifier,
            name: c.consilium_name,
            creator: generateCreator(c),
            created_at: c.created_at,
            participants: generateParticipants(c),
            status: c.open == "1" ? "Nyitott" : "Lezárt",
            actions: <Link to={"/consilium/" + c.identifier}>Megnyitás</Link>,
        }));

    useEffect(() => {
        let rows = fillTableData(props.consiliums);

        let tableData = {
            columns: [
                {
                    field: "id",
                    label: "Azonosító",
                },
                {
                    field: "name",
                    label: "Megnevezés",
                },
                {
                    field: "creator",
                    label: "Létrehozó",
                },
                {
                    field: "created_at",
                    label: "Létrehozva",
                },
                // {
                //   field: 'status',
                //   label: 'Státusz' ,

                // },
                {
                    field: "participants",
                    label: "Résztvevők",
                },
                {
                    label: " ",
                    field: "actions",
                },
            ],
            rows,
        };

        setTableData(tableData);
    }, [props.consiliums]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card">
            <div className="card-body">
                <h5
                    className={classes.title}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {props.title}
                </h5>
                {open && (
                    <>
                        <hr></hr>
                        {tableData && (
                            <div className={classes.caseDataTable}>
                                <MDBDataTable
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ConsiliumTable;
