import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get, post } from "../../config/fetch";
import * as actions from "../../store/ui/actions";

const PackageDetails = (props) => {
    const { id } = useParams();

    const [name, setName] = useState("");
    const [hotline, setHotline] = useState("");
    const [duty, setDuty] = useState("");
    const [weekdayDuty, setWeekdayDuty] = useState("");
    const [weekendDuty, setWeekendDuty] = useState("");
    const [freeChat, setFreeChat] = useState("");
    const [chat, setChat] = useState("");
    const [firstChat, setFirstChat] = useState("");
    const [secondOpinions, setSecondOpinions] = useState("");
    const [videoChat, setVideoChat] = useState("");
    const [prescription, setPrescription] = useState("");
    const [dutyFinder, setDutyFinder] = useState("");
    const [policlinicReservation, setPoliclinicReservation] = useState("");
    const [packagePrice, setPackagePrice] = useState("");

    useEffect(() => {
        get(`admin/package?id=${id}`).then((response) => {
            if (response.data.responseCode === "OK") {
                const fetchedPackage = response.data.package;
                setName(fetchedPackage.name);
                setHotline(fetchedPackage.hotline_price);
                setDuty(fetchedPackage.free_duty_per_month);
                setWeekdayDuty(fetchedPackage.duty_weekday_price);
                setWeekendDuty(fetchedPackage.duty_weekend_price);
                setFreeChat(fetchedPackage.free_chat_per_month);
                setChat(fetchedPackage.chat_price);
                setFirstChat(fetchedPackage.first_chat_price);
                setSecondOpinions(fetchedPackage.second_opinion_price);
                setVideoChat(fetchedPackage.video_consultation_min_price);
                setPrescription(fetchedPackage.prescription_price);
                setDutyFinder(fetchedPackage.duty_search_price);
                setPoliclinicReservation(
                    fetchedPackage.outpatient_discount_percentage
                );
                setPackagePrice(fetchedPackage.package_price);
                endLoading();
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePackage = () => {
        const packageData = {
            name,
            hotline_price: hotline,
            free_duty_per_month: duty,
            duty_weekday_price: weekdayDuty,
            duty_weekend_price: weekendDuty,
            free_chat_per_month: freeChat,
            chat_price: chat,
            first_chat_price: firstChat,
            second_opinion_price: secondOpinions,
            video_consultation_min_price: videoChat,
            prescription_price: prescription,
            duty_search_price: dutyFinder,
            outpatient_discount_percentage: policlinicReservation,
            package_price: packagePrice,
        };

        post("admin/update-package", { id, data: packageData })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleSubmit = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan menti a csomag változásait?",
            confirmButton: true,
            confirmButtonText: "Mentés",
            confirmButtonCallback: updatePackage,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Csomag adatok</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="package-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Csomag neve
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a csomag nevét!"
                                                className="form-control"
                                                type="text"
                                                value={name}
                                                onChange={(event) =>
                                                    setName(event.target.value)
                                                }
                                                id="name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="packagePrice"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Csomag ára (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="packagePrice"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a csomag árát!"
                                                className="form-control"
                                                type="number"
                                                value={packagePrice}
                                                onChange={(event) =>
                                                    setPackagePrice(
                                                        event.target.value
                                                    )
                                                }
                                                id="packagePrice"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="hotline"
                                            className="col-sm-2 col-form-label"
                                        >
                                            HOTLINE (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="hotline"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg HOTLINE árát!"
                                                className="form-control"
                                                type="number"
                                                value={hotline}
                                                onChange={(event) =>
                                                    setHotline(
                                                        event.target.value
                                                    )
                                                }
                                                id="hotline"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="duty"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Havi ingyenes orvosi ügyelet (db)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="duty"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az ingyenes orvosi ügyeletek számát!"
                                                className="form-control"
                                                type="number"
                                                value={duty}
                                                onChange={(event) =>
                                                    setDuty(event.target.value)
                                                }
                                                id="duty"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="weekdayDuty"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Orvosi ügyelet - Hétköznap (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="weekdayDuty"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a hétköznapi orvosi ügyelet árát!"
                                                className="form-control"
                                                type="number"
                                                value={weekdayDuty}
                                                onChange={(event) =>
                                                    setWeekdayDuty(
                                                        event.target.value
                                                    )
                                                }
                                                id="weekdayDuty"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="weekendDuty"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Orvosi ügyelet - Hétvége (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="weekendDuty"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a hétvégi orvosi ügyelet árát!"
                                                className="form-control"
                                                type="number"
                                                value={weekendDuty}
                                                onChange={(event) =>
                                                    setWeekendDuty(
                                                        event.target.value
                                                    )
                                                }
                                                id="weekendDuty"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="free-chat"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Havi ingyenes orvosi chat (db)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="free-chat"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a chat árát!"
                                                className="form-control"
                                                type="number"
                                                value={freeChat}
                                                onChange={(event) =>
                                                    setFreeChat(
                                                        event.target.value
                                                    )
                                                }
                                                id="free-chat"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="chat"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Orvosi Chat (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="chat"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a chat árát!"
                                                className="form-control"
                                                type="number"
                                                value={chat}
                                                onChange={(event) =>
                                                    setChat(event.target.value)
                                                }
                                                id="chat"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="chat"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Orvosi Chat első alkalom (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="firstChat"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az első chat árát!"
                                                className="form-control"
                                                type="number"
                                                value={firstChat}
                                                onChange={(event) =>
                                                    setFirstChat(
                                                        event.target.value
                                                    )
                                                }
                                                id="firstChat"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="secondOpinions"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Másodvélemény (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="secondOpinions"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a másodvélemény árát!"
                                                className="form-control"
                                                type="number"
                                                value={secondOpinions}
                                                onChange={(event) =>
                                                    setSecondOpinions(
                                                        event.target.value
                                                    )
                                                }
                                                id="secondOpinions"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="videoChat"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Videókonzultáció (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="videoChat"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a videókonzultáció árát!"
                                                className="form-control"
                                                type="number"
                                                value={videoChat}
                                                onChange={(event) =>
                                                    setVideoChat(
                                                        event.target.value
                                                    )
                                                }
                                                id="videoChat"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="prescription"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Receptírás - További alkalmak (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="prescription"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a további receptírás árát!"
                                                className="form-control"
                                                type="number"
                                                value={prescription}
                                                onChange={(event) =>
                                                    setPrescription(
                                                        event.target.value
                                                    )
                                                }
                                                id="prescription"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="dutyFinder"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Ügyeletkereső (Ft)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="dutyFinder"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az ügyeletkereső árát!"
                                                className="form-control"
                                                type="number"
                                                value={dutyFinder}
                                                onChange={(event) =>
                                                    setDutyFinder(
                                                        event.target.value
                                                    )
                                                }
                                                id="dutyFinder"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="policlinicReservation"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Járóbetegellátás foglalás kedvezmény
                                            (%)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="policlinicReservation"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a kedvezmény mértékét!"
                                                className="form-control"
                                                type="number"
                                                value={policlinicReservation}
                                                onChange={(event) =>
                                                    setPoliclinicReservation(
                                                        event.target.value
                                                    )
                                                }
                                                id="policlinicReservation"
                                            />
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(PackageDetails);
