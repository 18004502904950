import { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import FormRow from "../../../components/FormRow/FormRow";
import { endLoading, post } from "../../../config/fetch";
import { convertDate, responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./NewCase.module.css";

const NewCase = (props) => {
    const [data, setData] = useState({
        patient: null,
        // name: null,
        firstName: null,
        lastName: null,
        gender: "Férfi",
        securityNumber: null,
        idNumber: null,
        vip: false,
        takeEarlier: false,
        email: null,
        phone: null,
        birthDate: null,
        qualification: null,
        doctors: null,
        institution: null,
        description: "",
        surgerySelect: null,
        surgeryText: "",
        surgeryPrice: null,
        prosthesisPrice: null,
        examinationSize: null,
        examinationDescription: null,
        tools: null,
        anestDate: null,
        surgeryDate: null,
        pingDate: null,
        status: null,
        surgeryStatus: null,
        caseManagers: [],
    });

    const caseManagersToOptions = (caseManagers) =>
        caseManagers.map((c) => ({
            value: c.id,
            label: `${c.last_name} ${c.first_name}`,
        }));

    const setDataByKey = (key, value) => setData({ ...data, [key]: value });

    const [open, setOpen] = useState(false);

    const [caseManagerOptions, setCaseManagerOptions] = useState(
        caseManagersToOptions(props.caseManagers)
    );

    const getCaseManagersByQualificationAndInstitution = (qid, iid) => {
        post("admin/get-cmqi", { qid, iid })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setCaseManagerOptions(
                            caseManagersToOptions(response.data.caseManagers)
                        );
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleSubmit = () => {
        if (data.patient === null) {
            responseMessage("A páciens kiválasztása kötelező!");

            return;
        }

        if (!data.caseManagers.length) {
            responseMessage("Az esetmenedzser kiválasztása kötelező!");

            return;
        }

        if (data.patient.value == "0" && (!data.lastName || !data.firstName)) {
            responseMessage("A név megadása kötelező!");

            return;
        }

        // const _data = {
        //   patientId: data.patient ? data.patient.value : null,
        //   qualificationId: data.qualification ? data.qualification.value : null,
        //   doctorId: data.doctor ? data.doctor.value : null,
        //   institutionId: data.institution ? data.institution.value : null,
        //   // surgeryTypeName: typeof data.surgeryType === 'string' ?  data.surgeryType : data.surgeryType.label,
        //   // surgeryTypeId: (data.surgeryType && typeof data.surgeryType !== 'string') ? data.surgeryType.value : null,

        //   surgeryTypeName: (data.surgerySelect && data.surgerySelect.value !== '0') ? data.surgerySelect.label : data.surgeryText,
        //   surgeryTypeId:  (data.surgerySelect && data.surgerySelect.value !== '0') ? data.surgerySelect.value : null,

        //   status: data.status ? data.status.value : null,
        //   surgeryStatus: data.surgeryStatus ? data.surgeryStatus.value : null,
        //   // name: data.name,
        //   description: data.description ?? "",
        //   firstName: data.firstName,
        //   lastName: data.lastName,
        //   gender: data.gender,
        //   securityNumber: data.securityNumber,
        //   idNumber: data.idNumber,
        //   vip: data.vip ? '1' : '0',
        //   takeEarlier: data.takeEarlier ? '1' : '0',
        //   email: data.email,
        //   phone: data.phone,
        //   birthDate: data.birthDate,
        //   surgeryPrice: data.surgeryPrice,
        //   prosthesisPrice: data.prosthesisPrice,
        //   examinationSize: data.examinationSize ? data.examinationSize.value : null,
        //   examinationDescription: data.examinationDescription,
        //   tools: data.tools,
        //   anestDate: data.anestDate,
        //   surgeryDate: data.surgeryDate,
        //   pingDate: data.pingDate
        // }

        // console.log(_data);

        post("admin/new-case", {
            patientId: data.patient ? data.patient.value : null,
            qualificationId: data.qualification
                ? data.qualification.value
                : null,
            doctorIds: data.doctors?.map((d) => d.value),
            institutionId: data.institution ? data.institution.value : null,
            caseManagerIds: data.caseManagers.map((cm) => cm.value),
            // surgeryTypeName: typeof data.surgeryType === 'string' ?  data.surgeryType : data.surgeryType.label,
            // surgeryTypeId: (data.surgeryType && typeof data.surgeryType !== 'string') ? data.surgeryType.value : null,

            surgeryTypeName:
                data.surgerySelect && data.surgerySelect.value != "0"
                    ? data.surgerySelect.label
                    : data.surgeryText,
            surgeryTypeId:
                data.surgerySelect && data.surgerySelect.value != "0"
                    ? data.surgerySelect.value
                    : null,

            status: data.status ? data.status.value : null,
            surgeryStatus: data.surgeryStatus ? data.surgeryStatus.value : null,
            // name: data.name,
            description: data.description ?? "",
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            securityNumber: data.securityNumber,
            idNumber: data.idNumber,
            vip: data.vip ? "1" : "0",
            takeEarlier: data.takeEarlier ? "1" : "0",
            email: data.email,
            phone: data.phone,
            birthDate: data.birthDate,
            surgeryPrice: data.surgeryPrice,
            prosthesisPrice: data.prosthesisPrice,
            examinationSize: data.examinationSize
                ? data.examinationSize.value
                : null,
            examinationDescription: data.examinationDescription,
            tools: data.tools,
            anestDate: data.anestDate,
            surgeryDate: data.surgeryDate,
            pingDate: data.pingDate,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres felvétel!");
                        props.newCaseAdded();

                        endLoading();

                        break;
                    case "USER_MISSING":
                        responseMessage("A páciens kiválasztása kötelező!");
                        endLoading();

                        break;
                    case "CASE_MANAGER_MISSING":
                        responseMessage(
                            "Az esetmenedzser kiválasztása kötelező!"
                        );
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const generatePatientOptions = () => {
        let patients = props.users.map((u) => ({
            value: u.id,
            label: `${u.last_name} ${u.first_name} ${
                u.birth_date ? u.birth_date.replace(/-/g, ".") : ""
            }`,
        }));
        patients = [
            {
                value: "0",
                label: (
                    <span style={{ fontWeight: "bold" }}>
                        Nem regisztrált páciens
                    </span>
                ),
            },
            ...patients,
        ];

        return patients;
    };

    const generateSurgeryTypes = (qualificationId, types) => {
        const _types = types.filter(
            (t) => t.qualification_id == qualificationId
        );
        const _arr = _types.map((t) => ({
            value: t.id,
            label: t.name,
        }));

        return [
            {
                value: "0",
                label: <span style={{ fontWeight: "bold" }}>Egyedi műtét</span>,
            },
            ..._arr,
        ];
    };

    return (
        <Card>
            <CardBody>
                <h5
                    className={classes.title}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    Új eset felvétele
                </h5>
                {open && (
                    <>
                        <hr></hr>
                        <AvForm onValidSubmit={handleSubmit}>
                            <FormRow
                                type="customselect"
                                name="patient"
                                label="Páciens"
                                value={data.patient}
                                isMulti={false}
                                placeholder="Válasszon pácienst..."
                                options={generatePatientOptions()}
                                onChange={(e) => setDataByKey("patient", e)}
                            />
                            <FormRow
                                type="customselect"
                                name="case-managers"
                                label="Esetmenedzserek"
                                value={data.caseManagers}
                                isMulti={true}
                                placeholder="Válasszon esetmenedzsert..."
                                options={caseManagerOptions}
                                onChange={(e) =>
                                    setDataByKey("caseManagers", e)
                                }
                            />
                            {data.patient && data.patient.value == "0" && (
                                <>
                                    <FormRow
                                        type="text"
                                        name="lastName"
                                        label="Vezetéknév"
                                        value={data.lastName}
                                        validate={true}
                                        errorMessage="Adja meg a páciens keresztnevét"
                                        onChange={(e) =>
                                            setDataByKey(
                                                "lastName",
                                                e.target.value
                                            )
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                    <FormRow
                                        type="text"
                                        name="firstName"
                                        label="Keresztnév"
                                        value={data.firstName}
                                        validate={true}
                                        errorMessage="Adja meg a páciens keresztnevét"
                                        onChange={(e) =>
                                            setDataByKey(
                                                "firstName",
                                                e.target.value
                                            )
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                    <FormRow
                                        type="datepicker"
                                        name="birthDate"
                                        label="Születési dátum"
                                        value={data.birthDate}
                                        disableFuture={true}
                                        onChange={(e) =>
                                            setDataByKey(
                                                "birthDate",
                                                convertDate(e)
                                            )
                                        }
                                    />
                                    <FormRow
                                        type="select"
                                        name="gender"
                                        label="Neme"
                                        value={data.gender}
                                        options={[
                                            { value: "Férfi", label: "Férfi" },
                                            { value: "Nő", label: "Nő" },
                                        ]}
                                        onChange={(e) =>
                                            setDataByKey("gender", e)
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                    <FormRow
                                        type="text"
                                        name="securityNumber"
                                        label="TAJ szám"
                                        value={data.securityNumber}
                                        validate={false}
                                        // errorMessage="Adja meg a páciens TAJ számát"
                                        onChange={(e) =>
                                            setDataByKey(
                                                "securityNumber",
                                                e.target.value
                                            )
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                    <FormRow
                                        type="text"
                                        name="idNumber"
                                        label="Igazolvány szám"
                                        value={data.idNumber}
                                        validate={false}
                                        // errorMessage="Adja meg a páciens igazolvány számát"
                                        onChange={(e) =>
                                            setDataByKey(
                                                "idNumber",
                                                e.target.value
                                            )
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                    <FormRow
                                        type="text"
                                        name="email"
                                        label="Email"
                                        value={data.email}
                                        validate={false}
                                        // errorMessage="Adja meg a páciens nevét"
                                        onChange={(e) =>
                                            setDataByKey(
                                                "email",
                                                e.target.value
                                            )
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                    <FormRow
                                        type="text"
                                        name="phone"
                                        label="Telefonszám"
                                        value={data.phone}
                                        validate={false}
                                        // errorMessage="Adja meg a páciens nevét"
                                        onChange={(e) =>
                                            setDataByKey(
                                                "phone",
                                                e.target.value
                                            )
                                        }
                                        style={{ marginBottom: 0 }}
                                    />
                                </>
                            )}
                            <FormRow
                                type="customselect"
                                name="qualification"
                                label="Szakterület"
                                value={data.qualification}
                                isMulti={false}
                                placeholder="Válasszon szakterületet..."
                                options={props.qualifications.map((q) => ({
                                    value: q.id,
                                    label: q.name,
                                }))}
                                onChange={(e) => {
                                    setDataByKey("qualification", e);
                                    if (data.institution) {
                                        getCaseManagersByQualificationAndInstitution(
                                            e.value,
                                            data.institution.value
                                        );
                                    }
                                }}
                            />
                            <FormRow
                                type="customselect"
                                name="doctor"
                                label="Orvosok"
                                value={data.doctors}
                                isMulti={true}
                                placeholder="Válasszon orvost..."
                                options={props.doctors.map((d) => ({
                                    value: d.id,
                                    label: `${d.title} ${d.last_name} ${d.first_name}`,
                                }))}
                                onChange={(e) => setDataByKey("doctors", e)}
                            />
                            <FormRow
                                type="customselect"
                                name="institution"
                                label="Helyszín"
                                value={data.institution}
                                isMulti={false}
                                placeholder="Válasszon intézményt..."
                                options={props.institutions.map((i) => ({
                                    value: i.id,
                                    label: i.name,
                                }))}
                                onChange={(e) => {
                                    setDataByKey("institution", e);
                                    if (data.qualification) {
                                        getCaseManagersByQualificationAndInstitution(
                                            data.qualification.value,
                                            e.value
                                        );
                                    }
                                }}
                            />
                            {data["qualification"] &&
                                (data["qualification"].value == "26" ||
                                    data["qualification"].value == "27") && (
                                    <FormRow
                                        type="customselect"
                                        name="surgerySelect"
                                        label="Műtét típus választó"
                                        placeholder="Válasszon..."
                                        value={data.surgerySelect}
                                        options={generateSurgeryTypes(
                                            data["qualification"].value,
                                            props.surgeryTypes
                                        )}
                                        onChange={(e) =>
                                            setDataByKey("surgerySelect", e)
                                        }
                                        // style={{marginBottom: 0}}
                                    />
                                )}
                            {(!data.qualification ||
                                (data.qualification &&
                                    data["qualification"].value != "26" &&
                                    data["qualification"].value != "27") ||
                                (data.surgerySelect &&
                                    data.surgerySelect.value == "0")) && (
                                <FormRow
                                    type="text"
                                    name="surgeryText"
                                    label="Műtéti típus"
                                    value={data.surgeryText}
                                    validate={false}
                                    // errorMessage="Adja meg a páciens nevét"
                                    onChange={(e) =>
                                        setDataByKey(
                                            "surgeryText",
                                            e.target.value
                                        )
                                    }
                                    style={{ marginBottom: 0 }}
                                />
                            )}
                            <FormRow
                                type="datepicker"
                                name="anestDate"
                                label="Aneszt. időpontja"
                                value={data.anestDate}
                                withTime={true}
                                disablePast={true}
                                onChange={(e) =>
                                    setDataByKey(
                                        "anestDate",
                                        convertDate(e, "-", true)
                                    )
                                }
                            />
                            <FormRow
                                type="datepicker"
                                name="surgeryDate"
                                label="Műtét időpontja"
                                value={data.surgeryDate}
                                withTime={true}
                                disablePast={true}
                                onChange={(e) =>
                                    setDataByKey(
                                        "surgeryDate",
                                        convertDate(e, "-", true)
                                    )
                                }
                            />
                            <FormRow
                                type="datepicker"
                                name="pingDate"
                                label="Emlékeztető"
                                value={data.pingDate}
                                withTime={true}
                                disablePast={true}
                                onChange={(e) =>
                                    setDataByKey(
                                        "pingDate",
                                        convertDate(e, "-", true)
                                    )
                                }
                            />
                            <FormRow
                                type="select"
                                name="vip"
                                label="VIP"
                                value={data.vip}
                                options={[
                                    { value: false, label: "Nem" },
                                    { value: true, label: "Igen" },
                                ]}
                                onChange={(e) => setDataByKey("vip", e)}
                                style={{ marginBottom: 0 }}
                            />
                            <FormRow
                                type="select"
                                name="takeEarlier"
                                label="Vállalja korábban?"
                                value={data.takeEarlier}
                                options={[
                                    { value: false, label: "Nem" },
                                    { value: true, label: "Igen" },
                                ]}
                                onChange={(e) => setDataByKey("takeEarlier", e)}
                                style={{ marginBottom: 0 }}
                            />
                            <FormRow
                                type="customselect"
                                name="status"
                                label="Eset Státusz"
                                value={data.status}
                                isMulti={false}
                                placeholder="Válasszon eset státuszt..."
                                options={props.statuses.map((s) => ({
                                    value: s.id,
                                    label: s.name,
                                }))}
                                onChange={(e) => setDataByKey("status", e)}
                            />
                            <FormRow
                                type="customselect"
                                name="surgeryStatus"
                                label="Műtét Státusz"
                                value={data.surgeryStatus}
                                isMulti={false}
                                placeholder="Válasszon műtét státuszt..."
                                options={props.surgeryStatuses.map((s) => ({
                                    value: s.id,
                                    label: s.name,
                                }))}
                                onChange={(e) =>
                                    setDataByKey("surgeryStatus", e)
                                }
                            />
                            <hr></hr>
                            <button type="submit" className="btn btn-primary">
                                Felvétel
                            </button>
                        </AvForm>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default NewCase;
