import { Fragment, useState } from "react";
import { Row } from "reactstrap";
import CouponsComponent from "../../../../components/UserComponents/Coupons/Coupons";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import { PACKAGES, serviceNames } from "../../../../config/variables";
import styles from "./Coupons.module.css";

const Coupons = ({ propsBus, refreshCoupons }) => {
    const [isEditing, setIsEditing] = useState(false);

    const { props, userId, coupons } = propsBus;

    const sortedData = coupons?.length
        ? coupons.map((coupon) => ({
              ["Kupon kód"]: coupon.coupon_code,
              ["Elérhető"]: coupon.available ? "Igen" : "Nem",
              ["Típus"]: coupon.typeName,
              ["Létrehozva"]: coupon.created_at
                  ? `${coupon.adminLastName ?? ""} ${
                        coupon.adminFirstName ?? ""
                    } ${coupon.created_at}`
                  : null,
              ["Kedvezmény"]: coupon.discount_amount
                  ? `${coupon.discount_amount} Ft`
                  : coupon.discount_interval_days
                  ? `${coupon.discount_interval_days} nap`
                  : coupon.discount_percentage
                  ? `${coupon.discount_percentage} %`
                  : "",
              ["Csomag"]: PACKAGES[coupon.package_id],
              ["Szolgáltatás"]: serviceNames[coupon.service],
              ["Min. kosárérték"]: coupon.minimum_value,
              ["Lejárat"]: coupon.expires_at,
              ["Felhasznált"]: coupon.used ? "Igen" : "Nem",
              ["Inaktiválva"]: coupon.invalidated_at,
              ["Publikus megjegyzés"]: coupon.public_comment,
              ["Privát megjegyzés"]: coupon.private_comment,
          }))
        : null;

    return (
        <CollapseBox
            title="Kuponok"
            classInput={styles.scope}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            buttonSave="List nézet"
        >
            {isEditing ? (
                <>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter == "1" ? (
                        <Row>
                            {coupons?.length ? (
                                <div className="col-12">
                                    <CouponsComponent
                                        coupons={coupons}
                                        userId={userId}
                                        refreshCoupons={refreshCoupons}
                                    />
                                </div>
                            ) : (
                                <div className="col">
                                    <div className="d-block mb-2">
                                        <span>Nincs még hozzáadott kupon.</span>
                                    </div>
                                    <div className="d-block">
                                        <CouponsComponent
                                            onlyAddCoupon={true}
                                            refreshCoupons={refreshCoupons}
                                        />
                                    </div>
                                </div>
                            )}
                        </Row>
                    ) : null}
                </>
            ) : sortedData?.length ? (
                sortedData.map((data, i) => (
                    <Fragment key={"coupon-list" + i}>
                        {i > 0 && <hr />}
                        <ReadOnlyFieldList data={data} />
                    </Fragment>
                ))
            ) : (
                <div className="">
                    <div className="d-block">
                        <span>Nincs még hozzáadott kupon.</span>
                    </div>
                </div>
            )}
        </CollapseBox>
    );
};

export default Coupons;
