import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import { connect } from "react-redux";
import FileUploader from "../../components/FileUploader/FileUploader";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import * as actions from "../../store/ui/actions";

let originalQualifications = [];

const InstitutionDetails = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const url = window.location.href.split("/");
    const type = url[url.length - 1];

    const emptyInstitutionData = {
        name: null,
        full_address: null,
        city: null,
        in_patient_available: false,
        out_patient_available: false,
        introduction: "",
    };

    const [qualifications, setQualifications] = useState([]);
    const [instQualifications, setInstQualifications] = useState([]);

    const [institutionData, setInstitutionData] =
        useState(emptyInstitutionData);
    const [imageURL, setImageURL] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [inPatientAvailable, setInPatientAvailable] = useState(null);
    const [outPatientAvailable, setOutPatientAvailable] = useState(null);
    const [profileLink, setProfileLink] = useState("");

    const setInstitutionDataByKey = (key, value) =>
        setInstitutionData({ ...institutionData, [key]: value });

    useEffect(() => {
        get("admin/get-qualifications-institutions")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        setQualifications(response.data.qualifications);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    useEffect(() => {
        if (id !== "new" && type !== "new") {
            get(`admin/institution?id=${id}`)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK": {
                            const fetchedInstitution =
                                response.data.institution;
                            fetchedInstitution.in_patient_available =
                                fetchedInstitution.in_patient_available == "1";
                            fetchedInstitution.out_patient_available =
                                fetchedInstitution.out_patient_available == "1";
                            setInPatientAvailable(
                                fetchedInstitution.in_patient_available
                            );
                            setOutPatientAvailable(
                                fetchedInstitution.out_patient_available
                            );
                            setInstitutionData(fetchedInstitution);
                            setImageURL(fetchedInstitution.image);
                            setProfileLink(fetchedInstitution.profile_link);
                            setInstQualifications(response.data.qualifications);
                            originalQualifications =
                                response.data.qualifications;
                            endLoading();

                            break;
                        }
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    const handleSubmit = () => {
        const newInstitution = institutionData;
        newInstitution.in_patient_available =
            inPatientAvailable === true ? "1" : "0";
        newInstitution.out_patient_available =
            outPatientAvailable === true ? "1" : "0";
        const capitalizedCity =
            newInstitution.city.charAt(0).toUpperCase() +
            newInstitution.city.slice(1);

        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("id", id);
        formData.append("name", newInstitution.name);
        formData.append("full_address", newInstitution.full_address);
        formData.append("city", capitalizedCity);
        formData.append(
            "in_patient_available",
            newInstitution.in_patient_available
        );
        formData.append(
            "out_patient_available",
            newInstitution.out_patient_available
        );
        formData.append("introduction", newInstitution.introduction);
        formData.append("profileLink", profileLink);

        if (type === "new") {
            formData.append(
                "qualifications",
                JSON.stringify(instQualifications)
            );

            post("admin/new-institution", formData)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            navigate("/institutions");
                            endLoading();

                            break;
                        case "PROFILE_LINK_TAKEN":
                            endLoading();
                            responseMessage("A profil link foglalt!");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
        if (type !== "new") {
            if (
                JSON.stringify(originalQualifications) !==
                JSON.stringify(instQualifications)
            ) {
                formData.append(
                    "qualifications",
                    JSON.stringify(instQualifications)
                );
            }

            post("admin/update-institution", formData)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("Sikeres mentés!");
                            endLoading();

                            break;
                        case "PROFILE_LINK_TAKEN":
                            endLoading();
                            responseMessage("A profil link foglalt!");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const deleteInstitution = () => {
        if (id === "new") {
            return;
        }
        post("admin/delete-institution", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/institutions");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törli az intézményt? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteInstitution,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleSelectChange = (e) => {
        if (e === null) {
            return;
        }
        const modified = e.map((q) => ({ id: q.value, name: q.label }));
        setInstQualifications(modified);
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                {type === "new"
                                    ? "Új intézmény"
                                    : "Intézmény adatok"}
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="institution-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Név
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az intézmény nevét!"
                                                className="form-control"
                                                type="text"
                                                value={institutionData.name}
                                                onChange={(event) =>
                                                    setInstitutionDataByKey(
                                                        "name",
                                                        event.target.value
                                                    )
                                                }
                                                id="name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="city"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Város
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="city"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az intézmény városát!"
                                                className="form-control"
                                                type="text"
                                                value={institutionData.city}
                                                onChange={(event) =>
                                                    setInstitutionDataByKey(
                                                        "city",
                                                        event.target.value
                                                    )
                                                }
                                                id="city"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="full_address"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Teljes cím
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="full_address"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az intézmény teljes címét!"
                                                className="form-control"
                                                type="text"
                                                value={
                                                    institutionData.full_address
                                                }
                                                onChange={(event) =>
                                                    setInstitutionDataByKey(
                                                        "full_address",
                                                        event.target.value
                                                    )
                                                }
                                                id="full_address"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="qualification"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Szakterületek
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                value={instQualifications.map(
                                                    (q) => ({
                                                        value: q.id,
                                                        label: q.name,
                                                    })
                                                )}
                                                isMulti={true}
                                                placeholder="Válasszon szakterületet..."
                                                options={qualifications.map(
                                                    (q) => ({
                                                        value: q.id,
                                                        label: q.name,
                                                    })
                                                )}
                                                onChange={(e) =>
                                                    handleSelectChange(e)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Fekvőbeteg ellátás
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="in_patient_available">
                                                <AvCheckbox
                                                    checked={inPatientAvailable}
                                                    onChange={(event) =>
                                                        setInPatientAvailable(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Járobeteg ellátás
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="out_patient_available">
                                                <AvCheckbox
                                                    checked={
                                                        outPatientAvailable
                                                    }
                                                    onChange={(event) =>
                                                        setOutPatientAvailable(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="profileLink"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Profil link
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="profileLink"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a profil linket!"
                                                className="form-control"
                                                type="text"
                                                value={profileLink}
                                                onChange={(event) =>
                                                    setProfileLink(
                                                        event.target.value
                                                    )
                                                }
                                                id="profileLink"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "1rem" }}
                                    >
                                        <label
                                            htmlFor="image"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kép
                                        </label>
                                        <Col sm={10}>
                                            <FileUploader
                                                handleFile={handleFile}
                                            />
                                            {imageURL ? (
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                    onClick={cancelImageUpload}
                                                >
                                                    Mégsem
                                                </button>
                                            ) : null}
                                            {imageURL ? (
                                                <div className="image-preview">
                                                    <img
                                                        className="image"
                                                        src={imageURL}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="introduction"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Bemutatkozás
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="introduction"
                                                className="form-control"
                                                type="textarea"
                                                rows={6}
                                                value={
                                                    institutionData.introduction
                                                }
                                                onChange={(event) =>
                                                    setInstitutionDataByKey(
                                                        "introduction",
                                                        event.target.value
                                                    )
                                                }
                                                id="introduction"
                                            />
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                    {type !== "new" && (
                                        <button
                                            type="button"
                                            className="btn btn-danger float-right"
                                            onClick={handleDelete}
                                        >
                                            Intézmény törlése
                                        </button>
                                    )}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(InstitutionDetails);
