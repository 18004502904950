import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import huLocale from "date-fns/locale/hu";

const DatePicker = (props) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={huLocale}>
        {props.withTime ? (
            <KeyboardDateTimePicker
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                minDate={props.minDate}
                minDateMessage="A holnapi dátum a minimum"
                invalidDateMessage="Nem megfelelő dátum"
                ampm={false}
                // margin={props.margin}
                // id="date-picker-dialog"
                // label="Születési idő"
                format="yyyy.MM.dd HH:mm"
                value={props.value}
                onChange={props.onChange}
                okLabel="Rendben"
                cancelLabel="Mégsem"
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
            />
        ) : (
            <KeyboardDatePicker
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                minDate={props.minDate}
                minDateMessage="A holnapi dátum a minimum"
                invalidDateMessage="Nem megfelelő dátum"
                // margin={props.margin}
                // id="date-picker-dialog"
                // label="Születési idő"
                format="yyyy.MM.dd"
                value={props.value}
                onChange={props.onChange}
                okLabel="Rendben"
                cancelLabel="Mégsem"
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
            />
        )}
    </MuiPickersUtilsProvider>
);

export default DatePicker;
