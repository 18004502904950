import { useState } from "react";
import { format, parseISO } from "date-fns";
import SubscriptionChange from "../../../../components/UserComponents/SubscriptionChange/SubscriptionChange";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import { NH_PACKAGES, PACKAGES } from "../../../../config/variables";
import NextHealthSubscriptionAddOrUpdate from "../../../UserComponents/NextHealthSubscriptionAddOrUpdate/NextHealthSubscriptionAddOrUpdate";
import styles from "./Subscriptions.module.css";

const Subscriptions = ({ propsBus }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [nhaSubscriptions, setNhaSubscriptions] = useState(
        propsBus.nhaSubscriptions
    );

    const { props, packageInfo, doctors, handleSubscriptionChange } = propsBus;

    const sortedData = {
        ["Aktuális előfizetés"]: PACKAGES[packageInfo.id],
        ["Következő előfizetés"]: PACKAGES[packageInfo.nextMonthPackage],
        ["Kártya terhelés"]: packageInfo.nextCharge,
        ["Időszak vége"]: packageInfo.paymentNextDate,
    };

    const nhPackages = {
        ...NH_PACKAGES,
        pre_post_op: "PrePostOp",
    };

    return (
        <CollapseBox
            title="Előfizetések"
            classInput={styles.scope}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
        >
            {isEditing ? (
                <>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter == "1"
                        ? nhaSubscriptions && (
                              <SubscriptionChange
                                  packageInfo={packageInfo}
                                  handleSubscriptionChange={
                                      handleSubscriptionChange
                                  }
                              />
                          )
                        : null}
                </>
            ) : (
                <>
                    {nhaSubscriptions.length > 0 && (
                        <>
                            <h5 className="m-0">Előfizetés</h5>
                            <br />
                        </>
                    )}
                    <ReadOnlyFieldList data={sortedData} />
                </>
            )}
            {isEditing ? (
                <>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter == "1" ? (
                        <NextHealthSubscriptionAddOrUpdate
                            nhaSubscriptions={nhaSubscriptions}
                            doctors={doctors}
                            setNhaSubscriptions={setNhaSubscriptions}
                        />
                    ) : null}
                </>
            ) : nhaSubscriptions.length > 0 ? (
                <>
                    <hr />
                    <h5 className="m-0">Adatalapú csomagok</h5>
                    <br />
                    {nhaSubscriptions.map((nhaSubscription) => {
                        const sortedData = {
                            ["Csomag megnevezése"]:
                                nhPackages[nhaSubscription.service],
                            ["Lejárat"]: format(
                                parseISO(nhaSubscription.expiresAt),
                                "yyyy-MM-dd"
                            ),
                            ["Eszközök száma"]: nhaSubscription.maxDeviceCount
                                ? nhaSubscription.maxDeviceCount
                                : "∞",
                            ["Meghatalmazottak száma"]:
                                nhaSubscription.maxRepresentativeCount
                                    ? nhaSubscription.maxRepresentativeCount
                                    : "∞",
                        };

                        return (
                            <div key={nhaSubscription.id}>
                                <ReadOnlyFieldList data={sortedData} />
                            </div>
                        );
                    })}
                </>
            ) : null}
        </CollapseBox>
    );
};

export default Subscriptions;
