import { forwardRef, useEffect, useState } from "react";
import classes from "./Textarea.module.css";

const Textarea = forwardRef((props, ref) => {
    const [text, setText] = useState(props.value);

    useEffect(() => {
        setText(props.value);
    }, [props.value]);

    const onChangeText = (e) => {
        if (e.target.value.length <= 500) {
            if (props.onChange) {
                props.onChange(e.target.value);
            }
        }
    };
    if (props.customRender) {
        return (
            // <div style={props.contentContainerStyle} className={props.contentContainerClassname}>
            //     {props.label && <p className={classes.label}>{props.label}</p>}
            //     <div className={`${classes.container} ${props.containerClassname}`} style={props.containerStyle}>
            //         {props.customRenderFunction()}
            //     </div>
            // </div>
            <div
                style={props.contentContainerStyle}
                className={props.contentContainerClassname}
            >
                {props.label && <p className={classes.label}>{props.label}</p>}
                <div
                    className={`${classes.container} ${props.containerClassname}`}
                    style={props.containerStyle}
                >
                    <textarea
                        className={classes.textarea}
                        ref={ref}
                        onChange={onChangeText}
                        rows={props.rows || 10}
                        placeholder={props.placeholder}
                        maxLength={props.maxLength}
                        value={text}
                    ></textarea>
                    {props.customRenderFunction()}
                </div>
            </div>
        );
    }

    return (
        <div
            style={props.contentContainerStyle}
            className={props.contentContainerClassname}
        >
            {props.label && <p className={classes.label}>{props.label}</p>}
            <div
                className={`${classes.container} ${props.containerClassname}`}
                style={props.containerStyle}
            >
                <textarea
                    className={classes.textarea}
                    ref={ref}
                    onChange={onChangeText}
                    rows={props.rows || 10}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength}
                    value={text}
                ></textarea>
            </div>
        </div>
    );
});

Textarea.displayName = "Textarea";

export default Textarea;
