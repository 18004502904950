import { setUser as setSentryUser } from "@sentry/react";
import * as actionTypes from "./actionTypes";

const initialState = {
    token: null,
    isAuthLoading: true,
    loading: false, // Weirdly this is not an auth loading state
};

const login = (state, action) => {
    localStorage.setItem("token", action.token);
    localStorage.setItem("userId", action.userId);
    localStorage.setItem("name", action.name);
    localStorage.setItem("email", action.email);
    localStorage.setItem("access", JSON.stringify(action.access));

    setSentryUser({
        id: action.userId.toString(),
        ip_address: "{{auto}}",
        name: action.name,
        email: action.email,
    });

    return {
        ...state,
        token: action.token,
        isAuthLoading: false,
        userId: action.userId,
        name: action.name,
        email: action.email,
        access: action.access,
    };
};

const logout = (state) => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("access");

    setSentryUser(null);

    return {
        ...state,
        token: null,
        isAuthLoading: false,
        userId: null,
        name: null,
        email: null,
        access: {
            superAdmin: 0,
            callCenter: 0,
            finance: 0,
            doctor: 0,
            caseManager: 0,
            fullCaseManager: 0,
            doctorId: null,
        },
    };
};

const startLoad = (state) => ({
    ...state,
    loading: true,
});

const endLoadLoading = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return login(state, action);
        case actionTypes.LOGOUT:
            return logout(state, action);
        case actionTypes.START_LOADING:
            return startLoad(state);
        case actionTypes.END_LOADING:
            return endLoadLoading(state);
    }

    return state;
};

export default reducer;
