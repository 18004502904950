import { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Client } from "twilio-chat";
import { useLocation, useNavigate } from "react-router-dom";
import Textarea from "../../Chat/Textarea/Textarea";
import UploadedFile from "../../Chat/UploadedFile/UploadedFile";
import Button from "../../Chat/Button/Button";
import { Icons } from "../../../assets/Theme";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { toggleResponseModal } from "../../../store/ui/actions";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import ChatWindow from "../ChatWindow/ChatWindow";
import classes from "./ConsiliumRoom.module.css";

let chatClient;
let currentChannel;
let file;
let channelSID;

let creatorName;
let creatorId;
let consiliumName;
let consiliumDescription;
let birthDate;
let adminName;
let adminCreator;

const ConsiliumRoom = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [participantDoctors, setParticipantDoctors] = useState([]);
    const [allDoctors, setAllDoctors] = useState([]);
    const messagesRef = useRef();
    const inputRef = useRef();
    const fileInputRef = useRef();
    const [fileName, setFileName] = useState("");
    const [open, setOpen] = useState("");
    const [addDoctor, toggleAddDoctor] = useState(false);
    const [selectedDoctors, setSelectedDoctors] = useState([]);

    messagesRef.current = messages;

    useEffect(() => {
        //Belépés a channelbe, acces token kérés
        post("api/v1/consilium/enter-channel", {
            identifier: props.identifier,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        //Access token alapján csatlakozás a service-hez
                        channelSID = response.data.channelSID;
                        creatorName = response.data.doctor;
                        creatorId =
                            response.data.doctorId ??
                            response.data.adminCreator;
                        adminName = response.data.adminName ?? null;
                        adminCreator = response.data.adminCreator ?? null;
                        consiliumName = response.data.consiliumName;
                        consiliumDescription = response.data.description;
                        birthDate = response.data.birthDate;
                        setParticipantDoctors(response.data.doctors);
                        setAllDoctors(response.data.allDoctors);
                        setOpen(response.data.open == "1");
                        createClient(response.data.accessToken);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeStatus = () => {
        if (open) {
            props.toggleResponseModal(true, {
                title: "Megerősítés szükséges!",
                content: "Biztosan lezárja a konzíliumot?",
                confirmButton: true,
                confirmButtonText: "Lezárás",
                confirmButtonCallback: changeStatus,
                cancelButton: true,
                cancelButtonText: "Mégsem",
                cancelButtonCallback: null,
                disableBackdropClick: false,
            });
        } else {
            changeStatus();
        }
    };

    const changeStatus = () =>
        post("admin/consilium/change-channel-availability", {
            identifier: props.identifier,
            open: !open,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setOpen((prevState) => !prevState);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const addDoctors = () => {
        let formData = new FormData();
        formData.append("identifier", props.identifier);
        formData.append("doctors", JSON.stringify(selectedDoctors));

        post("admin/consilium/add-doctor", formData)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres hozzáadás!");

                        let currentPage = location.pathname;
                        navigate({ pathname: "/empty" });
                        navigate({ pathname: currentPage }, { replace: true });

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const createClient = (accessToken) =>
        Client.create(accessToken)
            .then((client) => {
                chatClient = client;
                //Aktuális channel kikeresése
                getUserChannels();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const getUserChannels = () =>
        chatClient.getUserChannelDescriptors().then(function (paginator) {
            let channels = [];
            channels = [...channels, ...paginator.items];

            if (paginator.hasNextPage) {
                paginator.nextPage().then((result) => {
                    channels = [...channels, ...result.items];

                    matchChannels(channels);
                });
            } else {
                matchChannels(channels);
            }
        });

    const matchChannels = (channels) => {
        for (let i = 0; i < channels.length; i++) {
            const channel = channels[i];
            if (channel.sid === channelSID) {
                channel
                    .getChannel()
                    .then((response) => {
                        currentChannel = response;
                        //Channel üzeneteinek lekérése
                        getMessages();
                        //Beérkező üzenet eseménykezelője
                        currentChannel.on("messageAdded", (message) =>
                            addMessage(message)
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                    });
            }
        }
    };

    const getMessages = () =>
        currentChannel.getMessages().then((response) => {
            endLoading();
            setMessages(response.items);
        });

    let addMessage = (message) =>
        setMessages([...messagesRef.current, message]);

    const sendMessage = (message) => {
        if (fileName !== "") {
            const formData = new FormData();
            formData.append("file", file);
            currentChannel.sendMessage(formData);
        } else if (message) {
            currentChannel.sendMessage(message);
        }
    };

    const onSendButtonClick = () => {
        if (fileName === "") {
            let message = inputRef.current.value;
            if (message) {
                sendMessage(message);
                inputRef.current.value = null;
            }
        } else {
            sendMessage(null);
            setFileName("");
            file = null;
        }
    };

    const onFileSelect = () => fileInputRef.current.click();

    const fileChange = (e) => {
        if (e?.target.files[0]) {
            file = e.target.files[0];
            setFileName(file.name);
        } else {
            setFileName("");
            file = null;
        }
    };

    const documentMessageComponent = () => {
        if ((file && file.type == "image/png") || file.type == "image/jpeg") {
            return (
                <div className={classes.documentMessageContainer}>
                    <img
                        src={URL.createObjectURL(file)}
                        className={classes.imageMessage}
                    />
                    <span className={classes.uploadFileName}>{file.name}</span>
                    <span
                        className={classes.delete}
                        onClick={() => fileChange(null)}
                    >
                        Mégsem
                    </span>
                </div>
            );
        }

        return (
            <div className={classes.documentMessageContainer}>
                <img
                    src={Icons.attachmentBlack}
                    className={classes.iconMessage}
                />
                <span className={classes.uploadFileName}>{file.name}</span>
                <span
                    className={classes.delete}
                    onClick={() => fileChange(null)}
                >
                    Mégsem
                </span>
            </div>
        );
    };

    const deleteUploadedFile = (message) =>
        props.toggleResponseModal(true, {
            content: "Biztosan törli a feltöltött fájlt?",
            confirmButtonText: "Törlés",
            confirmButtonCallback: () => onDeleteConfirm(message),
            cancelButton: true,
            cancelButtonText: "Mégsem",
        });

    const onDeleteConfirm = (message) => message.remove();

    const renderUploadedFiles = () => {
        let files = [];
        let i = 0;
        for (let m of messages) {
            if (m.type === "media") {
                files.push(
                    <UploadedFile
                        key={i}
                        media={m.media}
                        name={m.media.filename}
                        className={classes.uploadedFile}
                        style={{
                            border: "1px solid #929EA7",
                            margin: "0.5rem 0.5rem 0rem 0rem",
                        }}
                        deleteEnabled={false}
                        onDeleteClick={() => deleteUploadedFile(m)}
                    />
                );
            }
            i++;
        }

        return files;
    };

    const filterParticipants = () => {
        const participantIds = participantDoctors.map((d) => d.doctor_id);
        let filteredDoctorList = [];
        allDoctors.forEach((d) => {
            if (!participantIds.includes(d.doctor_id)) {
                filteredDoctorList.push(d);
            }
        });

        return filteredDoctorList;
    };

    return (
        <>
            <div className={classes.ChatRoom} style={props.style}>
                {participantDoctors && (
                    <ChatWindow
                        identifier={props.identifier}
                        messages={messages}
                        doctorId={props.doctorId}
                        doctorName={creatorName}
                        creatorId={creatorId}
                        adminCreator={adminCreator}
                        adminName={adminName}
                        doctors={participantDoctors}
                        isOpen={open}
                        name={consiliumName}
                        description={consiliumDescription}
                        birthDate={birthDate}
                        userId={props.userId}
                    ></ChatWindow>
                )}
                <div className={classes.fileContainer}>
                    {renderUploadedFiles()}
                </div>

                {open && (
                    <>
                        <div className={classes.textareaContainer}>
                            <Textarea
                                contentContainerClassname={classes.textarea}
                                rows={5}
                                placeholder="Üzenet"
                                ref={inputRef}
                                customRender={fileName !== ""}
                                customRenderFunction={documentMessageComponent}
                            />
                        </div>

                        <div className={classes.buttons}>
                            <Button
                                className={classes.sendButton}
                                onClick={onSendButtonClick}
                                text="Üzenet küldése"
                            />
                            <input
                                type="file"
                                className={classes.uploadInput}
                                ref={fileInputRef}
                                onChange={fileChange}
                            />
                            <Button
                                className={classes.button}
                                onClick={onFileSelect}
                            >
                                <div className={classes.uploadButtonInner}>
                                    <img
                                        src={Icons.attachmentGreen}
                                        className={classes.uploadIcon}
                                    />
                                    <span className={classes.buttonText}>
                                        Új dokumentum csatolása
                                    </span>
                                </div>
                            </Button>
                        </div>
                    </>
                )}

                <div className={classes.buttons} style={{ marginTop: 0 }}>
                    <Button
                        className={classes.sendButton}
                        onClick={handleChangeStatus}
                        text={
                            open ? "Konzílium lezárása" : "Konzílium megnyitása"
                        }
                    />
                    <Button
                        className={classes.sendButton}
                        text="Orvosok hozzáadása"
                        onClick={() => toggleAddDoctor((prev) => !prev)}
                    />
                </div>
            </div>
            {addDoctor && (
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <div className={classes.selectWrapper}>
                            <CustomSelect
                                isMulti={true}
                                placeholder="Orvos hozzáadása"
                                options={filterParticipants().map((d) => ({
                                    value: d.doctor_id,
                                    label: `${d.title ?? ""} ${d.last_name} ${
                                        d.first_name
                                    }`,
                                }))}
                                onChange={(e) => setSelectedDoctors(e)}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <button
                            className="btn btn-secondary"
                            style={{ marginBottom: "2rem" }}
                            onClick={addDoctors}
                        >
                            Hozzáadás
                        </button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    userId: state.auth.userId,
    doctorId: state.auth.access.doctorId,
    superAdmin: state.auth.access.superAdmin,
});

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(toggleResponseModal(show, responseModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsiliumRoom);
