import { useEffect, useState } from "react";
import { Button, Card, CardBody, Input } from "reactstrap";
import { responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import InfoComponent from "../InfoComponent/InfoComponent";
import Comment from "../Comment/Comment";
import classes from "./CommentsCard.module.css";

const CommentsCard = (props) => {
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState([]);
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        if (props?.comments?.length !== comments?.length) {
            setNewComment("");
        } else {
            setComments(props?.comments);
        }
    }, [props.comments, comments]);

    const createComment = () => {
        if (newComment === "") {
            return;
        }

        post("admin/create-case-comment", {
            caseId: props.caseId,
            userId: props.userId,
            comment: newComment,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres hozzáadás!");
                        props.refreshPage();
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleCommentStatusChange = (checked, commentId) =>
        post("admin/update-comment-status", {
            checked,
            caseId: props.caseId,
            userId: props.userId,
            commentId,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres módosítás!");
                        props.refreshPage();
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleCommentEdit = (comment, commentId) =>
        post("admin/update-comment-text", {
            comment,
            caseId: props.caseId,
            commentId,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres módosítás!");
                        props.refreshPage();
                        endLoading();
                        setIsEdited(true);

                        setTimeout(() => {
                            setIsEdited(false);
                        }, 1000);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <Card>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        <span>Megjegyzések</span>
                    </div>
                </div>
                <InfoComponent
                    title="Komment írása:"
                    editMode={true}
                    editComponent={
                        <Input
                            type="textarea"
                            rows={3}
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                    }
                />
                <Button color="primary" onClick={() => createComment()}>
                    Küldés
                </Button>
                <div className={classes.commentsWrapper}>
                    {props.comments.map((c) => (
                        <Comment
                            key={c.id}
                            name={`${c.adminLastName} ${c.adminFirstName}`}
                            date={c.created_at}
                            comment={c.comment}
                            status={c.status != "0"}
                            onDeleteComment={() => props.onDeleteComment(c.id)}
                            onChangeStatus={(checked) =>
                                handleCommentStatusChange(checked, c.id)
                            }
                            onEdit={(comment) =>
                                handleCommentEdit(comment, c.id)
                            }
                            isEdited={isEdited}
                        />
                    ))}
                </div>
            </CardBody>
        </Card>
    );
};

export default CommentsCard;
