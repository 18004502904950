import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES, serviceNames } from "../../config/variables";
import RatingsTable from "./RatingsTable/RatingsTable";

const Ratings = (props) => {
    const [ratings, setRatings] = useState(null);
    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        get("api/v1/rating/get-ratings")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setRatings(response.data.ratings);
                        calculateStatistics(response.data.ratings);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const calculateStatistics = useCallback(
        (data) => {
            const ratingsCount = data.length;

            let average = 0;
            let serviceRatings = {
                HOTLINE: { count: 0, ratingSum: 0 },
                DUTY: { count: 0, ratingSum: 0 },
                OUTPATIENT: { count: 0, ratingSum: 0 },
                VIDEO_CONSULTATION: { count: 0, ratingSum: 0 },
                CHAT: { count: 0, ratingSum: 0 },
                SECOND_OPINION: { count: 0, ratingSum: 0 },
                PRESCRIPTION: { count: 0, ratingSum: 0 },
            };

            data.forEach((d) => {
                average += parseInt(d.rating);
                serviceRatings[`${d.serviceName}`]["count"] =
                    serviceRatings[`${d.serviceName}`]["count"] + 1;
                serviceRatings[`${d.serviceName}`]["ratingSum"] =
                    serviceRatings[`${d.serviceName}`]["ratingSum"] +
                    parseInt(d.rating);
            });

            let bestServiceRating = 0;
            let bestServiceName = "";
            let worstServiceRating = 5;
            let worstServiceName = "";
            for (const service in serviceRatings) {
                let average = 0;
                if (serviceRatings[service].ratingSum !== 0) {
                    average =
                        serviceRatings[service].ratingSum /
                        serviceRatings[service].count;
                }
                if (bestServiceRating < average) {
                    bestServiceRating = average;
                    bestServiceName = service;
                }
                if (worstServiceRating > average) {
                    worstServiceRating = average;
                    worstServiceName = service;
                }
            }

            setStatistics({
                ratingsCount,
                average: average / ratingsCount,
                bestService: `${serviceNames[bestServiceName]} (${bestServiceRating})`,
                worstService: `${serviceNames[worstServiceName]} (${worstServiceRating})`,
            });
        },
        [props.ratings] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Értékelések</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <div className="card">
                            <div className="card-body">
                                <p style={styles.title}>Értékelések száma</p>
                                <p style={styles.text}>
                                    {statistics.ratingsCount}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="card">
                            <div className="card-body">
                                <p style={styles.title}>Átlag</p>
                                <p style={styles.text}>{statistics.average}</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="card">
                            <div className="card-body">
                                <p style={styles.title}>Legjobb</p>
                                <p style={styles.text}>
                                    {statistics.bestService}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="card">
                            <div className="card-body">
                                <p style={styles.title}>Legrosszabb</p>
                                <p style={styles.text}>
                                    {statistics.worstService}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                {ratings && (
                    <Row>
                        <Col>
                            <RatingsTable
                                title="Összes értékelés"
                                ratings={ratings}
                                defaultOpen={true}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </>
    );
};

const styles = {
    title: {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        marginBottom: 0,
    },
};

export default Ratings;
