import { Component } from "react";
import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    toggleRightSidebar,
} from "../../store/actions";
// import RightSidebar from "../../components/RightSidebar";
import TopBar from "./TopBar";
// Other Layout related Component
import Sidebar from "./Sidebar";
import Footer from "./Footer";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        };

        this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
        // this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
    }

    componentDidMount() {
        // Scroll Top to 0
        window.scrollTo(0, 0);

        if (this.props.layoutWidth) {
            this.props.changeLayoutWidth(this.props.layoutWidth);
        }

        if (this.props.leftSideBarType) {
            this.props.changeSidebarType(this.props.leftSideBarType);
        }
        if (this.props.topbarTheme) {
            this.props.changeTopbarTheme(this.props.topbarTheme);
        }
    }

    toggleMenuCallback = () => {
        if (this.props.leftSideBarType === "default") {
            this.props.changeSidebarType("condensed", this.state.isMobile);
        } else if (this.props.leftSideBarType === "condensed") {
            this.props.changeSidebarType("default", this.state.isMobile);
        }
    };

    render() {
        return (
            <>
                <div id="layout-wrapper">
                    <TopBar
                        toggleMenuCallback={this.toggleMenuCallback}
                        toggleRightSidebar={this.toggleRightSidebar}
                    />

                    <div className="vertical-menu">
                        <div data-simplebar className="h-100">
                            <Sidebar
                                theme={this.props.leftSideBarTheme}
                                type={this.props.leftSideBarType}
                                isMobile={this.state.isMobile}
                            />
                        </div>
                    </div>
                    <div
                        className="main-content"
                        style={{
                            minHeight: this.state.isMobile ? "100%" : "100vh",
                        }}
                    >
                        <div className="page-content">
                            {this.props.children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStatetoProps = (state) => ({
    ...state.layout,
});
export default connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth,
})(Layout);
