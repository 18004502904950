import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel } from "@material-ui/core";
import classes from "./Checkbox.module.css";

const Chbx = (props) => {
    const [checked, toggleCheck] = useState(props.checked);

    const GreenCheckbox = withStyles({
        root: {
            color: "#1FBA9C",
            "&$checked": {
                color: "#1FBA9C",
            },
            fontSize: "2.5rem",
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    useEffect(() => {
        toggleCheck(props.checked);
    }, [props.checked]);

    return (
        <FormControlLabel
            control={
                <GreenCheckbox
                    name="checkbox2"
                    checked={checked}
                    icon={
                        <CheckBoxOutlineBlankIcon
                            style={{ fontSize: "2rem" }}
                        />
                    }
                    checkedIcon={<CheckBoxIcon style={{ fontSize: "2rem" }} />}
                    onChange={(event) => {
                        if (!props.disabled) {
                            toggleCheck(event.target.checked);
                            if (props.onChange) {
                                props.onChange(event.target.checked);
                            }
                        }
                    }}
                />
            }
            label={props.label}
            classes={{ label: classes.checkboxLabel }}
            style={{ marginBottom: "3rem", ...props.style }}
        />
    );
};

export default Chbx;
