import type { ComponentProps } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Controller, useField } from "tdoc-common";

type Props = Omit<ComponentProps<typeof Input>, "type"> & {
    label?: string;
    name: string;
};

const FormNumberInput = (props: Props) => {
    const { control, isError, error } = useField(props.name);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={{ required: props.required }}
            render={({ field }) => (
                <FormGroup>
                    {props.label && <Label>{props.label}</Label>}
                    <Input
                        type="number"
                        {...field}
                        value={field.value ?? ""}
                        onChange={(event) =>
                            field.onChange(
                                event.target.value
                                    ? parseInt(event.target.value)
                                    : null
                            )
                        }
                        invalid={isError}
                        {...props}
                    />
                    {error && <FormFeedback>{error}</FormFeedback>}
                </FormGroup>
            )}
        />
    );
};

export default FormNumberInput;
