import getLocale from "./getLocale";

/**
 * Timestamp in milliseconds or ISO date string or date object
 */
type DateInput = number | string | Date;

const createDate = (date: DateInput) =>
    typeof date === "object" ? date : new Date(date);

export const formatDateTime = (date: DateInput) =>
    createDate(date).toLocaleString(getLocale(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });
