import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const Stats = () => {
    const [stats, setStats] = useState({});

    useEffect(() => {
        get("admin/stats")
            .then((response) => {
                // console.log(response);
                switch (response.data.responseCode) {
                    case "OK": {
                        const stats = {
                            allUsers: response.data.allUsers,
                            todayUsers: response.data.todayUsers,
                            usersWithCard: response.data.usersWithCard,
                            allHotlineCalls: response.data.allHotlineCalls,
                            todayHotlineCalls: response.data.todayHotlineCalls,
                            allDutyCalls: response.data.allDutyCalls,
                            todayDutyCalls: response.data.todayDutyCalls,
                            allChat: response.data.allChat,
                            todayChat: response.data.todayChat,
                            allSecondOpinion: response.data.allSecondOpinion,
                            todaySecondOpinion:
                                response.data.todaySecondOpinion,
                        };
                        setStats(stats);
                        // console.log(stats);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Statisztikák</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="package-table table table-striped table-bordered mb-0">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    Összes regisztrált
                                                    felhasználó
                                                </th>
                                                <td>{stats.allUsers}</td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Mai regisztrált felhasználó
                                                </th>
                                                <td>{stats.todayUsers}</td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Felhasználók megadott
                                                    bankkártyával
                                                </th>
                                                <td>{stats.usersWithCard}</td>
                                            </tr>
                                            <tr>
                                                <th>Összes hotline hívás</th>
                                                <td>{stats.allHotlineCalls}</td>
                                            </tr>
                                            <tr>
                                                <th>Mai hotline hívás</th>
                                                <td>
                                                    {stats.todayHotlineCalls}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Összes ügyelet hívás</th>
                                                <td>{stats.allDutyCalls}</td>
                                            </tr>
                                            <tr>
                                                <th>Mai ügyelet hívás</th>
                                                <td>{stats.todayDutyCalls}</td>
                                            </tr>
                                            <tr>
                                                <th>Összes üzenetváltás</th>
                                                <td>{stats.allChat}</td>
                                            </tr>
                                            <tr>
                                                <th>Mai üzenetváltás</th>
                                                <td>{stats.todayChat}</td>
                                            </tr>
                                            <tr>
                                                <th>Összes másodvélemény</th>
                                                <td>
                                                    {stats.allSecondOpinion}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Mai másodvélemény</th>
                                                <td>
                                                    {stats.todaySecondOpinion}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Stats;
