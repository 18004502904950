import { useState } from "react";
import { Button, Card, CardBody, Input } from "reactstrap";
import { convertDate, getAge, responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import InfoComponent from "../InfoComponent/InfoComponent";
import DatePicker from "../../DatePicker/DatePicker";
import { Icons } from "../../../assets/Theme";
import VipIcon from "../VipIcon/VipIcon";
import EditBtn from "../EditBtn/EditBtn";
import classes from "./PatientCard.module.css";

const PatientCard = (props) => {
    const originalData = props.casePatientData;
    const [editMode, setEditMode] = useState(false);

    const [patientCase] = useState(props.patientCase);
    const [casePatientData, setCasePatientData] = useState(
        props.casePatientData
    );

    const handleUpdate = () =>
        post("admin/update-case-patient", {
            caseId: patientCase.id,
            data: casePatientData,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        props.refreshPage();
                        endLoading();
                        setEditMode(false);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const createProfile = () => {
        const caseId = patientCase.id;
        const lastName = casePatientData.lastName;
        const firstName = casePatientData.firstName;
        const birthDate = casePatientData.birthDate;
        const securityNumber = casePatientData.securityNumber;
        const idNumber = casePatientData.idNumber;
        const gender = casePatientData.gender;
        const email = casePatientData.email;
        const phone = casePatientData.phone;

        const query = `?fromCase=${caseId}&lastName=${encodeURIComponent(
            lastName
        )}&firstName=${encodeURIComponent(firstName)}&birthDate=${
            birthDate || ""
        }&email=${encodeURIComponent(email || "")}&phone=${encodeURIComponent(
            phone || ""
        )}&securityNumber=${securityNumber || ""}&idNumber=${
            idNumber || ""
        }&gender=${gender || ""}`;

        props.onCreateProfile("/users/new" + query);
    };

    return (
        <Card style={{ borderRadius: "10px" }}>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        {casePatientData.gender == "Férfi" ? (
                            <img src={Icons.maleIcon} />
                        ) : (
                            <img src={Icons.famaleIcon} />
                        )}
                        <span style={{ marginLeft: "5px" }}>{`(${getAge(
                            casePatientData.birthDate
                        )})`}</span>
                        {casePatientData.vip == "1" && (
                            <VipIcon style={{ marginLeft: "10px" }} />
                        )}
                        {casePatientData.takeEarlier == "1" && (
                            <img
                                style={{ marginLeft: "10px" }}
                                src={Icons.earlierBlueIcon}
                            />
                        )}
                    </div>
                    {!editMode && (
                        <div>
                            <EditBtn onClick={() => setEditMode(true)} />
                        </div>
                    )}
                </div>
                <InfoComponent
                    title="Név"
                    info={
                        patientCase.last_name &&
                        patientCase.first_name &&
                        !patientCase.user_id
                            ? `${patientCase.last_name} ${patientCase.first_name} (nem regisztrált)`
                            : patientCase.last_name && patientCase.first_name
                            ? `${patientCase.last_name} ${patientCase.first_name}`
                            : `${patientCase.name} (nem regisztrált)`
                    }
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <>
                            <Input
                                style={{ marginTop: "0.5rem" }}
                                value={casePatientData.lastName}
                                onChange={(e) =>
                                    setCasePatientData({
                                        ...casePatientData,
                                        lastName: e.target.value,
                                    })
                                }
                                placeholder="Vezetéknév"
                            />
                            <Input
                                style={{ marginTop: "0.5rem" }}
                                value={casePatientData.firstName}
                                onChange={(e) =>
                                    setCasePatientData({
                                        ...casePatientData,
                                        firstName: e.target.value,
                                    })
                                }
                                placeholder="Keresztnév"
                            />
                        </>
                    }
                />
                <InfoComponent
                    title="Születési dátum"
                    info={
                        patientCase.birthDate
                            ? patientCase.birthDate.replace(/-/g, ".")
                            : "-"
                    }
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <DatePicker
                            value={casePatientData.birthDate}
                            disableFuture={true}
                            withTime={false}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    birthDate: convertDate(e, "-", false),
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="TAJ szám"
                    info={
                        casePatientData.securityNumber
                            ? casePatientData.securityNumber
                            : "-"
                    }
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <Input
                            value={casePatientData.securityNumber}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    securityNumber: e.target.value,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Igazolvány szám"
                    info={
                        casePatientData.idNumber
                            ? casePatientData.idNumber
                            : "-"
                    }
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <Input
                            value={casePatientData.idNumber}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    idNumber: e.target.value,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Nem"
                    info={patientCase.gender ? patientCase.gender : "-"}
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <Input
                            type="select"
                            name="gender"
                            value={casePatientData.gender}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    gender: e.target.value,
                                })
                            }
                            className="form-control"
                        >
                            <option value="Férfi">Férfi</option>
                            <option value="Nő">Nő</option>
                        </Input>
                    }
                />
                <InfoComponent
                    title="Email"
                    info={
                        patientCase.userEmail
                            ? patientCase.userEmail
                            : patientCase.email
                            ? patientCase.email
                            : "Nincs megadva"
                    }
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <Input
                            value={casePatientData.email}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    email: e.target.value,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Telefonszám"
                    info={
                        patientCase.userPhone
                            ? patientCase.userPhone
                            : patientCase.phone
                            ? patientCase.phone
                            : "Nincs megadva"
                    }
                    editMode={editMode}
                    notEditable={patientCase.user_id}
                    editComponent={
                        <Input
                            value={casePatientData.phone}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    phone: e.target.value,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Vip besorolás"
                    info={casePatientData.vip == "0" ? "Nem" : "Igen"}
                    editMode={editMode}
                    notEditable={false}
                    editComponent={
                        <Input
                            type="select"
                            name="vip"
                            value={casePatientData.vip == "0" ? "0" : "1"}
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    vip: e.target.value,
                                })
                            }
                        >
                            <option value="0">Nem</option>
                            <option value="1">Igen</option>
                        </Input>
                    }
                />
                <InfoComponent
                    title="Vállalja korábban?"
                    info={casePatientData.takeEarlier == "0" ? "Nem" : "Igen"}
                    editMode={editMode}
                    notEditable={false}
                    editComponent={
                        <Input
                            type="select"
                            name="takeEarlier"
                            value={
                                casePatientData.takeEarlier == "0" ? "0" : "1"
                            }
                            onChange={(e) =>
                                setCasePatientData({
                                    ...casePatientData,
                                    takeEarlier: e.target.value,
                                })
                            }
                        >
                            <option value="0">Nem</option>
                            <option value="1">Igen</option>
                        </Input>
                    }
                />
                {editMode && (
                    <div className={classes.btnWrapper}>
                        <Button color="primary" onClick={() => handleUpdate()}>
                            Változtatások mentése
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setEditMode(false);
                                setCasePatientData(originalData);
                            }}
                        >
                            Mégse
                        </Button>
                    </div>
                )}
                {!editMode && !patientCase["user_id"] && (
                    <Button
                        outline
                        color="primary"
                        onClick={() => createProfile()}
                    >
                        Páciens regisztrálása
                    </Button>
                )}
            </CardBody>
        </Card>
    );
};

export default PatientCard;
