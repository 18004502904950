import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { FormGroup } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { Client } from "twilio-chat";
import { useNavigate } from "react-router-dom";
import Textarea from "../Textarea/Textarea";
import UploadedFile from "../UploadedFile/UploadedFile";
import Button from "../Button/Button";
import { Icons } from "../../../assets/Theme";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { toggleResponseModal } from "../../../store/ui/actions";
import ChatWindow from "../ChatWindow/ChatWindow";
import RecommendService from "../../../components/RecommendService/RecommendService";
import classes from "./ChatRoom.module.css";

let chatClient;
let currentChannel;
let file;
let channelSID;
let doctorName;

const ChatRoom = (props) => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const messagesRef = useRef();
    const inputRef = useRef();
    const fileInputRef = useRef();
    const [fileName, setFileName] = useState("");
    const [open, setOpen] = useState("");
    const [status, setStatus] = useState("");
    const [passToOther, setPassToOther] = useState(false);
    const [doctorsToPass, setDoctorsToPass] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [patientId, setPatientId] = useState(null);
    const [toggleRecommendation, setToggleRecommendation] = useState(false);
    messagesRef.current = messages;

    useEffect(() => {
        post("admin/chat/channelInfos", {
            identifier: props.identifier,
        }).then((response) => {
            if (response.data.open == "0") {
                setOpen(false);
            } else {
                setOpen(true);
            }
            setStatus(response.data.status);
            setPatientId(response.data.patient_id);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //Belépés a channelbe, acces token kérés
        post("admin/chat/enter-channel", {
            identifier: props.identifier,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        //Access token alapján csatlakozás a service-hez
                        channelSID = response.data.channelSID;
                        doctorName = response.data.doctor;
                        createClient(response.data.accessToken);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeStatus = () => {
        if (open) {
            props.toggleResponseModal(true, {
                title: "Megerősítés szükséges!",
                content: "Biztosan lezárja a beszélgetést?",
                confirmButton: true,
                confirmButtonText: "Lezárás",
                confirmButtonCallback: changeStatus,
                cancelButton: true,
                cancelButtonText: "Mégsem",
                cancelButtonCallback: null,
                disableBackdropClick: false,
            });
        } else {
            changeStatus();
        }
    };

    const changeStatus = () =>
        post("admin/chat/change-channel-availability", {
            identifier: props.identifier,
            open: !open,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setOpen((prevState) => !prevState);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleTurnToSecondOpinion = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan másodvéleménynek nyilvánítja a beszélgetést? Ez a művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Igen",
            confirmButtonCallback: turnToSecondOpinion,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const turnToSecondOpinion = () =>
        post("admin/chat/mark-as-second-opinion", {
            identifier: props.identifier,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setStatus("1");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const openPassToOther = () =>
        post("admin/get-doctors-for-pass", { status })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        // console.log(response.data.doctors);
                        setPassToOther(true);
                        setDoctorsToPass(response.data.doctors);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const cancelPass = () => {
        setPassToOther(false);
        setSelectedDoctor(null);
    };

    const handlePassToOther = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan átadja az üzenetváltást a kiválasztott orvosnak? Ön már nem fog tovább hozzáférni!",
            confirmButton: true,
            confirmButtonText: "Igen",
            confirmButtonCallback: passChatToOther,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const passChatToOther = () => {
        // console.log(selectedDoctor);
        // console.log(props.identifier);
        // console.log(channelSID);

        post("admin/chat/pass-chat-to-other", {
            identifier: props.identifier,
            channelId: channelSID,
            doctorId: selectedDoctor,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres átadás!");
                        navigate("/chat");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const createClient = (accessToken) =>
        Client.create(accessToken)
            .then((client) => {
                chatClient = client;
                //Aktuális channel kikeresése
                getUserChannels();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const getUserChannels = () =>
        chatClient.getUserChannelDescriptors().then(function (paginator) {
            let channels = [];
            channels = [...channels, ...paginator.items];
            let hasMore = paginator.hasNextPage;

            const paginatorLoop = async () => {
                let p = paginator;
                let hasMore = true;
                while (hasMore) {
                    const result = await p.nextPage();
                    p = result;
                    channels = [...channels, ...result.items];
                    hasMore = result.hasNextPage;
                }
                matchChannels(channels);
            };

            if (!hasMore) {
                matchChannels(channels);
            } else {
                paginatorLoop();
            }
        });

    const matchChannels = (channels) => {
        for (let i = 0; i < channels.length; i++) {
            const channel = channels[i];
            if (channel.sid === channelSID) {
                channel
                    .getChannel()
                    .then((response) => {
                        currentChannel = response;
                        //Channel üzeneteinek lekérése
                        getMessages();
                        //Beérkező üzenet eseménykezelője
                        currentChannel.on("messageAdded", (message) =>
                            addMessage(message)
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                    });
            }
        }
    };

    const getMessages = () =>
        currentChannel.getMessages().then((response) => {
            endLoading();
            setMessages(response.items);
        });

    let addMessage = (message) =>
        setMessages([...messagesRef.current, message]);

    const sendMessage = (message) => {
        if (fileName !== "") {
            const formData = new FormData();
            formData.append("file", file);
            currentChannel.sendMessage(formData);
        } else if (message) {
            currentChannel.sendMessage(message);
        }
    };

    const onSendButtonClick = () => {
        if (fileName === "") {
            let message = inputRef.current.value;
            if (message) {
                sendMessage(message);
                inputRef.current.value = null;
            }
        } else {
            sendMessage(null);
            setFileName("");
            file = null;
        }
    };

    const onFileSelect = () => fileInputRef.current.click();

    const fileChange = (e) => {
        if (e?.target.files[0]) {
            file = e.target.files[0];
            setFileName(file.name);
        } else {
            setFileName("");
            file = null;
        }
    };

    const documentMessageComponent = () => {
        if ((file && file.type == "image/png") || file.type == "image/jpeg") {
            return (
                <div className={classes.documentMessageContainer}>
                    <img
                        src={URL.createObjectURL(file)}
                        className={classes.imageMessage}
                    />
                    <span className={classes.uploadFileName}>{file.name}</span>
                    <span
                        className={classes.delete}
                        onClick={() => fileChange(null)}
                    >
                        Mégsem
                    </span>
                </div>
            );
        }

        return (
            <div className={classes.documentMessageContainer}>
                <img
                    src={Icons.attachmentBlack}
                    className={classes.iconMessage}
                />
                <span className={classes.uploadFileName}>{file.name}</span>
                <span
                    className={classes.delete}
                    onClick={() => fileChange(null)}
                >
                    Mégsem
                </span>
            </div>
        );
    };

    const deleteUploadedFile = (message) =>
        props.toggleResponseModal(true, {
            content: "Biztosan törli a feltöltött fájlt?",
            confirmButtonText: "Törlés",
            confirmButtonCallback: () => onDeleteConfirm(message),
            cancelButton: true,
            cancelButtonText: "Mégsem",
        });

    const onDeleteConfirm = (message) => message.remove();

    const renderUploadedFiles = () => {
        let files = [];
        for (let m of messages) {
            if (m.type === "media") {
                files.push(
                    <UploadedFile
                        media={m.media}
                        name={m.media.filename}
                        className={classes.uploadedFile}
                        deleteEnabled={false}
                        onDeleteClick={() => deleteUploadedFile(m)}
                    />
                );
            }
        }

        return files;
    };

    return (
        <>
            <div className={classes.ChatRoom} style={props.style}>
                <ChatWindow
                    identifier={props.identifier}
                    otherName={doctorName}
                    messages={messages}
                    author={props.userId}
                    isOpen={open}
                    status={status}
                ></ChatWindow>
                <div className={classes.fileContainer}>
                    {renderUploadedFiles()}
                </div>
                {open && !passToOther ? (
                    <div className={classes.textareaContainer}>
                        <Textarea
                            contentContainerClassname={classes.textarea}
                            rows={5}
                            placeholder="Üzenet"
                            ref={inputRef}
                            customRender={fileName !== ""}
                            customRenderFunction={documentMessageComponent}
                        />
                    </div>
                ) : null}
                {open && !passToOther ? (
                    <div className={classes.buttons}>
                        <Button
                            className={classes.sendButton}
                            onClick={onSendButtonClick}
                            text="Üzenet küldése"
                        />
                        <input
                            type="file"
                            className={classes.uploadInput}
                            ref={fileInputRef}
                            onChange={fileChange}
                        />
                        <Button
                            className={classes.button}
                            onClick={onFileSelect}
                        >
                            <div className={classes.uploadButtonInner}>
                                <img
                                    src={Icons.attachmentGreen}
                                    className={classes.uploadIcon}
                                />
                                <span className={classes.buttonText}>
                                    Új dokumentum csatolása
                                </span>
                            </div>
                        </Button>
                    </div>
                ) : null}
                <div className={classes.buttons} style={{ marginTop: 0 }}>
                    {status == "0" && open && !passToOther ? (
                        <Button
                            className={classes.sendButton}
                            onClick={handleTurnToSecondOpinion}
                            text="Másodvéleménynek nyilvánítás"
                        />
                    ) : null}
                    {!passToOther ? (
                        <Button
                            className={classes.sendButton}
                            onClick={handleChangeStatus}
                            text={
                                open
                                    ? "Beszélgetés lezárása"
                                    : "Beszélgetés megnyitása"
                            }
                        />
                    ) : null}
                </div>
                <div className={classes.buttons} style={{ marginTop: 0 }}>
                    {!passToOther ? (
                        <Button
                            className={classes.sendButton}
                            onClick={openPassToOther}
                            text="Átadás más orvosnak"
                        />
                    ) : null}
                    {!passToOther ? (
                        <Button
                            className={classes.sendButton}
                            onClick={() =>
                                setToggleRecommendation((prev) => !prev)
                            }
                            text="Szolgáltatás ajánlás"
                        />
                    ) : null}
                </div>
            </div>
            {toggleRecommendation && !passToOther && (
                <RecommendService
                    patientId={patientId}
                    onCancelRecommend={() =>
                        setToggleRecommendation((prev) => !prev)
                    }
                />
            )}
            {passToOther ? (
                <div style={{ paddingBottom: "3rem" }}>
                    <Row>
                        <Col sm={{ size: 6, offset: 2 }}>
                            <FormGroup>
                                <Select
                                    placeholder="Válasszon orvost..."
                                    noOptionsMessage={() => "Nincs találat"}
                                    options={doctorsToPass.map((d) => ({
                                        value: d.doctor_id,
                                        label: `${d.title ? d.title : ""} ${
                                            d.last_name
                                        } ${d.first_name} - ${d.qualification}`,
                                    }))}
                                    menuPlacement="top"
                                    onChange={(e) => setSelectedDoctor(e.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={1}>
                            <button
                                className="btn btn-primary"
                                onClick={handlePassToOther}
                                color="primary"
                            >
                                Küldés
                            </button>
                        </Col>
                        <Col sm={1}>
                            <button
                                className="btn btn-secondary"
                                onClick={cancelPass}
                            >
                                Vissza
                            </button>
                        </Col>
                    </Row>
                </div>
            ) : null}
        </>
    );
};
const mapStateToProps = (state) => ({
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(toggleResponseModal(show, responseModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
