import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import { connect } from "react-redux";
import FileUploader from "../../components/FileUploader/FileUploader";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { generatePassword } from "../../config/helpers";
import { endLoading, get, post } from "../../config/fetch";
import OpenableCard from "../../components/OpenableCard/OpenableCard";
import CaseSettings from "../../components/AdminCards/CaseSettings/CaseSettings";
import * as actions from "../../store/ui/actions";

const AdminDetails = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [imageURL, setImageURL] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [callCenter, setCallCenter] = useState(false);
    const [finance, setFinance] = useState(false);
    const [caseManager, setCaseManager] = useState(false);
    const [fullCaseManager, setFullCaseManager] = useState(false);
    const [doctor, setDoctor] = useState(false);
    const [pharmacist, setPharmacist] = useState(false);
    const [doctorId, setDoctorId] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [dutyCommission, setDutyCommission] = useState(null);
    const [chatCommission, setChatCommission] = useState(null);
    const [secondOpinionCommission, setSecondOpinionCommission] =
        useState(null);
    const [outpatientCommission, setOutpatientCommission] = useState(null);
    const [videoConsultationCommission, setVideoConsultationCommission] =
        useState(null);

    const [qualifications, setQualifications] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [caseQualifications, setCaseQualifications] = useState([]);
    const [caseInstitutions, setCaseInstitutions] = useState([]);

    useEffect(() => {
        get(`admin/admin-user?id=${id}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const fetchedAdmin = response.data.admin;
                        // setName(fetchedAdmin.name);
                        setLastName(fetchedAdmin.last_name);
                        setFirstName(fetchedAdmin.first_name);
                        setEmail(fetchedAdmin.email);
                        setPhone(fetchedAdmin.phone);
                        setSuperAdmin(fetchedAdmin.super_admin != "0");
                        setCallCenter(fetchedAdmin.call_center != "0");
                        setFinance(fetchedAdmin.finance != "0");
                        setCaseManager(fetchedAdmin.case_manager != "0");
                        setFullCaseManager(
                            fetchedAdmin.full_case_manager != "0"
                        );
                        setDoctor(fetchedAdmin.doctor != "0");
                        setPharmacist(fetchedAdmin.pharmacist != "0");
                        setImageURL(fetchedAdmin.image);
                        setDoctorId(fetchedAdmin.doctor_id ?? null);
                        setDutyCommission(fetchedAdmin.duty_commission);
                        setChatCommission(fetchedAdmin.chat_commission);
                        setSecondOpinionCommission(
                            fetchedAdmin.second_opinion_commission
                        );
                        setOutpatientCommission(
                            fetchedAdmin.outpatient_commission
                        );
                        setVideoConsultationCommission(
                            fetchedAdmin.video_consultation_commission
                        );

                        setCaseInstitutions(response.data.caseInstitutions);
                        setCaseQualifications(response.data.caseQualifications);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        get("admin/get-qualifications-institutions")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        setQualifications(response.data.qualifications);
                        setInstitutions(response.data.institutions);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("id", id);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("superAdmin", superAdmin === true ? 1 : 0);
        formData.append("callCenter", callCenter === true ? 1 : 0);
        formData.append("finance", finance === true ? 1 : 0);
        formData.append("caseManager", caseManager === true ? 1 : 0);
        formData.append("fullCaseManager", fullCaseManager === true ? 1 : 0);
        formData.append("pharmacist", pharmacist === true ? 1 : 0);
        formData.append("doctor", doctor === true ? 1 : 0);
        formData.append("doctorId", doctor === true ? doctorId : null);

        post("admin/update-admin-user", formData)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        endLoading();

                        break;
                    case "EMAIL_OR_PHONE_TAKEN":
                        endLoading();
                        responseMessage(
                            "Az email cím vagy telefonszám foglalt!"
                        );

                        break;
                    case "WRONG_EMAIL":
                        endLoading();
                        responseMessage("Helytelen email formátum!");

                        break;
                    case "UBER_ADMIN_CANT_EDIT":
                        endLoading();
                        responseMessage("Az Uber Admin nem szerkeszthető!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleUpdate = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan menti a változásokat?",
            confirmButton: true,
            confirmButtonText: "Igen",
            confirmButtonCallback: handleSubmit,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const changePassword = () => {
        // console.log(newPassword);
        post("admin/change-password", {
            id,
            oldPassword: "",
            newPassword,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        responseMessage("Sikeres jelszó változtatás.");
                        setNewPassword("");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handlePasswordChange = () => {
        if (!newPassword) {
            return;
        }
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan megváltoztatja az Admin felhasználó jelszavát?",
            confirmButton: true,
            confirmButtonText: "Igen",
            confirmButtonCallback: changePassword,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });
    };

    const deleteAdmin = () =>
        post("admin/delete-admin-user", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/admins");

                        break;
                    case "UBER_ADMIN_CANT_DELETE":
                        endLoading();
                        responseMessage("Az Uber Admin nem törölhető!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan törli az admint? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteAdmin,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    const updateCommissions = () => {
        let commissions = {
            dutyCommission,
            chatCommission,
            secondOpinionCommission,
            outpatientCommission,
            videoConsultationCommission,
        };
        post("admin/update-commissions", { commissions, id })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Admin adatok</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="admin-form">
                                <AvForm onValidSubmit={handleUpdate}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="last-name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Vezetéknév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="last-name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az admin vezetéknevét!"
                                                className="form-control"
                                                type="text"
                                                value={lastName}
                                                onChange={(event) =>
                                                    setLastName(
                                                        event.target.value
                                                    )
                                                }
                                                id="last-name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="first-name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Keresztnév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="first-name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az admin keresztnevét!"
                                                className="form-control"
                                                type="text"
                                                value={firstName}
                                                onChange={(event) =>
                                                    setFirstName(
                                                        event.target.value
                                                    )
                                                }
                                                id="first-name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="email"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="email"
                                                validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                }}
                                                errorMessage="Adjon meg valós email címet!"
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                                id="email"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="image"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kép
                                        </label>
                                        <Col sm={10}>
                                            <FileUploader
                                                handleFile={handleFile}
                                            />
                                            {imageURL ? (
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                    onClick={cancelImageUpload}
                                                >
                                                    Kép törlése
                                                </button>
                                            ) : null}
                                            {imageURL ? (
                                                <div className="image-preview">
                                                    <img
                                                        className="image"
                                                        src={imageURL}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="phone"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Telefonszám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="phone"
                                                className="form-control"
                                                type="text"
                                                value={phone}
                                                onChange={(event) =>
                                                    setPhone(event.target.value)
                                                }
                                                id="phone"
                                            />
                                        </Col>
                                    </Row>

                                    <div>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Hozzáférési szint
                                            </label>
                                            <Col sm={10}>
                                                <AvCheckboxGroup
                                                    inline
                                                    name="access"
                                                >
                                                    <AvCheckbox
                                                        label="Szuper Admin"
                                                        checked={superAdmin}
                                                        onChange={(event) =>
                                                            setSuperAdmin(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                    <AvCheckbox
                                                        label="Call Center"
                                                        checked={callCenter}
                                                        onChange={(event) =>
                                                            setCallCenter(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                    <AvCheckbox
                                                        label="Pénzügy"
                                                        checked={finance}
                                                        onChange={(event) =>
                                                            setFinance(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                    <AvCheckbox
                                                        label="Esetmenedzser"
                                                        checked={caseManager}
                                                        onChange={(event) =>
                                                            setCaseManager(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                    <AvCheckbox
                                                        label="Esetmenedzser minden szakterülethez"
                                                        checked={
                                                            fullCaseManager
                                                        }
                                                        onChange={(event) =>
                                                            setFullCaseManager(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                    <AvCheckbox
                                                        label="Orvos"
                                                        checked={doctor}
                                                        onChange={(event) =>
                                                            setDoctor(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                    <AvCheckbox
                                                        label="Patikus"
                                                        checked={pharmacist}
                                                        onChange={(event) =>
                                                            setPharmacist(
                                                                event.target
                                                                    .checked
                                                            )
                                                        }
                                                    />
                                                </AvCheckboxGroup>
                                            </Col>
                                        </Row>
                                        {doctor ? (
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="doctor-id"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Orvos ID
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="doctor-id"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg az Orvos ID-t!"
                                                        className="form-control"
                                                        type="text"
                                                        value={doctorId}
                                                        onChange={(event) =>
                                                            setDoctorId(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="doctor-id"
                                                    />
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </div>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger float-right"
                                        onClick={handleDelete}
                                    >
                                        Admin törlése
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>

                {props.access.superAdmin == "1" ? (
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody className="general-form">
                                    <AvForm
                                        onValidSubmit={handlePasswordChange}
                                    >
                                        <Row className="form-group">
                                            <label
                                                htmlFor="passwordChange"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Jelszó változtatás
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="passwordChange"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg az új jelszót!"
                                                    className="form-control"
                                                    type="text"
                                                    value={newPassword}
                                                    onChange={(event) =>
                                                        setNewPassword(
                                                            event.target.value
                                                        )
                                                    }
                                                    id="passwordChange"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={() =>
                                                        setNewPassword(
                                                            generatePassword()
                                                        )
                                                    }
                                                >
                                                    Generálás
                                                </button>
                                            </Col>
                                            <Col>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary float-right"
                                                >
                                                    Változtatás
                                                </button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                ) : null}

                {props.access.superAdmin == "1" && (
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody className="general-form">
                                    <AvForm
                                        onValidSubmit={handlePasswordChange}
                                    >
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Ügyeleti jutalék
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="duty-commission"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) =>
                                                        setDutyCommission(
                                                            event.target.value
                                                        )
                                                    }
                                                    value={dutyCommission}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Chat jutalék
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="chat-commission"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) =>
                                                        setChatCommission(
                                                            event.target.value
                                                        )
                                                    }
                                                    value={chatCommission}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Másodvélemény jutalék
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="second-opinion-commission"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) =>
                                                        setSecondOpinionCommission(
                                                            event.target.value
                                                        )
                                                    }
                                                    value={
                                                        secondOpinionCommission
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Járóbeteg jutalék
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="outpatient-commission"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) =>
                                                        setOutpatientCommission(
                                                            event.target.value
                                                        )
                                                    }
                                                    value={outpatientCommission}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Videó konzultáció jutalék
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="video-consultation-commission"
                                                    validate={{
                                                        required: {
                                                            value: false,
                                                        },
                                                    }}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(event) =>
                                                        setVideoConsultationCommission(
                                                            event.target.value
                                                        )
                                                    }
                                                    value={
                                                        videoConsultationCommission
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={updateCommissions}
                                        >
                                            Mentés
                                        </button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                )}
                {qualifications &&
                    institutions &&
                    caseInstitutions &&
                    caseQualifications &&
                    props.access.superAdmin == "1" && (
                        <OpenableCard
                            title={"Esetmenedzsment hozzáférés"}
                            id="admin-form"
                        >
                            <CaseSettings
                                qualifications={qualifications}
                                institutions={institutions}
                                caseInstitutions={caseInstitutions}
                                caseQualifications={caseQualifications}
                                adminId={id}
                            />
                        </OpenableCard>
                    )}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDetails);
