import { dateToLocalInputString } from "tdoc-common";
import {
    FormDateTimeInput,
    FormError,
    FormNumberInput,
    FormSubmitButton,
} from "../../form";

const CaseNhaSubscriptionFormInner = () => (
    <>
        <FormDateTimeInput
            name="expiresAt"
            required
            min={dateToLocalInputString()}
            label="Lejárat"
        />
        <FormNumberInput
            name="maxDeviceCount"
            type="number"
            label="Eszközök száma (üres = nincs korlát)"
        />
        <FormNumberInput
            name="maxRepresentativeCount"
            type="number"
            label="Meghatalmazottak száma (üres = nincs korlát)"
        />
        <FormError />
        <FormSubmitButton>Mentés</FormSubmitButton>
    </>
);

export default CaseNhaSubscriptionFormInner;
