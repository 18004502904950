import { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import Grid from "@material-ui/core/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../../assets/Theme";
import { formatNumber } from "../reservationUtilities";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import { endLoading, post } from "../../../config/fetch";
import classes from "./ReservationSummary.module.css";
import InfoRow from "./InfoRow/InfoRow";
import Textarea from "./Textarea/Textarea";
import Button from "./Button/Button";
import PersonalForm from "./PersonalForm/PersonalForm";
import BillingForm from "./BillingForm/BillingForm";
import AlertLine from "./AlertLine/AlertLine";
// import { connect } from 'react-redux';
// import * as actions from '../../../store/actions/index';
// import SubscriptionAndServiceModal from '../../Modals/SubscriptionAndServiceModal/SubscriptionAndServiceModal';

const ReservationSummary = () => {
    // http://localhost:3000/idopont-kivalasztva?selectedTime=2021-07-19%2017%3A30&doctor=Dr.%20Schuller%20J%C3%A1nos&institution=TritonLife%20Medical%20Center%20Genium&institutionAddress=Budapest%2C%20Th%C3%B6k%C3%B6ly%20%C3%BAt%20137%2C%201146%20Magyarorsz%C3%A1g&service=F%C3%BCl-orr-g%C3%A9g%C3%A9szeri%20szakorvosi%20vizsg%C3%A1lat&price=28000

    const navigate = useNavigate();

    const getReservationData = (key) =>
        appointmentData ? appointmentData[key] : params.get(key);

    const [comment, setComment] = useState("");

    const search = useLocation().search;
    const urlQuery = decodeURIComponent(search);
    const params = new URLSearchParams(urlQuery);
    let appointmentData = null;

    const id = getReservationData("id");
    const date = getReservationData("selectedTime");
    const doctor = getReservationData("doctor");
    const doctorId = getReservationData("doctorId");
    const institution = getReservationData("institution");
    const institutionId = getReservationData("institutionId");
    const address = getReservationData("institutionAddress");
    const service = getReservationData("service");
    const serviceId = getReservationData("serviceId");
    const price = getReservationData("price");
    const isVideo = getReservationData("isVideo") === "true";
    const patient = getReservationData("patient");
    const patientId = getReservationData("patientId");
    const patientHasCard = getReservationData("patientHasCard") !== "false";
    const doctorImage = appointmentData
        ? appointmentData["doctorImage"]
        : urlQuery.substr(urlQuery.indexOf("doctorImage") + 12);

    const [missingData, setMissingData] = useState({
        personal: false,
        billing: false,
    });
    const [userData, setUserData] = useState({
        personal: null,
        billing: null,
    });

    const personalRef = useRef();
    const billingRef = useRef();

    const saveUserData = () => {
        let personal = null;
        let billing = null;
        if (missingData.personal) {
            personal = personalRef.current.data;
        }
        if (missingData.billing) {
            billing = billingRef.current.data;
            if (!billing.country || billing.country === "") {
                billing.country = "Magyarország";
            }
        }

        if (personal || billing) {
            post("api/v1/reservation/update-user-data-admin", {
                personal,
                billing,
                patientId,
            })
                .then((response) => {
                    endLoading();
                    switch (response.data.responseCode) {
                        case "OK":
                            setMissingData({
                                personal: null,
                                billing: null,
                            });

                            // sendReservation();
                            break;
                        case "MISSING_DATA":
                            responseMessage("Minden adat megadása kötelező!");

                            break;
                        case "WRONG_PHONE":
                            responseMessage("Hibás telefonszám!");

                            break;
                        case "WRONG_EMAIL":
                            responseMessage("Hibás email!");

                            break;
                        case "EMAIL_TAKEN":
                            responseMessage("Email foglalt!");

                            break;
                        case "PHONE_TAKEN":
                            responseMessage("Telefonszám foglalt!");

                            break;
                        case "INVALID_SECURITY_NUMBER":
                            responseMessage("Hibás TAJ szám!");

                            break;
                        default:
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => console.error(error));
        }
    };

    const onReservationClick = (isChargeCard = "true") => {
        if (
            isChargeCard === "true" &&
            (missingData.personal || missingData.billing)
        ) {
            responseMessage("Kérjük adja meg személyes és számlázási adatait!");

            return;
        }

        initReservation(isChargeCard);
    };

    const sendEmailWithLink = () => {
        if (missingData.personal || missingData.billing) {
            return;
        }

        let urlParams;
        if (isVideo) {
            urlParams = `id=${id}&selectedTime=${date}&doctor=${doctor}&doctorId=${doctorId}&service=${service}&serviceId=${serviceId}&isVideo=${isVideo}&price=${price}&doctorImage=${doctorImage}`;
        } else {
            urlParams = `id=${id}&selectedTime=${date}&doctor=${doctor}&doctorId=${doctorId}&service=${service}&serviceId=${serviceId}&institution=${institution}&institutionAddress=${address}&institutionId=${institutionId}&isVideo=${isVideo}&price=${price}&doctorImage=${doctorImage}`;
        }

        post("api/v1/reservation/send-link", {
            query: encodeURIComponent(urlParams),
            patientId,
            selectedTime: date,
            service,
            doctor,
            isVideo,
            price,
            institution,
            address,
            comment,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres link küldés!");
                        navigate(`/reservations`);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const initReservation = (isChargeCard = "true") =>
        post("api/v1/reservation/init-reservation-admin", {
            service: isVideo ? "VIDEO_CONSULTATION" : "OUTPATIENT",
            selectedTime: date,
            isChargeCard,
            appointmentId: id,
            // otherPatient: otherPatient ? dataRef.current.data : null,
            comment,
            patientId,
        })
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres foglalás!");
                        navigate(`/reservations`);

                        break;
                    case "SELECTED_TIME_ALREADY_RESERVED":
                        responseMessage(
                            "A kiválasztott időpontot időközben lefoglalták. Válasszon új időpontot!"
                        );
                        navigate(
                            `/reservation?patient=${patientId}&type=${
                                isVideo ? "videoConsultation" : "outpatient"
                            }&selectedDoctor=${doctorId}&selectedInstitution=${institutionId}&selectedService=${serviceId}&noCache=true`
                        );

                        break;
                    case "EMPTY_DEFAULT_CARD": {
                        break;
                    }
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const checkUserDatas = () =>
        post("api/v1/reservation/checkout-data-check-admin", {
            patientId,
        }).then((response) => {
            switch (response.data.responseCode) {
                case "OK":
                    break;
                case "MISSING_DATA":
                    setMissingData({
                        personal: response.data.error.personal,
                        billing: response.data.error.billing,
                    });
                    setUserData({
                        personal: response.data.data.personal,
                        billing: response.data.data.billing,
                    });

                    break;
                default:
                    break;
            }
            endLoading();
        });

    useEffect(() => {
        checkUserDatas();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Időpont kiválasztva
                            </h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <div style={{ marginBottom: "2rem" }}>
                                        <InfoRow
                                            name="Páciens:"
                                            value={patient}
                                            noBG={true}
                                        />
                                        <InfoRow name="Időpont:" value={date} />
                                        {doctor && (
                                            <InfoRow
                                                name="Orvos:"
                                                value={doctor}
                                                noBG={true}
                                                image={doctorImage}
                                            />
                                        )}
                                        {!isVideo && (
                                            <>
                                                <InfoRow
                                                    name="Helyszín:"
                                                    value={institution}
                                                    extraText={address}
                                                />
                                                <InfoRow
                                                    name="Vizsgálat:"
                                                    value={service}
                                                    noBG={true}
                                                />
                                                <InfoRow
                                                    name="Ár:"
                                                    value={`${formatNumber(
                                                        price
                                                    )} Ft`}
                                                />
                                            </>
                                        )}
                                        {isVideo && (
                                            <>
                                                <InfoRow
                                                    name="Vizsgálat:"
                                                    value={service}
                                                />
                                                <InfoRow
                                                    name="Ár:"
                                                    value={`${formatNumber(
                                                        price
                                                    )} Ft`}
                                                    noBG={true}
                                                />
                                            </>
                                        )}
                                    </div>

                                    {(missingData.personal ||
                                        missingData.billing) && (
                                        <Grid container justify="center">
                                            <Grid item md={6} xs={12}>
                                                <AlertLine
                                                    text="Figyelem! A foglaláshoz töltse ki a páciens hiányzó adatait!"
                                                    style={{
                                                        marginBottom: "2rem",
                                                    }}
                                                    icon={
                                                        Icons.exclamationBlack
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {missingData.personal &&
                                        userData.personal && (
                                            <Grid
                                                container
                                                justify="center"
                                                style={{ marginBottom: "3rem" }}
                                            >
                                                <Grid item md={6} xs={12}>
                                                    <PersonalForm
                                                        ref={personalRef}
                                                        data={userData.personal}
                                                        mainTitle="Hiányzó személyes adatok"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                    {missingData.billing &&
                                        userData.billing && (
                                            <Grid
                                                container
                                                justify="center"
                                                style={{ marginBottom: "3rem" }}
                                            >
                                                <Grid item md={6} xs={12}>
                                                    <BillingForm
                                                        ref={billingRef}
                                                        data={userData.billing}
                                                        mainTitle="Hiányzó számlázási adatok"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                    <Grid container justify="center">
                                        <Grid item md={6} xs={12}>
                                            <p className={classes.textLabel}>
                                                Megjegyzés szövege
                                            </p>
                                            <Textarea
                                                rows={5}
                                                value={comment}
                                                onChange={(value) =>
                                                    setComment(value)
                                                }
                                                maxLength="500"
                                            />
                                            <p className={classes.charInfo}>
                                                {500 - comment.length} karaktert
                                                írhat még
                                            </p>
                                            {missingData.billing ||
                                            missingData.personal ? (
                                                <div
                                                    className={
                                                        classes.saveBtnWrapper
                                                    }
                                                >
                                                    <Button
                                                        text="Mentés"
                                                        onClick={() =>
                                                            saveUserData()
                                                        }
                                                    />
                                                    <span>vagy</span>
                                                    <Button
                                                        text="Véglegesítés"
                                                        onClick={() =>
                                                            onReservationClick(
                                                                "false"
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        classes.btnWrapper
                                                    }
                                                >
                                                    <Button
                                                        text="Kártya terhelés"
                                                        onClick={() =>
                                                            onReservationClick()
                                                        }
                                                        disabled={
                                                            !patientHasCard
                                                        }
                                                    />
                                                    <Button
                                                        text="Link küldés"
                                                        onClick={() =>
                                                            sendEmailWithLink()
                                                        }
                                                        disabled={false}
                                                    />
                                                    <Button
                                                        text="Véglegesítés"
                                                        onClick={() =>
                                                            onReservationClick(
                                                                "false"
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ReservationSummary;
