import { init, setTag } from "@sentry/react";
import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import { getEnv } from "tdoc-common";

const ignoreErrors = ["Add patterns for ignored errors here"];

const dsn = getEnv("SENTRY_DSN");
const environment = getEnv("ENVIRONMENT");
const namespace = getEnv("NAMESPACE");
const release = getEnv("RELEASE");

export const initSentry = () => {
    init({
        dsn,
        environment,
        release,
        attachStacktrace: true,
        integrations: [
            new ExtraErrorData(),
            new CaptureConsole({
                levels: ["error", "assert"],
            }),
            new Integrations.BrowserTracing({
                tracingOrigins: [/t-doc/],
            }),
        ],
        ignoreErrors,
        tracesSampleRate: 0.1,
    });
    setTag("namespace", namespace);
};
