import { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { Grid, Hidden } from "@material-ui/core";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import Textarea from "../../../components/UI/Textarea/Textarea";
import Button from "../../../components/UI/Button/Button";
import UploadedFile from "../../Chat/UploadedFile/UploadedFile";
import { Icons } from "../../../assets/Theme";
import { endLoading, get, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import classes from "./NewConsilium.module.css";

const NewConsilium = (props) => {
    const navigate = useNavigate();
    const spacing = window.innerWidth > 599 ? 6 : 6;

    const [files, setFiles] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctors, setSelectedDoctors] = useState(null);

    const inputRef = useRef();
    const uploadInputRef = useRef();
    const nameRef = useRef();
    const descriptionRef = useRef();
    const birthDateRef = useRef();

    useEffect(() => {
        get("api/v1/medical/get-doctors")
            .then((response) => {
                endLoading();
                const filteredDoctors = response.data.data.filter(
                    (d) => d.id != props.doctorId && d.consilium == "1"
                );
                setDoctors(filteredDoctors);
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fileAdded = (event) => {
        const file = event.target.files[0];
        setFiles([...files, file]);
        uploadInputRef.current.value = null;
    };

    const onDeleteClick = (e, index) => {
        let newFiles = files;
        newFiles.splice(index, 1);
        setFiles([...newFiles]);
    };

    const startConsilium = () => {
        let formData = new FormData();
        if (files.length > 0) {
            let i = 0;
            for (let f of files) {
                formData.append(`file${i++}`, f);
            }
        }

        if (!nameRef.current.value) {
            responseMessage("A megnevezés kötelező!");

            return;
        }

        formData.append("name", nameRef.current.value);
        formData.append("description", descriptionRef.current.value);
        formData.append("message", inputRef.current.value);
        formData.append("doctors", JSON.stringify(selectedDoctors));
        formData.append("birthDate", birthDateRef.current.value);

        post("api/v1/consilium/create-chat", formData)
            .then((response) => {
                endLoading();
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres létrehozás!");
                        navigate(`/consilium/${response.data.identifier}`);

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col xs={12}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Új konzílium</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Grid container spacing={spacing}>
                                    <Hidden xsDown>
                                        <Grid item sm={1}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={10}>
                                        <Textarea
                                            label="Megnevezés"
                                            placeholder="Név"
                                            ref={nameRef}
                                            rows={1}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={spacing}>
                                    <Hidden xsDown>
                                        <Grid item sm={1}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={10}>
                                        <Textarea
                                            label="Születési dátum"
                                            placeholder="Születési dátum"
                                            ref={birthDateRef}
                                            rows={1}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={spacing}>
                                    <Hidden xsDown>
                                        <Grid item sm={1}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={10}>
                                        <Textarea
                                            label="Leírás"
                                            placeholder="Leírás"
                                            ref={descriptionRef}
                                            rows={3}
                                        />
                                    </Grid>
                                </Grid>
                                {doctors.length > 0 && (
                                    <Grid container spacing={spacing}>
                                        <Hidden xsDown>
                                            <Grid item sm={1}></Grid>
                                        </Hidden>
                                        <Grid item xs={12} sm={10}>
                                            <p className={classes.label}>
                                                Orvosok
                                            </p>
                                            <CustomSelect
                                                isMulti={true}
                                                placeholder="Orvos hozzáadása"
                                                options={doctors.map((d) => ({
                                                    value: d.id,
                                                    label: d.name,
                                                }))}
                                                onChange={(e) =>
                                                    setSelectedDoctors(e)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={spacing}>
                                    <Hidden xsDown>
                                        <Grid item sm={1}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={10}>
                                        <Textarea
                                            label="Üzenet szövege"
                                            placeholder="Üzenet"
                                            ref={inputRef}
                                        />
                                        <div className={classes.fileContainer}>
                                            {files.map((item, index) => (
                                                <UploadedFile
                                                    source={URL.createObjectURL(
                                                        item
                                                    )}
                                                    onDeleteClick={(e) =>
                                                        onDeleteClick(e, index)
                                                    }
                                                    key={`key_${item.name}`}
                                                    name={item.name}
                                                    style={{
                                                        border: "1px solid #929EA7",
                                                        margin: "0.5rem 0.5rem 0rem 0rem",
                                                    }}
                                                    // className={classes.uploadedFile}
                                                    deleteEnabled
                                                />
                                            ))}
                                        </div>
                                        <input
                                            type="file"
                                            className={classes.uploadInput}
                                            ref={uploadInputRef}
                                            onChange={fileAdded}
                                        />
                                        <div className={classes.buttons}>
                                            <Button
                                                className={classes.sendButton}
                                                onClick={startConsilium}
                                                text="Konzílium létrehozása"
                                            />
                                            <input
                                                type="file"
                                                className={classes.uploadInput}
                                                ref={uploadInputRef}
                                                onChange={fileAdded}
                                            />
                                            <Button
                                                className={classes.button}
                                                onClick={() =>
                                                    uploadInputRef.current.click()
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.uploadButtonInner
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            Icons.attachmentGreen
                                                        }
                                                        className={
                                                            classes.uploadIcon
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            classes.buttonText
                                                        }
                                                    >
                                                        Dokumentum csatolása
                                                    </span>
                                                </div>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    doctorId: state.auth.access.doctorId,
});

export default connect(mapStateToProps, null)(NewConsilium);
