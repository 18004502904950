import { useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation } from "react-router-dom";
import DatePicker from "../../components/DatePicker/DatePicker";
import NewItemBtn from "../../components/CaseManager/NewItemBtn/NewItemBtn";
import { endLoading, post } from "../../config/fetch";
import { convertDate, responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const AddServiceBatch = () => {
    const { state } = useLocation();

    const { users } = state;

    const [serviceData, setServiceData] = useState([
        {
            service: "",
            expiresAt: "",
            quantity: "",
            comment: "",
        },
    ]);

    const addService = () => {
        setServiceData([
            ...serviceData,
            { service: "", expiresAt: "", quantity: "", comment: "" },
        ]);
    };

    const handleSubmit = () => {
        post("admin/add-extra-service-batch", {
            services: serviceData,
            userIds: users,
        })
            .then((res) => {
                switch (res.data.responseCode) {
                    case "OK":
                        endLoading();
                        responseMessage(ALERT_MESSAGES.success);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch(() => {
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const forms = serviceData.map((data, index) => (
        <>
            <hr />
            <Row className="form-group">
                <Col sm={2}>
                    <CloseIcon
                        style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#ff1900",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            const newData = [...serviceData];
                            newData.splice(index, 1);
                            setServiceData(newData);
                        }}
                    />
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Input
                            type="select"
                            name="select"
                            onChange={(e) => {
                                const newData = [...serviceData];
                                newData[index].service = e.target.value;
                                setServiceData(newData);
                            }}
                        >
                            <option value="" selected disabled>
                                Válasszon szolgáltatást...
                            </option>
                            <option value="HOTLINE">Hotline hívása</option>
                            <option value="DUTY">Ügyelet hívása</option>
                            <option value="VIDEO_CONSULTATION">
                                Videó konzultáció
                            </option>
                            <option value="CHAT">Üzenetváltás</option>
                            <option value="SECOND_OPINION">
                                Másodvélemény
                            </option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={1}>
                    <p>
                        <strong>Lejárati idő:</strong>
                    </p>
                </Col>
                <Col sm={3}>
                    <DatePicker
                        onChange={(date) => {
                            const newData = [...serviceData];
                            newData[index].expiresAt = convertDate(date);
                            setServiceData(newData);
                        }}
                        value={serviceData[index].expiresAt}
                        disablePast={true}
                        disableFuture={false}
                    />
                </Col>
                <Col sm={1}>
                    <p>
                        <strong>Darabszám:</strong>
                    </p>
                </Col>
                <Col sm={2}>
                    <Input
                        type="number"
                        min="1"
                        onChange={(e) => {
                            const newData = [...serviceData];
                            newData[index].quantity = e.target.value;
                            setServiceData(newData);
                        }}
                    />
                </Col>
            </Row>
            <Row className="form-group">
                <Col>
                    <FormGroup>
                        <Input
                            type="textarea"
                            placeholder="Komment"
                            onChange={(e) => {
                                const newData = [...serviceData];
                                newData[index].comment = e.target.value;
                                setServiceData(newData);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    ));

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Szolgáltatás hozzáadása
                            </h4>
                        </div>
                    </Col>
                </Row>
            </div>

            <AvForm onValidSubmit={handleSubmit}>
                <Card>
                    <CardBody>
                        <NewItemBtn
                            text={`+ Új`}
                            onClick={() => addService()}
                        />
                        <br></br>
                        {forms}
                    </CardBody>
                </Card>
                <Button type="submit" color="primary">
                    Mentés
                </Button>
            </AvForm>
        </>
    );
};

export default AddServiceBatch;
