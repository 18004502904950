import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import PatientInfos from "./PatientInfos/PatientInfos";
import ReservationPicker from "./ReservationPicker/ReservationPicker";

const sortData = (data, onlyVideo, onlyOutpatient) => {
    const datas = [];
    let i = 0;
    for (let d of data) {
        if (
            (onlyVideo && !d.video_consultation) ||
            (onlyOutpatient && d.video_consultation)
        )
            continue;
        datas.push({
            id: i++,
            appointmentId: d.amnt_doctors_id,
            institution: d.institution_name,
            institutionId: d.institution_id,
            institutionAddress: d.institution_address,
            institutionImage: d.institution_image,
            profileImageUrl: d.profile_image_url,
            qualification: d.qualification_name,
            qualificationId: d.qualification_id,
            service: d.specialization_name,
            serviceId: d.specialization_id,
            doctor: d.doctor_name,
            doctorId: d.doctor_id,
            isVideo: d.video_consultation,
            price: d.amount,
        });
    }

    return datas;
};

const Reservation = () => {
    const search = useLocation().search;
    const urlQuery = decodeURIComponent(search);
    const params = new URLSearchParams(urlQuery);

    const patient = params.get("patient");
    const type = params.get("type");

    const [allData, setAllData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [users, setUsers] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    let selectedQualification = params.get("selectedQualification");
    if (selectedQualification) {
        selectedQualification = +selectedQualification;
    }

    let selectedDoctor = params.get("selectedDoctor");
    if (selectedDoctor) {
        selectedDoctor = +selectedDoctor;
    }

    let selectedInstitution = params.get("selectedInstitution");
    if (selectedInstitution) {
        selectedInstitution = +selectedInstitution;
    }

    let selectedService = params.get("selectedService");
    if (selectedService) {
        selectedService = +selectedService;
    }

    let noCache = params.get("noCache") === "true";

    useEffect(() => {
        get("api/v1/reservation/get-possibilities-admin", null, false).then(
            (response) => {
                endLoading();
                setAllData(response.data.data);
                // setFilterData(sortData(response.data.data, false, false));
                // if (type && (type !== 'videoConsultation' && type !== 'outpatient')) {

                // }

                if (!type) {
                    setFilterData(sortData(response.data.data, false, false));

                    return;
                }
                if (type === "videoConsultation") {
                    // setSelectedType({value: 'videoConsultation', label: 'Csak videókonzultáció'});
                    // setFilterData(sortData(response.data.data, true, false));
                    onSelectType(
                        {
                            value: "videoConsultation",
                            label: "Csak videókonzultáció",
                        },
                        response.data.data,
                        false
                    );
                } else if (type === "outpatient") {
                    // setSelectedType({value: 'outpatient', label: 'Csak járóbeteg ellátás'});
                    // setFilterData(sortData(response.data.data, false, true));
                    onSelectType(
                        {
                            value: "outpatient",
                            label: "Csak járóbeteg ellátás",
                        },
                        response.data.data,
                        false
                    );
                } else if (type === "both") {
                    // setFilterData(sortData(response.data.data, false, false));
                    // setSelectedType({value: 'both', label: 'Összes'});
                    onSelectType(
                        { value: "both", label: "Összes" },
                        response.data.data,
                        false
                    );
                }
            }
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        get("admin/users")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setUsers(response.data.users);
                        if (patient) {
                            const p = getPatientData(
                                response.data.users,
                                patient
                            );
                            setSelectedPatient({
                                value: p.id,
                                label: `${p.last_name} ${p.first_name}`,
                            });
                        }
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectType = (e, data, patientReset = true) => {
        setSelectedType(e);
        switch (e.value) {
            case "outpatient":
                setFilterData(sortData(data, false, true));
                if (patientReset) {
                    setSelectedPatient(null);
                }

                break;
            case "videoConsultation":
                setFilterData(sortData(data, true, false));
                if (patientReset) {
                    setSelectedPatient(null);
                }

                break;
            case "both":
                setFilterData(sortData(data, false, false));
                if (patientReset) {
                    setSelectedPatient(null);
                }

                break;
            default:
                break;
        }
    };

    const getPatientData = (users, id) => users.find((u) => u.id == id);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Időpontfoglalás</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="card">
                            <div className="card-body">
                                <CustomSelect
                                    value={selectedType}
                                    isMulti={false}
                                    placeholder="Megjelenítendő naptárak: Összes, Csak járóbeteg ellátás, Csak videókonzultáció"
                                    options={[
                                        { value: "both", label: "Összes" },
                                        {
                                            value: "outpatient",
                                            label: "Csak járóbeteg ellátás",
                                        },
                                        {
                                            value: "videoConsultation",
                                            label: "Csak videókonzultáció",
                                        },
                                    ]}
                                    onChange={(e) => onSelectType(e, allData)}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                {users && selectedType && (
                    <>
                        <Row>
                            <Col sm={12}>
                                <div className="card">
                                    <div className="card-body">
                                        <CustomSelect
                                            value={selectedPatient}
                                            isMulti={false}
                                            placeholder="Válasszon pácienst..."
                                            options={users.map((u) => ({
                                                value: u.id,
                                                label: `${u.last_name} ${u.first_name}`,
                                            }))}
                                            onChange={(e) =>
                                                setSelectedPatient(e)
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
                {selectedPatient && (
                    <Row>
                        <Col sm={12}>
                            <PatientInfos
                                users={users}
                                selected={selectedPatient.value}
                            />
                        </Col>
                    </Row>
                )}
                {selectedPatient && selectedType && filterData && (
                    <Row>
                        <Col sm={12}>
                            <div className="card">
                                <div className="card-body">
                                    <ReservationPicker
                                        selectedQualification={
                                            selectedQualification
                                        }
                                        selectedDoctor={selectedDoctor}
                                        selectedInstitution={
                                            selectedInstitution
                                        }
                                        selectedService={selectedService}
                                        selectedPatient={selectedPatient}
                                        patientData={getPatientData(
                                            users,
                                            selectedPatient.value
                                        )}
                                        filterData={filterData}
                                        onlyOutpatient={
                                            selectedType.value === "outpatient"
                                        }
                                        onlyVideo={
                                            selectedType.value ===
                                            "videoConsultation"
                                        }
                                        noCache={noCache}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    );
};

export default Reservation;
