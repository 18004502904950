import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import FileUploader from "../../components/FileUploader/FileUploader";
import { endLoading, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const NewAdmin = () => {
    const navigate = useNavigate();
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [imageData, setImageData] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [callCenter, setCallCenter] = useState(false);
    const [finance, setFinance] = useState(false);
    const [caseManager, setCaseManager] = useState(false);
    const [fullCaseManager, setFullCaseManager] = useState(false);
    const [pharmacist, setPharmacist] = useState(false);

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("phone", phone);
        formData.append("superAdmin", superAdmin === true ? 1 : 0);
        formData.append("callCenter", callCenter === true ? 1 : 0);
        formData.append("finance", finance === true ? 1 : 0);
        formData.append("caseManager", caseManager === true ? 1 : 0);
        formData.append("fullCaseManager", fullCaseManager === true ? 1 : 0);
        formData.append("pharmacist", pharmacist === true ? 1 : 0);

        post("admin/create-admin-user", formData)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/admins");

                        break;
                    case "EMAIL_OR_PHONE_TAKEN":
                        endLoading();
                        responseMessage(
                            "Az email cím vagy telefonszám foglalt!"
                        );

                        break;
                    case "WRONG_EMAIL":
                        endLoading();
                        responseMessage("Helytelen email formátum!");

                        break;
                    default:
                        console.error("default_error");
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Új Admin felhasználó
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="admin-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="last-name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Vezetéknév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="last-name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az admin vezetéknevét!"
                                                className="form-control"
                                                type="text"
                                                value={lastName}
                                                onChange={(event) =>
                                                    setLastName(
                                                        event.target.value
                                                    )
                                                }
                                                id="last-name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="first-name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Keresztnév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="first-name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az admin keresztnevét!"
                                                className="form-control"
                                                type="text"
                                                value={firstName}
                                                onChange={(event) =>
                                                    setFirstName(
                                                        event.target.value
                                                    )
                                                }
                                                id="first-name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="email"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="email"
                                                validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                }}
                                                errorMessage="Adjon meg valós email címet!"
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                                id="email"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Ideiglenes jelszó
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="password"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adjon meg egy jelszót!"
                                                className="form-control"
                                                type="text"
                                                value={password}
                                                onChange={(event) =>
                                                    setPassword(
                                                        event.target.value
                                                    )
                                                }
                                                id="password"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "1rem" }}
                                    >
                                        <label
                                            htmlFor="image"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kép
                                        </label>
                                        <Col sm={10}>
                                            <FileUploader
                                                handleFile={handleFile}
                                            />
                                            {imageURL ? (
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                    onClick={cancelImageUpload}
                                                >
                                                    Mégsem
                                                </button>
                                            ) : null}
                                            {imageURL ? (
                                                <div className="image-preview">
                                                    <img
                                                        className="image"
                                                        src={imageURL}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="phone"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Telefonszám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="phone"
                                                className="form-control"
                                                type="text"
                                                value={phone}
                                                onChange={(event) =>
                                                    setPhone(event.target.value)
                                                }
                                                id="phone"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Hozzáférési szint
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup
                                                inline
                                                name="access"
                                            >
                                                <AvCheckbox
                                                    label="Szuper Admin"
                                                    checked={superAdmin}
                                                    onChange={(event) =>
                                                        setSuperAdmin(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                                <AvCheckbox
                                                    label="Call Center"
                                                    checked={callCenter}
                                                    onChange={(event) =>
                                                        setCallCenter(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                                <AvCheckbox
                                                    label="Pénzügy"
                                                    checked={finance}
                                                    onChange={(event) =>
                                                        setFinance(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                                <AvCheckbox
                                                    label="Esetmenedzser"
                                                    checked={caseManager}
                                                    onChange={(event) =>
                                                        setCaseManager(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                                <AvCheckbox
                                                    label="Esetmenedzser minden szakterülethez"
                                                    checked={fullCaseManager}
                                                    onChange={(event) =>
                                                        setFullCaseManager(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                                <AvCheckbox
                                                    label="Patikus"
                                                    checked={pharmacist}
                                                    onChange={(event) =>
                                                        setPharmacist(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default NewAdmin;
