import cn from "classnames";
import styles from "./ReadOnlyFieldList.module.css";

const ReadOnlyFieldList = ({ data, classInput }) => (
    <div className={cn(styles.scope, classInput)}>
        {Object.entries(data).map(([title, value], i) => (
            <dl key={title + i}>
                <dt>{title ?? "-"}</dt>
                <dd>{value ?? "-"}</dd>
            </dl>
        ))}
    </div>
);

export default ReadOnlyFieldList;
