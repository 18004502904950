import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import FileUploader from "../../components/FileUploader/FileUploader";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES, LANGUAGES } from "../../config/variables";
import TextEditor from "../../components/TextEditor/TextEditor";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const NewDoctor = () => {
    const navigate = useNavigate();

    const [qualifications, setQualifications] = useState([]);
    const [institutions, setInstitutions] = useState([]);

    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [doctorQualifications, setDoctorQualifications] = useState([]);
    const [doctorLanguages, setDoctorLanguages] = useState([]);
    const [doctorInstitutions, setDoctorInstitutions] = useState([]);

    const [imageData, setImageData] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [introduction, setIntroduction] = useState("");
    const [adminPhone, setAdminPhone] = useState("");
    const [profileLink, setProfileLink] = useState("");

    useEffect(() => {
        get("admin/get-qualifications-institutions")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        setQualifications(response.data.qualifications);
                        setInstitutions(response.data.institutions);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("title", title);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("qualifications", JSON.stringify(doctorQualifications));
        formData.append("institutions", JSON.stringify(doctorInstitutions));
        formData.append("languages", JSON.stringify(doctorLanguages));
        formData.append("introduction", introduction);
        formData.append("adminPhone", adminPhone);
        formData.append("profileLink", profileLink);

        post("admin/create-doctor", formData)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        navigate("/doctors");

                        break;
                    case "EMAIL_OR_PHONE_TAKEN":
                        endLoading();
                        responseMessage(
                            "Az email cím vagy telefonszám foglalt!"
                        );

                        break;
                    case "WRONG_EMAIL":
                        endLoading();
                        responseMessage("Helytelen email formátum!");

                        break;
                    case "PROFILE_LINK_TAKEN":
                        endLoading();
                        responseMessage("A profil link foglalt!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    const handleEditorChange = (content) => setIntroduction(content);

    const handleSelectChange = (e, type) => {
        let functionToUse;
        switch (type) {
            case "qualifications":
                functionToUse = setDoctorQualifications;

                break;
            case "languages":
                functionToUse = setDoctorLanguages;

                break;
            case "institutions":
                functionToUse = setDoctorInstitutions;

                break;
            default:
                break;
        }
        if (e === null) {
            functionToUse([]);

            return;
        }
        const modified = e.map((q) => ({ id: q.value, name: q.label }));
        functionToUse(modified);
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Új orvos</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="user-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="title"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Titulus
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="title"
                                                // validate={{ required: { value: true } }}
                                                errorMessage="Adja meg az orvos titulusát!"
                                                className="form-control"
                                                type="text"
                                                value={title}
                                                onChange={(event) =>
                                                    setTitle(event.target.value)
                                                }
                                                id="title"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="lastName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Vezetéknév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="lastName"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az orvos vezetéknevét!"
                                                className="form-control"
                                                type="text"
                                                value={lastName}
                                                onChange={(event) =>
                                                    setLastName(
                                                        event.target.value
                                                    )
                                                }
                                                id="lastName"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="firstName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Keresztnév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="firstName"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az orvos keresztnevét!"
                                                className="form-control"
                                                type="text"
                                                value={firstName}
                                                onChange={(event) =>
                                                    setFirstName(
                                                        event.target.value
                                                    )
                                                }
                                                id="firstName"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="qualification"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Szakterületek
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                isMulti={true}
                                                placeholder="Válasszon szakterületet..."
                                                options={qualifications.map(
                                                    (q) => ({
                                                        value: q.id,
                                                        label: q.name,
                                                    })
                                                )}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "qualifications"
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="institutions"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Intézmények
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                isMulti={true}
                                                placeholder="Válasszon intézményt..."
                                                options={institutions.map(
                                                    (i) => ({
                                                        value: i.id,
                                                        label: i.name,
                                                    })
                                                )}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "institutions"
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="languages"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Beszélt nyelvek
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                isMulti={true}
                                                placeholder="Válasszon nyelvet..."
                                                options={LANGUAGES}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "languages"
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="email"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="email"
                                                validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                }}
                                                errorMessage="Adjon meg valós email címet!"
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                                id="email"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Ideiglenes jelszó
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="password"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adjon meg egy jelszót!"
                                                className="form-control"
                                                type="text"
                                                value={password}
                                                onChange={(event) =>
                                                    setPassword(
                                                        event.target.value
                                                    )
                                                }
                                                id="password"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="adminPhone"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Admin telefonszám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="adminPhone"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                errorMessage="Adjon meg egy telefonszámot!"
                                                className="form-control"
                                                type="text"
                                                value={adminPhone}
                                                onChange={(event) =>
                                                    setAdminPhone(
                                                        event.target.value
                                                    )
                                                }
                                                id="adminPhone"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="profileLink"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Profil link
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="profileLink"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a profil linket!"
                                                className="form-control"
                                                type="text"
                                                value={profileLink}
                                                onChange={(event) =>
                                                    setProfileLink(
                                                        event.target.value
                                                    )
                                                }
                                                id="profileLink"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "1rem" }}
                                    >
                                        <label
                                            htmlFor="image"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kép
                                        </label>
                                        <Col sm={10}>
                                            <FileUploader
                                                handleFile={handleFile}
                                            />
                                            {imageURL ? (
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                    onClick={cancelImageUpload}
                                                >
                                                    Mégsem
                                                </button>
                                            ) : null}
                                            {imageURL ? (
                                                <div className="image-preview">
                                                    <img
                                                        className="image"
                                                        src={imageURL}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="introduction"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Bemutatkozás
                                        </label>
                                        <Col sm={10}>
                                            <TextEditor
                                                value={introduction}
                                                onEditorChange={
                                                    handleEditorChange
                                                }
                                            ></TextEditor>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default NewDoctor;
