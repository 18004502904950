import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvForm,
} from "availity-reactstrap-validation";
import { useUpdateCaseMutation } from "tdoc-common";
import { endLoading, startLoading } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./ChecklistCard.module.css";

interface Props {
    caseId: string;
    checklist: string[];
    setChecklist: (value: unknown) => void;
}

type IsDisabled = {
    arajanlat: boolean;
    dijbekero: boolean;
    elolegszamla: boolean;
    aneszt_ping: boolean;
};

const ChecklistCard = ({ caseId, checklist, setChecklist }: Props) => {
    const [updateCase] = useUpdateCaseMutation();
    const [isDisabled, setDisabled] = useState<IsDisabled>({
        arajanlat: false,
        dijbekero: false,
        elolegszamla: false,
        aneszt_ping: false,
    });

    const toggleDisabled = (item: string, includes: boolean) => {
        setDisabled((prevState) => ({
            ...prevState,
            [item]: includes,
        }));
    };

    useEffect(() => {
        toggleDisabled("elolegszamla", !checklist.includes("dijbekero"));
        toggleDisabled("dijbekero", checklist.includes("elolegszamla"));
    }, [checklist]);

    const handleChange = (item: string) => {
        setChecklist(
            !checklist.includes(item)
                ? [...checklist, item]
                : checklist.filter((checklistItem) => checklistItem !== item)
        );
    };

    const handleSubmit = () => {
        startLoading();
        updateCase({ caseId, checklist })
            .unwrap()
            .then(() => {
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <Card>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        <span>Checklist</span>
                    </div>
                </div>

                <div>
                    <Row className="form-group">
                        <Col sm={10}>
                            <AvForm>
                                <AvCheckboxGroup inline name="checklist">
                                    <AvCheckbox
                                        label="Árajánlat elfogadva"
                                        checked={checklist.includes(
                                            "arajanlat"
                                        )}
                                        disabled={isDisabled.arajanlat}
                                        onChange={() =>
                                            handleChange("arajanlat")
                                        }
                                    />
                                    <AvCheckbox
                                        label="Díjbekérő kiküldve"
                                        checked={checklist.includes(
                                            "dijbekero"
                                        )}
                                        disabled={isDisabled.dijbekero}
                                        onChange={() =>
                                            handleChange("dijbekero")
                                        }
                                    />
                                    <AvCheckbox
                                        label="Előlegszámla kiküldve"
                                        checked={checklist.includes(
                                            "elolegszamla"
                                        )}
                                        disabled={isDisabled.elolegszamla}
                                        onChange={() =>
                                            handleChange("elolegszamla")
                                        }
                                    />
                                    <AvCheckbox
                                        label="Aneszt előtti ping"
                                        checked={checklist.includes(
                                            "aneszt_ping"
                                        )}
                                        disabled={isDisabled.aneszt_ping}
                                        onChange={() =>
                                            handleChange("aneszt_ping")
                                        }
                                    />
                                </AvCheckboxGroup>
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Mentés
                                </Button>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    );
};

export default ChecklistCard;
