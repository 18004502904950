import { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "../../components/DatePicker/DatePicker";
import { endLoading, get, post } from "../../config/fetch";
import { convertDate, responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const NewUser = () => {
    const navigate = useNavigate();
    let query = useQuery();

    const [fromCase, setFromCase] = useState(null);

    const [lastName, setLastName] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [gender, setGender] = useState("Férfi");
    const [securityNumber, setSecurityNumber] = useState(null);
    const [idNumber, setIdNumber] = useState("");
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [phone, setPhone] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [subscription, setSubscription] = useState("1");
    const [nextCharge, setNextCharge] = useState(null);
    const [paymentNextDate, setPaymentNextDate] = useState(null);
    const [birthPlace, setBirthPlace] = useState(null);
    const [mothersName, setMothersName] = useState(null);
    const [employers, setEmployers] = useState(null);

    const [billingName, setBillingName] = useState(null);
    const [zipcode, setZipcode] = useState(null);
    const [country, setCountry] = useState(null);
    const [settlement, setSettlement] = useState(null);
    const [street, setStreet] = useState(null);
    const [houseNumber, setHouseNumber] = useState(null);
    const [floorDoor, setFloorDoor] = useState(null);
    const [taxNumber, setTaxNumber] = useState(null);
    const [employer, setEmployer] = useState({
        value: "0",
        label: "Nincs munkáltató",
    });

    const [newsletter, setNewsletter] = useState(null);

    const dateChangeHandler = (date) => setDateOfBirth(convertDate(date));

    const nextChargeHandler = (date) => setNextCharge(convertDate(date));

    const paymentNextDateHandler = (date) =>
        setPaymentNextDate(convertDate(date));

    const handleSubmit = () => {
        const user = {
            lastName,
            firstName,
            gender,
            securityNumber,
            idNumber,
            email,
            password,
            phone,
            dateOfBirth,
            subscription,
            employer: employer.value ? employer.value : employer,
            nextCharge,
            paymentNextDate,
            birthPlace,
            mothersName,
            newsletter,
        };

        const billing = {
            billingName,
            zipcode,
            country,
            settlement,
            street,
            houseNumber,
            floorDoor,
            taxNumber,
        };

        post("admin/create-user", {
            user,
            billing,
            fromCase,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        if (!fromCase) {
                            navigate("/users");
                        } else {
                            navigate(`/case-manager/${fromCase}`);
                        }

                        break;
                    case "EMAIL_OR_PHONE_TAKEN":
                        endLoading();
                        responseMessage(
                            "Az email cím vagy telefonszám foglalt!"
                        );

                        break;
                    case "WRONG_EMAIL":
                        endLoading();
                        responseMessage("Helytelen email formátum!");

                        break;
                    case "INVALID_SECURITY_NUMBER":
                        endLoading();
                        responseMessage("A megadott TAJ szám hibás!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    useEffect(() => {
        get("admin/employers")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        let employerData = response.data.employers;
                        employerData = [
                            { id: "0", name: "Nincs munkáltató" },
                            ...employerData,
                        ];
                        setEmployers(employerData);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

        if (query.get("fromCase")) {
            setFromCase(query.get("fromCase"));

            const firstName = query.get("firstName");
            const lastName = query.get("lastName");
            const email = query.get("email");
            const birthDate = query.get("birthDate");
            const phone = query.get("phone");
            const gender = query.get("gender");
            const securityNumber = query.get("securityNumber");
            const idNumber = query.get("idNumber");

            setFirstName(firstName);
            setLastName(lastName);
            if (email) {
                setEmail(email);
            }
            if (birthDate) {
                setDateOfBirth(birthDate);
            }
            if (phone) {
                setPhone(phone);
            }
            if (gender) {
                setGender(gender);
            }
            if (securityNumber) {
                setSecurityNumber(securityNumber);
            }
            if (idNumber) {
                setIdNumber(idNumber);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Új felhasználó</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="user-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="last-name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Vezetéknév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="last-name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a felhasználó vezetéknevét!"
                                                className="form-control"
                                                type="text"
                                                value={lastName}
                                                onChange={(event) =>
                                                    setLastName(
                                                        event.target.value
                                                    )
                                                }
                                                id="last-name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="first-name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Keresztnév
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="first-name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a felhasználó keresztnevét!"
                                                className="form-control"
                                                type="text"
                                                value={firstName}
                                                onChange={(event) =>
                                                    setFirstName(
                                                        event.target.value
                                                    )
                                                }
                                                id="first-name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="dateOfBirth"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Születési dátum
                                        </label>
                                        <Col sm={10}>
                                            <DatePicker
                                                value={dateOfBirth}
                                                onChange={(date) =>
                                                    dateChangeHandler(date)
                                                }
                                                disableFuture={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="birthPlace"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Születési hely
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="birthPlace"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                errorMessage="Adja meg a születési helyet!"
                                                className="form-control"
                                                type="text"
                                                value={birthPlace}
                                                onChange={(event) =>
                                                    setBirthPlace(
                                                        event.target.value
                                                    )
                                                }
                                                id="birthPlace"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="mothersName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Anyja neve
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="mothersName"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                errorMessage="Adja meg az anyja nevét!"
                                                className="form-control"
                                                type="text"
                                                value={mothersName}
                                                onChange={(event) =>
                                                    setMothersName(
                                                        event.target.value
                                                    )
                                                }
                                                id="mothersName"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2 col-form-label">
                                            Neme
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                type="select"
                                                name="select"
                                                value={gender}
                                                onChange={(event) =>
                                                    setGender(
                                                        event.target.value
                                                    )
                                                }
                                                className="form-control"
                                            >
                                                <option value="Férfi">
                                                    Férfi
                                                </option>
                                                <option value="Nő">Nő</option>
                                                <option value="Nem nyilatkozom">
                                                    Nem nyilatkozom
                                                </option>
                                            </AvField>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="security-number"
                                            className="col-sm-2 col-form-label"
                                        >
                                            TAJ szám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="security-number"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                errorMessage="Adja meg a TAJ számot!"
                                                className="form-control"
                                                type="text"
                                                value={securityNumber}
                                                placeholder="123-456-789"
                                                onChange={(event) =>
                                                    setSecurityNumber(
                                                        event.target.value
                                                    )
                                                }
                                                id="security-number"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="id-number"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Személyi ig. / Útlevél szám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="id-number"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                errorMessage="Adjon meg egy azonoító számot!"
                                                className="form-control"
                                                type="text"
                                                value={idNumber}
                                                onChange={(event) =>
                                                    setIdNumber(
                                                        event.target.value
                                                    )
                                                }
                                                id="id-number"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="email"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="email"
                                                validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                }}
                                                errorMessage="Adjon meg valós email címet!"
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(event) =>
                                                    setEmail(event.target.value)
                                                }
                                                id="email"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Ideiglenes jelszó
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="password"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adjon meg egy jelszót!"
                                                className="form-control"
                                                type="text"
                                                value={password}
                                                onChange={(event) =>
                                                    setPassword(
                                                        event.target.value
                                                    )
                                                }
                                                id="password"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "15px" }}
                                    >
                                        <label
                                            htmlFor="phone"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Telefonszám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="phone"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adjon meg egy telefonszámot!"
                                                className="form-control"
                                                type="text"
                                                value={phone}
                                                onChange={(event) =>
                                                    setPhone(event.target.value)
                                                }
                                                id="phone"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        <label className="col-sm-2 col-form-label">
                                            Előfizetés
                                        </label>
                                        <Col sm={10}>
                                            <select
                                                value={subscription}
                                                onChange={(event) =>
                                                    setSubscription(
                                                        event.target.value
                                                    )
                                                }
                                                className="form-control"
                                            >
                                                <option value="1">
                                                    Előfizetés nélkül
                                                </option>
                                                <option value="2">Basic</option>
                                                <option value="3">
                                                    Optimum
                                                </option>
                                                <option value="4">
                                                    Premium
                                                </option>
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        <label className="col-sm-2 col-form-label">
                                            Munkáltató
                                        </label>
                                        <Col sm={10}>
                                            <select
                                                value={employer}
                                                onChange={(event) =>
                                                    setEmployer(
                                                        event.target.value
                                                    )
                                                }
                                                className="form-control"
                                            >
                                                {employers && (
                                                    <>
                                                        {employers.map(
                                                            (employer) => (
                                                                <option
                                                                    key={
                                                                        employer.id
                                                                    }
                                                                    value={
                                                                        employer.id
                                                                    }
                                                                >
                                                                    {
                                                                        employer.name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="nextCharge"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kártya terhelés
                                        </label>
                                        <Col sm={10}>
                                            <DatePicker
                                                value={nextCharge}
                                                onChange={(date) =>
                                                    nextChargeHandler(date)
                                                }
                                                disableFuture={false}
                                                disablePast={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="paymentNextDate"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Időszak vége
                                        </label>
                                        <Col sm={10}>
                                            <DatePicker
                                                value={paymentNextDate}
                                                onChange={(date) =>
                                                    paymentNextDateHandler(date)
                                                }
                                                disableFuture={false}
                                                disablePast={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Hírlevél
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="newsletter">
                                                <AvCheckbox
                                                    checked={newsletter}
                                                    onChange={(event) =>
                                                        setNewsletter(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col sm={2}>
                                            <label>Számlázási adatok</label>
                                        </Col>
                                        <Col sm={10}>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="billing-name"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Név
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="billing-name"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a számlázási nevet!"
                                                        className="form-control"
                                                        type="text"
                                                        value={billingName}
                                                        onChange={(event) =>
                                                            setBillingName(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="billing-name"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="country"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Ország
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="country"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg az országot!"
                                                        className="form-control"
                                                        type="text"
                                                        value={country}
                                                        onChange={(event) =>
                                                            setCountry(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="country"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="zipcode"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Irányítószám
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="zipcode"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg az irányítószámot!"
                                                        className="form-control"
                                                        type="text"
                                                        value={zipcode}
                                                        onChange={(event) =>
                                                            setZipcode(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="zipcode"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="settlement"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Város
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="settlement"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a város!"
                                                        className="form-control"
                                                        type="text"
                                                        value={settlement}
                                                        onChange={(event) =>
                                                            setSettlement(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="settlement"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="street"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Utca
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="street"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a címet!"
                                                        className="form-control"
                                                        type="text"
                                                        value={street}
                                                        onChange={(event) =>
                                                            setStreet(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="street"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="houseNumber"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Házszám
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="houseNumber"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a házszámot!"
                                                        className="form-control"
                                                        type="text"
                                                        value={houseNumber}
                                                        onChange={(event) =>
                                                            setHouseNumber(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="houseNumber"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="floorDoor"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Emelet / Ajtó
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="floorDoor"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a házszámot!"
                                                        className="form-control"
                                                        type="text"
                                                        value={floorDoor}
                                                        onChange={(event) =>
                                                            setFloorDoor(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="floorDoor"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <label
                                                    htmlFor="taxNumber"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Adószám
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        className="form-control"
                                                        name="taxNumber"
                                                        type="text"
                                                        value={taxNumber}
                                                        onChange={(event) =>
                                                            setTaxNumber(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        id="taxNumber"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default NewUser;
