import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { endLoading, post } from "../../../config/fetch";
import { convertDate, responseMessage } from "../../../utility";
import {
    ALERT_MESSAGES,
    COUPON_TYPES,
    SERVICES,
} from "../../../config/variables";

const NewCoupon = () => {
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState({
        coupon_code: null,
        type: null,
        expires_at: null,
        discount_interval_days: null,
        discount_amount: null,
        discount_percentage: null,
        minimum_value: null,
        package_id: "0",
        service: "0",
        multi_use: "0",
        private_comment: null,
        public_comment: null,
    });

    const setCouponDataByKey = (key, value) =>
        setCoupon({ ...coupon, [key]: value });

    const dateChangeHandler = (date) =>
        setCoupon({ ...coupon, expires_at: convertDate(date) });

    const handleSubmit = () => {
        if (coupon.type === null) {
            responseMessage("A kupon típusának megadása kötelező!");

            return;
        }

        if (coupon.type == "1") {
            if (coupon.package_id == "0") {
                responseMessage("A csomag kiválasztása kötelező!");

                return;
            }
        }

        if (coupon.type == "4" || coupon.type == "5" || coupon.type == "6") {
            if (coupon.service == "0") {
                responseMessage("A szolgáltatás kiválasztása kötelező!");

                return;
            }
        }

        // console.log(coupon);

        post("api/v1/coupon/create-global-coupon", { data: coupon })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        navigate("/coupons");

                        break;
                    case "CODE_TAKEN":
                        endLoading();
                        responseMessage("A megadott kupon kód már létezik!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Új kupon</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="coupon-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="coupon_code"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Kupon kód
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="coupon_code"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg a kupon kódját!"
                                                className="form-control"
                                                type="text"
                                                value={coupon.coupon_code}
                                                onChange={(event) =>
                                                    setCouponDataByKey(
                                                        "coupon_code",
                                                        event.target.value
                                                    )
                                                }
                                                id="coupon_code"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="form-group"
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        <label className="col-sm-2 col-form-label">
                                            Típus
                                        </label>
                                        <Col sm={10}>
                                            <select
                                                value={coupon.type}
                                                onChange={(event) =>
                                                    setCouponDataByKey(
                                                        "type",
                                                        event.target.value
                                                    )
                                                }
                                                className="form-control"
                                            >
                                                <option value="0">
                                                    Válasszon...
                                                </option>
                                                {COUPON_TYPES.map(
                                                    ({ id, type }) => (
                                                        <option
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {type}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </Col>
                                    </Row>
                                    {(coupon.type == "1" ||
                                        coupon.type == "4") && (
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Kedvezmény (nap)
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="discount_interval_days"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg a napok számát!"
                                                    className="form-control"
                                                    type="number"
                                                    value={
                                                        coupon.discount_interval_days
                                                    }
                                                    onChange={(event) =>
                                                        setCouponDataByKey(
                                                            "discount_interval_days",
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {(coupon.type == "2" ||
                                        coupon.type == "5") && (
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Kedvezmény (Ft)
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="discount_amount"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg az összeget!"
                                                    className="form-control"
                                                    type="number"
                                                    value={
                                                        coupon.discount_percentage
                                                    }
                                                    onChange={(event) =>
                                                        setCouponDataByKey(
                                                            "discount_amount",
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {(coupon.type == "3" ||
                                        coupon.type == "6") && (
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">
                                                Kedvezmény (%)
                                            </label>
                                            <Col sm={10}>
                                                <AvField
                                                    name="discount_percentage"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                        },
                                                    }}
                                                    errorMessage="Adja meg a százalékot!"
                                                    className="form-control"
                                                    type="number"
                                                    value={
                                                        coupon.discount_percentage
                                                    }
                                                    onChange={(event) =>
                                                        setCouponDataByKey(
                                                            "discount_percentage",
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {coupon.type == "1" && (
                                        <Row
                                            className="form-group"
                                            style={{ marginBottom: "2rem" }}
                                        >
                                            <label className="col-sm-2 col-form-label">
                                                Csomag
                                            </label>
                                            <Col sm={10}>
                                                <select
                                                    value={coupon.package_id}
                                                    onChange={(event) =>
                                                        setCouponDataByKey(
                                                            "package_id",
                                                            event.target.value
                                                        )
                                                    }
                                                    className="form-control"
                                                >
                                                    <option value="0">
                                                        Válasszon...
                                                    </option>
                                                    <option value="2">
                                                        Basic
                                                    </option>
                                                    <option value="3">
                                                        Optimum
                                                    </option>
                                                    <option value="4">
                                                        Premium
                                                    </option>
                                                </select>
                                            </Col>
                                        </Row>
                                    )}
                                    {(coupon.type == "4" ||
                                        coupon.type == "5" ||
                                        coupon.type == "6") && (
                                        <Row
                                            className="form-group"
                                            style={{ marginBottom: "2rem" }}
                                        >
                                            <label className="col-sm-2 col-form-label">
                                                Szolgáltatás
                                            </label>
                                            <Col sm={10}>
                                                <select
                                                    value={coupon.service}
                                                    onChange={(event) =>
                                                        setCouponDataByKey(
                                                            "service",
                                                            event.target.value
                                                        )
                                                    }
                                                    className="form-control"
                                                >
                                                    <option value="0">
                                                        Válasszon...
                                                    </option>
                                                    {SERVICES.map(
                                                        ({ id, name }) => (
                                                            <option
                                                                value={id}
                                                                key={id}
                                                            >
                                                                {name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </Col>
                                        </Row>
                                    )}
                                    {coupon.type !== null &&
                                        coupon.type != "0" && (
                                            <Row className="form-group">
                                                <label className="col-sm-2 col-form-label">
                                                    Min. kosárérték (Ft)
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="minimum_value"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a százalékot!"
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            coupon.minimum_value
                                                        }
                                                        onChange={(event) =>
                                                            setCouponDataByKey(
                                                                "minimum_value",
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    {coupon.type !== null &&
                                        coupon.type != "0" && (
                                            <Row className="form-group">
                                                <label className="col-sm-2 col-form-label">
                                                    Lejárati dátum
                                                </label>
                                                <Col sm={10}>
                                                    <DatePicker
                                                        value={
                                                            coupon.expires_at
                                                        }
                                                        onChange={(date) =>
                                                            dateChangeHandler(
                                                                date
                                                            )
                                                        }
                                                        disablePast={true}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    {coupon.type !== null &&
                                        coupon.type != "0" && (
                                            <Row
                                                className="form-group"
                                                style={{ marginBottom: "2rem" }}
                                            >
                                                <label className="col-sm-2 col-form-label">
                                                    Többször felhasználható
                                                </label>
                                                <Col sm={10}>
                                                    <select
                                                        value={coupon.multi_use}
                                                        onChange={(event) =>
                                                            setCouponDataByKey(
                                                                "multi_use",
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        className="form-control"
                                                    >
                                                        <option value="1">
                                                            Igen
                                                        </option>
                                                        <option value="0">
                                                            Nem
                                                        </option>
                                                    </select>
                                                </Col>
                                            </Row>
                                        )}
                                    {coupon.type !== null &&
                                        coupon.type != "0" && (
                                            <Row className="form-group">
                                                <label className="col-sm-2 col-form-label">
                                                    Publikus komment
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="public_comment"
                                                        className="form-control"
                                                        type="textarea"
                                                        rows={3}
                                                        value={
                                                            coupon.public_comment
                                                        }
                                                        onChange={(event) =>
                                                            setCouponDataByKey(
                                                                "public_comment",
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    {coupon.type !== null &&
                                        coupon.type != "0" && (
                                            <Row className="form-group">
                                                <label className="col-sm-2 col-form-label">
                                                    Privát komment
                                                </label>
                                                <Col sm={10}>
                                                    <AvField
                                                        name="private_comment"
                                                        className="form-control"
                                                        type="textarea"
                                                        rows={3}
                                                        value={
                                                            coupon.private_comment
                                                        }
                                                        onChange={(event) =>
                                                            setCouponDataByKey(
                                                                "private_comment",
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default NewCoupon;
