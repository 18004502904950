import classNames from "classnames/bind";
import classes from "./ReservationButton.module.css";

const cx = classNames.bind(classes);

const ReservationButton = (props) => {
    const onButtonClick = () => {
        if (props.onClick) props.onClick();
    };

    return (
        <div
            className={cx("button", { hidden: props.hidden })}
            style={props.style}
            onClick={onButtonClick}
        >
            {props.text}
        </div>
    );
};

export default ReservationButton;
