import { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";
import { endLoading, post } from "../../config/fetch";
import { convertDate, formatNumber, responseMessage } from "../../utility";
import DatePicker from "../../components/DatePicker/DatePicker";
import classes from "./Statistics.module.css";

const colorScheme = [
    "#25CCF7",
    "#FD7272",
    "#54a0ff",
    "#00d2d3",
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#ecf0f1",
    "#95a5a6",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#bdc3c7",
    "#7f8c8d",
    "#55efc4",
    "#81ecec",
    "#74b9ff",
    "#a29bfe",
    "#dfe6e9",
    "#00b894",
    "#00cec9",
    "#0984e3",
    "#6c5ce7",
    "#ffeaa7",
    "#fab1a0",
    "#ff7675",
    "#fd79a8",
    "#fdcb6e",
    "#e17055",
    "#d63031",
    "#feca57",
    "#5f27cd",
    "#54a0ff",
    "#01a3a4",
];

const options = {
    scales: {
        yAxes: [
            {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
            },
        ],
    },
};

const serviceTableColumns = [
    {
        field: "doctor",
        headerName: "Orvos",
        flex: 1,
    },
    {
        field: "HOTLINE",
        headerName: "Hotline",
        valueFormatter: (params) => formatNumber(params.value),
        flex: 1,
    },
    {
        field: "DUTY",
        headerName: "Ügyelet hívása",
        valueFormatter: (params) => formatNumber(params.value),
        flex: 1,
    },
    {
        field: "CHAT",
        headerName: "Chat",
        valueFormatter: (params) => formatNumber(params.value),
        flex: 1,
    },
    {
        field: "SECOND_OPINION",
        headerName: "Másodvélemény",
        valueFormatter: (params) => formatNumber(params.value),
        flex: 1,
    },
    {
        field: "OUTPATIENT",
        headerName: "Járóbeteg",
        valueFormatter: (params) => formatNumber(params.value),
        flex: 1,
    },
    {
        field: "VIDEO_CONSULTATION",
        headerName: "Videó konzultáció",
        valueFormatter: (params) => formatNumber(params.value),
        flex: 1,
    },
];

const priceTableColumns = [
    {
        field: "doctor",
        headerName: "Orvos",
        flex: 1,
    },
    {
        field: "HOTLINE",
        headerName: "Hotline",
        valueFormatter: (params) => `${formatNumber(params.value)} Ft`,
        flex: 1,
    },
    {
        field: "DUTY",
        headerName: "Ügyelet hívása",
        valueFormatter: (params) => `${formatNumber(params.value)} Ft`,
        flex: 1,
    },
    {
        field: "CHAT",
        headerName: "Chat",
        valueFormatter: (params) => `${formatNumber(params.value)} Ft`,
        flex: 1,
    },
    {
        field: "SECOND_OPINION",
        headerName: "Másodvélemény",
        valueFormatter: (params) => `${formatNumber(params.value)} Ft`,
        flex: 1,
    },
    {
        field: "OUTPATIENT",
        headerName: "Járóbeteg",
        valueFormatter: (params) => `${formatNumber(params.value)} Ft`,
        flex: 1,
    },
    {
        field: "VIDEO_CONSULTATION",
        headerName: "Videó konzultáció",
        valueFormatter: (params) => `${formatNumber(params.value)} Ft`,
        flex: 1,
    },
];

const eventTableColumns = [
    {
        field: "created_at",
        headerName: "Időpont",
        flex: 1,
    },
    {
        field: "event_name",
        headerName: "Esemény",
        flex: 1,
    },
    {
        field: "user_name",
        headerName: "Felhasználó",
        flex: 1,
    },
    {
        field: "event_price",
        headerName: "Fizetett összeg",
        valueFormatter: (params) =>
            params.value !== "-" ? `${formatNumber(params.value)} Ft` : "-",
        flex: 1,
    },
    {
        field: "in_subscription",
        headerName: "Csomagban foglalt",
        flex: 1,
    },
];

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);

const Statistics = () => {
    const [stackChartSource, setStackChartSource] = useState(false);
    const [serviceTableRows, setServiceTableRows] = useState(null);
    const [priceTableRows, setPriceTableRows] = useState(null);
    const [commissionTableRows, setCommissionTableRows] = useState(null);
    const [commissions, setCommissions] = useState(null);
    const [datasFrom, setDatasFrom] = useState(new Date());
    const [datasTo, setDatasTo] = useState(new Date());
    const [events, setEvents] = useState(null);

    const findDoctorCommission = (doctorId, commissions, commissionType) => {
        for (let c of commissions) {
            if (c.doctor_id === doctorId) {
                return c[`${commissionType.toLowerCase()}_commission`];
            }
        }
    };

    const getCommissionPercentage = (commission) =>
        commission || commission === 0 ? commission : "-";
    const getCommissionAmount = (commission, amount) =>
        !isNaN(commission) ? amount * commission : "-";

    const commissionTableColumns = [
        {
            field: "doctor",
            headerName: "Orvos",
            flex: 1,
        },
        {
            field: "DUTY_COMMISSION",
            headerName: "Ügyelet",
            valueGetter: (params) =>
                getCommissionPercentage(
                    findDoctorCommission(
                        params.row.doctorId,
                        commissions,
                        "DUTY"
                    )
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft/db` : "-",
            flex: 1,
        },
        {
            field: "DUTY",
            headerName: "Ügyelet Ft",
            valueGetter: (params) =>
                getCommissionAmount(
                    getCommissionPercentage(
                        findDoctorCommission(
                            params.row.doctorId,
                            commissions,
                            "DUTY"
                        )
                    ),
                    params.value
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft` : "-",

            flex: 1,
        },
        {
            field: "CHAT_COMMISSION",
            headerName: "Chat",
            valueGetter: (params) =>
                getCommissionPercentage(
                    findDoctorCommission(
                        params.row.doctorId,
                        commissions,
                        "CHAT"
                    )
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft/db` : "-",
            flex: 1,
        },
        {
            field: "CHAT",
            headerName: "Chat Ft",
            valueGetter: (params) =>
                getCommissionAmount(
                    getCommissionPercentage(
                        findDoctorCommission(
                            params.row.doctorId,
                            commissions,
                            "CHAT"
                        )
                    ),
                    params.value
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft` : "-",
            flex: 1,
        },
        {
            field: "SECOND_OPINION_COMMISSION",
            headerName: "Másodvélemény ",
            valueGetter: (params) =>
                getCommissionPercentage(
                    findDoctorCommission(
                        params.row.doctorId,
                        commissions,
                        "SECOND_OPINION"
                    )
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft/db` : "-",
            flex: 1,
        },
        {
            field: "SECOND_OPINION",
            headerName: "Másodvélemény Ft",
            valueGetter: (params) =>
                getCommissionAmount(
                    getCommissionPercentage(
                        findDoctorCommission(
                            params.row.doctorId,
                            commissions,
                            "SECOND_OPINION"
                        )
                    ),
                    params.value
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft` : "-",
            flex: 1,
        },
        {
            field: "VIDEO_CONSULTATION_COMMISSION",
            headerName: "Videó konzultáció Ft/db",
            valueGetter: (params) =>
                getCommissionPercentage(
                    findDoctorCommission(
                        params.row.doctorId,
                        commissions,
                        "VIDEO_CONSULTATION"
                    )
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft/db` : "-",
            flex: 1,
        },
        {
            field: "VIDEO_CONSULTATION",
            headerName: "Másodvélemény Ft",
            valueGetter: (params) =>
                getCommissionAmount(
                    getCommissionPercentage(
                        findDoctorCommission(
                            params.row.doctorId,
                            commissions,
                            "VIDEO_CONSULTATION"
                        )
                    ),
                    params.value
                ),
            valueFormatter: (params) =>
                params.value !== "-" ? `${params.value} Ft` : "-",
            flex: 1,
        },
    ];

    const onButtonClick = () => {
        if (!datasTo) {
            responseMessage(
                "Válassza ki, hogy meddig szeretné megjeleníteni az adatokat!"
            );

            return false;
        }
        if (!datasFrom) {
            responseMessage(
                "Válassza ki, hogy mettől szeretné megjeleníteni az adatokat!"
            );

            return false;
        }
        getDatas();
    };

    const getDatas = () =>
        post("api/v1/statistics/get-events", {
            from: convertDate(datasFrom),
            to: convertDate(datasTo),
        })
            .then((response) => {
                endLoading();
                if (response.data.data?.length) {
                    setEvents(response.data.data);
                    setCommissions(response.data.commissions);
                    const emptyObj = {
                        HOTLINE: 0,
                        DUTY: 0,
                        CHAT: 0,
                        SECOND_OPINION: 0,
                        OUTPATIENT: 0,
                        VIDEO_CONSULTATION: 0,
                    };
                    //Orvosonként, hogy kinek hány szolgáltatása volt
                    const tableData = {
                        "Név nélkül": { ...emptyObj },
                    };

                    //Orvosonként, hogy mennyi volt a szolgáltatásokból származó bevétel
                    const priceDatas = {
                        "Név nélkül": {
                            ...emptyObj,
                            doctorId: -1,
                        },
                        Összesen: {
                            ...emptyObj,
                            doctorId: -1,
                        },
                    };

                    // const commissions = {
                    //     'Név nélkül': { ...emptyObj }
                    // }

                    for (let d of response.data.data) {
                        let price = +d.event_price || 0;
                        let doctorName = d.doctor_name || "Név nélkül";
                        if (doctorName in tableData) {
                            tableData[doctorName][d.event_name] =
                                tableData[doctorName][d.event_name] + 1;
                            priceDatas[doctorName][d.event_name] =
                                priceDatas[doctorName][d.event_name] + price;
                        } else {
                            tableData[doctorName] = {
                                doctorId: d.doctor_id,
                                ...emptyObj,
                                [d.event_name]: 1,
                            };
                            priceDatas[doctorName] = {
                                doctorId: d.doctor_id,
                                ...emptyObj,
                                [d.event_name]: price,
                            };
                            // commissions[doctorName] = {
                            //     doctorId: d.doctor_id,
                            //     ...emptyObj,
                            //     [d.event_name]: d[`${d.event_name.toLowerCase()}_commission`]
                            // }
                        }
                        priceDatas["Összesen"][d.event_name] =
                            priceDatas["Összesen"][d.event_name] + price;
                    }

                    const sourceData = [];
                    const serviceRowData = [];
                    let i = 0;
                    const services = [
                        "HOTLINE",
                        "DUTY",
                        "CHAT",
                        "SECOND_OPINION",
                        "OUTPATIENT",
                        "VIDEO_CONSULTATION",
                    ];
                    //Átkonvertálás tömbbe, mert a táblázatba és chartba tömbbe kell adat, nem objektumban
                    for (let key in tableData) {
                        sourceData.push({
                            label: key,
                            data: services.map(
                                (element) => tableData[key][element]
                            ),
                            backgroundColor: colorScheme[i++],
                        });
                        serviceRowData.push({
                            id: i,
                            doctor: key,
                            ...tableData[key],
                        });
                    }

                    // Orvosonként szolgáltatás darab
                    setServiceTableRows(serviceRowData);

                    //Chart fourrása
                    setStackChartSource({
                        labels: [
                            "Hotline",
                            "Ügyelet",
                            "Chat",
                            "Másodvélemény",
                            "Járóbeteg",
                            "Videó konzultáció",
                        ],
                        datasets: sourceData,
                    });

                    const priceRowDatas = [];
                    const commissionRowDatas = [];

                    //Átkonvertálás tömbbe, mert a táblázatba és chartba tömbbe kell adat, nem objektumban
                    i = 0;
                    for (let key in priceDatas) {
                        priceRowDatas.push({
                            id: i++,
                            doctor: key,
                            ...priceDatas[key],
                        });
                        if (priceDatas[key].doctorId !== -1) {
                            commissionRowDatas.push({
                                id: i++,
                                doctor: key,
                                ...priceDatas[key],
                            });
                        }
                    }
                    setPriceTableRows(priceRowDatas);
                    setCommissionTableRows(commissionRowDatas);
                }
            })
            .catch((error) => {
                endLoading();
                console.error(error);
            });

    return (
        <>
            <div className={`${classes.statElement} ${classes.queryInputs}`}>
                <div>
                    Lekérdezés kezdete:{" "}
                    <DatePicker
                        disableFuture
                        value={datasFrom}
                        onChange={(date) => setDatasFrom(date)}
                    />
                </div>
                <div>
                    Lekérdezés vége:{" "}
                    <DatePicker
                        disableFuture
                        value={datasTo}
                        onChange={(date) => setDatasTo(date)}
                    />
                </div>
                <button className="btn btn-primary" onClick={onButtonClick}>
                    Lekérés
                </button>
            </div>
            <div className={classes.statElement}>
                <p className={classes.header}>Esemény tábla</p>
                <div style={{ marginTop: "50px", marginBottom: "40px" }}>
                    {events && (
                        <DataGrid
                            autoHeight
                            pageSize={20}
                            rows={events}
                            columns={eventTableColumns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={classes.statElement}>
                <p className={classes.header}>
                    Szolgáltatás használat orvosonként
                </p>
                <div style={{ marginTop: "50px", marginBottom: "40px" }}>
                    {serviceTableRows && (
                        <DataGrid
                            autoHeight
                            rows={serviceTableRows}
                            columns={serviceTableColumns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    )}
                </div>
                {stackChartSource && (
                    <Bar data={stackChartSource} options={options} />
                )}
            </div>
            <div className={classes.statElement}>
                <p className={classes.header}>Szolgáltatás bevétel</p>
                <div style={{ marginTop: "30px", marginBottom: "40px" }}>
                    {priceTableRows && (
                        <DataGrid
                            autoHeight
                            aria-label="Szolgáltatás bevétel"
                            rows={priceTableRows}
                            columns={priceTableColumns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={classes.statElement}>
                <p className={classes.header}>Jutalékok</p>
                <div style={{ marginTop: "30px", marginBottom: "40px" }}>
                    {commissionTableRows && (
                        <DataGrid
                            autoHeight
                            rows={serviceTableRows}
                            columns={commissionTableColumns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            title="export"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Statistics;
