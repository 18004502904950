import { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { getDate } from "../../config/helpers";
import { getSocket } from "../../config/socket";
import * as actions from "../../store/ui/actions";
import CallCard from "./CallCard";

const Calls = (props) => {
    const [calls, setCalls] = useState([]);
    const [callType, setCallType] = useState("");
    const location = useLocation();

    const type = location.pathname.split("/")[1];
    // const [available, setAvailable] = useState(null);
    const callsRef = useRef();

    // t-doc chat, second opinion
    const [qualifications, setQualifications] = useState("");
    const [symptoms, setSymptoms] = useState("");
    const [doctors, setDoctors] = useState("");

    // status Codes
    const [statusCodes, setStatusCodes] = useState(null);

    const filterCalls = (calls) => {
        const date = new Date();
        const statusOneCalls = calls.filter((call) => call.status == "1");
        const filteredCalls = [];

        for (let call of statusOneCalls) {
            if (date.getTime() - new Date(call.created_at).getTime() < 300000) {
                filteredCalls.push(call);
            }
        }

        const modifiedCalls = calls.filter((call) => call.status != "1");
        setCalls([...modifiedCalls, ...filteredCalls]);
    };

    useEffect(() => {
        const interval = setInterval(
            () => filterCalls(callsRef.current),
            300000
        );

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let event = "";
        if (type === "hotline-calls") {
            event = "hotlineStatusChange";
            setCallType("hotline");
        } else if (type === "duty-calls") {
            event = "dutyStatusChange";
            setCallType("duty");
        }

        const socket = getSocket();
        socket.on(event, (msg) => {
            const id = msg.id;
            if (msg.status == "1") {
                setCalls((prevState) => [msg, ...prevState]);
            } else {
                const call = callsRef.current.filter(
                    (call) => call.id == id
                )[0];
                call.status = msg.status;
                call.otherName = msg.other_name || "";
                const filteredCalls = callsRef.current.filter(
                    (call) => call.id != id
                );
                const updatedCalls = [call, ...filteredCalls];
                setCalls(updatedCalls);
            }
        });

        get(`admin/calls?type=${type}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setQualifications(response.data.qualifications);
                        setSymptoms(response.data.symptoms);
                        setDoctors(response.data.doctors);
                        // setCalls(response.data.calls);
                        filterCalls(response.data.calls);
                        // setAvailable(response.data.adminAvailability);
                        setStatusCodes(response.data.statusCodes);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

        return () => {
            socket.off("hotlineStatusChange");
            socket.off("dutyStatusChange");
        };
    }, [type]);

    const createComment = (user_id, call_id, newComment) =>
        post("admin/create-comment", { user_id, comment: newComment })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        endLoading();

                        const createdComment = {
                            id: response.data.newCommentId,
                            user_id,
                            name: response.data.adminName,
                            created_at: getDate(),
                            comment: newComment,
                        };

                        const actualCall = calls.filter(
                            (call) => call.id == call_id
                        );
                        const modifiedComments = [
                            createdComment,
                            ...actualCall[0].comments,
                        ];
                        const modifiedCall = {
                            ...actualCall[0],
                            comments: modifiedComments,
                        };
                        const modifiedCalls = [...calls];
                        const index = calls.findIndex((el) => el.id == call_id);
                        modifiedCalls[index] = modifiedCall;
                        setCalls(modifiedCalls);

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    // const onChangeAvailability = (type, value, id) => {
    //     const modifiedValue = value == true ? "1" : "0";
    //     post("admin/change-admin-availability", {
    //         type,
    //         value: modifiedValue,
    //         id,
    //     })
    //         .then((response) => {
    //             switch (response.data.responseCode) {
    //                 case "OK":
    //                     endLoading();
    //
    //                     break;
    //                 default:
    //                     endLoading();
    //                     responseMessage(ALERT_MESSAGES.errorTryAgain);
    //
    //                     break;
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             endLoading();
    //             responseMessage(ALERT_MESSAGES.errorTryAgain);
    //         });
    // };

    const onSendServiceData = (data) =>
        post("admin/recommend-service", {
            service: data.service,
            userId: data.userId,
            qualification: data.qualification,
            qualificationId: data.qualificationId,
            specialization: data.specialization,
            specializationId: data.specializationId,
            symptom: data.symptom,
            symptomId: data.symptomId,
            doctor: data.doctor,
            doctorId: data.doctorId,
        })
            .then(() => {
                responseMessage("Ajánlott szolgáltatás elküldve!");
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleCallBack = (patientPhone, callId) =>
        post("admin/call-back", { patientPhone, callId })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        props.toggleResponseModal(true, {
                            title: "Visszahíváshoz kérjük tárcsázza a következő számot:",
                            content: "+36 1 533 3150",
                            confirmButton: false,
                            confirmButtonText: "Igen",
                            confirmButtonCallback: null,
                            cancelButton: true,
                            cancelButtonText: "Vissza",
                            cancelButtonCallback: null,
                            disableBackdropClick: false,
                        });

                        break;
                    case "NO_PHONE":
                        endLoading();
                        responseMessage(
                            "Jelenleg belépett felhasználónak nincs telefonszáma, így a hívás nem tud megtörténni!"
                        );

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <div className="container-fluid">
            <Row className="align-items-center">
                <Col sm={6}>
                    <div className="page-title-box">
                        <h4 className="font-size-18">
                            {callType === "hotline"
                                ? "Hotline hívások"
                                : "Ügyeleti hívások"}
                        </h4>
                    </div>
                </Col>
            </Row>
            {/*{available == "0" || (available == "1" && callType === "duty") ? (*/}
            {/*    <>*/}
            {/*        <Row>*/}
            {/*            <Col sm={1}>*/}
            {/*                <p className={classes.text}>*/}
            {/*                    <strong>*/}
            {/*                        {available ? "Elérhető" : "Nem elérhető"}*/}
            {/*                    </strong>*/}
            {/*                </p>*/}
            {/*            </Col>*/}
            {/*            <Col>*/}
            {/*                <AvailabilitySlider*/}
            {/*                    available={available == "1"}*/}
            {/*                    id={"admin"}*/}
            {/*                    type={callType}*/}
            {/*                    onChangeAvailability={onChangeAvailability}*/}
            {/*                />*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <br></br>*/}
            {/*    </>*/}
            {/*) : null}*/}
            <div className="row">
                <div className="col-12">
                    <h5>Folyamatban lévő hívások</h5>
                    <br></br>
                    <ul className="list-group">
                        {calls.map((call, index) => {
                            if (
                                call.status == "1000" ||
                                call.status == "3000" ||
                                (type == "hotline-calls" &&
                                    call.status == "3003")
                            ) {
                                return (
                                    <CallCard
                                        key={index}
                                        call={call}
                                        index={index}
                                        qualifications={qualifications}
                                        statusCodes={statusCodes}
                                        symptoms={symptoms}
                                        doctors={doctors}
                                        onSendServiceData={onSendServiceData}
                                        createComment={createComment}
                                    />
                                );
                            }
                        })}
                    </ul>
                    <h5>Kezdeményezett hívások</h5>
                    <br></br>
                    <ul className="list-group">
                        {calls.map((call, index) => {
                            if (call.status == "1") {
                                return (
                                    <CallCard
                                        key={index}
                                        call={call}
                                        index={index}
                                        qualifications={qualifications}
                                        symptoms={symptoms}
                                        doctors={doctors}
                                        onSendServiceData={onSendServiceData}
                                        createComment={createComment}
                                    />
                                );
                            }
                        })}
                    </ul>
                    <h5>Korábbi hívások</h5>
                    <br></br>
                    <ul className="list-group">
                        {calls.map((call, index) => {
                            if (
                                call.status == "3" ||
                                call.status == "3001" ||
                                call.status == "1001" ||
                                call.status == "1002" ||
                                call.status == "1003" ||
                                (call.status == "3003" &&
                                    type === "duty-calls") ||
                                call.status == "1004"
                            ) {
                                return (
                                    <CallCard
                                        key={index}
                                        call={call}
                                        index={index}
                                        qualifications={qualifications}
                                        statusCodes={statusCodes}
                                        symptoms={symptoms}
                                        doctors={doctors}
                                        handleCallBack={handleCallBack}
                                        onSendServiceData={onSendServiceData}
                                        createComment={createComment}
                                    />
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(Calls);

// export default Calls;
