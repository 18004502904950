import { ApiFormWrapper } from "tdoc-common";
import { Form } from "reactstrap";
import { NextHealthService } from "./model";
import { useCreateSubscriptionMutation } from "./api";
import CaseNhaSubscriptionFormInner from "./CaseNhaSubscriptionFormInner";

interface Props {
    userId: number;
    onSuccess: () => void;
}

const CaseNhaSubscriptionCreateForm = ({ userId, onSuccess }: Props) => {
    const [create] = useCreateSubscriptionMutation();

    return (
        <ApiFormWrapper
            defaultValues={{ userId, service: NextHealthService.PrePostOp }}
            mutation={create}
            onSuccess={onSuccess}
        >
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <CaseNhaSubscriptionFormInner />
                </Form>
            )}
        </ApiFormWrapper>
    );
};

export default CaseNhaSubscriptionCreateForm;
