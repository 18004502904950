import classes from "./InfoComponent.module.css";

const InfoComponent = (props) => (
    <>
        <div className={classes.descriptionContainer}>
            <p>{props.title}</p>
        </div>
        {!props.editMode ? (
            <p className={classes.text}>{props.info}</p>
        ) : (
            <>
                {props.notEditable ? (
                    <p className={classes.text}>{props.info}</p>
                ) : (
                    <div style={{ marginBottom: "15px" }}>
                        {props.editComponent}
                    </div>
                )}
            </>
        )}
    </>
);

export default InfoComponent;
