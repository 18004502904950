import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { MetricType } from "@madmedical/sdk";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import * as actions from "../../store/ui/actions";
import PatientCard from "../../components/CaseManager/PatientCard/PatientCard";
import SurgeryCard from "../../components/CaseManager/SurgeryCard/SurgeryCard";
import AppointmentsCard from "../../components/CaseManager/AppointmentsCard/AppointmentsCard";
import PricesCard from "../../components/CaseManager/PricesCard/PricesCard";
import CommentsCard from "../../components/CaseManager/CommentsCard/CommentsCard";
import ExaminationsCard from "../../components/CaseManager/ExaminationsCard/ExaminationsCard";
import RecommendService from "../../components/RecommendService/RecommendService";
import CaseNhaSubscriptions from "../../features/nexthealth/subscription/CaseNhaSubscriptions";
import PatientBrief from "../../components/CaseManager/PatientBrief/PatientBrief";
import ChecklistCard from "../../components/CaseManager/ChecklistCard/ChecklistCard";
import PreOpAlert from "../../features/nexthealth/PreOpAlert";
import NextHealthWidget from "../../features/nexthealth/NextHealthWidget";
import classes from "./CaseDetails.module.css";

const CaseDetails = (props) => {
    const { id } = useParams();
    // const location = useLocation();
    const navigate = useNavigate();

    const [patientCase, setPatientCase] = useState(null);
    const [comments, setComments] = useState(null);
    const [recommend, toggleRecommend] = useState(false);

    const [patient, setPatient] = useState(null);

    const [casePatientData, setCasePatientData] = useState(null);
    const [caseSurgeryData, setCaseSurgeryData] = useState(null);
    const [caseDateData, setCaseDateData] = useState(null);
    const [casePriceData, setCasePriceData] = useState(null);

    const [status, setStatus] = useState(null);
    const [surgeryStatus, setSurgeryStatus] = useState(null);
    const [surgeryTypes, setSurgeryTypes] = useState(null);
    const [insurants, setInsurants] = useState(null);
    const [examData, setExamData] = useState(null);
    const [checklist, setChecklist] = useState(null);

    const [data, setData] = useState({
        patients: null,
        doctors: null,
        qualifications: null,
        institutions: null,
    });

    const [refresh, setRefresh] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        get(`admin/get-case?id=${id}`, firstLoad)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        const pCase = response.data.case;
                        setPatientCase(pCase);

                        if (pCase.checklist) {
                            setChecklist(pCase.checklist.split(","));
                        } else {
                            setChecklist([]);
                        }

                        setComments(response.data.comments);
                        setSurgeryTypes(response.data.surgeryTypes);
                        setInsurants(response.data.insurants);
                        setExamData({
                            exams: response.data.exams,
                            classifications: response.data.classifications,
                            caseExams: response.data.caseExams,
                            defaultExams: response.data.defaultExams,
                        });

                        setPatient(
                            response.data.case.user_id
                                ? {
                                      value: response.data.case.user_id,
                                      label: `${response.data.case.userLastName} ${response.data.case.userFirstName}`,
                                  }
                                : null
                        );

                        setCasePatientData({
                            name: pCase.name,
                            lastName: pCase.last_name,
                            firstName: pCase.first_name,
                            gender: pCase.gender ? pCase.gender : "Férfi",
                            securityNumber: pCase.security_number,
                            idNumber: pCase.id_number,
                            birthDate: pCase.birthDate,
                            email: pCase.email,
                            phone: pCase.phone,
                            vip: pCase.vip,
                            takeEarlier: pCase.take_earlier,
                        });

                        setCaseSurgeryData({
                            doctors: response.data.caseDoctors?.map((d) => ({
                                value: d.id,
                                label: `${d.title} ${d.last_name} ${d.first_name}`,
                            })),
                            caseManagers: response.data.caseCaseManagers?.map(
                                (cm) => ({
                                    value: cm.id,
                                    label: `${cm.last_name} ${cm.first_name}`,
                                })
                            ),
                            qualification: response.data.case.qualification_id
                                ? {
                                      value: response.data.case
                                          .qualification_id,
                                      label: response.data.case
                                          .qualificationName,
                                  }
                                : null,
                            institution: response.data.case.institution_id
                                ? {
                                      value: response.data.case.institution_id,
                                      label: `${response.data.case.institutionName}`,
                                  }
                                : null,
                            surgeryType: pCase.surgery_type_id
                                ? {
                                      value: pCase.surgery_type_id,
                                      label: pCase.surgery_type,
                                  }
                                : pCase.surgery_type,
                            tools: pCase.tools,
                            description: pCase.description,
                        });

                        setCaseDateData({
                            anest: pCase.anestDate,
                            surgery: pCase.surgeryDate,
                            ping: pCase.pingDate,
                            otherPingDate: pCase.other_ping_date,
                            otherPingDescription: pCase.other_ping_description,
                        });

                        setCasePriceData({
                            insurant: pCase.insurant,
                            insurantName: pCase.insurant_name,
                            insurantFinance: pCase.insurant_finance,
                            surgeryPrice: pCase.surgery_price,
                            discount: pCase.discount,
                            extraPrice: response.data.extraPrice,
                        });

                        setStatus(
                            response.data.case.status &&
                                response.data.case.status != "0"
                                ? {
                                      value: response.data.case.status,
                                      label: response.data.case.statusName,
                                  }
                                : null
                        );

                        setSurgeryStatus(
                            response.data.case.surgery_status &&
                                response.data.case.surgery_status != "0"
                                ? {
                                      value: response.data.case.surgery_status,
                                      label: response.data.case
                                          .surgeryStatusName,
                                  }
                                : null
                        );

                        setData({
                            // patients: response.data.patients,
                            doctors: response.data.doctors,
                            qualifications: response.data.qualifications,
                            institutions: response.data.institutions,
                            statuses: response.data.statuses,
                            surgeryStatuses: response.data.surgeryStatuses,
                            caseManagers: response.data.caseManagers,
                        });

                        endLoading();
                        setFirstLoad(false);

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

    const modifyCase = (status) =>
        post("admin/modify-case", {
            caseId: id,
            userId: patientCase.user_id,
            status,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        if (status == "1") {
                            responseMessage("Sikeres lezárás!");
                        } else {
                            responseMessage("Sikeres megnyitás!");
                        }

                        setPatientCase({ ...patientCase, closed: status });

                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleStatus = (value, type) => {
        let caseStatus = null;
        let sStatus = null;

        if (type === "status") {
            caseStatus = value;
            sStatus = surgeryStatus ? surgeryStatus.value : null;
        }
        if (type === "surgeryStatus") {
            caseStatus = status ? status.value : null;
            sStatus = value;
        }

        post("admin/modify-case-status", {
            caseId: id,
            status: caseStatus,
            surgeryStatus: sStatus,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres módosítás!");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const deleteCase = () =>
        post("admin/delete-case", { caseId: id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/case-manager");
                        responseMessage("Sikeres törlés!");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törli az esetet? A művelet nem visszavonható, minden adat és komment törlésre kerül!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteCase,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleCommentDelete = (commentId, caseId, adminId) =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content: "Biztosan törli a kommentet? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: () =>
                deleteComment(commentId, caseId, adminId),
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const deleteComment = (commentId, caseId, adminId) =>
        post("admin/delete-case-comment", { commentId, caseId, adminId })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres törlés!");
                        refreshPage();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const refreshPage = () => {
        // let currentPage = location.pathname;
        setTimeout(() => {
            //navigate({ pathname: "/empty" });
            //navigate({ pathname: currentPage }, { replace: true });
            setRefresh(!refresh);
        }, 1000);
    };

    return (
        <Container fluid>
            {patientCase?.user_id ? (
                <PatientBrief userId={patientCase.user_id} />
            ) : (
                <></>
            )}

            <Card style={{ borderRadius: "10px", marginTop: "24px" }}>
                {patientCase && (
                    <CardBody>
                        <div className={classes.cardInner}>
                            <Row>
                                <Col xl={4} lg={12}>
                                    <h1 className={classes.title}>
                                        Eset részletei
                                    </h1>
                                    <div className={classes.idContainer}>
                                        <span>ID:</span>
                                        <span className={classes.idText}>
                                            {patientCase.id}
                                        </span>
                                    </div>
                                </Col>
                                {data.statuses && data.surgeryStatuses && (
                                    <Col
                                        xl={4}
                                        lg={6}
                                        className="mt-xl-0 mt-lg-2"
                                    >
                                        <p className={classes.label}>
                                            Eset Státusz
                                        </p>
                                        <CustomSelect
                                            value={status}
                                            isMulti={false}
                                            placeholder="Válasszon eset státuszt..."
                                            options={data.statuses.map((s) => ({
                                                value: s.id,
                                                label: s.name,
                                            }))}
                                            onChange={(e) => {
                                                setStatus(e);
                                                handleStatus(e.value, "status");
                                            }}
                                        />
                                    </Col>
                                )}
                                {data.statuses && data.surgeryStatuses && (
                                    <Col
                                        xl={4}
                                        lg={6}
                                        className="mt-xl-0 mt-lg-2"
                                    >
                                        <p className={classes.label}>
                                            Műtét Státusz
                                        </p>
                                        <CustomSelect
                                            value={surgeryStatus}
                                            isMulti={false}
                                            placeholder="Válasszon műtét státuszt..."
                                            options={data.surgeryStatuses.map(
                                                (s) => ({
                                                    value: s.id,
                                                    label: s.name,
                                                })
                                            )}
                                            onChange={(e) => {
                                                setSurgeryStatus(e);
                                                handleStatus(
                                                    e.value,
                                                    "surgeryStatus"
                                                );
                                            }}
                                        />
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    <div className={classes.btnWrapper}>
                                        {patientCase?.user_id && (
                                            <Button
                                                style={{ marginRight: "8px" }}
                                                color="primary"
                                                onClick={() =>
                                                    toggleRecommend(
                                                        (prev) => !prev
                                                    )
                                                }
                                            >
                                                Szolgáltatás ajánlás
                                            </Button>
                                        )}
                                        {recommend && (
                                            <RecommendService
                                                patientId={patientCase.user_id}
                                                onCancelRecommend={() =>
                                                    toggleRecommend(
                                                        (prev) => !prev
                                                    )
                                                }
                                            />
                                        )}
                                        {patientCase.closed == "0" ? (
                                            <Button
                                                style={{ marginRight: "8px" }}
                                                color="danger"
                                                onClick={() => modifyCase("1")}
                                            >
                                                Eset lezárása
                                            </Button>
                                        ) : (
                                            <Button
                                                style={{ marginRight: "8px" }}
                                                color="primary"
                                                onClick={() => modifyCase("0")}
                                            >
                                                Eset megnyitása
                                            </Button>
                                        )}
                                        <Button
                                            color="danger"
                                            onClick={handleDelete}
                                        >
                                            Eset törlése
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                )}
            </Card>
            {patientCase && caseSurgeryData && (
                <PreOpAlert
                    userId={patientCase?.user_id}
                    caseDoctorIds={caseSurgeryData.doctors.map(
                        (d) => "d" + d.value
                    )}
                />
            )}
            <Row>
                <Col xl={3} lg={4} md={6}>
                    {casePatientData && patientCase && (
                        <PatientCard
                            patientCase={patientCase}
                            data={data}
                            patient={patient}
                            casePatientData={casePatientData}
                            refreshPage={() => refreshPage()}
                            onCreateProfile={(url) => navigate(url)}
                        />
                    )}
                    {data.doctors &&
                        data.institutions &&
                        data.qualifications &&
                        caseSurgeryData &&
                        patientCase &&
                        surgeryTypes && (
                            <SurgeryCard
                                data={data}
                                caseSurgeryData={caseSurgeryData}
                                patientCase={patientCase}
                                surgeryTypes={surgeryTypes}
                                refreshPage={() => refreshPage()}
                            />
                        )}
                </Col>
                <Col xl={6} lg={4} md={6}>
                    <Row>
                        <Col xl={6} lg={12}>
                            {patientCase && caseDateData && (
                                <AppointmentsCard
                                    patientCase={patientCase}
                                    caseDateData={caseDateData}
                                    refreshPage={() => refreshPage()}
                                />
                            )}
                            {casePriceData && insurants && (
                                <PricesCard
                                    casePriceData={casePriceData}
                                    caseId={id}
                                    refreshPage={() => refreshPage()}
                                    insurants={insurants}
                                />
                            )}
                        </Col>
                        <Col xl={6} lg={12}>
                            {patientCase && examData && (
                                <ExaminationsCard
                                    patientCase={patientCase}
                                    examData={examData}
                                    refreshPage={() => refreshPage()}
                                />
                            )}
                            {patientCase && checklist && (
                                <ChecklistCard
                                    caseId={id}
                                    checklist={checklist}
                                    setChecklist={setChecklist}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col xl={3} lg={4} md={6}>
                    {patientCase && comments && (
                        <CommentsCard
                            userId={patientCase.user_id}
                            caseId={id}
                            refreshPage={() => refreshPage()}
                            comments={comments}
                            onDeleteComment={(commentId) =>
                                handleCommentDelete(
                                    commentId,
                                    id,
                                    props.adminId
                                )
                            }
                        />
                    )}
                    {patientCase && caseSurgeryData && (
                        <Card>
                            <CardBody>
                                <NextHealthWidget
                                    userId={patientCase.user_id}
                                    caseDoctorIds={caseSurgeryData.doctors.map(
                                        (d) => "d" + d.value
                                    )}
                                    metricType={MetricType.BloodPressure}
                                />
                                <NextHealthWidget
                                    userId={patientCase.user_id}
                                    caseDoctorIds={caseSurgeryData.doctors.map(
                                        (d) => "d" + d.value
                                    )}
                                    metricType={MetricType.BloodSugar}
                                />
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>

            {patientCase?.user_id && (
                <CaseNhaSubscriptions userId={patientCase.user_id} />
            )}
        </Container>
    );
};

const mapStateToProps = (state) => ({
    adminId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
