import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CustomCheckbox from "../../CustomCheckbox/CustomCheckbox";
import classes from "./Comment.module.css";

const Comment = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [comment, setComment] = useState();

    useEffect(() => {
        if (editMode && props.isEdited && props.comment !== comment) {
            setEditMode(false);
        } else if (!editMode) {
            setComment(props.comment);
        }
    }, [editMode, props.isEdited, props.comment, comment]);

    const date = new Date(`${props.date}Z`);

    const dateString = `${date.toLocaleDateString(
        "hu-HU",
        {}
    )} ${date.toLocaleTimeString("hu-HU", {})}`;

    return (
        <div className={classes.main}>
            <div className={classes.title}>
                <CustomCheckbox
                    default={props.status}
                    onChange={(checked) => props.onChangeStatus(checked)}
                />
                <div className={classes.textWrapper}>
                    <span className={classes.name}>{props.name}</span>
                    <span className={classes.date}>{dateString}</span>
                </div>
                {!editMode && (
                    <div style={{ display: "flex" }}>
                        <div
                            className={classes.icon}
                            onClick={() => setEditMode(true)}
                        >
                            <EditIcon />
                        </div>
                        <div
                            className={classes.icon}
                            onClick={() => props.onDeleteComment()}
                            style={{ marginLeft: "10px" }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                )}
            </div>
            <div>
                {!editMode ? (
                    <p className={classes.comment}>{props.comment}</p>
                ) : (
                    <Input
                        type="textarea"
                        rows={3}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                )}
            </div>
            {editMode && (
                <div className={classes.btnWrapper}>
                    <Button
                        color="primary"
                        onClick={() => props.onEdit(comment)}
                    >
                        Változtatások mentése
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => setEditMode(false)}
                    >
                        Mégse
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Comment;
