import { useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// eslint-disable-next-line import/no-named-as-default
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import store from "../../store/index";
import { NextHealthMenu } from "../../features/nexthealth";

const SidebarContent = () => {
    const access = store.getState().auth.access;

    return (
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                {/* Customs */}

                {access.superAdmin == "1" ||
                access.callCenter == "1" ||
                access.doctor == "1" ? (
                    <li>
                        <Link to="/users" className="waves-effect">
                            <i className="fas fa-user-friends"></i>
                            <span>Felhasználók</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" ? (
                    <li>
                        <Link to="/admins" className="waves-effect">
                            <i className="fas fa-users-cog"></i>
                            <span>Admin felhasználók</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/doctors" className="waves-effect">
                            <i className="fas fa-user-md"></i>
                            <span>Orvosok</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/institutions" className="waves-effect">
                            <i className="fas fa-hospital"></i>
                            <span>Partner Intézmények</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/qualifications" className="waves-effect">
                            <i className="fas fa-diagnoses"></i>
                            <span>Szakterületek</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/employers" className="waves-effect">
                            <i className="fas fa-briefcase"></i>
                            <span>Munkáltatók</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/packages" className="waves-effect">
                            <i className="fas fa-cubes"></i>
                            <span>Csomagok</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/coupons" className="waves-effect">
                            <i className="fas fa-gift"></i>
                            <span>Kuponok</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" ? (
                    <li>
                        <Link to="/documents" className="waves-effect">
                            <i className="fas fa-file-alt"></i>
                            <span>Dokumentumok</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.doctor == "1" ? (
                    <li>
                        <Link to="/chat" className="waves-effect">
                            <i className="fas fa-comment-medical"></i>
                            <span>Chat</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/hotline-calls" className="waves-effect">
                            <i className="fas fa-headset"></i>
                            <span>Hotline hívások</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.doctor == "1" ? (
                    <li>
                        <Link to="/duty-calls" className="waves-effect">
                            <i className="fas fa-phone"></i>
                            <span>Ügyeleti hívások</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.doctor == "1" ? (
                    <li>
                        <Link to="/duty-doctors" className="waves-effect">
                            <i className="fas fa-user-md"></i>
                            <span>Ügyeletes orvosok</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.pharmacist == "1" ? (
                    <li>
                        <Link
                            to="/prescription-redeems"
                            className="waves-effect"
                        >
                            <i className="fas fa-comment-medical"></i>
                            <span>Gyógyszerigény</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" ||
                access.callCenter == "1" ||
                access.doctor == "1" ? (
                    <li>
                        <Link to="/reservations" className="waves-effect">
                            <i className="fas fa-calendar-check"></i>
                            <span>Időpontfoglalás</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/duties" className="waves-effect">
                            <i className="fas fa-hospital-alt"></i>
                            <span>Ügyeletek</span>
                        </Link>
                    </li>
                ) : null}
                {access.superAdmin == "1" ||
                access.callCenter == "1" ||
                access.caseManager == "1" ||
                access.fullCaseManager == "1" ? (
                    <li>
                        <Link to="/daily-summary" className="waves-effect">
                            <i className="fas fa-calendar-alt"></i>
                            <span>Napi történések</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" ||
                access.callCenter == "1" ||
                access.doctor == "1" ||
                access.caseManager == "1" ||
                access.fullCaseManager == "1" ? (
                    <li>
                        <Link to="/case-manager" className="waves-effect">
                            <i className="fas fa-folder-open"></i>
                            <span>Esetmenedzsment</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.finance == "1" ? (
                    <li>
                        <Link to="/stats" className="waves-effect">
                            <i className="fas fa-chart-bar"></i>
                            <span>Statisztikák</span>
                        </Link>
                    </li>
                ) : null}
                {access.superAdmin == "1" || access.finance == "1" ? (
                    <li>
                        <Link to="/statistics" className="waves-effect">
                            <i className="fas fa-chart-line"></i>
                            <span>Kimutatások</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.doctor == "1" ? (
                    <li>
                        <Link to="/consilium" className="waves-effect">
                            <i className="fas fa-comments"></i>
                            <span>Konzilium</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" ||
                access.doctor == "1" ||
                access.callCenter == "1" ? (
                    <li>
                        <Link to="/prescription" className="waves-effect">
                            <i className="fas fa-prescription-bottle-alt"></i>
                            <span>E-recept</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" || access.callCenter == "1" ? (
                    <li>
                        <Link to="/qa" className="waves-effect">
                            <i className="fas fa-question"></i>
                            <span>{`Q&A Kérdések`}</span>
                        </Link>
                    </li>
                ) : null}

                {access.superAdmin == "1" ? (
                    <li>
                        <Link to="/ratings" className="waves-effect">
                            <i className="fas fa-star"></i>
                            <span>Értékelések</span>
                        </Link>
                    </li>
                ) : null}
                <NextHealthMenu />
            </ul>
        </div>
    );
};

interface Props {
    theme: string;
    type: string;
    isMobile: boolean;
}

interface Props {
    type: string;
    isMobile: boolean;
}

const Sidebar = ({ type, isMobile }: Props) => {
    const location = useLocation();

    const activateParentDropdown = useCallback((item: HTMLAnchorElement) => {
        const parent = item.closest("ul");

        if (!parent) {
            return;
        }

        if (!item.classList.contains("mm-active")) {
            for (const child of Array.from(parent.children)) {
                child.firstElementChild?.classList.remove("mm-active");
            }
            item.classList.add("mm-active");
        }
    }, []);

    useEffect(() => {
        if (type !== "condensed" || isMobile) {
            new MetisMenu("#side-menu");

            let matchingMenuItem = null;
            const ul = document.getElementById("side-menu");
            const items = ul?.getElementsByTagName("a") ?? [];
            for (let i = 0; i < items.length; ++i) {
                if (location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];

                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
    }, [activateParentDropdown, isMobile, location.pathname, type]);

    return (
        <>
            {type !== "condensed" ? (
                <SimpleBar style={{ maxHeight: "100%" }}>
                    <SidebarContent />
                </SimpleBar>
            ) : (
                <SidebarContent />
            )}
        </>
    );
};

export default Sidebar;
