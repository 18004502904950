import { createRoot } from "react-dom/client";
import invariant from "tiny-invariant";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import * as actions from "./store/custom-auth/actions";
import Root from "./features/Root";

store.dispatch(actions.checkLoggedIn());

const container = document.getElementById("root");
invariant(container);
const root = createRoot(container);

root.render(
    <Root>
        <App />
    </Root>
);

serviceWorker.unregister();
