import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import invariant from "tiny-invariant";
import { useState } from "react";
import type { Ulid } from "tdoc-common";
import { useNavigate as useNextHealthNavigate } from "@madmedical/sdk";
import { useGetSubscriptionsQuery } from "./api";
import { NextHealthService } from "./model";
import CaseNhaSubscription from "./CaseNhaSubscription";
import CaseNhaSubscriptionCreateForm from "./CaseNhaSubscriptionCreateForm";
import CaseNhaSubscriptionEditForm from "./CaseNhaSubscriptionEditForm";

interface Props {
    userId: number;
}

const CaseNhaSubscriptions = ({ userId }: Props) => {
    const { data: subscriptions, isLoading } = useGetSubscriptionsQuery({
        userId,
    });
    const { navigateToPatient } = useNextHealthNavigate();
    // id of subs to edit or "new"
    const [formId, setFormId] = useState<Ulid>();

    const handleNewClick = () => {
        setFormId("new");
    };

    const handleEditClick = (id: Ulid) => () => {
        setFormId(id);
    };

    const handleFormSuccess = () => {
        setFormId(undefined);
    };

    if (isLoading) {
        return null;
    }

    invariant(subscriptions);

    return (
        <Card>
            <CardHeader>
                NextHealth PrePostOp előfizetések
                {!!subscriptions.length && (
                    <Button
                        color="link"
                        onClick={() => navigateToPatient(`u${userId}`)}
                    >
                        Páciens adatlap
                    </Button>
                )}
            </CardHeader>
            <CardBody>
                {subscriptions
                    .filter(
                        ({ service }) => service === NextHealthService.PrePostOp
                    )
                    .map((subscription) => {
                        const { id } = subscription;

                        return (
                            <div key={id}>
                                {formId === id ? (
                                    <CaseNhaSubscriptionEditForm
                                        userId={userId}
                                        subscription={subscription}
                                        onSuccess={handleFormSuccess}
                                    />
                                ) : (
                                    <>
                                        <CaseNhaSubscription
                                            subscription={subscription}
                                        />
                                        <Button
                                            color="link"
                                            onClick={handleEditClick(id)}
                                        >
                                            Szerkesztés
                                        </Button>
                                    </>
                                )}
                            </div>
                        );
                    })}
                {formId === "new" && (
                    <CaseNhaSubscriptionCreateForm
                        userId={userId}
                        onSuccess={handleFormSuccess}
                    />
                )}
            </CardBody>
            <CardFooter>
                <Button color="primary" onClick={handleNewClick}>
                    Új NextHealth előfizetés
                </Button>
            </CardFooter>
        </Card>
    );
};

export default CaseNhaSubscriptions;
