import { useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import ChatMessage from "../ChatMessage/ChatMessage";
import { MONTHS } from "../../../config/variables";
import { returnWithZero } from "../../../utility";
import classes from "./ChatWindow.module.css";

const ChatWindow = (props) => {
    const startRef = useRef();
    const endRef = useRef();
    const messagesRef = useRef();

    useEffect(() => {
        messagesRef.current.scrollTo({
            top: endRef.current.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    }, [props.messages]);

    const renderDate = (date) =>
        `${MONTHS[date.getMonth()]} ${date.getDate()}, ${returnWithZero(
            date.getHours()
        )}:${returnWithZero(date.getMinutes())}`;

    const renderAuthor = (doctorId, adminName = null) => {
        if (doctorId.substring(0, 1) === "a") {
            return adminName;
        }
        const doctor = props.doctors.find((d) => d.doctor_id === doctorId);
        if (doctor) {
            return `${doctor.title ?? ""} ${doctor.last_name} ${
                doctor.first_name
            }`;
        }
    };

    const renderMessages = () => {
        let messages = [];
        props.messages.forEach((message, index) => {
            const withDate =
                index === 0 ||
                props.messages[index - 1].author !== message.author ||
                message.dateCreated.getTime() -
                    props.messages[index - 1].dateCreated.getTime() >
                    1000 * 60 * 30;
            const type =
                message.type === "text"
                    ? "text"
                    : message.media.contentType.startsWith("image/")
                    ? "image"
                    : "attachment";

            messages.push(
                <ChatMessage
                    key={message.sid}
                    direction={
                        message.author === props.doctorId ||
                        (message.author === "system" &&
                            `a${props.userId}` === props.creatorId) ||
                        `a${props.userId}` === message.author
                            ? "sent"
                            : "received"
                    }
                    type={type}
                    date={renderDate(message.dateCreated)}
                    withDate={withDate}
                    otherName={
                        message.author === "system"
                            ? renderAuthor(props.creatorId, props.adminName)
                            : renderAuthor(message.author, props.adminName)
                    }
                    text={message.body}
                    media={message.type === "media" ? message.media : null}
                />
            );
        });

        return messages;
    };

    const renderParticipants = () => {
        let doctorString = "";
        props.doctors.forEach((d, index) => {
            doctorString += `${d.title ?? ""} ${d.last_name} ${d.first_name}`;
            if (index !== props.doctors.length - 1) {
                doctorString += ", ";
            }
        });

        return doctorString;
    };

    return (
        <div className={classes.ChatWindow} style={props.style}>
            <div className={classes.header}>
                <span className={classes.title}>Konzílium</span>
                <span className={classes.identifier}>
                    Azonosító: {props.identifier}
                </span>
            </div>
            <div className={classes.infos}>
                <Grid container>
                    <Grid item>
                        <span>
                            {" "}
                            {props.isOpen ? (
                                <p className={classes.info}>
                                    <strong>Státusz:</strong> Nyitott
                                </p>
                            ) : (
                                <p className={classes.info}>Státusz: Lezárt</p>
                            )}
                        </span>
                    </Grid>
                    <Grid item>
                        <span>
                            <p className={classes.info}>
                                <strong>Létrehozó:</strong>{" "}
                                {props.doctorName
                                    ? props.doctorName
                                    : props.adminName ?? ""}
                            </p>
                        </span>
                    </Grid>
                    {props.birthDate && (
                        <Grid item>
                            <span>
                                <p className={classes.info}>
                                    <strong>Születési dátum:</strong>
                                    {` ${props.birthDate}`}
                                </p>
                            </span>
                        </Grid>
                    )}
                </Grid>
            </div>
            <div className={classes.infos}>
                <span>
                    <p className={classes.info}>
                        <strong>Megnevezés:</strong>{" "}
                        {props.name ?? "Nincs megadva"}
                    </p>
                </span>
            </div>
            <div className={classes.infos}>
                <span>
                    <p className={classes.info}>
                        <strong>Leírás:</strong>{" "}
                        {props.description ?? "Nincs megadva"}
                    </p>
                </span>
            </div>
            <div className={classes.infos}>
                <span>
                    <p className={classes.info}>
                        <strong>Résztvevők:</strong> {renderParticipants()}
                    </p>
                </span>
            </div>
            <div className={classes.inner} ref={messagesRef}>
                <div className={classes.startDummy} ref={startRef}></div>
                {renderMessages()}
                <div
                    className={classes.endDummy}
                    ref={endRef}
                    id="endRef"
                ></div>
            </div>
        </div>
    );
};

export default ChatWindow;
