import { Colors } from "../../../assets/Theme";
import classes from "./Button.module.css";

const button = (props) => {
    let classNames = `${classes.Button} ${props.className} ${
        props.whiteBorder && classes.whiteBorder
    }`;
    if (props.text) {
        if (props.disabled) {
            return (
                <div
                    className={classNames}
                    style={{
                        ...props.style,
                        justifyContent: "center",
                        backgroundColor: Colors.grey,
                        borderColor: Colors.grey,
                    }}
                >
                    {props.text}
                </div>
            );
        }

        return (
            <div
                className={classNames}
                style={{ ...props.style, justifyContent: "center" }}
                onClick={props.onClick}
            >
                {props.text}
            </div>
        );
    }
    if (props.rightIcon) {
        return (
            <div
                className={classNames}
                style={props.style}
                onClick={props.onClick}
            >
                <div className={classes.dummy}></div>
                {props.children}
                <img
                    alt=""
                    className={classes.rightIcon}
                    src={props.rightIcon}
                />
            </div>
        );
    }

    return (
        <div className={classNames} style={props.style} onClick={props.onClick}>
            {props.children}
        </div>
    );
};

export default button;
