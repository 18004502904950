import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import TextEditor from "../../../components/TextEditor/TextEditor";

const AnswerForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [message, setMessage] = useState(null);
    const [answer, setAnswer] = useState(null);

    useEffect(() => {
        post("api/v1/contact/get-message", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setMessage(response.data.message);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSend = () =>
        post("api/v1/contact/answer-message", {
            id,
            answer,
            email: message.email,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres küldés!");
                        navigate("/qa");
                        endLoading();

                        break;
                    case "ALREADY_ANSWERED":
                        responseMessage(
                            "Az kérdés már megválaszolásra került!"
                        );
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const handleDelete = () =>
        post("api/v1/contact/delete-message", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres törlés!");
                        navigate("/qa");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">{`Q&A Kérdés`}</h4>
                        </div>
                    </Col>
                </Row>
                {message && (
                    <div className="card">
                        <div className="card-body">
                            <Row>
                                <Col sm={4}>
                                    <span>Név:</span>
                                    <span
                                        style={{
                                            marginLeft: "1rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {message.name}
                                    </span>
                                </Col>
                                <Col sm={4}>
                                    <span>Email:</span>
                                    <span
                                        style={{
                                            marginLeft: "1rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {message.email}
                                    </span>
                                </Col>
                                <Col sm={4}>
                                    <span>Telefonszám:</span>
                                    <span
                                        style={{
                                            marginLeft: "1rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {message.phone}
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "2rem" }}>
                                <Col>
                                    <p>{message.message}</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                {message && (
                    <div className="card">
                        <div className="card-body">
                            {message.answered && message.answer_html ? (
                                <TextEditor
                                    value={message.answer_html}
                                    disabled={true}
                                />
                            ) : (
                                <TextEditor
                                    value={answer}
                                    onEditorChange={(content) =>
                                        setAnswer(content)
                                    }
                                />
                            )}
                            {message.answered == "1" ? (
                                <button
                                    className="btn btn-danger float-right"
                                    style={{ marginTop: "1rem" }}
                                    onClick={handleDelete}
                                >
                                    Törlés
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary float-right"
                                    style={{ marginTop: "1rem" }}
                                    onClick={handleSend}
                                >
                                    Válasz
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AnswerForm;
