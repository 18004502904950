import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { endLoading, get } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";
import ConsiliumTable from "../ConsiliumTable/ConsiliumTable";

const ConsiliumList = () => {
    const [channels, setChannels] = useState(null);
    const [participate, setParticipate] = useState(false);

    useEffect(() => {
        get("api/v1/consilium/get-channels")
            .then((response) => {
                endLoading();
                setChannels(response.data.channels);
                setParticipate(response.data.participateConsilium == "1");
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Konzílium</h4>
                        </div>
                    </Col>
                    {participate && (
                        <Col sm="6">
                            <div className="float-right">
                                <Link to={"/consilium/new"}>
                                    <button className="btn btn-primary">
                                        Új konzílium
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    )}
                </Row>

                <div className="row">
                    <div className="col-12">
                        {channels && (
                            <ConsiliumTable
                                title="Aktív konzíliumok"
                                consiliums={channels.filter(
                                    (c) => c.open == "1"
                                )}
                                defaultOpen={true}
                            />
                        )}
                        {channels && (
                            <ConsiliumTable
                                title="Lezárt konzíliumok"
                                consiliums={channels.filter(
                                    (c) => c.open == "0"
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConsiliumList;
