import { Navigate } from "react-router-dom";
import type { ReactNode } from "react";
import { useSelector } from "../store";

interface Props {
    isAuthProtected: boolean;
    rights?: string[];
    children: ReactNode;
}

const RouteAccess = ({ isAuthProtected, rights, children }: Props) => {
    const { token, access } = useSelector((state) => state.auth);

    if (!token) {
        if (isAuthProtected) {
            return <Navigate to={{ pathname: "/login" }} />;
        }
    }

    if (rights) {
        for (const a of rights) {
            if (access[a]) {
                return <>{children}</>;
            }
        }

        if (access.finance) {
            return <Navigate to={{ pathname: "/stats" }} />;
        }

        return <Navigate to={{ pathname: "/users" }} />;
    }

    return <>{children}</>;
};

export default RouteAccess;
