import { useEffect, useState } from "react";
import { Col, Media, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const Employers = () => {
    const [employers, setEmployers] = useState(null);

    const [tableData, setTableData] = useState({
        columns: [
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Név",
                field: "name",
            },
            {
                label: "Email",
                field: "email",
            },
            {
                label: "Telefonszám",
                field: "phone",
            },
            {
                label: "Kép",
                field: "image",
            },
            {
                label: "Gyógyszerkiszállítás",
                field: "delivery",
            },
            {
                label: "Szállítási cím",
                field: "address",
            },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    });

    useEffect(() => {
        get("admin/employers")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setEmployers(response.data.employers);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    useEffect(() => {
        if (employers) {
            fillTableData(employers);
        }
    }, [employers]); // eslint-disable-line react-hooks/exhaustive-deps

    const fillTableData = (employersData) => {
        const modifiedData = employersData.map((e) => ({
            id: e.id,
            name: e.name,
            email: e.email,
            phone: e.phone,
            image: e.image && (
                <Media>
                    <Media className="Pic" object src={e.image} alt="Kép" />
                </Media>
            ),
            delivery: e.delivery ? "Igen" : "Nem",
            address: e.employerAddress ?? "-",
            actions: <Link to={"/employers/" + e.id}>Műveletek</Link>,
        }));
        setTableData({
            ...tableData,
            rows: modifiedData,
        });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Munkáltatók</h4>
                        </div>
                    </Col>

                    <Col sm="6">
                        <div className="float-right">
                            <Link to={"/employers/new"}>
                                <button className="btn btn-primary">
                                    Új munkáltató
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="employers-table"
                                    disableRetreatAfterSorting={true}
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Employers;
