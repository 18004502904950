import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import classes from "../Calls/CallCard.module.css";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const DutyDoctors = () => {
    const [selectedMonth, setSelectedMonth] = useState(
        `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
        }`
    );

    const [dutyDoctors, setDutyDoctors] = useState([]);

    const handleDelete = (id) => {
        post(`admin/delete-duty-doctor`, { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        getDutyDoctors();
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                }
                endLoading();
            })
            .catch((error) => {
                console.log(error);
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    const getDutyDoctors = async () => {
        get(`admin/duty-doctors?selectedMonth=${selectedMonth}`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDutyDoctors(response.data.dutyDoctors);
                        endLoading();

                        break;
                    default:
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        endLoading();
                }
                endLoading();
            })
            .catch(() => {
                responseMessage(ALERT_MESSAGES.errorTryAgain);
                endLoading();
            });
    };

    useEffect(() => {
        getDutyDoctors();
    }, [selectedMonth]); // eslint-disable-line react-hooks/exhaustive-deps

    const DoctorRow = (doctor) => (
        <li className="list-group-item channelListItem">
            <Row>
                <Col sm={2}>
                    <p className={classes.text}>
                        <strong>
                            Név:{" "}
                            {`${doctor.title} ${doctor.last_name} ${doctor.first_name}`}
                        </strong>
                    </p>
                </Col>
                <Col sm={2}>
                    <p className={classes.text}>
                        <strong>
                            Tól:{" "}
                            {format(new Date(doctor.from), "yyyy-MM-dd HH:mm")}
                        </strong>
                    </p>
                </Col>
                <Col sm={2}>
                    <p className={classes.text}>
                        <strong>
                            Ig:{" "}
                            {format(new Date(doctor.to), "yyyy-MM-dd HH:mm")}
                        </strong>
                    </p>
                </Col>

                <Col sm={2}>
                    <span
                        className={"fa fa-trash"}
                        onClick={() => handleDelete(doctor.id)}
                        style={{
                            cursor: "pointer",
                            color: "red",
                        }}
                    ></span>
                </Col>
                <Col sm={2}>
                    <Link to={`/duty-doctors/${doctor.id}`}>
                        <span className={"fa fa-edit"}></span>
                    </Link>
                </Col>
            </Row>
        </li>
    );

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <h3>Ügyeletes orvosok</h3>
                <Link to={"/duty-doctors/new"}>
                    <button
                        className="btn btn-primary"
                        style={{
                            marginBottom: "20px",
                            marginTop: "20px",
                        }}
                    >
                        Új
                    </button>
                </Link>
                <input
                    type="month"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                />
            </div>
            {dutyDoctors.length > 0 ? (
                <ul className="list-group">
                    {dutyDoctors.map((doctor) => DoctorRow(doctor))}
                </ul>
            ) : (
                <h3>Nincs adat</h3>
            )}
        </>
    );
};

export default DutyDoctors;
