import { Card, CardBody } from "reactstrap";
import { useState } from "react";
import classes from "./OpenableCard.module.css";

const OpenableCard = (props) => {
    const [open, setOpen] = useState(props.defaultOpen);

    return (
        <Card>
            <CardBody id={props.id}>
                <h5
                    className={classes.title}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {props.title}
                </h5>
                {open && (
                    <>
                        <hr></hr>
                        {props.children}
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default OpenableCard;
