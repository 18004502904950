import { useState } from "react";
import { Button, Card, CardBody, Input } from "reactstrap";
import { convertDate, responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import InfoComponent from "../InfoComponent/InfoComponent";
import DatePicker from "../../DatePicker/DatePicker";
import EditBtn from "../EditBtn/EditBtn";
import classes from "./AppointmentsCard.module.css";

const AppointmentsCard = (props) => {
    const patientCase = props.patientCase;
    const originalData = props.caseDateData;
    const [editMode, setEditMode] = useState(false);
    const [caseDateData, setCaseDateData] = useState(props.caseDateData);

    const handleUpdate = () =>
        post("admin/update-case-dates", {
            caseId: patientCase.id,
            data: caseDateData,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        props.refreshPage();
                        endLoading();
                        setEditMode(false);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <Card>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        <span>Időpontok</span>
                    </div>
                    {!editMode && (
                        <div>
                            <EditBtn onClick={() => setEditMode(true)} />
                        </div>
                    )}
                </div>
                <InfoComponent
                    title="Műtét időpontja"
                    info={
                        caseDateData.surgery
                            ? caseDateData.surgery.replace(/-/g, ".")
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <DatePicker
                            value={
                                caseDateData.surgery
                                    ? caseDateData.surgery
                                    : null
                            }
                            withTime={true}
                            onChange={(e) =>
                                setCaseDateData({
                                    ...caseDateData,
                                    surgery: convertDate(e, "-", true),
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Aneszt. időpontja"
                    info={
                        caseDateData.anest
                            ? caseDateData.anest.replace(/-/g, ".")
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <DatePicker
                            value={
                                caseDateData.anest ? caseDateData.anest : null
                            }
                            withTime={true}
                            onChange={(e) =>
                                setCaseDateData({
                                    ...caseDateData,
                                    anest: convertDate(e, "-", true),
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Emlékeztető"
                    info={
                        caseDateData.ping
                            ? caseDateData.ping.replace(/-/g, ".")
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <DatePicker
                            value={caseDateData.ping ? caseDateData.ping : null}
                            withTime={true}
                            onChange={(e) =>
                                setCaseDateData({
                                    ...caseDateData,
                                    ping: convertDate(e, "-", true),
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Egyéb ping"
                    info={
                        caseDateData.otherPingDate
                            ? caseDateData.otherPingDate.replace(/-/g, ".")
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <DatePicker
                            value={
                                caseDateData.otherPingDate
                                    ? caseDateData.otherPingDate
                                    : null
                            }
                            withTime={true}
                            onChange={(e) =>
                                setCaseDateData({
                                    ...caseDateData,
                                    otherPingDate: convertDate(e, "-", true),
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Egyéb ping leírása"
                    info={
                        caseDateData.otherPingDescription &&
                        caseDateData.otherPingDescription !== ""
                            ? caseDateData.otherPingDescription
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <Input
                            type="textarea"
                            rows={2}
                            value={caseDateData.otherPingDescription}
                            onChange={(e) =>
                                setCaseDateData({
                                    ...caseDateData,
                                    otherPingDescription: e.target.value,
                                })
                            }
                        />
                    }
                />
                {editMode && (
                    <div className={classes.btnWrapper}>
                        <Button color="primary" onClick={() => handleUpdate()}>
                            Változtatások mentése
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setEditMode(false);
                                setCaseDateData(originalData);
                            }}
                        >
                            Mégse
                        </Button>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default AppointmentsCard;
