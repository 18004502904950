import { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import classes from "./RecommendService.module.css";

const RecommendService = (props) => {
    const userId = props.patientId;

    const [service, setService] = useState("");

    const [selectedQualification, setSelectedQualification] = useState(null);
    const [selectedSymptom, setSelectedSymptom] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedSpecialization, setSelectedSpecialization] = useState(null);

    // t-doc chat, second opinion
    const [qualifications, setQualifications] = useState("");
    const [symptoms, setSymptoms] = useState("");
    const [doctors, setDoctors] = useState("");

    const select1 = useRef();
    const select2 = useRef();
    const select3 = useRef();

    useEffect(() => {
        get(`admin/get-service-recommendation-data`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setQualifications(response.data.qualifications);
                        setSymptoms(response.data.symptoms);
                        setDoctors(response.data.doctors);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const onChangeService = (service) => {
        setService(service);
        setSelectedQualification(null);
        setSelectedSymptom(null);
        setSelectedDoctor(null);
        setSelectedSpecialization(null);
        if (select1.current && select2.current && select3.current) {
            select1.current.state.value = null;
            select2.current.state.value = null;
            select3.current.state.value = null;
        }
    };

    const onSendServiceData = () => {
        const data = {
            userId,
            service,
            qualification: selectedQualification
                ? selectedQualification.qualification
                : null,
            qualificationId: selectedQualification
                ? selectedQualification.qualification_id
                : null,
            specialization: selectedSpecialization
                ? selectedSpecialization.specialization
                : null,
            specializationId: selectedSpecialization
                ? selectedSpecialization.specialization_id
                : null,
            symptom: selectedSymptom ? selectedSymptom.symptom : null,
            symptomId: selectedSymptom ? selectedSymptom.symptom_id : null,
            doctor: selectedDoctor ? selectedDoctor.doctor : null,
            doctorId: selectedDoctor ? selectedDoctor.doctor_id : null,
        };

        post("admin/recommend-service", {
            ...data,
        })
            .then((response) => {
                if (response.data.responseCode === "SYSTEM_ERROR") {
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                    endLoading();

                    return;
                }
                responseMessage("Ajánlott szolgáltatás elküldve!");
                props.onCancelRecommend();
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <Card>
            <CardBody>
                <Row>
                    <Link to={`/reservation?type=both&patient=${userId}`}>
                        <button
                            className="btn btn-primary"
                            style={{ margin: "12px" }}
                        >
                            Időpontfoglalás
                        </button>
                    </Link>
                </Row>
                <Row>
                    <Col xl={3} lg={6}>
                        <p className={classes.text}>
                            <strong>Egyéb szolgáltatás</strong>
                        </p>
                    </Col>
                    <Col xl={3} lg={6}>
                        <FormGroup style={{ marginBottom: "0.5rem" }}>
                            <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                onChange={(e) =>
                                    onChangeService(e.target.value)
                                }
                            >
                                <option value="" selected disabled>
                                    Válasszon szolgáltatást...
                                </option>
                                <option value="HOTLINE">Hotline hívása</option>
                                <option value="DUTY">Ügyelet hívása</option>
                                <option value="CHAT">Üzenetváltás</option>
                                <option value="SECOND_OPINION">
                                    Másodvélemény
                                </option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {service === "HOTLINE" ? (
                    <div>
                        <Row>
                            <Col>
                                <Button
                                    color="primary"
                                    onClick={onSendServiceData}
                                >
                                    Küldés
                                </Button>
                                <Button
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() => props.onCancelRecommend()}
                                >
                                    Vissza
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : null}
                {service === "DUTY" ? (
                    <div>
                        <Row>
                            <Col>
                                <Button
                                    color="primary"
                                    onClick={onSendServiceData}
                                >
                                    Küldés
                                </Button>
                                <Button
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() => props.onCancelRecommend()}
                                >
                                    Vissza
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : null}
                {service === "CHAT" || service === "SECOND_OPINION" ? (
                    <div>
                        <Row>
                            <Col sm={3}>
                                <FormGroup style={{ marginBottom: "0.5rem" }}>
                                    <Select
                                        ref={select1}
                                        menuPlacement="top"
                                        placeholder="Válasszon szakterületet..."
                                        noOptionsMessage={() => "Nincs találat"}
                                        options={qualifications.map((q) => ({
                                            value: q.id,
                                            label: q.name,
                                        }))}
                                        onChange={(e) =>
                                            setSelectedQualification({
                                                qualification_id: e.value,
                                                qualification: e.label,
                                            })
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup style={{ marginBottom: "0.5rem" }}>
                                    <Select
                                        ref={select2}
                                        menuPlacement="top"
                                        placeholder="Válasszon tünetet..."
                                        noOptionsMessage={() => "Nincs találat"}
                                        options={symptoms.map((s) => ({
                                            value: s.id,
                                            label: s.name,
                                        }))}
                                        onChange={(e) =>
                                            setSelectedSymptom({
                                                symptom_id: e.value,
                                                symptom: e.label,
                                            })
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup style={{ marginBottom: "0.5rem" }}>
                                    <Select
                                        ref={select3}
                                        menuPlacement="top"
                                        placeholder="Válasszon orvost..."
                                        noOptionsMessage={() => "Nincs találat"}
                                        options={doctors.map((d) => ({
                                            value: d.doctor_id,
                                            label: `${d.last_name} ${d.first_name}`,
                                        }))}
                                        onChange={(e) =>
                                            setSelectedDoctor({
                                                doctor_id: e.value,
                                                doctor: e.label,
                                            })
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button
                                    color="primary"
                                    onClick={onSendServiceData}
                                >
                                    Küldés
                                </Button>
                                <Button
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() => props.onCancelRecommend()}
                                >
                                    Vissza
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </CardBody>
        </Card>
    );
};

export default RecommendService;
