import * as actionTypes from "./actionTypes";

export const login = (userId, token, name, email, access) => ({
    type: actionTypes.LOGIN,
    token,
    userId,
    name,
    email,
    access,
});

export const logout = () => ({
    type: actionTypes.LOGOUT,
});

export const startLoad = () => ({
    type: actionTypes.START_LOADING,
});

export const endLoad = () => ({
    type: actionTypes.END_LOADING,
});

export const checkLoggedIn = () => (dispatch) => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    if (!token) {
        dispatch(logout());
    } else {
        const access = JSON.parse(localStorage.getItem("access"));
        dispatch(login(userId, token, name, email, access));
    }
};
