import { FormFeedback, FormGroup } from "reactstrap";
import { useFormError } from "tdoc-common";

const FormError = () => {
    const formError = useFormError();

    if (!formError) {
        return null;
    }

    return (
        <FormGroup>
            <FormFeedback>{formError}</FormFeedback>
        </FormGroup>
    );
};

export default FormError;
