import { useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { endLoading, post } from "../config/fetch";
import { logout } from "../store/custom-auth/actions";
// users
import user from "../assets/images/users/user_icon.png";

const ProfileMenu = (props) => {
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);

    const toggle = () => setMenu((prevState) => !prevState);

    const handleLogout = () => {
        post("admin/logout");
        endLoading();
        props.logout();
        navigate("/login");
    };

    return (
        <>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={user}
                        alt="Header Avatar"
                    />
                </DropdownToggle>
                <DropdownMenu right>
                    <Link to={"/profile"}>
                        <DropdownItem>
                            <i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
                            Profil
                        </DropdownItem>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <div onClick={handleLogout} className="dropdown-item">
                        <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                        <span>Kijelentkezés</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(ProfileMenu);
