import { useEffect, useRef, useState } from "react";
// import { Row, Col, Card, CardBody } from "reactstrap";
import { Button, Col, FormGroup, Row } from "reactstrap";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import DayContainer from "./DayContainer/DayContainer";

let originalDailyData;

const DailySummary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    // daily data
    const [allData, setAllData] = useState(null);
    const [dailyData, setDailyData] = useState(null);

    // users
    const [users, setUsers] = useState(null);

    // t-doc chat, second opinion
    const [qualifications, setQualifications] = useState("");
    const [symptoms, setSymptoms] = useState("");
    const [doctors, setDoctors] = useState("");

    // search field
    const [selectedPatient, setSelectedPatient] = useState("");

    const select1 = useRef();

    const handleChange = (id) => (event, isExpanded) =>
        setExpanded(isExpanded ? id : false);

    useEffect(() => {
        get(`admin/daily-summary`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setQualifications(response.data.qualifications);
                        setSymptoms(response.data.symptoms);
                        setDoctors(response.data.doctors);

                        setAllData(response.data.dailyData);
                        setDailyData(response.data.dailyData.slice(0, 14));
                        originalDailyData = response.data.dailyData.slice(
                            0,
                            14
                        );
                        setUsers(response.data.users);

                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const onSendServiceData = (data) =>
        post("admin/recommend-service", {
            service: data.service,
            userId: data.userId,
            qualification: data.qualification,
            qualificationId: data.qualificationId,
            specialization: data.specialization,
            specializationId: data.specializationId,
            symptom: data.symptom,
            symptomId: data.symptomId,
            doctor: data.doctor,
            doctorId: data.doctorId,
        })
            .then(() => {
                responseMessage("Ajánlott szolgáltatás elküldve!");
                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const filterDailyDataByUserId = () => {
        if (!selectedPatient) {
            return;
        }
        const modified = [];
        originalDailyData.forEach((data) =>
            data.patientStatus.forEach((p) => {
                if (p.user_id == selectedPatient) {
                    modified.push(data);
                }
            })
        );
        setDailyData(modified);
    };

    const filterDailyDataByStatus = (status) => {
        const modified = [];
        originalDailyData.forEach((data) => {
            let dayData = { ...data, patientStatus: [], filtered: false };
            data.patientStatus.forEach((p) => {
                if (p.status == status) {
                    dayData.patientStatus = [...dayData.patientStatus, p];
                    dayData.filtered = true;
                }
            });
            if (dayData.filtered === true) {
                modified.push(dayData);
            }
        });
        setDailyData(modified);
    };

    const onStatusChange = () => {
        let currentPage = location.pathname;
        navigate({ pathname: "/empty" });
        navigate({ pathname: currentPage }, { replace: true });
    };

    return (
        <div className="container-fluid">
            <div className="page-title-box">
                <h4 className="font-size-18">Napi történések</h4>
            </div>

            {users && (
                <>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            {dailyData.length === 14 ? (
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setDailyData(allData);
                                        originalDailyData = allData;
                                    }}
                                >
                                    Minden történés
                                </Button>
                            ) : (
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setDailyData(allData.slice(0, 14));
                                        originalDailyData = allData.slice(
                                            0,
                                            14
                                        );
                                    }}
                                >
                                    Legutóbbi 2 hét
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <Select
                                    ref={select1}
                                    placeholder="Páciens keresése..."
                                    noOptionsMessage={() => "Nincs találat"}
                                    options={users.map((u) => ({
                                        value: u.id,
                                        label: `${u.last_name} ${u.first_name}`,
                                    }))}
                                    onChange={(e) =>
                                        setSelectedPatient(e.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <Button
                                color="primary"
                                onClick={filterDailyDataByUserId}
                            >
                                Keresés
                            </Button>
                        </Col>
                    </Row>
                    <div style={{ marginBottom: "1rem" }}>
                        <Button
                            style={{
                                marginRight: "1rem",
                                backgroundColor: "#FFD943",
                            }}
                            // color="warning"
                            onClick={() => filterDailyDataByStatus("1")}
                        >
                            Folyamatban lévő feladatok
                        </Button>
                        <Button
                            style={{ marginRight: "1rem" }}
                            v
                            color="primary"
                            onClick={() => filterDailyDataByStatus("0")}
                        >
                            Kész feladatok
                        </Button>

                        <Button
                            color="secondary"
                            onClick={() => {
                                select1.current.state.value = "";
                                setDailyData(originalDailyData);
                            }}
                        >
                            Összes mutatása
                        </Button>
                    </div>
                </>
            )}
            <div style={{ minHeight: "40rem", marginBottom: "1rem" }}>
                {dailyData &&
                    users &&
                    qualifications &&
                    symptoms &&
                    doctors &&
                    dailyData.map((d, index) => (
                        <DayContainer
                            date={d.date}
                            patientStatus={d.patientStatus}
                            summary={d.summary}
                            allUsers={users}
                            expanded={expanded}
                            key={index}
                            id={index}
                            onChange={handleChange(index)}
                            qualifications={qualifications}
                            symptoms={symptoms}
                            doctors={doctors}
                            onSendServiceData={onSendServiceData}
                            onStatusChange={onStatusChange}
                        />
                    ))}
            </div>
        </div>
    );
};

export default DailySummary;
