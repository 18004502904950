import { Dialog } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Icons } from "../../../assets/Theme";
import classes from "./ChatMessage.module.css";

const ChatMessage = (props) => {
    const [modalOpen, toggleModal] = useState(false);
    const [mediaSource, setmediaSource] = useState(props.image || null);
    const attachmentRef = useRef();

    const handleModalClose = () => toggleModal(!modalOpen);

    const dialog = (
        <Dialog
            open={modalOpen}
            fullWidth={true}
            maxWidth="lg"
            onClose={handleModalClose}
        >
            <img src={mediaSource} className={classes.modalImage} />
        </Dialog>
    );

    const shortenName = (name) => name;

    const onAttachmentClick = () => attachmentRef.current.click();
    let fileAttr;

    if (props.media) {
        fileAttr = props.media.filename.split(".");
    }

    useEffect(() => {
        if (props.media) {
            props.media
                .getContentTemporaryUrl()
                .then((response) => setmediaSource(response));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const calculateSize = (size) => {
        let sizeInKB = size / 1024;
        if (sizeInKB < 100) {
            return sizeInKB.toFixed(2) + " KB";
        }

        return (sizeInKB / 1024).toFixed(2) + " MB";
    };

    if (props.type === "text") {
        return (
            <div
                className={`${classes.ChatMessage} ${
                    props.direction === "sent"
                        ? classes.messageSent
                        : classes.messageReceived
                }`}
            >
                {props.withDate && (
                    <p className={classes.date}>
                        {props.direction !== "sent" ? props.otherName : "Ön"},{" "}
                        {props.date}
                    </p>
                )}
                <div className={classes.messageBody}>{props.text}</div>
            </div>
        );
    } else if (props.type === "image") {
        return (
            <div
                className={`${classes.ChatMessage} ${
                    props.direction === "sent"
                        ? classes.messageSent
                        : classes.messageReceived
                }`}
            >
                {props.withDate && (
                    <p className={classes.date}>
                        {props.direction !== "sent" ? props.otherName : "Ön"},{" "}
                        {props.date}
                    </p>
                )}
                <div
                    className={`${classes.messageBody} ${classes.imageMessageBody}`}
                >
                    <img
                        src={mediaSource}
                        className={classes.image}
                        onClick={() => toggleModal(true)}
                    />
                </div>
                {modalOpen && dialog}
            </div>
        );
    } else if (props.type === "attachment") {
        return (
            <div
                className={`${classes.ChatMessage} ${
                    props.direction === "sent"
                        ? classes.messageSent
                        : classes.messageReceived
                }`}
            >
                {props.withDate && (
                    <p className={classes.date}>
                        {props.direction !== "sent" ? props.otherName : "Ön"},{" "}
                        {props.date}
                    </p>
                )}
                <a
                    className={classes.attachment}
                    ref={attachmentRef}
                    href={mediaSource}
                    download={props.media.filename}
                    target="_blank"
                    rel="noreferrer"
                ></a>
                <div
                    className={`${classes.messageBody} ${classes.attachmentMessageBody}`}
                    onClick={onAttachmentClick}
                >
                    <div className={classes.attachmentLeft}>
                        <img
                            src={Icons.attachmentBlack}
                            className={classes.icon}
                        />
                        <div className={classes.dataContainer}>
                            <span className={classes.fileName}>
                                <span className={classes.name}>
                                    {shortenName(fileAttr[0])}
                                </span>
                                .{fileAttr[1]}
                            </span>
                            <span className={classes.fileSize}>
                                {calculateSize(props.media.size)}
                            </span>
                        </div>
                    </div>
                    <div className={classes.attachmentRight}>Letöltés</div>
                </div>
            </div>
        );
    }
};

export default ChatMessage;
