import { useEffect, useRef, useState } from "react";
import { useDidMountEffect } from "../reservationUtilities";
import classes from "./ReservationFilter.module.css";
import ReservationSelect from "./ReservationSelect/ReservationSelect";

const ReservationFilter = (props) => {
    const [filterStyle, setFilterStyle] = useState({});
    const [data] = useState(props.filterData);
    const [qualifications, setQualifications] = useState([]);
    const [selectedQualificationId, setSelectedQualificationId] = useState(
        props.selectedQualificationId
    );
    const [services, setServices] = useState([]);
    const [selectedServiceId, setSelectedServiceId] = useState(
        props.selectedServiceId
    );
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState(
        props.selectedDoctorId
    );
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitutionId, setSelectedInstitutionId] = useState(
        props.selectedInstitutionId
    );

    const filterRef = useRef();

    const sortQualifications = () => {
        const addedQualificationIds = [];
        const qualifications = [];
        for (let d of data) {
            const searchInstitutionId =
                selectedInstitutionId || d.institutionId;
            if (
                !addedQualificationIds.includes(d.qualificationId) &&
                d.institutionId == searchInstitutionId
            ) {
                qualifications.push({
                    id: d.qualificationId,
                    title: d.qualification,
                });
                addedQualificationIds.push(d.qualificationId);
            }
        }
        qualifications.sort((a, b) => a.title.localeCompare(b.title));
        setQualifications(qualifications);
    };

    const sortServices = () => {
        const addedServiceIds = [];
        const services = [];
        for (let d of data) {
            const searchQualificationId =
                selectedQualificationId || d.qualificationId;
            const searchDoctorId = selectedDoctorId || d.doctorId;
            const searchInstitutionId =
                selectedInstitutionId || d.institutionId;
            if (selectedDoctorId || selectedQualificationId) {
                if (
                    !addedServiceIds.includes(d.serviceId) &&
                    d.doctorId == searchDoctorId &&
                    d.institutionId == searchInstitutionId &&
                    d.qualificationId == searchQualificationId
                ) {
                    services.push({
                        id: d.serviceId,
                        title: d.service,
                    });
                    addedServiceIds.push(d.serviceId);
                }
            }
        }
        services.sort((a, b) => a.title.localeCompare(b.title));
        setServices(services);
    };

    const sortDoctors = () => {
        const addedDoctorsIds = [];
        const doctors = [];
        for (let d of data) {
            const searchQualificationId =
                selectedQualificationId || d.qualificationId;
            const searchServiceId = selectedServiceId || d.serviceId;
            const searchInstitutionId =
                selectedInstitutionId || d.institutionId;
            //Ha van kiválasztott vizsgálat, csak az ahhoz tartozó orvosokat töltöm be
            if (
                !addedDoctorsIds.includes(d.doctorId) &&
                d.serviceId == searchServiceId &&
                d.institutionId == searchInstitutionId &&
                d.qualificationId == searchQualificationId
            ) {
                addedDoctorsIds.push(d.doctorId);
                doctors.push({
                    title: d.doctor,
                    id: d.doctorId,
                });
            }
        }
        doctors.sort((a, b) => a.title.localeCompare(b.title));
        setDoctors(doctors);
    };

    const sortInstitutions = () => {
        const addedInstitutionIds = [];
        const institutions = [];
        for (let d of data) {
            //Ha van kiválasztott vizsgálat/orvos akkor csak az oda tartozó intézményeket töltöm be, egyébként pedig a keresési feltétel egyenlő lesz a saját vizsgálat/orvos id-val,tehát mindig igaz
            const searchQualificationId =
                selectedQualificationId || d.qualificationId;
            const searchServiceId = selectedServiceId || d.serviceId;
            const searchDoctorId = selectedDoctorId || d.doctorId;
            if (
                !addedInstitutionIds.includes(d.institutionId) &&
                d.serviceId == searchServiceId &&
                d.doctorId == searchDoctorId &&
                d.qualificationId == searchQualificationId
            ) {
                addedInstitutionIds.push(d.institutionId);
                institutions.push({
                    title: d.institution,
                    id: d.institutionId,
                });
            }
        }
        institutions.sort((a, b) => a.title.localeCompare(b.title));
        setInstitutions(institutions);
    };

    const sortData = () => {
        const fittingData = [];
        if (
            (selectedQualificationId &&
                (selectedServiceId || selectedDoctorId)) ||
            selectedDoctorId
        ) {
            for (let d of data) {
                const searchQualificationId =
                    selectedQualificationId || d.qualificationId;
                const searchServiceId = selectedServiceId || d.serviceId;
                const searchDoctorId = selectedDoctorId || d.doctorId;
                const searchInstitutionId =
                    selectedInstitutionId || d.institutionId;
                if (
                    d.qualificationId == searchQualificationId &&
                    d.serviceId == searchServiceId &&
                    d.doctorId == searchDoctorId &&
                    d.institutionId == searchInstitutionId
                ) {
                    fittingData.push(d);
                }
            }
        }

        if (props.dataSetter) {
            props.dataSetter(fittingData);
        }
    };

    //Props-ból adatok a data state-be
    // useEffect(() => {
    //     console.log('[FILTER DATA CHANGE]: ', props.filterData, data);
    //     setData(props.filterData);
    // }, [props.filterData]);

    //Data state változás esetén szakterületek újrarenderelése, első betöltéskor ha van előre kiválasztott szakterület, betöltöm a hozzá tartozó vizsgálatokat
    useEffect(() => {
        if (data?.length) {
            sortQualifications();
            sortServices();
            sortDoctors();
            sortInstitutions();
            if (props.selectedDoctorId) {
                sortData();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useDidMountEffect(() => {
        sortServices();
        sortDoctors();
        sortInstitutions();
    }, [selectedQualificationId]);

    useDidMountEffect(() => {
        sortDoctors();
        sortInstitutions();
    }, [selectedServiceId]);

    useDidMountEffect(() => {
        sortServices();
        sortInstitutions();
    }, [selectedDoctorId]);

    useDidMountEffect(() => {
        sortServices();
        sortDoctors();
    }, [selectedInstitutionId]);

    useDidMountEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        sortData();
    }, [selectedServiceId, selectedDoctorId, selectedInstitutionId]);

    useEffect(() => {
        if (!props.staticPosition) {
            const topPosition = filterRef.current.offsetTop;
            setFilterStyle({
                position: "sticky",
                top: topPosition,
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={classes.reservationFilter}
            ref={filterRef}
            style={filterStyle}
        >
            <div className={classes.filterContainer}>
                {!props.selectedDoctorId && (
                    <ReservationSelect
                        options={qualifications}
                        rootClass={classes.muiSelect}
                        label="Szakterületek"
                        selectedId={selectedQualificationId}
                        noOptionsText="Nem található ilyen szakterület"
                        onChange={setSelectedQualificationId}
                    />
                )}
                <ReservationSelect
                    options={services}
                    rootClass={classes.muiSelect}
                    selectedId={selectedServiceId}
                    label="Vizsgálat"
                    noOptionsText="Nem található ilyen vizsgálat"
                    onChange={setSelectedServiceId}
                />
                <ReservationSelect
                    options={doctors}
                    rootClass={classes.muiSelect}
                    selectedId={selectedDoctorId}
                    label="Orvos"
                    noOptionsText="Nem található ilyen orvos"
                    onChange={setSelectedDoctorId}
                />
                {!props.withoutInstitution && (
                    <ReservationSelect
                        options={institutions}
                        rootClass={classes.muiSelect}
                        selectedId={selectedInstitutionId}
                        label="Helyszínek"
                        noOptionsText="Nem található ilyen helyszín"
                        onChange={setSelectedInstitutionId}
                    />
                )}
            </div>
        </div>
    );
};

export default ReservationFilter;
