import CaseTable from "../../../../pages/CaseManager/CaseTable/CaseTable";
import CollapseBox from "../CollapseBox/CollapseBox";
import styles from "./CasesActive.module.css";

const CasesActive = ({ propsBus }) => {
    const { cases, filterCasesByStatus } = propsBus;

    return (
        <CollapseBox title="Aktív esetek" classInput={styles.scope}>
            {cases ? (
                <CaseTable
                    title="Aktív esetek"
                    cases={filterCasesByStatus("0")}
                    defaultOpen={true}
                    hiddenTitle={true}
                />
            ) : (
                <></>
            )}
        </CollapseBox>
    );
};

export default CasesActive;
