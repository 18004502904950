import store from "./store/index";
import * as actions from "./store/ui/actions";

export const responseMessage = (content = "", title = null, component = null) =>
    store.dispatch(
        actions.toggleResponseModal(true, {
            title,
            content,
            component,
        })
    );

export const returnWithZero = (number) => (number < 10 ? "0" + number : number);

export const convertDate = (date, separator = "-", withHours = false) => {
    try {
        if (!withHours) {
            return (
                date.getFullYear() +
                separator +
                returnWithZero(date.getMonth() + 1) +
                separator +
                returnWithZero(date.getDate())
            );
        }

        return (
            date.getFullYear() +
            separator +
            returnWithZero(date.getMonth() + 1) +
            separator +
            returnWithZero(date.getDate()) +
            " " +
            returnWithZero(date.getHours()) +
            ":" +
            returnWithZero(date.getMinutes())
        );
    } catch (error) {
        return null;
    }
};

export const formatNumber = (x) =>
    x || x === 0 ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";

export const getAge = (dateString) => {
    if (!dateString) {
        return "-";
    }
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const prescriptionRedeemStatuses = [
    "new",
    "in_progress",
    "on_order",
    "no_prescription",
    "completed",
    "failed",
    "declined",
    "closed",
];

export const dispatchPrescriptionRedeemStatusText = (status) => {
    switch (status.toLowerCase()) {
        case "new":
            return "Új";
        case "in_progress":
            return "Feldolgozás alatt";
        case "on_order":
            return "Rendelés alatt";
        case "no_prescription":
            return "Nincs recept a felhőben";
        case "completed":
            return "Teljesítve";
        case "failed":
            return "Sikertelen";
        case "declined":
            return "Elutasítva";
        case "closed":
            return "Lezárt";
        default:
            return "N/A";
    }
};

export const dispatchPrescriptionRedeemStatusColor = (status) => {
    switch (status.toLowerCase()) {
        case "new":
            return "green";
        case "in_progress":
            return "blue";
        case "on_order":
            return "orange";
        case "no_prescription":
            return "purple";
        case "completed":
            return "green";
        case "failed":
            return "red";
        case "declined":
            return "red";
        case "closed":
            return "grey";
        default:
            return "grey";
    }
};
