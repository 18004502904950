import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import styles from "./BillingInfo.module.css";

const BillingInfo = ({ propsBus }) => {
    const [isEditing, setIsEditing] = useState(false);

    const {
        props,
        userData,
        cards,
        setUserDataByKey,
        setCardToDefault,
        handleSubmit,
        handleDelete,
    } = propsBus;

    const dataBilling = {
        ["Hozzáadott kártyák"]: userData.defaultCardId
            ? userData.defaultCardId
            : "Nincs hozzáadott kártya",
        ["Név"]:
            userData.firstName || userData.lastName
                ? `${userData.firstName ?? ""} ${userData.lastName ?? ""}`
                : null,
        ["Ország"]: userData.country,
        // TODO: közterület jellegének input fieldjét pótolni
        ["Számlázási cím"]: userData.settlement
            ? `${userData.zipcode ?? ""} ${userData.settlement ?? ""}, ${
                  userData.street ?? ""
              } ${userData.houseNumber ?? ""}  ${userData.floorDoor ?? ""}`
            : "-",
        ["Adószám"]: userData.taxNumber,
        ["Megjegyzés"]: userData.comment,
    };

    const dataAddress = {
        ["Ország"]: userData.addressCountry,
        // TODO: közterület jellegének input fieldjét pótolni
        ["Lakcím"]: userData.addressSettlement
            ? `${userData.addressZip ?? ""} ${
                  userData.addressSettlement ?? ""
              }, ${userData.addressStreet ?? ""} ${
                  userData.addressHouseNumber ?? ""
              }  ${userData.addressFloorDoor ?? ""}`
            : "-",
    };

    return (
        <CollapseBox
            title="Számlázási adatok"
            classInput={styles.scope}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
        >
            {isEditing ? (
                <>
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody id="billing-form">
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm={2}>
                                                <label>Számlázási adatok</label>
                                            </Col>
                                            <Col sm={10}>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="cards"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Hozzáadott kártyák
                                                    </label>

                                                    <Col sm={10}>
                                                        {cards?.length === 0 ? (
                                                            <input
                                                                className="form-control default-info"
                                                                type="text"
                                                                placeholder="Nincs hozzáadott kártya."
                                                                readOnly
                                                            />
                                                        ) : null}
                                                        {cards?.length ? (
                                                            cards.map(
                                                                (
                                                                    card,
                                                                    index
                                                                ) => (
                                                                    <Row
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="mt-2"
                                                                    >
                                                                        <Col
                                                                            sm={
                                                                                4
                                                                            }
                                                                        >
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder={
                                                                                        "Kártya neve: " +
                                                                                        card.name
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col
                                                                            sm={
                                                                                4
                                                                            }
                                                                        >
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder={
                                                                                        "Hozzádva: " +
                                                                                        card.created_at
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col
                                                                            sm={
                                                                                4
                                                                            }
                                                                        >
                                                                            <div className="form-group">
                                                                                {card.id ==
                                                                                userData.defaultCardId ? (
                                                                                    <input
                                                                                        className="form-control default-info"
                                                                                        type="text"
                                                                                        placeholder="Alapértelmezett"
                                                                                        readOnly
                                                                                    />
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() =>
                                                                                            setCardToDefault(
                                                                                                card.id
                                                                                            )
                                                                                        }
                                                                                        className="btn btn-primary"
                                                                                    >
                                                                                        Alapértelmezett
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Col>
                                                </Row>

                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="billing-name"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Név
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="billing-name"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a számlázási nevet!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.billingName
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "billingName",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="billing-name"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="country"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Ország
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="country"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg az országot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.country
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "country",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="country"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="zipcode"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Irányítószám
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="zipcode"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg az irányítószámot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.zipcode
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "zipcode",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="zipcode"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="settlement"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Város
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="settlement"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a város!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.settlement
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "settlement",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="settlement"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="street"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Utca
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="street"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a címet!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.street
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "street",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="street"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="houseNumber"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Házszám
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="houseNumber"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a házszámot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.houseNumber
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "houseNumber",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="houseNumber"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="floorDoor"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Emelet / Ajtó
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="floorDoor"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a házszámot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.floorDoor
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "floorDoor",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="floorDoor"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="taxNumber"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Adószám
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            className="form-control"
                                                            name="taxNumber"
                                                            type="text"
                                                            value={
                                                                userData.taxNumber
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "taxNumber",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="taxNumber"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="comment"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Megjegyzés
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            className="form-control"
                                                            name="comment"
                                                            type="text"
                                                            value={
                                                                userData.comment
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "comment",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="comment"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={2}>
                                                <label>Lakcím adatok</label>
                                            </Col>
                                            <Col sm={10}>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="address-country"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Ország
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="address-country"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg az országot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.addressCountry
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "addressCountry",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="address-country"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="address-zip"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Irányítószám
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="address-zip"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg az irányítószámot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.addressZip
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "addressZip",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="address-zip"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="address-settlement"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Város
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="address-settlement"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a várost!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.addressSettlement
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "addressSettlement",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="address-settlement"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="address-street"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Utca
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="address-street"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a címet!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.addressStreet
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "addressStreet",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="address-street"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="address-houseNumber"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Házszám
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="address-houseNumber"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a házszámot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.addressHouseNumber
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "addressHouseNumber",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="address-houseNumber"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label
                                                        htmlFor="address-floorDoor"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        Emelet / Ajtó
                                                    </label>
                                                    <Col sm={10}>
                                                        <AvField
                                                            name="address-floorDoor"
                                                            validate={{
                                                                required: {
                                                                    value: false,
                                                                },
                                                            }}
                                                            errorMessage="Adja meg a házszámot!"
                                                            className="form-control"
                                                            type="text"
                                                            value={
                                                                userData.addressFloorDoor
                                                            }
                                                            onChange={(event) =>
                                                                setUserDataByKey(
                                                                    "addressFloorDoor",
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                            id="address-floorDoor"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {(props.access.superAdmin == "1" ||
                                            props.access.callCenter == "1") && (
                                            <>
                                                <hr></hr>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Mentés
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn float-right"
                                                    onClick={handleDelete}
                                                >
                                                    <p className="delete-button-text">
                                                        Felhasználó törlése
                                                    </p>
                                                </button>
                                            </>
                                        )}
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </>
            ) : (
                <>
                    <ReadOnlyFieldList data={dataBilling} />
                    <hr />
                    <ReadOnlyFieldList data={dataAddress} />
                </>
            )}
        </CollapseBox>
    );
};

export default BillingInfo;
