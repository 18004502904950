import { useEffect, useRef } from "react";

export const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export const formatNumber = (x) =>
    x || x === 0 ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";

export const returnWithZero = (number) => (number < 10 ? "0" + number : number);

export const getTimeOfDate = (date) =>
    returnWithZero(date.getHours()) + ":" + returnWithZero(date.getMinutes());

export const DAYS = [
    "Hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
    "Vasárnap",
];

export const MOBILE_DAYS = ["H", "K", "SZ", "CS", "P", "SZ", "V"];

export const MONTHS = [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
];

export const getDayDate = (dayOfWeek, weekStart) => {
    const startDate = new Date(weekStart);
    startDate.setDate(startDate.getDate() + dayOfWeek);

    return (
        returnWithZero(startDate.getMonth() + 1) +
        "." +
        returnWithZero(startDate.getDate())
    );
};

export const isMobile = () => window.innerWidth <= 599;

export const getWeekNumber = (inputDate = null) => {
    let now = inputDate ?? new Date();
    const d = new Date(
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const getMonday = (d = null) => {
    if (d) {
        d = new Date(d);
    } else {
        d = new Date();
    }
    const day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    return new Date(d.setDate(diff));
};

export const convertDate = (
    date = new Date(),
    separator = "-",
    withHours = false
) => {
    try {
        if (!withHours) {
            return (
                date.getFullYear() +
                separator +
                returnWithZero(date.getMonth() + 1) +
                separator +
                returnWithZero(date.getDate())
            );
        }

        // console.log(date);
        return (
            date.getFullYear() +
            separator +
            returnWithZero(date.getMonth() + 1) +
            separator +
            returnWithZero(date.getDate()) +
            " " +
            returnWithZero(date.getHours()) +
            ":" +
            returnWithZero(date.getMinutes())
        );
    } catch (error) {
        return null;
    }
};

export const getURLParams = (url) => {
    const query = new URLSearchParams(url);

    return query.entries();
};

export const getURLParamsByKey = (url, key) => {
    const params = getURLParams(url);
    for (let p of params) {
        if (p[0] == key) {
            return p[1];
        }
    }

    return null;
};
