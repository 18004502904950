import { useEffect, useState } from "react";
import { Button, Card, CardBody, Input } from "reactstrap";
import { responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import InfoComponent from "../InfoComponent/InfoComponent";
import EditBtn from "../EditBtn/EditBtn";
import NewItemBtn from "../NewItemBtn/NewItemBtn";
import CustomSelect from "../../CustomSelect/CustomSelect";
import ExamRow from "./ExamRow/ExamRow";
import classes from "./ExaminationsCard.module.css";

const ExaminationsCard = (props) => {
    const allExams = props.examData.exams;
    const caseId = props.patientCase.id;
    const [editMode, setEditMode] = useState(false);
    const [selectedClassification, setSelectedClassification] = useState(
        props.patientCase.surgery_classification || "0"
    );
    const [classifications, setClassifications] = useState([]);
    const [namedExams, setNamedExams] = useState([]);
    const [otherExams, setOtherExams] = useState([]);
    const qualificationId = props.patientCase.qualification_id;

    const [addNamed, setAddNamed] = useState(null);
    const [addCustom, setAddCustom] = useState("");
    const [addOther, setAddOther] = useState("");

    const [addNewNamed, setAddNewNamed] = useState(true);

    useEffect(() => {
        filterClassifications();
        getNamedExams();
        getOtherExams();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdate = async () => {
        let _otherExams = otherExams;
        let _namedExams = namedExams;

        if (addOther) {
            const examName = addOther;

            let exams = [...otherExams];
            exams.push({
                case_id: caseId,
                exam_id: null,
                exam_name: examName,
                is_ok: "0",
                is_ready: "0",
            });
            _otherExams = exams;
        }

        if (addNewNamed && addCustom) {
            let exams = [...namedExams];
            exams.push({
                case_id: caseId,
                exam_id: "0",
                exam_name: addCustom,
                is_ok: "0",
                is_ready: "0",
            });
            _namedExams = exams;
        }

        post("admin/update-case-exams", {
            caseId,
            classification: selectedClassification,
            namedExams: _namedExams,
            otherExams: _otherExams,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        props.refreshPage();
                        endLoading();
                        setEditMode(false);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleStatusUpdate = (examId, readyValue, okValue) =>
        post(
            "admin/update-exam-status",
            {
                caseId,
                examId,
                okValue,
                readyValue,
            },
            true
        )
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        props.refreshPage();
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const getClassName = (id) => {
        if (!id || id == "0") {
            return "-";
        }
        const _class = props.examData.classifications.find((c) => c.id == id);

        return _class.name;
    };

    const getNamedExams = () => {
        const _exams = props.examData.caseExams.filter(
            (e) => e.exam_id != null
        );
        setNamedExams(_exams);
    };

    const getOtherExams = () => {
        const _exams = props.examData.caseExams.filter(
            (e) => e.exam_id == null
        );
        setOtherExams(_exams);
    };

    const filterClassifications = () => {
        const _classes = props.examData.classifications.filter((c) => {
            if (qualificationId == "27") {
                return c.qualification_id == "27";
            }

            return c.qualification_id != "27";
        });
        setClassifications(_classes);
    };

    const onAddNamedExam = () => {
        if (!addNamed) {
            return;
        }
        const examId = addNamed.value;
        const examName = addNamed.label;

        let exams = [...namedExams];
        exams.push({
            case_id: caseId,
            exam_id: examId,
            exam_name: examName,
            is_ok: "0",
            is_ready: "0",
        });
        setNamedExams(exams);
        setAddNamed(null);
    };

    const onAddOtherExam = () => {
        if (!addOther) {
            return;
        }
        const examName = addOther;

        let exams = [...otherExams];
        exams.push({
            case_id: caseId,
            exam_id: null,
            exam_name: examName,
            is_ok: "0",
            is_ready: "0",
        });
        setAddOther("");
        setOtherExams(exams);
    };

    const onDeleteNamedExam = (examIndex) => {
        let exams = [...namedExams];
        const filtered = exams.filter((e, index) => index != examIndex);
        setNamedExams(filtered);
    };

    const onDeleteOtherExam = (examIndex) => {
        let exams = [...otherExams];
        const filtered = exams.filter((e, index) => index != examIndex);
        setOtherExams(filtered);
    };

    const onAddCustom = () => {
        if (addCustom) {
            let exams = [...namedExams];
            exams.push({
                case_id: caseId,
                exam_id: "0",
                exam_name: addCustom,
                is_ok: "0",
                is_ready: "0",
            });
            setNamedExams(exams);
            setAddCustom("");
        }
    };

    useEffect(() => {
        if (addNamed) {
            setAddNewNamed(false);
            onAddNamedExam();
        }
    }, [addNamed]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedClassification != "1" && editMode) {
            // console.log('run');
            const _filtered = props.examData.defaultExams.filter(
                (e) => e.classification_id == selectedClassification
            );
            let _exams = [];
            _filtered.forEach((f) => {
                let foundExam = allExams.find((e) => e.id == f.examination_id);
                let examName = foundExam.name;

                let data = {
                    case_id: caseId,
                    exam_id: f.examination_id,
                    exam_name: examName,
                    is_ok: "0",
                    is_ready: "0",
                };
                _exams.push(data);
            });
            setNamedExams(_exams);
        }
    }, [selectedClassification]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        <span>Vizsgálatok</span>
                    </div>
                    {!editMode && (
                        <div>
                            <EditBtn onClick={() => setEditMode(true)} />
                        </div>
                    )}
                </div>
                <InfoComponent
                    title="Besorolás"
                    info={getClassName(selectedClassification)}
                    editMode={editMode}
                    notEditable={false}
                    editComponent={
                        <Input
                            type="select"
                            value={selectedClassification}
                            onChange={(e) =>
                                setSelectedClassification(e.target.value)
                            }
                        >
                            <option disabled selected value={"0"}>
                                Válasszon besorolást
                            </option>
                            {classifications.map((c) => (
                                <option key={c.id} value={c.id}>
                                    {c.name}
                                </option>
                            ))}
                        </Input>
                    }
                />
                <div>
                    {namedExams.map((e, index) => (
                        <ExamRow
                            key={index}
                            name={e.exam_name}
                            editMode={editMode}
                            onDeleteExam={() => onDeleteNamedExam(index)}
                            isReady={e.is_ready}
                            isOk={e.is_ok}
                            onChangeStatus={(ready, ok) =>
                                handleStatusUpdate(e.id, ready, ok)
                            }
                        />
                    ))}
                </div>
                {editMode && (
                    <>
                        <div style={{ marginTop: "15px" }}>
                            {addNewNamed ? (
                                <>
                                    <CustomSelect
                                        value={addNamed}
                                        isMulti={false}
                                        placeholder="Válasszon vizsgálatot..."
                                        options={allExams.map((e) => ({
                                            value: e.id,
                                            label: e.name,
                                        }))}
                                        onChange={(e) => setAddNamed(e)}
                                    />
                                    <Input
                                        type="text"
                                        placeholder="Egyedi vizsgálat"
                                        value={addCustom}
                                        onChange={(e) =>
                                            setAddCustom(e.target.value)
                                        }
                                        style={{ marginTop: "15px" }}
                                    ></Input>
                                </>
                            ) : null}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <NewItemBtn
                                text={`+ Mező hozzáadása`}
                                onClick={
                                    // () => onAddNamedExam()
                                    () => {
                                        onAddCustom();
                                        setAddNewNamed(true);
                                    }
                                }
                            />
                        </div>
                    </>
                )}
                <div
                    style={{
                        marginBottom: "15px",
                        borderTop: "2px solid black",
                    }}
                >
                    {otherExams.map((e, index) => (
                        <ExamRow
                            key={index}
                            name={e.exam_name}
                            editMode={editMode}
                            onDeleteExam={() => onDeleteOtherExam(index)}
                            isReady={e.is_ready}
                            isOk={e.is_ok}
                            onChangeStatus={(ready, ok) =>
                                handleStatusUpdate(e.id, ready, ok)
                            }
                        />
                    ))}
                </div>
                {editMode && (
                    <>
                        <Input
                            type="text"
                            value={addOther}
                            onChange={(e) => setAddOther(e.target.value)}
                            style={{ marginTop: "15px" }}
                        ></Input>
                        <div style={{ marginTop: "15px" }}>
                            <NewItemBtn
                                text={`+ Mező hozzáadása`}
                                onClick={() => {
                                    onAddOtherExam();
                                    // setAddNewOther(true);
                                }}
                            />
                        </div>
                    </>
                )}
                {editMode && (
                    <div className={classes.btnWrapper}>
                        <Button color="primary" onClick={() => handleUpdate()}>
                            Változtatások mentése
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setEditMode(false);
                                getNamedExams();
                                getOtherExams();
                            }}
                        >
                            Mégse
                        </Button>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default ExaminationsCard;
