import { Fragment, useRef, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import classes from "./CallCard.module.css";

const CallCard = (props) => {
    const userId = props.call.caller_id;
    let statusName;

    if (props.statusCodes) {
        const statusCode = props.statusCodes.filter(
            (item) => item.status == props.call.status
        );
        statusName = statusCode[0].meaning;
    }

    const [recommendation, setRecommendation] = useState(false);
    const [service, setService] = useState("");
    const [showComments, setShowComments] = useState(false);
    const [newComment, setNewComment] = useState("");

    const [selectedQualification, setSelectedQualification] = useState(null);
    const [selectedSymptom, setSelectedSymptom] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedSpecialization, setSelectedSpecialization] = useState(null);

    const select1 = useRef();
    const select2 = useRef();
    const select3 = useRef();

    const onChangeService = (service) => {
        setService(service);
        setSelectedQualification(null);
        setSelectedSymptom(null);
        setSelectedDoctor(null);
        setSelectedSpecialization(null);
        if (select1.current && select2.current && select3.current) {
            select1.current.state.value = null;
            select2.current.state.value = null;
            select3.current.state.value = null;
        }
    };

    const toggleRecommendation = (index) => {
        if (recommendation === index) {
            setRecommendation(false);
        } else {
            setRecommendation(index);
        }
    };

    const toggleComments = (index) => {
        if (index === showComments) {
            setShowComments(null);
        } else {
            setShowComments(index);
        }
    };

    const createComment = () => {
        if (!newComment) {
            return;
        }
        props.createComment(props.call.caller_id, props.call.id, newComment);
        setNewComment("");
    };

    const onSendServiceData = () => {
        const data = {
            userId,
            service,
            qualification: selectedQualification
                ? selectedQualification.qualification
                : null,
            qualificationId: selectedQualification
                ? selectedQualification.qualification_id
                : null,
            specialization: selectedSpecialization
                ? selectedSpecialization.specialization
                : null,
            specializationId: selectedSpecialization
                ? selectedSpecialization.specialization_id
                : null,
            symptom: selectedSymptom ? selectedSymptom.symptom : null,
            symptomId: selectedSymptom ? selectedSymptom.symptom_id : null,
            doctor: selectedDoctor ? selectedDoctor.doctor : null,
            doctorId: selectedDoctor ? selectedDoctor.doctor_id : null,
        };

        props.onSendServiceData(data);
    };

    const handleCallBack = () =>
        props.handleCallBack(props.call.caller_phone, props.call.id);

    const startDate = new Date(`${props.call.created_at}Z`);

    const startDateString = `${startDate.toLocaleDateString(
        "hu-HU",
        {}
    )} ${startDate.toLocaleTimeString("hu-HU", {})}`;

    return (
        <li
            className="list-group-item channelListItem"
            style={
                props.call.status == "1002" ? { border: "1px solid red" } : null
            }
        >
            <Row>
                <Col sm={3}>
                    <p className={classes.text}>
                        <strong>Hívó:</strong> {props.call.last_name}{" "}
                        {props.call.first_name}
                    </p>
                </Col>
                <Col sm={3}>
                    <p className={classes.text}>
                        <strong>Telefonszám:</strong> {props.call.caller_phone}
                    </p>
                </Col>
                <Col sm={3}>
                    <p className={classes.text}>
                        <strong>Születési dátum:</strong>{" "}
                        {props.call.birth_date}
                    </p>
                </Col>
                <Col sm={3}>
                    <p className={classes.text}>
                        <strong>Hívás kezdete:</strong> {startDateString}
                    </p>
                </Col>
            </Row>
            <hr></hr>
            {props.call.status == "1000" || props.call.status == "1002" ? (
                <div>
                    <Row>
                        <Col>
                            <p className={classes.text}>
                                <strong>Fogadó:</strong> {props.call.otherName}
                            </p>
                        </Col>
                    </Row>
                    <hr></hr>
                </div>
            ) : null}
            {props.call.status == "3" ||
            props.call.status == "3001" ||
            props.call.status == "1001" ||
            props.call.status == "1002" ||
            props.call.status == "3003" ||
            props.call.status == "1004" ? (
                <div>
                    <Row>
                        <Col sm={3}>
                            <p className={classes.text}>
                                <strong>Státusz:</strong> {statusName}
                            </p>
                        </Col>
                        {props.call.status == "1002" ||
                        props.call.status == "3003" ||
                        props.call.status == "1004" ? (
                            <Col sm={3}>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleCallBack}
                                >
                                    Visszahívás
                                </button>
                            </Col>
                        ) : null}
                    </Row>
                    <hr></hr>
                </div>
            ) : null}
            <Row>
                <Col sm={3}>
                    <p className={classes.text}>
                        <strong>Műveletek:</strong>
                    </p>
                </Col>
                <Col sm={3}>
                    <Link to={"/users/" + props.call.caller_id}>
                        Páciens adatlap
                    </Link>
                </Col>
                <Col sm={3}>
                    <Link
                        to={"#"}
                        onClick={() => {
                            setService("");
                            toggleRecommendation(props.index);
                        }}
                    >
                        Szolgáltatás ajánlás
                    </Link>
                </Col>
                <Col sm={3}>
                    <Link to={`/reservation?type=both&patient=${userId}`}>
                        <button className="btn btn-primary">
                            Időpontfoglalás
                        </button>
                    </Link>
                </Col>
            </Row>
            {recommendation === props.index ? (
                <div>
                    <hr></hr>
                    <Row>
                        <Col sm={3}>
                            <p className={classes.text}>
                                <strong>Válasszon szolgáltatást!</strong>
                            </p>
                        </Col>
                        <Col sm={3}>
                            <FormGroup style={{ marginBottom: "0.5rem" }}>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    onChange={(e) =>
                                        onChangeService(e.target.value)
                                    }
                                >
                                    <option value="" selected disabled>
                                        Válasszon szolgáltatást...
                                    </option>
                                    <option value="HOTLINE">
                                        Hotline hívása
                                    </option>
                                    <option value="DUTY">Ügyelet hívása</option>
                                    <option value="CHAT">Üzenetváltás</option>
                                    <option value="SECOND_OPINION">
                                        Másodvélemény
                                    </option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {service === "HOTLINE" ? (
                        <div>
                            <Row>
                                <Col>
                                    <Button
                                        color="primary"
                                        onClick={onSendServiceData}
                                    >
                                        Küldés
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => setRecommendation(false)}
                                    >
                                        Vissza
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {service === "DUTY" ? (
                        <div>
                            <Row>
                                <Col>
                                    <Button
                                        color="primary"
                                        onClick={onSendServiceData}
                                    >
                                        Küldés
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => setRecommendation(false)}
                                    >
                                        Vissza
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {service === "CHAT" || service === "SECOND_OPINION" ? (
                        <div>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Select
                                            ref={select1}
                                            placeholder="Válasszon szakterületet..."
                                            noOptionsMessage={() =>
                                                "Nincs találat"
                                            }
                                            options={props.qualifications.map(
                                                (q) => ({
                                                    value: q.id,
                                                    label: q.name,
                                                })
                                            )}
                                            onChange={(e) =>
                                                setSelectedQualification({
                                                    qualification_id: e.value,
                                                    qualification: e.label,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Select
                                            ref={select2}
                                            placeholder="Válasszon tünetet..."
                                            noOptionsMessage={() =>
                                                "Nincs találat"
                                            }
                                            options={props.symptoms.map(
                                                (s) => ({
                                                    value: s.id,
                                                    label: s.name,
                                                })
                                            )}
                                            onChange={(e) =>
                                                setSelectedSymptom({
                                                    symptom_id: e.value,
                                                    symptom: e.label,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Select
                                            ref={select3}
                                            placeholder="Válasszon orvost..."
                                            noOptionsMessage={() =>
                                                "Nincs találat"
                                            }
                                            options={props.doctors.map((d) => ({
                                                value: d.doctor_id,
                                                label: `${d.last_name} ${d.first_name}`,
                                            }))}
                                            onChange={(e) =>
                                                setSelectedDoctor({
                                                    doctor_id: e.value,
                                                    doctor: e.label,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Button
                                        color="primary"
                                        onClick={onSendServiceData}
                                    >
                                        Küldés
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => setRecommendation(false)}
                                    >
                                        Vissza
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                </div>
            ) : null}
            <hr></hr>
            <Row>
                <Col>
                    <Link
                        to={"#"}
                        onClick={() =>
                            toggleComments(props.index, props.call.caller_id)
                        }
                    >
                        Kommentek
                    </Link>
                </Col>
            </Row>
            {showComments == props.index ? (
                <>
                    <br></br>
                    {props.call.comments ? (
                        <Row>
                            {props.call.comments.map((comment, index) => (
                                <Fragment key={index}>
                                    <Col sm={2}>
                                        <p className={classes.text}>
                                            {comment.name}
                                        </p>
                                    </Col>
                                    <Col sm={2}>
                                        <p className={classes.text}>
                                            {comment.created_at}
                                        </p>
                                    </Col>
                                    <Col
                                        sm={8}
                                        style={{ marginBottom: "1rem" }}
                                    >
                                        <p className={classes.text}>
                                            {comment.comment}
                                        </p>
                                    </Col>
                                </Fragment>
                            ))}
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <p
                                    className={classes.text}
                                    style={{ marginBottom: "2rem" }}
                                >
                                    Nincs megjeleníthető komment.
                                </p>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col sm={2}>
                            <p className={classes.text}>
                                <strong>Komment írása:</strong>
                            </p>
                        </Col>
                        <Col sm={8}>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    value={newComment}
                                    onChange={(e) =>
                                        setNewComment(e.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Button
                                color="primary"
                                onClick={() => createComment()}
                            >
                                Küldés
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </li>
    );
};

export default CallCard;
