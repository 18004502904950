export const EVENTS = {
    REGISTRATION: "Regisztráció",
    LOGIN: "Bejelentkezés",
    PASSWORD_CHANGE: "Jelszó változtatás",
    CHAT: "Üzenetváltás",
};

export const ALERT_MESSAGES = {
    errorTryAgain: "Valami hiba történt. Kérjük, próbálja meg újból!",
};

export const MONTHS = [
    "január",
    "február",
    "március",
    "április",
    "május",
    "június",
    "július",
    "augusztus",
    "szeptember",
    "október",
    "november",
    "december",
];

export const serviceNames = {
    HOTLINE: "Egészségügyi hotline",
    DUTY: "Általános orvosi konzultáció",
    OUTPATIENT: "Járóbeteg ellátás",
    VIDEO_CONSULTATION: "Videókonzultáció",
    CHAT: "Orvosi chat",
    SECOND_OPINION: "Másodvélemény",
    PRESCRIPTION: "E-recept",
};

export const PACKAGES = {
    1: "Előfizetés nélkül",
    2: "Basic",
    3: "Optimum",
    4: "Premium",
};

export const NH_PACKAGES = {
    diabetes: "Diabétesz csomag",
    pregnancy: "Várandósgondozás",
    basic: "Alap csomag",
};

export const PriceApproval = {
    KE: "ke", // Kézi
    VE: "ve", // Általános FIX %
    V0: "v0", // Általános 0% / Teljes áras
    KG: "kg", // Közgyógy
    TK: "tk", // EÜ. tér. köt.
    TM: "tm", // EÜ. rend.
    HV: "hv", // HM.
    UB: "ub", // ÜB.
    K9: "k9", // Közgyógy/EÜ tér.köt
    AG: "ag", // Állatgyógyszer
    K0: "k0", // Közgyógy/EÜ tér.ment.
    AV: "av", // Állatgyógyszer
    KV: "kv", // Külföldi vény
};

export const COUPON_TYPES = [
    { id: "1", type: "Előfizetés - X ideig" },
    { id: "2", type: "Előfizetés - X összeg" },
    { id: "3", type: "Előfizetés - X százalék" },
    { id: "4", type: "Szolgáltatás - X ideig" },
    { id: "5", type: "Szolgáltatás - X összeg" },
    { id: "6", type: "Szolgáltatás - X százalék" },
];

export const SERVICES = [
    { id: "HOTLINE", name: "Hotline hívás" },
    { id: "DUTY", name: "Ügyelet hívás" },
    { id: "OUTPATIENT", name: "Időpontfoglalás" },
    { id: "VIDEO_CONSULTATION", name: "Videó konzultáció" },
    { id: "CHAT", name: "Üzenetváltás" },
    { id: "SECOND_OPINION", name: "Másodvélemény" },
];
export const LANGUAGES = [
    { value: "HU", label: "Magyar" },
    { value: "AF", label: "Afrikanns" },
    { value: "SQ", label: "Albán" },
    { value: "AR", label: "Arab" },
    { value: "HY", label: "Örmény" },
    { value: "EU", label: "Basque" },
    { value: "BN", label: "Bengáli" },
    { value: "BG", label: "Bolgár" },
    { value: "CA", label: "Katalán" },
    { value: "KM", label: "Khmer" },
    { value: "ZH", label: "Kínai (Mandarin)" },
    { value: "HR", label: "Horvát" },
    { value: "CS", label: "Cseh" },
    { value: "DA", label: "Dán" },
    { value: "NL", label: "Holland" },
    { value: "EN", label: "Angol" },
    { value: "ET", label: "Észt" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finn" },
    { value: "FR", label: "Francia" },
    { value: "KA", label: "Grúz" },
    { value: "DE", label: "Német" },
    { value: "EL", label: "Görög" },
    { value: "GU", label: "Gudzsaráti" },
    { value: "HE", label: "Héber" },
    { value: "HI", label: "Hindi" },
    { value: "IS", label: "Izlandi" },
    { value: "ID", label: "Indonéz" },
    { value: "GA", label: "Ír" },
    { value: "IT", label: "Olasz" },
    { value: "JA", label: "Japán" },
    { value: "JW", label: "Jávai" },
    { value: "KO", label: "Koreai" },
    { value: "LA", label: "Latin" },
    { value: "LV", label: "Lett" },
    { value: "LT", label: "Litván" },
    { value: "MK", label: "Macedón" },
    { value: "MS", label: "Maláj" },
    { value: "ML", label: "Malajálam" },
    { value: "MT", label: "Máltai" },
    { value: "MI", label: "Maori" },
    { value: "MR", label: "Maráthi" },
    { value: "MN", label: "Mongol" },
    { value: "NE", label: "Nepáli" },
    { value: "NO", label: "Norvég" },
    { value: "FA", label: "Perzsa" },
    { value: "PL", label: "Lengyel" },
    { value: "PT", label: "Portugál" },
    { value: "PA", label: "Pandzsábi" },
    { value: "QU", label: "Kecsua" },
    { value: "RO", label: "Román" },
    { value: "RU", label: "Orosz" },
    { value: "SM", label: "Szamoai" },
    { value: "SR", label: "Szerb" },
    { value: "SK", label: "Szlovák" },
    { value: "SL", label: "Szlovén" },
    { value: "ES", label: "Spanyol" },
    { value: "SYC", label: "Szír" },
    { value: "SW", label: "Szuahéli" },
    { value: "SV", label: "Svéd " },
    { value: "TA", label: "Tamil" },
    { value: "TT", label: "Tatár" },
    { value: "TE", label: "Telugu" },
    { value: "TH", label: "Thai" },
    { value: "BO", label: "Tibeti" },
    { value: "TO", label: "Tonga" },
    { value: "TR", label: "Török" },
    { value: "UK", label: "Ukrán" },
    { value: "UR", label: "Urdu" },
    { value: "UZ", label: "Üzbég" },
    { value: "VI", label: "Vietnámi" },
    { value: "CY", label: "Walesi" },
    { value: "XH", label: "Xhosza" },
];

export const DAYS = [
    "Vasárnap",
    "Héfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
];
