import { useEffect, useMemo, useState } from "react";
import { Card, CardFooter, CardHeader } from "reactstrap";
import { Icons } from "../../../assets/Theme";
import { endLoading, get } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import styles from "./PatientBrief.module.css";

const PatientBrief = ({ userId }) => {
    const [userData, setUserData] = useState(null);

    const processedData = useMemo(
        () =>
            userData
                ? {
                      name:
                          userData.nameFull ||
                          `${userData.nameLast} ${userData.nameFirst}`,
                      avatarUrl:
                          userData.avatarUrl ||
                          (userData.gender === "Férfi" && Icons.maleIcon) ||
                          (userData.gender === "Nő" && Icons.femaleIcon) ||
                          "",
                  }
                : null,
        [userData]
    );

    useEffect(() => {
        get(`admin/user?id=${userId}`)
            .then((response) => {
                const fetchedUserData = response.data.user;
                setUserData(
                    fetchedUserData
                        ? {
                              avatarUrl: fetchedUserData.profile_image,
                              dateOfBirth: fetchedUserData.birth_date,
                              gender: fetchedUserData.gender,
                              insuranceNumber: fetchedUserData.security_number,
                              nameFirst: fetchedUserData.first_name,
                              nameFull: fetchedUserData.billingName,
                              nameLast: fetchedUserData.last_name,
                          }
                        : null
                );

                endLoading();
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card tag="section" className={styles.scope}>
            {processedData ? (
                <CardHeader tag="figure">
                    <img
                        src={processedData.avatarUrl}
                        alt={processedData.name}
                    />
                    <figcaption>{processedData.name}</figcaption>
                </CardHeader>
            ) : (
                <></>
            )}

            {userData ? (
                <CardFooter tag="dl">
                    <span>
                        <dt>Születési dátum</dt>
                        <dd>
                            {userData.dateOfBirth?.replaceAll("-", ". ") ?? "–"}
                        </dd>
                    </span>
                    <span>
                        <dt>TAJ szám</dt>
                        <dd>
                            {userData.insuranceNumber?.replaceAll("-", " ") ??
                                "–"}
                        </dd>
                    </span>
                </CardFooter>
            ) : (
                <></>
            )}
        </Card>
    );
};

export default PatientBrief;
