import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { endLoading, get } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import classes from "./ChannelList.module.css";

const ChannelList = (props) => {
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        get("admin/chat/channels")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        setChannels(
                            response.data.channels
                                .map((channel) => ({
                                    ...channel,
                                    orderDate: new Date(
                                        channel.date ?? channel.created_at
                                    ),
                                }))
                                .sort((a, b) => b.orderDate - a.orderDate)
                        );
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    return (
        <div className="container-fluid">
            <Row className="align-items-center">
                <Col sm={6}>
                    <div className="page-title-box">
                        <h4 className="font-size-18">Beszélgetések</h4>
                    </div>
                </Col>
            </Row>
            <div className="row">
                <div className="col-12">
                    <ul className="list-group">
                        {channels.map((channel, index) => {
                            if (channel.payed == "0") {
                                return;
                            }

                            // media is stored as JSON in the same field…
                            const media =
                                channel.message[0] === "{"
                                    ? JSON.parse(channel.message)
                                    : null;

                            let status;
                            if (channel.status == "0") {
                                status = "Üzenetváltás";
                            } else if (channel.status == "2") {
                                status = "Másodvélemény";
                            } else if (channel.status == "1") {
                                status = "Másodvéleménynek jelölve";
                            }

                            return (
                                <li
                                    key={index}
                                    className={
                                        (channel.doctorMessage.length === 0 ||
                                            channel.author.substring(0, 1) !==
                                                "d") &&
                                        channel.open == "1"
                                            ? "newChannel list-group-item channelListItem"
                                            : "list-group-item channelListItem"
                                    }
                                >
                                    <div>
                                        {props.isSuperAdmin && (
                                            <>
                                                <Row>
                                                    <Col sm={4}>
                                                        <p
                                                            className={
                                                                classes.text
                                                            }
                                                        >
                                                            <strong>
                                                                Orvos neve:{" "}
                                                            </strong>
                                                            {channel.title}{" "}
                                                            {
                                                                channel.doctorLastName
                                                            }{" "}
                                                            {
                                                                channel.doctorFirstName
                                                            }{" "}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <hr></hr>
                                            </>
                                        )}
                                        <Row>
                                            <Col sm={4}>
                                                <p className={classes.text}>
                                                    {channel.created_at}
                                                    {(channel.doctorMessage
                                                        .length === 0 ||
                                                        channel.author.substring(
                                                            0,
                                                            1
                                                        ) !== "d") &&
                                                    channel.open == "1" ? (
                                                        <span
                                                            className={
                                                                classes.new
                                                            }
                                                        >
                                                            <strong>ÚJ</strong>
                                                        </span>
                                                    ) : null}
                                                </p>
                                            </Col>
                                            <Col sm={4}>
                                                <p className={classes.text}>
                                                    <strong>Típus:</strong>{" "}
                                                    {status}{" "}
                                                </p>
                                            </Col>
                                            <Col sm={2}>
                                                <p className={classes.text}>
                                                    <strong>Státusz:</strong>{" "}
                                                    {channel.open == "1"
                                                        ? "Nyitott"
                                                        : "Lezárt"}{" "}
                                                </p>
                                            </Col>
                                            <Col sm={2}>
                                                <Link
                                                    to={
                                                        "channel/" +
                                                        channel.identifier
                                                    }
                                                >
                                                    Ugrás a beszélgetésre
                                                </Link>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col>
                                                <p className={classes.text}>
                                                    <strong>Páciens:</strong>{" "}
                                                    {channel.patientLastName}{" "}
                                                    {channel.patientFirstName}{" "}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className={classes.text}>
                                                    <strong>Tünetek:</strong>{" "}
                                                    {channel.symptoms}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className={classes.text}>
                                                    <strong>
                                                        Szakterület:
                                                    </strong>{" "}
                                                    {channel.qualification !=
                                                    "0"
                                                        ? channel.qualification
                                                        : "Nem meghatározott."}
                                                </p>
                                            </Col>
                                        </Row>
                                        {channel.author ? (
                                            <div>
                                                <hr></hr>
                                                <Row>
                                                    <Col sm={8}>
                                                        {media ? (
                                                            <Row>
                                                                <Col sm={2}>
                                                                    <p
                                                                        className={
                                                                            classes.text
                                                                        }
                                                                    >
                                                                        <strong>
                                                                            Utolsó
                                                                            üzenet:
                                                                        </strong>
                                                                    </p>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <p
                                                                        className={
                                                                            classes.text
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {channel.author.substring(
                                                                            0,
                                                                            1
                                                                        ) ===
                                                                        "d"
                                                                            ? "Ön"
                                                                            : "Páciens"}{" "}
                                                                    </p>
                                                                </Col>
                                                                <Col sm={8}>
                                                                    <p
                                                                        className={
                                                                            classes.text
                                                                        }
                                                                    >
                                                                        Fájl -{" "}
                                                                        {
                                                                            media.MediaFilename
                                                                        }
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <Row>
                                                                <Col sm={2}>
                                                                    <p
                                                                        className={
                                                                            classes.text
                                                                        }
                                                                    >
                                                                        <strong>
                                                                            Utolsó
                                                                            üzenet:
                                                                        </strong>
                                                                    </p>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <p
                                                                        className={
                                                                            classes.text
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {channel.author.substring(
                                                                            0,
                                                                            1
                                                                        ) ===
                                                                        "d"
                                                                            ? "Ön"
                                                                            : "Páciens"}{" "}
                                                                    </p>
                                                                </Col>
                                                                <Col sm={8}>
                                                                    <p
                                                                        className={
                                                                            classes.text
                                                                        }
                                                                    >
                                                                        {channel.message.substring(
                                                                            0,
                                                                            50
                                                                        ) +
                                                                            "..."}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <p
                                                            className={
                                                                classes.text
                                                            }
                                                        >
                                                            <strong>
                                                                Időpont:
                                                            </strong>{" "}
                                                            {channel.date}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ) : null}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isSuperAdmin: state.auth.access
        ? state.auth.access.superAdmin == "1"
        : null,
});

export default connect(mapStateToProps)(ChannelList);
