import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ChatRoom from "../ChatRoom/ChatRoom";

const PrescriptionPage = () => {
    const { identifier } = useParams();

    return (
        <div className="container-fluid">
            <Row>
                <Col>
                    <ChatRoom identifier={identifier} />
                </Col>
            </Row>
        </div>
    );
};

export default PrescriptionPage;
