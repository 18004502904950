import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import DatePicker from "../../DatePicker/DatePicker";
import { responseMessage } from "../../../utility";

const SubscriptionChange = (props) => {
    const infos = props.packageInfo;

    const [actualPackage, setActualPackage] = useState(infos.id.toString());
    const [nextPackage, setNextPackage] = useState(infos.nextMonthPackage);
    const [nextCharge, setNextCharge] = useState(infos.nextCharge);
    const [nextPayment, setNextPayment] = useState(infos.paymentNextDate);

    const handleSubmit = () => {
        const data = {
            actualPackage,
            nextPackage,
            nextCharge,
            nextPayment,
        };
        if (actualPackage == "1" && nextPackage == "1") {
            data.nextCharge = null;
            data.nextPayment = null;
        }
        if (actualPackage != "1" && nextPackage == "1") {
            data.nextCharge = null;
            if (nextPayment === null) {
                responseMessage("Az időszak végének megadása kötelező!");

                return;
            }
        }
        if (actualPackage != "1" && nextPackage != "1") {
            if (nextCharge === null || nextPayment === null) {
                responseMessage(
                    "A kártya terhelés és időszak végének megadása kötelező!"
                );

                return;
            }
        }
        if (actualPackage == "1" && nextPackage != "1") {
            if (nextCharge === null || nextPayment === null) {
                responseMessage(
                    "A kártya terhelés és időszak végének megadása kötelező!"
                );

                return;
            }
        }
        // console.log(data);
        props.handleSubscriptionChange(data);
    };

    const setMinDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        return tomorrow;
    };

    return (
        <Card>
            <CardBody>
                <h5>Előfizetés váltás</h5>
                <br></br>
                <AvForm onValidSubmit={handleSubmit}>
                    <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                            Aktuális előfizetés
                        </label>
                        <Col sm={10}>
                            <select
                                className="form-control"
                                value={actualPackage}
                                onChange={(e) =>
                                    setActualPackage(e.target.value)
                                }
                            >
                                <option value="1">Előfizetés nélkül</option>
                                <option value="2">Basic</option>
                                <option value="3">Optimum</option>
                                <option value="4">Premium</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                            Következő előfizetés
                        </label>
                        <Col sm={10}>
                            <select
                                className="form-control"
                                value={nextPackage}
                                onChange={(e) => setNextPackage(e.target.value)}
                            >
                                <option value="1">Előfizetés nélkül</option>
                                <option value="2">Basic</option>
                                <option value="3">Optimum</option>
                                <option value="4">Premium</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                            Kártya terhelés
                        </label>
                        <Col sm={10}>
                            <DatePicker
                                value={nextCharge}
                                onChange={(date) => setNextCharge(date)}
                                disableFuture={false}
                                disablePast={true}
                                minDate={setMinDate()}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                            Időszak vége
                        </label>
                        <Col sm={10}>
                            <DatePicker
                                value={nextPayment}
                                onChange={(date) => setNextPayment(date)}
                                disableFuture={false}
                                disablePast={true}
                                minDate={setMinDate()}
                            />
                        </Col>
                    </Row>
                    <hr></hr>
                    <button type="submit" className="btn btn-primary">
                        Váltás
                    </button>
                </AvForm>
            </CardBody>
        </Card>
    );
};

export default SubscriptionChange;
