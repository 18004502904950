import { useEffect, useState } from "react";
import { Button, Card, CardBody, Input } from "reactstrap";
import { responseMessage } from "../../../utility";
import { endLoading, post } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import InfoComponent from "../InfoComponent/InfoComponent";
import CustomSelect from "../../CustomSelect/CustomSelect";
import EditBtn from "../EditBtn/EditBtn";
import classes from "./SurgeryCard.module.css";

const SurgeryCard = (props) => {
    const data = props.data;
    const patientCase = props.patientCase;
    const originalData = props.caseSurgeryData;

    const getCaseManagersByQualificationAndInstitution = (qid, iid) => {
        post("admin/get-cmqi", { qid, iid })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setCaseManagerOptions(
                            caseManagersToOptions(response.data.caseManagers)
                        );
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const caseManagersToOptions = (caseManagers) =>
        caseManagers
            .map((c) => ({
                value: c.id,
                label: `${c.last_name} ${c.first_name}`,
            }))
            .filter((cm) => cm.label?.trim() !== "");

    const [caseManagerOptions, setCaseManagerOptions] = useState(
        caseManagersToOptions(data.caseManagers)
    );

    const [editMode, setEditMode] = useState(false);
    const [caseSurgeryData, setCaseSurgeryData] = useState(
        props.caseSurgeryData
    );

    const [customSurgery, setCustomSurgery] = useState(null);

    useEffect(() => {
        if (
            caseSurgeryData.qualification &&
            (caseSurgeryData.qualification.value == "27" ||
                caseSurgeryData.qualification.value == "26") &&
            typeof caseSurgeryData.surgeryType === "string"
        ) {
            setCaseSurgeryData({
                ...caseSurgeryData,
                surgeryType: {
                    value: "0",
                    label: (
                        <span style={{ fontWeight: "bold" }}>Egyedi műtét</span>
                    ),
                },
            });
            setCustomSurgery(caseSurgeryData.surgeryType);
        }

        if (caseSurgeryData.qualification && caseSurgeryData.institution) {
            getCaseManagersByQualificationAndInstitution(
                caseSurgeryData.qualification.value,
                caseSurgeryData.institution.value
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdate = () => {
        let surgeryTypeName;
        let surgeryTypeId;

        if (!caseSurgeryData.surgeryType) {
            surgeryTypeName = "";
            surgeryTypeId = null;
        } else {
            if (
                customSurgery &&
                caseSurgeryData.surgeryType !== "string" &&
                caseSurgeryData.surgeryType.value == "0"
            ) {
                surgeryTypeName = customSurgery;
                surgeryTypeId = null;
            } else {
                if (typeof caseSurgeryData.surgeryType === "string") {
                    surgeryTypeName = caseSurgeryData.surgeryType;
                    surgeryTypeId = null;
                } else {
                    surgeryTypeName = caseSurgeryData.surgeryType.label;
                    surgeryTypeId = caseSurgeryData.surgeryType.value;
                }
            }
        }

        let _data = {
            qualificationId: caseSurgeryData.qualification
                ? caseSurgeryData.qualification.value
                : null,
            doctorIds: caseSurgeryData.doctors
                ? caseSurgeryData.doctors.map((cm) => cm.value)
                : [],
            caseManagerIds: caseSurgeryData.caseManagers
                ? caseSurgeryData.caseManagers.map((cm) => cm.value)
                : [],
            institutionId: caseSurgeryData.institution
                ? caseSurgeryData.institution.value
                : null,
            // surgeryType:  caseSurgeryData.surgeryType,
            tools: caseSurgeryData.tools,
            description: caseSurgeryData.description,
            surgeryTypeName,
            surgeryTypeId,
        };

        post("admin/update-case-surgery", {
            caseId: patientCase.id,
            data: _data,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        props.refreshPage();
                        endLoading();
                        setEditMode(false);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const generateSurgeryTypes = (qualificationId, types) => {
        const _types = types.filter(
            (t) => t.qualification_id == qualificationId
        );
        const _arr = _types.map((t) => ({
            value: t.id,
            label: t.name,
        }));

        return [
            {
                value: "0",
                label: <span style={{ fontWeight: "bold" }}>Egyedi műtét</span>,
            },
            ..._arr,
        ];
    };

    return (
        <Card style={{ borderRadius: "10px" }}>
            <CardBody>
                <div className={classes.titleWrapper}>
                    <div className={classes.titleLeft}>
                        <span>Műtét adatai</span>
                    </div>
                    {!editMode && (
                        <div>
                            <EditBtn onClick={() => setEditMode(true)} />
                        </div>
                    )}
                </div>
                <InfoComponent
                    title="Szakterület"
                    info={
                        caseSurgeryData.qualification
                            ? caseSurgeryData.qualification.label
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <CustomSelect
                            value={caseSurgeryData.qualification}
                            isMulti={false}
                            placeholder="Válasszon szakterületet..."
                            options={data.qualifications.map((q) => ({
                                value: q.id,
                                label: q.name,
                            }))}
                            onChange={(e) => {
                                setCaseSurgeryData({
                                    ...caseSurgeryData,
                                    qualification: e,
                                    surgeryType: null,
                                });
                                if (caseSurgeryData.qualification) {
                                    getCaseManagersByQualificationAndInstitution(
                                        e.value,
                                        caseSurgeryData.institution.value
                                    );
                                }
                            }}
                        />
                    }
                />
                <InfoComponent
                    title="Orvosok"
                    info={
                        caseSurgeryData.doctors
                            ? caseSurgeryData.doctors
                                  .map((d) => d.label)
                                  .join(", ")
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <CustomSelect
                            value={caseSurgeryData.doctors}
                            isMulti={true}
                            placeholder="Válasszon orvost..."
                            options={data.doctors.map((d) => ({
                                value: d.id,
                                label: `${d.title} ${d.last_name} ${d.first_name}`,
                            }))}
                            onChange={(e) =>
                                setCaseSurgeryData({
                                    ...caseSurgeryData,
                                    doctors: e,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Esetmenedzser"
                    info={
                        caseSurgeryData.caseManagers
                            ? caseSurgeryData.caseManagers
                                  .map((cm) => cm.label)
                                  .join(", ")
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <CustomSelect
                            value={caseSurgeryData.caseManagers}
                            isMulti={true}
                            placeholder="Válasszon esetmenedzsert..."
                            options={caseManagerOptions}
                            onChange={(e) =>
                                setCaseSurgeryData({
                                    ...caseSurgeryData,
                                    caseManagers: e,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Helyszín"
                    info={
                        caseSurgeryData.institution
                            ? caseSurgeryData.institution.label
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <CustomSelect
                            value={caseSurgeryData.institution}
                            isMulti={false}
                            placeholder="Válasszon intézményt..."
                            options={data.institutions.map((q) => ({
                                value: q.id,
                                label: q.name,
                            }))}
                            onChange={(e) => {
                                setCaseSurgeryData({
                                    ...caseSurgeryData,
                                    institution: e,
                                });

                                if (caseSurgeryData.qualification) {
                                    getCaseManagersByQualificationAndInstitution(
                                        caseSurgeryData.qualification.value,
                                        e.value
                                    );
                                }
                            }}
                        />
                    }
                />
                {caseSurgeryData.qualification &&
                (caseSurgeryData.qualification.value == "26" ||
                    caseSurgeryData.qualification.value == "27") ? (
                    <InfoComponent
                        title="Műtét típusa"
                        info={
                            caseSurgeryData.surgeryType
                                ? caseSurgeryData.surgeryType.label
                                    ? caseSurgeryData.surgeryType.label
                                    : caseSurgeryData.surgeryType
                                : "-"
                        }
                        editMode={editMode}
                        editComponent={
                            <CustomSelect
                                value={caseSurgeryData.surgeryType}
                                isMulti={false}
                                placeholder="Válasszon műtét típust..."
                                options={generateSurgeryTypes(
                                    caseSurgeryData.qualification.value,
                                    props.surgeryTypes
                                )}
                                onChange={(e) =>
                                    setCaseSurgeryData({
                                        ...caseSurgeryData,
                                        surgeryType: e,
                                    })
                                }
                            />
                        }
                    />
                ) : (
                    <InfoComponent
                        title="Műtét típusa"
                        info={
                            caseSurgeryData.surgeryType !== ""
                                ? caseSurgeryData.surgeryType
                                : "-"
                        }
                        editMode={editMode}
                        editComponent={
                            <Input
                                value={caseSurgeryData.surgeryType}
                                onChange={(e) =>
                                    setCaseSurgeryData({
                                        ...caseSurgeryData,
                                        surgeryType: e.target.value,
                                    })
                                }
                            />
                        }
                    />
                )}
                {caseSurgeryData.surgeryType &&
                    caseSurgeryData.surgeryType.value == "0" && (
                        <InfoComponent
                            title="Egyedi műtét neve"
                            info={customSurgery}
                            editMode={editMode}
                            editComponent={
                                <Input
                                    value={customSurgery}
                                    onChange={(e) =>
                                        setCustomSurgery(e.target.value)
                                    }
                                />
                            }
                        />
                    )}

                <InfoComponent
                    title="Eszközök"
                    info={caseSurgeryData.tools ?? "-"}
                    editMode={editMode}
                    editComponent={
                        <Input
                            value={caseSurgeryData.tools}
                            onChange={(e) =>
                                setCaseSurgeryData({
                                    ...caseSurgeryData,
                                    tools: e.target.value,
                                })
                            }
                        />
                    }
                />
                <InfoComponent
                    title="Leírás"
                    info={
                        caseSurgeryData.description &&
                        caseSurgeryData.description !== ""
                            ? caseSurgeryData.description
                            : "-"
                    }
                    editMode={editMode}
                    editComponent={
                        <Input
                            type="textarea"
                            rows={4}
                            value={caseSurgeryData.description}
                            onChange={(e) =>
                                setCaseSurgeryData({
                                    ...caseSurgeryData,
                                    description: e.target.value,
                                })
                            }
                        />
                    }
                />
                {editMode && (
                    <div className={classes.btnWrapper}>
                        <Button color="primary" onClick={() => handleUpdate()}>
                            Változtatások mentése
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                setEditMode(false);
                                setCaseSurgeryData(originalData);
                            }}
                        >
                            Mégse
                        </Button>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default SurgeryCard;
