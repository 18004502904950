import { Fragment } from "react";
import { Col, Row } from "reactstrap";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import { serviceNames } from "../../../../config/variables";
import { convertDate } from "../../../../utility";
import styles from "./ServicesUsed.module.css";

const getRemainingService = (extraServices, serviceName) => {
    let remaining = 0;
    const services = extraServices.filter(
        (s) => s.service_name === serviceName && s.available == "1"
    );

    for (const service of services) {
        remaining += service.quantity ? service.quantity : 1;
    }

    return remaining;
};

const ServicesUsed = ({ propsBus }) => {
    const { extraServices, userEvents, packageInfo } = propsBus;

    // Copied from src/components/UserComponents/ServiceInfos/ServiceInfos.jsx

    const endDate = new Date(packageInfo.paymentNextDate);
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);

    // #####################

    let remainingChat = getRemainingService(extraServices, "CHAT");

    if (
        userEvents.filter((e) => e.event_name === "CHAT").length >=
        packageInfo.freeChatPerMonth
    )
        remainingChat -= packageInfo.freeChatPerMonth;

    if (remainingChat < 0) remainingChat = 0;

    // #####################

    let remainingDuty = getRemainingService(extraServices, "DUTY");

    if (
        userEvents.filter((e) => e.event_name === "DUTY").length >=
        packageInfo.freeDutyPerMonth
    )
        remainingDuty -= packageInfo.freeDutyPerMonth;

    if (remainingDuty < 0) remainingDuty = 0;

    // #####################

    let remainingHotline = getRemainingService(extraServices, "HOTLINE");

    if (remainingHotline < 0) remainingHotline = 0;

    // #####################

    let remainingVideoConsultations = getRemainingService(
        extraServices,
        "VIDEO_CONSULTATION"
    );

    if (remainingVideoConsultations < 0) remainingVideoConsultations = 0;

    // #####################

    let remainingSecondOpinion = getRemainingService(
        extraServices,
        "SECOND_OPINION"
    );

    if (remainingSecondOpinion < 0) remainingSecondOpinion = 0;

    // #####################

    const sortedData = {
        ["Aktuális előfizetés"]: `${
            packageInfo.name ?? ""
        } (Havi Ingyenes Chat: ${
            packageInfo.freeChatPerMonth ?? ""
        }, Ügyelet: ${packageInfo.freeDutyPerMonth ?? ""})`,
        ["Időszak"]: `${startDate ? convertDate(startDate) : ""} -- ${
            packageInfo.paymentNextDate ?? ""
        }`,
        ["Hátralévő Ingyenes Chat"]: remainingChat,
        ["Hátralévő Ingyenes Ügyelet"]: remainingDuty,
        ["Hátralévő Ingyenes Hotline"]: remainingHotline,
        ["Hátralévő Ingyenes Másodvélemény"]: remainingSecondOpinion,
        ["Hátralévő Ingyenes Videó konzultáció"]: remainingVideoConsultations,
    };

    return (
        <CollapseBox title="Használt szolgáltatások" classInput={styles.scope}>
            <>
                <ReadOnlyFieldList data={sortedData} />

                {userEvents.length ? (
                    userEvents.map((event, index) => (
                        <Fragment key={index}>
                            {event.event_name === "LOGIN" ||
                            event.event_name === "REGISTRATION" ||
                            event.event_name === "PASSWORD_CHANGE" ? (
                                <></>
                            ) : (
                                <>
                                    <hr />

                                    <Row>
                                        <Col sm={3}>
                                            <p>
                                                {serviceNames[
                                                    event.event_name
                                                ] ??
                                                    (event.created_at
                                                        ? "Egyéb szolgáltatás"
                                                        : "")}
                                            </p>
                                        </Col>
                                        <Col sm={9}>
                                            <p>{event.created_at}</p>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Fragment>
                    ))
                ) : (
                    <></>
                )}
            </>
        </CollapseBox>
    );
};

export default ServicesUsed;
