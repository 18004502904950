import type { MetricType } from "@madmedical/sdk";
import { DashboardWidget } from "@madmedical/sdk";
import useResolveNextHealthUserId from "./useResolveNextHealthUserId";

interface Props {
    userId: number | undefined;
    caseDoctorIds: string[];
    metricType: MetricType;
}

const NextHealthWidget = ({ userId, caseDoctorIds, metricType }: Props) => {
    const nextHealthUserId = useResolveNextHealthUserId({
        userId,
        caseDoctorIds,
    });

    if (!nextHealthUserId) {
        return null;
    }

    return (
        <DashboardWidget
            integratorUserId={nextHealthUserId}
            metricType={metricType}
        />
    );
};

export default NextHealthWidget;
