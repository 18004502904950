import { useEffect, useState } from "react";
import { Col, Media, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const Doctors = (props) => {
    const [doctors, setDoctors] = useState([]);

    useEffect(() => {
        get("admin/doctors")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDoctors(response.data.doctors);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    // const onChangeAvailability = (type, value, id) => {
    //     const modifiedValue = value === true ? "1" : "0";
    //     post("admin/change-admin-availability", {
    //         type,
    //         value: modifiedValue,
    //         id,
    //     })
    //         .then((response) => {
    //             switch (response.data.responseCode) {
    //                 case "OK":
    //                     endLoading();
    //
    //                     break;
    //                 default:
    //                     endLoading();
    //                     responseMessage(ALERT_MESSAGES.errorTryAgain);
    //
    //                     break;
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             endLoading();
    //             responseMessage(ALERT_MESSAGES.errorTryAgain);
    //         });
    // };

    let tableData = {
        columns: [
            {
                label: "ID",
                field: "doctor_id",
                // sort: "asc"
            },
            {
                label: "Titulus",
                field: "title",
                // sort: "asc"
            },
            {
                label: "Vezetéknév",
                field: "last_name",
                // sort: "asc"
            },
            {
                label: "Keresztnév",
                field: "first_name",
                // sort: "asc"
            },
            {
                label: "Szakterületek",
                field: "qualification",
                // sort: "asc"
            },
            {
                label: "Email",
                field: "email",
                // sort: "asc"
            },
            {
                label: "Kép",
                field: "image",
                // sort: "asc"
            },
            // {
            //   label: "Bemutatkozás",
            //   field: "introduction",
            //   // sort: "asc"
            // },
            // {
            //     label: "Ügyeleti hívások",
            //     field: "duty_calls",
            //     // sort: "asc"
            // },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const fillTableData = (doctorData) => {
        tableData.rows = doctorData.map((doctor) => {
            let modifiedDoctor = {
                doctor_id: doctor.doctor_id,
                title: doctor.title,
                first_name: doctor.first_name,
                last_name: doctor.last_name,
                email: doctor.email,
                qualification: doctor.qualifications
                    .map((q) => q.name)
                    .join(", "),
                image: doctor.image ? (
                    <Media>
                        <Media
                            className="Pic"
                            object
                            src={doctor.image}
                            alt="Kép"
                        />
                    </Media>
                ) : null,
                introduction: doctor.introduction
                    ? doctor.introduction.substring(0, 100) + "..."
                    : doctor.introduction,
                // duty_calls: (
                //     <AvailabilitySlider
                //         available={doctor.available_duty == "1"}
                //         id={doctor.doctor_id}
                //         type={"duty"}
                //         onChangeAvailability={onChangeAvailability}
                //     />
                // ),

                actions: (
                    <Link to={"/doctors/" + doctor.doctor_id}>Műveletek</Link>
                ),
            };
            if (
                doctor.participate_duty == "0" ||
                doctor.participate_duty === null
            ) {
                modifiedDoctor.duty_calls = null;
            }

            return modifiedDoctor;
        });
    };
    fillTableData(doctors);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Orvosok</h4>
                        </div>
                    </Col>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter == "1" ? (
                        <Col sm="6">
                            <div className="float-right">
                                <Link to={"/doctors/new"}>
                                    <button className="btn btn-primary">
                                        Új orvos
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    ) : null}
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="doctor-table"
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps)(Doctors);
