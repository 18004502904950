import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import NewItemBtn from "../../components/CaseManager/NewItemBtn/NewItemBtn";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { ALERT_MESSAGES, NH_PACKAGES } from "../../config/variables";
import DatePicker from "../../components/DatePicker/DatePicker";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";

const AddNhaPackageBatch = () => {
    const { state } = useLocation();

    const { users } = state;

    const [doctors, setDoctors] = useState([]);

    const nhPackages = {
        ...NH_PACKAGES,
        pre_post_op: "PrePostOp",
    };

    useEffect(() => {
        get("admin/doctors")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDoctors(response.data.doctors);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const [nhaSubscriptionData, setNhaSubscriptionData] = useState([
        {
            id: undefined,
            service: undefined,
            expiresAt: new Date(),
            maxDeviceCount: undefined,
            maxRepresentativeCount: undefined,
            doctors: [],
        },
    ]);

    const onAddNhSubscription = () => {
        setNhaSubscriptionData([
            ...nhaSubscriptionData,
            {
                id: undefined,
                service: undefined,
                expiresAt: new Date(),
                maxDeviceCount: undefined,
                maxRepresentativeCount: undefined,
                doctors: [],
            },
        ]);
    };

    const handleSubmit = () => {
        let validFields = {
            service: true,
            expiresAt: true,
        };

        const data = nhaSubscriptionData.map((nhaSubscription) => ({
            id: nhaSubscription.id,
            service: nhaSubscription.service?.value,
            expiresAt: nhaSubscription.expiresAt,
            maxDeviceCount:
                nhaSubscription.maxDeviceCount === ""
                    ? undefined
                    : nhaSubscription.maxDeviceCount,
            maxRepresentativeCount:
                nhaSubscription.maxRepresentativeCount === ""
                    ? undefined
                    : nhaSubscription.maxRepresentativeCount,
            doctors: nhaSubscription.doctors?.map((doctor) => doctor.value),
        }));

        const dateLtEqNow = (dateStr) => {
            const now = new Date().toISOString().split("T")[0];
            const date = new Date(dateStr).toISOString().split("T")[0];

            return date <= now;
        };

        for (const subscription of data) {
            if (!subscription.service) {
                validFields.service = false;
            }

            if (
                !subscription.expiresAt ||
                dateLtEqNow(subscription.expiresAt)
            ) {
                validFields.expiresAt = false;
            }
        }

        if (!validFields.service) {
            responseMessage("Csomag kiválasztása kötelező!");

            return;
        }

        if (!validFields.expiresAt) {
            responseMessage("Lejárat dátuma nem lehet üres vagy múltbeli!");

            return;
        }

        console.log(data, users);

        post(`api/nhsubscriptions/add-nhsub-batch`, {
            subscriptions: data,
            userIds: users,
        })
            .then((response) => {
                if (response.status === 200) {
                    responseMessage("Sikeres mentés!");

                    endLoading();

                    return;
                }

                responseMessage(ALERT_MESSAGES.errorTryAgain);
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const forms = nhaSubscriptionData.map((nhaSubscription, index) => (
        <>
            <hr />
            <Row className="form-group">
                <CloseIcon
                    style={{
                        width: "3rem",
                        height: "3rem",
                        color: "#ff1900",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        const newNhaSubscriptionData = [...nhaSubscriptionData];
                        newNhaSubscriptionData.splice(index, 1);
                        setNhaSubscriptionData(newNhaSubscriptionData);
                    }}
                />
                <Col sm={10}>
                    <CustomSelect
                        value={nhaSubscriptionData[index].service}
                        isMulti={false}
                        placeholder="Válasszon a csomagok közül"
                        options={Object.entries(nhPackages).map(
                            ([key, value]) => ({
                                value: key,
                                label: value,
                            })
                        )}
                        onChange={(e) => {
                            const newNhaSubscriptionData = [
                                ...nhaSubscriptionData,
                            ];
                            newNhaSubscriptionData[index].service = e;
                            setNhaSubscriptionData(newNhaSubscriptionData);
                        }}
                    />
                </Col>
            </Row>
            <Row className="form-group">
                <label className="col-sm-2 col-form-label">Lejárat</label>
                <Col sm={10}>
                    <DatePicker
                        name="expiresAt"
                        value={nhaSubscriptionData[index].expiresAt}
                        disablePast={true}
                        validate={{
                            required: { value: true },
                        }}
                        onChange={(date) => {
                            const newNhaSubscriptionData = [
                                ...nhaSubscriptionData,
                            ];
                            newNhaSubscriptionData[index].expiresAt = date;
                            setNhaSubscriptionData(newNhaSubscriptionData);
                        }}
                    />
                </Col>
            </Row>

            <Row className="form-group">
                <label className="col-sm-2 col-form-label">
                    Eszközök száma (üres = nincs korlát)
                </label>
                <Col sm={10}>
                    <AvField
                        name="maxDeviceCount"
                        value={nhaSubscriptionData[index].maxDeviceCount}
                        className="form-control"
                        min="1"
                        type="number"
                        onChange={(e) => {
                            const newNhaSubscriptionData = [
                                ...nhaSubscriptionData,
                            ];
                            newNhaSubscriptionData[index].maxDeviceCount =
                                e.target.value;
                            setNhaSubscriptionData(newNhaSubscriptionData);
                        }}
                    />
                </Col>
            </Row>

            <Row className="form-group">
                <label className="col-sm-2 col-form-label">
                    Meghatalmazottak száma (üres = nincs korlát)
                </label>
                <Col sm={10}>
                    <AvField
                        name="maxRepresentativeCount"
                        type="number"
                        min="1"
                        value={
                            nhaSubscriptionData[index].maxRepresentativeCount
                        }
                        onChange={(e) => {
                            const newNhaSubscriptionData = [
                                ...nhaSubscriptionData,
                            ];
                            newNhaSubscriptionData[
                                index
                            ].maxRepresentativeCount = e.target.value;
                            setNhaSubscriptionData(newNhaSubscriptionData);
                        }}
                        className="form-control"
                    />
                </Col>
            </Row>
            <Row className="form-group">
                <label className="col-sm-2 col-form-label">
                    Orvos(ok) választása
                </label>
                <Col sm={10}>
                    <CustomSelect
                        value={nhaSubscriptionData[index].doctors ?? []}
                        isMulti={true}
                        placeholder="Válasszon orvost"
                        options={doctors.map((doctor) => ({
                            value: doctor.doctor_id,
                            label: `${doctor.title ?? ""} ${doctor.last_name} ${
                                doctor.first_name
                            }`,
                        }))}
                        onChange={(e) => {
                            const newNhaSubscriptionData = [
                                ...nhaSubscriptionData,
                            ];
                            newNhaSubscriptionData[index].doctors = e;
                            setNhaSubscriptionData(newNhaSubscriptionData);
                        }}
                    />
                </Col>
            </Row>
        </>
    ));

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                Adatalapú csomag hozzáadása
                            </h4>
                        </div>
                    </Col>
                </Row>
            </div>

            <AvForm onValidSubmit={handleSubmit}>
                <Card>
                    <CardBody>
                        <NewItemBtn
                            text={`+ Új`}
                            onClick={() => onAddNhSubscription()}
                        />
                        <br></br>
                        {forms}
                    </CardBody>
                </Card>
                <Button type="submit" color="primary">
                    Mentés
                </Button>
            </AvForm>
        </>
    );
};

export default AddNhaPackageBatch;
