import { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { serviceNames } from "../../../config/variables";
import classes from "./RatingsTable.module.css";

const RatingsTable = (props) => {
    const [tableData, setTableData] = useState(null);
    const [open, setOpen] = useState(props.defaultOpen);

    const fillTableData = (ratingsData) =>
        ratingsData.map((r) => ({
            rating: r.rating,
            service: serviceNames[`${r.serviceName}`],
            serviceDate: r.eventDate.replace(/-/g, "."),
            ratedAt: r.ratedAt.replace(/-/g, "."),
            review: r.review,
            user: r.userId,
            doctor: `${r.doctorTitle} ${r.doctorLastName} ${r.doctorFirstName}`,
        }));

    useEffect(() => {
        let rows = fillTableData(props.ratings);
        let tableData = {
            columns: [
                {
                    label: "Értékelés",
                    field: "rating",
                },
                {
                    label: "Szolgáltatás",
                    field: "service",
                },
                {
                    label: "Szolgáltatás dátuma",
                    field: "serviceDate",
                },
                {
                    label: "Értékelve",
                    field: "ratedAt",
                },
                {
                    label: "Megjegyzés",
                    field: "review",
                },
                {
                    label: "Felhasználó ID",
                    field: "user",
                },
                {
                    label: "Orvos",
                    field: "doctor",
                },
            ],
            rows,
        };

        setTableData(tableData);
    }, [props.ratings]);

    return (
        <div className="card">
            <div className="card-body">
                <h5
                    className={classes.title}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {props.title}
                </h5>
                {open && (
                    <>
                        <hr></hr>
                        {tableData && (
                            <MDBDataTable
                                responsive
                                striped
                                noBottomColumns
                                bordered
                                data={tableData}
                                infoLabel={["", "-", "a(z)", "elemből"]}
                                noRecordsFoundLabel="Nincs megjeleníthető elem"
                                paginationLabel={["Előző", "Következő"]}
                                searchLabel="Keresés"
                                entriesLabel="Megjelenítendő elemek száma"
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default RatingsTable;
