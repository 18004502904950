import { forwardRef, useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "./Input.css";
import Cleave from "cleave.js/react";
import classes from "./Input.module.css";

const Input = forwardRef((props, ref) => {
    const [defaultValue, setDefaultValue] = useState(props.defaultValue);

    useEffect(() => {
        setDefaultValue(props.defaultValue);
    }, [props.defaultValue]);

    if (props.type == "phone") {
        return (
            <PhoneInput
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
                // value={value}
                value={props.value}
                defaultCountry="HU"
                international
                countryCallingCodeEditable={false}
                flags={flags}
                className={`${classes.input} ${
                    props.bigger && classes.bigger
                } ${props.className}`}
                style={props.style}
            />
        );
        // } else if (props.type == 'passwordRegister') {
        //     return (
        //         <div className={classes.passwordContainer} style={props.style}>
        //             <ReactPasswordStrength
        //                 inputProps={{ type: passWordVisible ? 'text' : 'password' }}
        //                 minLength={6}
        //                 className={`${classes.input2} ${props.bigger && classes.bigger} ${props.className}`}
        //                 style={{ padding: 0, backgroundColor: 'transparent', border: 'none' }}
        //                 scoreWords={['GYENGE', 'GYENGE', 'KÖZEPES', 'JÓ', 'ERŐS']}
        //                 tooShortWord='TÚL RÖVID'
        //                 changeCallback={(input) => {
        //                     if (props.onChange) {
        //                         props.onChange(input.password)
        //                     }
        //                 }} />

        //             <div className={classes.visibility} onClick={() => { setPasswordType(!passWordVisible) }}>
        //                 {passWordVisible ?
        //                     <VisibilityIcon classes={{ root: classes.icon }} /> :
        //                     <VisibilityOffIcon classes={{ root: classes.icon }} />}
        //             </div>
        //         </div>
        //     )
    } else if (props.type === "taj") {
        return (
            <Cleave
                options={{ delimiter: "-", blocks: [3, 3, 3], numeral: false }}
                value={defaultValue}
                className={`${classes.input} ${
                    props.bigger && classes.bigger
                } ${props.className}`}
                style={props.style}
                type={props.type || "text"}
                placeholder={props.placeholder}
                onChange={(e) => {
                    // setValue(e.target.value);
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
            />
        );
    }

    return (
        <input
            ref={ref}
            defaultValue={defaultValue}
            className={`${classes.input} ${props.bigger && classes.bigger} ${
                props.className
            }`}
            style={props.style}
            type={props.type || "text"}
            placeholder={props.placeholder}
            onChange={(e) => {
                // setValue(e.target.value);
                if (props.onChange) {
                    props.onChange(e);
                }
            }}
        />
    );
});

Input.displayName = "Input";

export default Input;
