import { useEffect, useRef, useState } from "react";
import { Icons } from "../../../assets/Theme";
import classes from "./UploadedFile.module.css";

const UploadedFile = (props) => {
    const [source, setSource] = useState(props.source || null);
    const downloadRef = useRef();

    useEffect(() => {
        if (props.media) {
            props.media
                .getContentTemporaryUrl()
                .then((response) => setSource(response));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderName = (fileName) => {
        let length = window.innerHeight > 700 ? 25 : 20;
        if (fileName.length > length) {
            let name = fileName.substr(0, fileName.lastIndexOf("."));
            let extension = fileName.substr(fileName.lastIndexOf("."));

            return name.substr(0, length - 5) + "..." + extension;
        }

        return fileName;
    };

    const onDownloadClick = () => downloadRef.current.click();

    const onDeleteClick = (e, index = null) => {
        props.onDeleteClick(index);
        e.stopPropagation();
    };

    return (
        <>
            <a
                href={source}
                download={props.name}
                ref={downloadRef}
                target="_blank"
                className={classes.download}
                rel="noreferrer"
            ></a>
            <div
                className={`${classes.UploadedFile} ${props.className}`}
                style={props.style}
                onClick={onDownloadClick}
            >
                <img src={Icons.attachmentBlack} className={classes.icon} />
                <span className={classes.name}>{renderName(props.name)}</span>
                {props.deleteEnabled && (
                    <img
                        src={Icons.xRed}
                        className={`${classes.icon} ${classes.delete}`}
                        onClick={onDeleteClick}
                    />
                )}
            </div>
        </>
    );
};

export default UploadedFile;
