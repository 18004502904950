import axios from "axios";
// import { Config } from './config';
import { getEnv } from "tdoc-common";
import store from "../store/index";
import * as actions from "../store/custom-auth/actions";

const instance = axios.create({
    baseURL: `https://${getEnv("HOSTNAME_BACKEND")}`,
});

export const get = (url, isFirstLoad = true) => {
    if (isFirstLoad) {
        store.dispatch(actions.startLoad());
    }

    return instance.get(`/${url}`, {
        headers: { Token: store.getState().auth.token },
    });
};

export const post = (url, options = {}, withLoading = true) => {
    if (withLoading) {
        store.dispatch(actions.startLoad());
    }

    return instance.post(`/${url}`, options, {
        headers: { Token: store.getState().auth.token },
    });
};

//Válasz elkapása: nincs belogolva a user, kijelentkeztés
instance.interceptors.response.use((response) => {
    if (response.data.responseCode === "NOT_AUTHENTICATED") {
        store.dispatch(actions.logout());
        endLoading();

        return Promise.reject("NOT_AUTHENTICATED");
    }

    return response;
});

export const endLoading = () => store.dispatch(actions.endLoad());

export const startLoading = () => store.dispatch(actions.startLoad());
