import { useState } from "react";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import { ALERT_MESSAGES } from "../../config/variables";
import { responseMessage } from "../../utility";
import { endLoading, post } from "../../config/fetch";
// Redux
// availity-reactstrap-validation
// actions
import * as actions from "../../store/custom-auth/actions";

const Login = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    // handleValidSubmit
    const handleValidSubmit = () =>
        post("admin/login", { email, password })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        setError("");
                        const adminUser = {
                            id: response.data.id,
                            email: response.data.email,
                            name: response.data.name,
                        };
                        props.login(
                            adminUser.id,
                            response.data.token,
                            adminUser.name,
                            adminUser.email,
                            response.data.access
                        );

                        const access = response.data.access;
                        if (
                            access.superAdmin == "0" &&
                            (access.caseManager == "1" ||
                                access.fullCaseManager == "1")
                        ) {
                            navigate("/case-manager");
                        } else if (
                            access.superAdmin == "0" &&
                            access.pharmacist == "1"
                        ) {
                            navigate("/prescription-redeems");
                        } else if (
                            access.finance == "1" &&
                            access.superAdmin == "0"
                        ) {
                            navigate("/statistics");
                        } else {
                            navigate("/users");
                        }

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                    case "EMAIL_OR_PASSWORD_DONT_MATCH":
                        setError("Nem létező emailcím - jelszó párosítás!");
                        endLoading();

                        break;
                    case "MISSING_EMAIL_OR_PASSWORD":
                        endLoading();
                        setError("Nem adott meg email címet vagy jelszót!");
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                setError("Szerver hiba!");
            });

    return (
        <>
            <div className="account-pages my-5 pt-5">
                <div className="container">
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <div className="position-relative">
                                <Card className="overflow-hidden">
                                    <div className="bg-primary">
                                        <div className="text-primary text-center p-4">
                                            <h5 className="text-white font-size-20">
                                                Üdvözöljük!
                                            </h5>
                                            <p className="text-white">
                                                Bejelentkezés a T-DOC Admin-ba.
                                            </p>
                                        </div>
                                    </div>

                                    <CardBody className="p-4">
                                        <div className="p-3">
                                            <AvForm
                                                className="form-horizontal mt-4"
                                                onValidSubmit={
                                                    handleValidSubmit
                                                }
                                            >
                                                {error ? (
                                                    <Alert color="danger">
                                                        {error}
                                                    </Alert>
                                                ) : null}

                                                <div className="form-group">
                                                    <AvField
                                                        name="email"
                                                        label="Email"
                                                        className="form-control"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                            },
                                                        }}
                                                        errorMessage="Adjon meg valós email címet!"
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Email"
                                                        type="email"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <AvField
                                                        name="password"
                                                        label="Jelszó"
                                                        type="password"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                            },
                                                        }}
                                                        errorMessage="Adja meg a jelszavát!"
                                                        value={password}
                                                        onChange={(e) =>
                                                            setPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Jelszó"
                                                    />
                                                </div>

                                                <Row className="form-group">
                                                    <Col
                                                        sm={12}
                                                        className="text-center"
                                                    >
                                                        <button
                                                            className="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Bejelentkezés
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="mt-5 text-center">
                                <p className="mb-0">
                                    © {new Date().getFullYear()} T-DOC
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    login: (userId, token, name, email, access) =>
        dispatch(actions.login(userId, token, name, email, access)),
});

export default connect(null, mapDispatchToProps)(Login);
