import { useMenu } from "@madmedical/sdk";
import { withNextHealthEnabled } from "tdoc-common";

const NextHealthMenu = () => {
    const menu = useMenu();

    return (
        <>
            {menu.map(({ Icon, title, onPress }) => (
                <li key={title} onClick={onPress}>
                    <a href="#">
                        <i>
                            <Icon />
                        </i>
                        <span>{title}</span>
                    </a>
                </li>
            ))}
        </>
    );
};

export default withNextHealthEnabled(NextHealthMenu);
