import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CustomCheckbox from "../../../CustomCheckbox/CustomCheckbox";
import { Colors } from "../../../../assets/Theme";
import classes from "./ExamRow.module.css";

const ExamRow = (props) => {
    const [editMode, setEditMode] = useState(props.editMode);
    const [ready, setReady] = useState(props.isReady != "0");
    const [ok, setOk] = useState(props.isOk != "0");

    useEffect(() => {
        setEditMode(props.editMode);
    }, [props.editMode]);

    return (
        <div className={classes.main}>
            <div
                className={classes.nameWrapper}
                style={{ color: ready && ok ? Colors.green : Colors.darkBlue }}
            >
                {props.name}
            </div>
            {!editMode ? (
                <div className={classes.checkBoxWrapper}>
                    <CustomCheckbox
                        onChange={(value) => {
                            setReady(value);
                            props.onChangeStatus(value, ok);
                        }}
                        default={props.isReady != "0"}
                    />
                    <CustomCheckbox
                        onChange={(value) => {
                            setOk(value);
                            props.onChangeStatus(ready, value);
                        }}
                        default={props.isOk != "0"}
                    />
                </div>
            ) : (
                <div
                    className={classes.deleteWrapper}
                    onClick={() => props.onDeleteExam()}
                >
                    <CloseIcon />
                </div>
            )}
        </div>
    );
};

export default ExamRow;
