import { Editor } from "@tinymce/tinymce-react";

const DocumentTextEditor = (props) => (
    //  table tabledelete tableprops tablerowprops tablecellprops tableinsertrowbefore tableinsertrowafter tabledeleterow tableinsertcolbefore tableinsertcolafter tabledeletecol' ,

    <Editor
        apiKey="hnoqomgh9lz6596jqy7k33cjdj7r7u6l77pnz5xamtm553w1"
        init={{
            height: 500,
            menubar: false,
            plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount table",
            ],
            toolbar:
                "undo redo | formatselect | fontselect | bold italic underline | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | link openlink unlink | removeformat help | wordcount | \
          table",

            content_style:
                ".mce-content-body {font-size: 16px; font-family: 'Garamond', serif;}",
            font_formats: `
          Garamond=garamond, serif;
          Roboto=Roboto, sans-serif;
          Andale Mono=andale mono,times;
          Arial=arial,helvetica,sans-serif;
          Arial Black=arial black,avant garde;
          Book Antiqua=book antiqua,palatino;
          Comic Sans MS=comic sans ms,sans-serif;
          Courier New=courier new,courier;
          Georgia=georgia,palatino;
          Helvetica=helvetica;
          Impact=impact,chicago;
          Symbol=symbol;
          Tahoma=tahoma,arial,helvetica,sans-serif;
          Terminal=terminal,monaco;
          Times New Roman=times new roman,times;
          Trebuchet MS=trebuchet ms,geneva;
          Verdana=verdana,geneva;
          Webdings=webdings;
          Wingdings=wingdings,zapf dingbats`,
            language_url: "/langs/hu_HU.js",
            language: "hu_HU",
            statusbar: false,
        }}
        value={props.value}
        onEditorChange={props.onEditorChange}
        disabled={props.disabled}
    />
);
export default DocumentTextEditor;
