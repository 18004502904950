import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { endLoading, get } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";

const PackageList = (props) => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        get("admin/packages")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setPackages(response.data.packages);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Csomagok</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="package-table table table-striped table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th>Csomag neve</th>
                                                {packages.map((item) => (
                                                    <th key={item.id}>
                                                        <span className="t-header">
                                                            {item.name}
                                                        </span>
                                                        {props.access
                                                            .superAdmin ==
                                                        "1" ? (
                                                            <Link
                                                                to={
                                                                    "/packages/" +
                                                                    item.id
                                                                }
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </Link>
                                                        ) : null}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    HOTLINE (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {item.hotline_price}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Havi ingyenes orvosi ügyelet
                                                    (db)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.free_duty_per_month
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Orvosi ügyelet - Hétköznap
                                                    (Ft){" "}
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.duty_weekday_price
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Orvosi ügyelet - Hétvége
                                                    (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.duty_weekend_price
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Havi ingyenes chat (db)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.free_chat_per_month
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Orvosi chat (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {item.chat_price}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Orvosi chat első alkalom
                                                    (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {item.first_chat_price}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Másodvélemény (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.second_opinion_price
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Videókonzultáció (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.video_consultation_min_price
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Receptírás - További
                                                    alkalmak (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.prescription_price
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Ügyeletkereső (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {item.duty_search_price}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Járóbetegellátás foglalás
                                                    kedvezmény (%)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {
                                                            item.outpatient_discount_percentage
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr className="table-warning">
                                                <th scope="row">
                                                    Csomag ára (Ft)
                                                </th>
                                                {packages.map((item) => (
                                                    <td key={item.id}>
                                                        {item.package_price}
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps)(PackageList);
