import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
    useDispatch as useDispatchBase, // eslint-disable-line @typescript-eslint/no-restricted-imports
    useSelector as useSelectorBase, // eslint-disable-line @typescript-eslint/no-restricted-imports
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { baseApi as api } from "tdoc-common";
import layoutReducer from "./layout/reducer";
import customAuthReducer from "./custom-auth/reducers";
import uiReducer from "./ui/reducers";
import rootSaga from "./sagas";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    layout: layoutReducer,
    auth: customAuthReducer,
    ui: uiReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    onAuthRequired: () => {
                        window.location.pathname = "/login";
                    },
                },
            },
        }).concat(api.middleware, sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);

type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useDispatchBase<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorBase;

export default store;
