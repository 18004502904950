import { PreOpAlert as NhaPreOpAlert } from "@madmedical/sdk";
import useResolveNextHealthUserId from "./useResolveNextHealthUserId";

interface Props {
    userId: number | undefined;
    caseDoctorIds: string[];
}

const PreOpAlert = ({ userId, caseDoctorIds }: Props) => {
    const nextHealthUserId = useResolveNextHealthUserId({
        userId,
        caseDoctorIds,
    });

    if (!nextHealthUserId) {
        return null;
    }

    return <NhaPreOpAlert integratorUserId={nextHealthUserId} />;
};

export default PreOpAlert;
