import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES, PACKAGES, serviceNames } from "../../config/variables";
import { getDate } from "../../config/helpers";

const Coupons = (props) => {
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        get("api/v1/coupon/get-global-coupons")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setCoupons(response.data.coupons);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const changeCouponValidation = (couponId, available) =>
        post("api/v1/coupon/change-global-coupon-validation", {
            couponId,
            available,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres módosítás");
                        let allCoupons = [...coupons];
                        let modifiedCoupon = coupons.find(
                            (c) => c.id == couponId
                        );
                        const index = coupons.indexOf(modifiedCoupon);
                        modifiedCoupon.available =
                            modifiedCoupon.available == "1" ? "0" : "1";
                        modifiedCoupon.invalidated_at =
                            modifiedCoupon.invalidated_at ? null : getDate();
                        allCoupons[index] = modifiedCoupon;
                        // console.log(modifiedCoupon);
                        // console.log(index);
                        setCoupons(allCoupons);
                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    let tableData = {
        columns: [
            {
                label: "ID",
                field: "id",
            },
            {
                label: <p style={{ paddingRight: "4rem" }}>Kód</p>,
                field: "coupon_code",
            },
            {
                label: <p style={{ paddingRight: "8rem" }}>Típus</p>,
                field: "type",
            },
            {
                label: "Elérhető",
                field: "available",
            },
            {
                label: <p style={{ paddingRight: "4rem" }}>Lejárat</p>,
                field: "expires_at",
            },
            {
                label: "Beváltva (db)",
                field: "usedTimes",
            },
            {
                label: <p style={{ paddingRight: "4rem" }}>Utolsó beváltás</p>,
                field: "redeemed_at",
            },
            {
                label: "Kedvezményes napok (db)",
                field: "discount_interval_days",
            },
            {
                label: "Kedvezményes ár (Ft)",
                field: "discount_amount",
            },
            {
                label: "Kedvezmény %",
                field: "discount_percentage",
            },
            {
                label: "Minimum érték (Ft)",
                field: "minimum_value",
            },
            {
                label: "Csomag",
                field: "package",
            },
            {
                label: "Szolgáltatás",
                field: "service",
            },
            {
                label: "Többször felhasználható",
                field: "multi_use",
            },
            {
                label: <p style={{ paddingRight: "4rem" }}>Létrehozó</p>,
                field: "admin",
            },
            {
                label: <p style={{ paddingRight: "4rem" }}>Létrehozva</p>,
                field: "created_at",
            },
            {
                label: <p style={{ paddingRight: "8rem" }}>Privát komment</p>,
                field: "private_comment",
            },
            {
                label: <p style={{ paddingRight: "8rem" }}>Publikus komment</p>,
                field: "public_comment",
            },
            {
                label: <p style={{ paddingRight: "4rem" }}>Inaktiválva</p>,
                field: "invalidated_at",
            },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    };

    const exportCoupons = () =>
        get("api/v1/coupon/export-coupons")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        window.open(response.data.link);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    const fillTableData = (couponData) => {
        tableData.rows = couponData.map((coupon) => ({
            id: coupon.id,
            coupon_code: coupon.coupon_code,
            type: coupon.typeName,
            available: coupon.available == "1" ? "Igen " : "Nem",
            expires_at: coupon.expires_at,
            usedTimes: coupon.usedTimes,
            redeemed_at: coupon.redeemed_at,
            discount_interval_days: coupon.discount_interval_days,
            discount_amount: coupon.discount_amount,
            discount_percentage: coupon.discount_percentage,
            minimum_value: coupon.minimum_value,
            package: coupon.package_id ? PACKAGES[+coupon.package_id] : "",
            service: coupon.service ? serviceNames[coupon.service] : "",
            multi_use: coupon.multi_use == "1" ? "Igen" : "Nem",
            admin:
                coupon.last_name && coupon.first_name
                    ? `${coupon.last_name} ${coupon.first_name}`
                    : "",
            private_comment: coupon.private_comment,
            public_comment: coupon.public_comment,
            created_at: coupon.created_at,
            invalidated_at: coupon.invalidated_at,
            actions:
                coupon.available == "1" ? (
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                            changeCouponValidation(coupon.id, coupon.available)
                        }
                    >
                        Inaktiválás
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                            changeCouponValidation(coupon.id, coupon.available)
                        }
                    >
                        Aktiválás
                    </button>
                ),
        }));
    };
    fillTableData(coupons);

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Kuponok</h4>
                        </div>
                    </Col>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter ? (
                        <Col sm="6">
                            <div className="float-right">
                                <Link to={"/coupons/new"}>
                                    <button
                                        style={{ marginRight: "1rem" }}
                                        className="btn btn-primary"
                                    >
                                        Új kupon
                                    </button>
                                </Link>
                                <Link to={"/coupons/generate"}>
                                    <button className="btn btn-primary">
                                        Kuponok generálása
                                    </button>
                                </Link>
                                <button
                                    className="btn btn-primary"
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() => exportCoupons()}
                                >
                                    Exportálás
                                </button>
                            </div>
                        </Col>
                    ) : null}
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="doctor-table"
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    access: state.auth.access,
});

export default connect(mapStateToProps)(Coupons);
