import Select from "react-select";

const CustomSelect = (props) => {
    if (props.inModal) {
        return (
            <Select
                menuPosition={"fixed"}
                value={props.value}
                defaultValue={props.defaultValue}
                isMulti={props.isMulti}
                isDisabled={props.disabled}
                placeholder={props.placeholder}
                noOptionsMessage={() => "Nincs találat"}
                options={props.options}
                onChange={props.onChange}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        // primary25: '#1FBA9C',
                        primary: "#1FBA9C",
                    },
                })}
            />
        );
    }

    return (
        <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={props.value}
            defaultValue={props.defaultValue}
            isDisabled={props.disabled}
            isMulti={props.isMulti}
            placeholder={props.placeholder}
            noOptionsMessage={() => "Nincs találat"}
            options={props.options}
            onChange={props.onChange}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    // primary25: '#1FBA9C',
                    primary: "#1FBA9C",
                },
            })}
        />
    );
};

export default CustomSelect;
