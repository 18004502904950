import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import {
    dispatchPrescriptionRedeemStatusColor,
    dispatchPrescriptionRedeemStatusText,
    responseMessage,
} from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import { endLoading, get } from "../../config/fetch";
import * as actions from "../../store/ui/actions";

const PrescriptionRedeems = () => {
    const [prescriptionRedeems, setPrescriptionRedeems] = useState([]);
    const [tableData, setTableData] = useState({
        columns: [
            {
                label: "ID",
                field: "id",
            },
            {
                label: "Név",
                field: "name",
            },
            {
                label: "TAJ",
                field: "securityNumber",
            },
            {
                label: "Státusz",
                field: "status",
            },
            {
                label: "Beérkezés dátuma",
                field: "createdAt",
            },
            {
                label: " ",
                field: "actions",
            },
        ],
        rows: [],
    });

    useEffect(() => {
        get("admin/get-prescription-redeems")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setPrescriptionRedeems(response.data.redeems);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    useEffect(() => {
        fillTableData(prescriptionRedeems);
    }, [prescriptionRedeems]); // eslint-disable-line react-hooks/exhaustive-deps

    const fillTableData = (redeemData) => {
        const modifiedData = redeemData.map((q) => ({
            id: q.id,
            name: q.last_name + " " + q.first_name,
            securityNumber: q.security_number,
            status: (
                <span
                    style={{
                        color: dispatchPrescriptionRedeemStatusColor(q.status),
                    }}
                >
                    {dispatchPrescriptionRedeemStatusText(q.status)}
                </span>
            ),
            createdAt: q.created_at,
            actions: (
                <Link to={"/prescription-redeems/" + q.id}>Műveletek</Link>
            ),
        }));
        setTableData({
            ...tableData,
            rows: modifiedData,
        });
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Gyógyszerigények</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <MDBDataTable
                                    className="doctor-table"
                                    disableRetreatAfterSorting={true}
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(PrescriptionRedeems);
