import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { endLoading, get } from "../../../config/fetch";
import { ALERT_MESSAGES } from "../../../config/variables";
import { responseMessage } from "../../../utility";

let tableData = {
    columns: [
        {
            field: "id",
            label: "Azonosító",
        },
        {
            field: "patientId",
            label: "Páciens ID",
        },
        {
            field: "patient",
            label: "Páciens",
        },
        {
            field: "qualification",
            label: "Szakterület",
        },
        {
            field: "medicines",
            label: "Gyógyszerek",
        },
        {
            field: "doctor",
            label: "Választott orvos",
        },
        {
            field: "created_at",
            label: "Létrehozva",
        },
        {
            field: "passed",
            label: "Továbbítva",
        },
        {
            field: "status",
            label: "Státusz",
        },
        {
            label: " ",
            field: "actions",
        },
    ],
    rows: [],
};

const PrescriptionList = () => {
    const [channels, setChannels] = useState(null);

    useEffect(() => {
        get("api/v1/prescription/get-channels-admin")
            .then((response) => {
                endLoading();
                generateRows(response.data.channels);
                setChannels(response.data.channels);
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    // const generateCreator = (channel) => {
    //   const doctor = channel.participants.find(d => {
    //     return channel.doctor_id === d.doctor_id
    //   })
    //   return `${doctor.title ?? ''} ${doctor.last_name} ${doctor.first_name}`;
    // }

    // const generateParticipants = (channel) => {
    //   let participantsString = "";
    //   channel.participants.forEach((p, index) => {
    //     if (index !== channel.participants.length - 1) {
    //       participantsString += `${p.title ?? ''} ${p.last_name} ${p.first_name}, `
    //     } else {
    //       participantsString += `${p.title ?? ''} ${p.last_name} ${p.first_name}`
    //     }
    //   });
    //   return participantsString;
    // }

    const generateRows = (channels) => {
        tableData.rows = channels.map((c) => ({
            id: c.identifier,
            patientId: c.patient_id,
            patient: `${c.patientLastName} ${c.patientFirstName}`,
            qualification: c.qualification ?? "",
            medicines: c.medicines,
            doctor: c.selected_doctor_id
                ? `${c.title} ${c.doctorLastName} ${c.doctorFirstName}`
                : "",
            created_at: c.created_at,
            status: c.open == "1" ? "Nyitott" : "Lezárt",
            passed: c.passed == "1" ? "Igen" : "Nem",
            actions: (
                <Link to={"/prescription/" + c.identifier}>Megnyitás</Link>
            ),
        }));
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">E-recept</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {channels && (
                                    <MDBDataTable
                                        responsive
                                        striped
                                        noBottomColumns
                                        bordered
                                        data={tableData}
                                        infoLabel={["", "-", "a(z)", "elemből"]}
                                        noRecordsFoundLabel="Nincs megjeleníthető elem"
                                        paginationLabel={["Előző", "Következő"]}
                                        searchLabel="Keresés"
                                        entriesLabel="Megjelenítendő elemek száma"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrescriptionList;
