import { Fragment, useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { serviceNames } from "../../../../config/variables";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import CollapseBox from "../CollapseBox/CollapseBox";
import ReadOnlyFieldList from "../ReadOnlyFieldList/ReadOnlyFieldList";
import styles from "./ServicesAdded.module.css";

const ServicesAdded = ({ propsBus }) => {
    const [isEditing, setIsEditing] = useState(false);

    const {
        props,
        extraServices,
        extraServiceData,
        setExtraServiceDataByKey,
        dateChangeHandler,
        handleAddExtraService,
        handleDeleteExtraService,
    } = propsBus;

    const sortedData = extraServices.length
        ? extraServices.map((es) => {
              const expireDate = es.expires_at
                  ? es.expires_at.split(" ")[0]
                  : "Nincs";

              return {
                  ["Szolgáltatás"]: serviceNames[es.service_name],
                  ["Lejárat"]: expireDate,
                  ["Hozzáadva"]: es.created_at,
                  ["Hozzáadó"]: `${es.last_name ?? ""} ${es.first_name ?? ""}`,
                  ["Megjegyzés"]: es.comment,
                  ["Elérhetőség"]:
                      Number(es.available) === 1 ? "Elérhető" : "Felhasználva",
                  ["Darabszám"]: !es.quantity
                      ? es.original_quantity
                          ? es.original_quantity
                          : "-"
                      : es.quantity,
              };
          })
        : [];

    return (
        <CollapseBox
            title="Hozzáadott szolgáltatások"
            classInput={styles.scope}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
        >
            {isEditing ? (
                <>
                    {props.access.superAdmin == "1" ||
                    props.access.callCenter == "1" ? (
                        <Row>
                            <div className="col-12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {extraServices.map((es, index) => {
                                                let expireDate = "Nincs";
                                                if (es.expires_at) {
                                                    expireDate =
                                                        es.expires_at.split(
                                                            " "
                                                        )[0];
                                                }

                                                return (
                                                    <Fragment key={index}>
                                                        <Col sm={2}>
                                                            <p>
                                                                {
                                                                    serviceNames[
                                                                        es
                                                                            .service_name
                                                                    ]
                                                                }
                                                            </p>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <p>
                                                                <strong>
                                                                    Lejárat:
                                                                </strong>{" "}
                                                                {expireDate}
                                                            </p>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <p>
                                                                <strong>
                                                                    Hozzáadva:
                                                                </strong>{" "}
                                                                {es.created_at}
                                                            </p>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <p>
                                                                <strong>
                                                                    Hozzáadó:
                                                                </strong>{" "}
                                                                {es.last_name}{" "}
                                                                {es.first_name}
                                                            </p>
                                                        </Col>
                                                        <Col
                                                            sm={1}
                                                            style={{
                                                                marginBottom:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <p>
                                                                <strong>
                                                                    Komment:
                                                                </strong>{" "}
                                                                {es.comment}
                                                            </p>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <p>
                                                                <strong>
                                                                    {es.available ==
                                                                    "1"
                                                                        ? "Elérhető"
                                                                        : "Felhasználva"}
                                                                </strong>
                                                            </p>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <p>
                                                                <strong>
                                                                    Darabszám:
                                                                </strong>{" "}
                                                                {!es.quantity
                                                                    ? es.original_quantity
                                                                        ? es.original_quantity
                                                                        : "-"
                                                                    : es.quantity}
                                                            </p>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Button
                                                                style={{
                                                                    padding:
                                                                        "0.2rem",
                                                                    backgroundColor:
                                                                        "red",
                                                                    color: "white",
                                                                }}
                                                                onClick={() => {
                                                                    handleDeleteExtraService(
                                                                        es.id
                                                                    );
                                                                }}
                                                            >
                                                                Eltávolítás
                                                            </Button>
                                                        </Col>
                                                    </Fragment>
                                                );
                                            })}
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={2}>
                                                <p>
                                                    <strong>
                                                        Szolgáltatás hozzáadása:
                                                    </strong>
                                                </p>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        name="select"
                                                        value={
                                                            extraServiceData.name
                                                        }
                                                        onChange={(e) =>
                                                            setExtraServiceDataByKey(
                                                                "name",
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                            disabled
                                                        >
                                                            Válasszon
                                                            szolgáltatást...
                                                        </option>
                                                        <option value="HOTLINE">
                                                            Hotline hívása
                                                        </option>
                                                        <option value="DUTY">
                                                            Ügyelet hívása
                                                        </option>
                                                        <option value="VIDEO_CONSULTATION">
                                                            Videó konzultáció
                                                        </option>
                                                        <option value="CHAT">
                                                            Üzenetváltás
                                                        </option>
                                                        <option value="SECOND_OPINION">
                                                            Másodvélemény
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <p>
                                                    <strong>
                                                        Lejárati idő:
                                                    </strong>
                                                </p>
                                            </Col>
                                            <Col sm={3}>
                                                <DatePicker
                                                    value={
                                                        extraServiceData.date
                                                    }
                                                    onChange={(date) =>
                                                        dateChangeHandler(
                                                            date,
                                                            "date",
                                                            "service"
                                                        )
                                                    }
                                                    disablePast={true}
                                                    disableFuture={false}
                                                />
                                            </Col>
                                            <Col sm={1}>
                                                <p>
                                                    <strong>Darabszám:</strong>
                                                </p>
                                            </Col>
                                            <Col sm={2}>
                                                <Input
                                                    type="number"
                                                    min="1"
                                                    value={
                                                        !extraServiceData.quantity
                                                            ? extraServiceData.original_quantity
                                                                ? extraServiceData.original_quantity
                                                                : ""
                                                            : extraServiceData.quantity
                                                    }
                                                    onChange={(e) =>
                                                        setExtraServiceDataByKey(
                                                            "quantity",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={2}></Col>
                                            <Col sm={8}>
                                                <FormGroup>
                                                    <Input
                                                        type="textarea"
                                                        placeholder="Komment"
                                                        value={
                                                            extraServiceData.comment
                                                        }
                                                        onChange={(e) =>
                                                            setExtraServiceDataByKey(
                                                                "comment",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={2}>
                                                <Button
                                                    color="primary"
                                                    onClick={
                                                        handleAddExtraService
                                                    }
                                                >
                                                    Hozzáadás
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    ) : null}
                </>
            ) : sortedData.length ? (
                sortedData.map((data, i) => (
                    <Fragment key={"services-list" + i}>
                        {i > 0 && <hr />}
                        <ReadOnlyFieldList data={data} />
                    </Fragment>
                ))
            ) : (
                <span>Nincs még hozzáadott szolgáltatás.</span>
            )}
        </CollapseBox>
    );
};

export default ServicesAdded;
