import { Grid } from "@material-ui/core";
import { useState } from "react";
import ReservationCalendar from "../ReservationCalendar/ReservationCalendar";
import ReservationFilter from "../ReservationFilter/ReservationFilter";
import { getMonday } from "../reservationUtilities";
import classes from "./ReservationPicker.module.css";

const ReservationPicker = (props) => {
    const [fittingAppointments, setFittingAppointments] = useState([]);

    return (
        <div className={classes.picker}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <ReservationFilter
                        selectedQualificationId={props.selectedQualification}
                        selectedDoctorId={props.selectedDoctor}
                        selectedInstitutionId={props.selectedInstitution}
                        selectedServiceId={props.selectedService}
                        filterData={props.filterData}
                        dataSetter={setFittingAppointments}
                        withoutInstitution={props.onlyVideo}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    {fittingAppointments.map((element) => (
                        <ReservationCalendar
                            key={element.id}
                            id={element.id}
                            appointmentId={element.appointmentId}
                            doctorName={element.doctor}
                            doctorId={element.doctorId}
                            qualifications={element.qualification}
                            service={element.service}
                            serviceId={element.serviceId}
                            institution={element.institution}
                            institutionId={element.institutionId}
                            institutionAddress={element.institutionAddress}
                            institutionImage={element.institutionImage}
                            profileImageUrl={element.profileImageUrl}
                            isVideo={element.isVideo}
                            price={element.price}
                            noCache={props.noCache}
                            firstWeek={getMonday()}
                            selectedPatient={props.selectedPatient}
                            patientHasCard={!!props.patientData.default_card_id}
                        />
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

export default ReservationPicker;
