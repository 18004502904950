import type {
    FieldValues,
    SubmitHandler,
    UseFormReturn,
} from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import type { BaseSyntheticEvent, ReactNode } from "react";
import type { DefaultValues } from "react-hook-form/dist/types/form";
import type { MutationActionCreatorResult } from "../store/model";
import FormErrorContext from "./FormErrorContext";
import { handleError } from "./apiError";

interface ChildrenProps<Values extends FieldValues>
    extends Pick<UseFormReturn<Values>, "getValues" | "setValue"> {
    isSuccess: boolean;
    handleSubmit: (e: BaseSyntheticEvent) => void;
    submitForm: () => void;
}

interface Props<Values extends FieldValues, MutationResult> {
    readonly defaultValues: DefaultValues<Values>;
    readonly mutation: (
        values: Values
    ) => MutationActionCreatorResult<MutationResult>;
    readonly resetOnSuccess?: boolean;
    readonly onSuccess?: (result: MutationResult) => void;
    readonly children:
        | ((props: ChildrenProps<Values>) => ReactNode)
        | ReactNode;
}

const ApiFormWrapper = <Values extends FieldValues, MutationResult>({
    defaultValues,
    mutation,
    resetOnSuccess = false,
    onSuccess,
    children,
}: Props<Values, MutationResult>) => {
    const methods = useForm<Values>({ defaultValues });
    const [formError, setFormError] = useState<string>();
    const [isSuccess, setSuccess] = useState(false);

    const {
        reset,
        setError,
        handleSubmit: makeHandleSubmit,
        getValues,
        setValue,
    } = methods;

    const resetForm = () => {
        reset();
        setFormError(undefined);
    };

    const onSubmit: SubmitHandler<Values> = (values) => {
        mutation(values)
            .unwrap()
            .then((result) => {
                if (resetOnSuccess) {
                    resetForm();
                }

                if (result) {
                    setSuccess(true);
                    onSuccess?.(result);
                }
            })
            .catch(handleError({ setError, setFormError }));
    };

    const handleSubmit = makeHandleSubmit(onSubmit);

    return (
        <FormErrorContext.Provider value={formError}>
            <FormProvider {...methods}>
                {children instanceof Function
                    ? children({
                          getValues,
                          setValue,
                          isSuccess,
                          handleSubmit,
                          submitForm: handleSubmit,
                      })
                    : children}
            </FormProvider>
        </FormErrorContext.Provider>
    );
};

export default ApiFormWrapper;
