import * as actionTypes from "./actionTypes";

const initialState = {
    showResponseModal: false,
    responseModal: {
        title: null,
        content: null,
        confirmButton: true,
        confirmButtonText: "Rendben",
        confirmButtonCallback: null,
        cancelButton: false,
        cancelButtonText: "Mégsem",
        cancelButtonCallback: null,
        disableBackdropClick: false,
    },
};

export const toggleResponseModal = (state, action) => ({
    ...state,
    showResponseModal: action.show,
    responseModal: {
        ...{
            title: null,
            content: null,
            component: null,
            confirmButton: true,
            confirmButtonText: "Rendben",
            confirmButtonCallback: null,
            cancelButton: false,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        },
        ...action.responseModal,
    },
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_RESPONSE_MODAL:
            return toggleResponseModal(state, action);
    }

    return state;
};

export default reducer;
