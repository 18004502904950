import { useRef } from "react";

const FileUploader = (props) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => hiddenFileInput.current.click();

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            props.handleFile(fileUploaded);
            event.target.value = null;
        }
    };

    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-primary"
                type="button"
            >
                Kép feltöltés
            </button>
            <input
                accept="image/*"
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
            />
        </>
    );
};

export default FileUploader;
