import { Navigate } from "react-router-dom";
import Users from "../pages/Users/Users";
import UserDetails from "../pages/UserDetails/UserDetails";
import NewUser from "../pages/NewUser/NewUser";
import AdminDetails from "../pages/AdminDetails/AdminDetails";
import Admins from "../pages/Admins/Admins";
import NewAdmin from "../pages/NewAdmin/NewAdmin";
import Doctors from "../pages/Doctors/Doctors";
import DoctorDetails from "../pages/DoctorDetails/DoctorDetails";
import NewDoctor from "../pages/NewDoctor/NewDoctor";
import PackageList from "../pages/PackageList/PackageList";
import PackageDetails from "../pages/PackageDetails/PackageDetails";
import ChatPage from "../pages/Chat/ChatPage/ChatPage";
import ChannelList from "../pages/Chat/ChannelList/ChannelList";
import Profile from "../pages/Profile/Profile";
import Calls from "../pages/Calls/Calls";
import Stats from "../pages/Stats/Stats";
import Institutions from "../pages/Institutions/Institutions";
import InstitutionDetails from "../pages/InstitutionDetails/InstitutionDetails";
import Reservations from "../pages/Reservations/Reservations";
import Coupons from "../pages/Coupons/Coupons";
import NewCoupon from "../pages/Coupons/NewCoupon/NewCoupon";
import Qualifications from "../pages/Qualifications/Qualifications";
import QualificationDetails from "../pages/Qualifications/QualificationDetails/QualificationDetails";
import Duties from "../pages/Duties/Duties";
import DutyDetails from "../pages/DutyDetails/DutyDetails";
import DailySummary from "../pages/DailySummary/DailySummary";
import CaseManager from "../pages/CaseManager/CaseManager";
import CaseDetails from "../pages/CaseDetails/CaseDetails";
import Statistics from "../pages/Statistics/Statistics";
import Employers from "../pages/Employers/Employers";
import EmployerDetails from "../pages/EmployerDetails/EmployerDetails";
import ConsiliumList from "../pages/Consilium/ConsiliumList/ConsiliumList";
import NewConsilium from "../pages/Consilium/NewConsilium/NewConsilium";
import ConsiliumPage from "../pages/Consilium/ConsiliumPage/ConsiliumPage";
import PrescriptionList from "../pages/Prescription/PrescriptionList/PrescriptionList";
import PrescriptionPage from "../pages/Prescription/PrescriptionPage/PrescriptionPage";
import Documents from "../pages/Documents/Documents";
import QA from "../pages/QA/QA";
import AnswerForm from "../pages/QA/AnswerForm/AnswerForm";
import Reservation from "../pages/Reservation/Reservation";
import ReservationSummary from "../pages/Reservation/ReservationSummary/ReservationSummary";
import Ratings from "../pages/Ratings/Ratings";
import Login2 from "../pages/Authentication/Login2";
import GenerateCoupon from "../pages/Coupons/GenerateCoupon/GenerateCoupon";
import PrescriptionRedeems from "../pages/PrescriptionRedeems/PrescriptionRedeems";
import PrescriptionRedeemDetails from "../pages/PrescriptionRedeems/PrescriptionRedeemDetails";
import DutyDoctors from "../pages/DutyDoctors/DutyDoctors";
import NewDutyDoctor from "../pages/NewDutyDoctor/NewDutyDoctor";
import AddNhaPackageBatch from "../pages/Users/AddNhaPackageBatch";
import AddServiceBatch from "../pages/Users/AddServiceBatch";

const authProtectedRoutes = [
    // Users
    {
        path: "users/new",
        component: NewUser,
        rights: ["superAdmin", "callCenter", "doctor"],
    },
    {
        path: "users/:id",
        component: UserDetails,
        rights: ["superAdmin", "callCenter", "doctor"],
    },
    {
        path: "users",
        component: Users,
        rights: ["superAdmin", "callCenter", "doctor"],
    },
    {
        path: "/users/add-nha-package-batch",
        component: AddNhaPackageBatch,
        rights: ["superAdmin", "callCenter", "doctor"],
    },
    {
        path: "/users/add-extra-service-batch",
        component: AddServiceBatch,
        rights: ["superAdmin", "callCenter", "doctor"],
    },

    // Admins
    { path: "admins/new", component: NewAdmin, rights: ["superAdmin"] },
    { path: "admins/:id", component: AdminDetails, rights: ["superAdmin"] },
    { path: "admins", component: Admins, rights: ["superAdmin"] },

    // Doctors
    {
        path: "doctors/new",
        component: NewDoctor,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "doctors/:id",
        component: DoctorDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "doctors",
        component: Doctors,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duty-doctors",
        component: DutyDoctors,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duty-doctors/new",
        component: NewDutyDoctor,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duty-doctors/:id",
        component: NewDutyDoctor,
        rights: ["superAdmin", "callCenter"],
    },

    // Packages
    {
        path: "packages/:id",
        component: PackageDetails,
        rights: ["superAdmin"],
    },
    {
        path: "packages",
        component: PackageList,
        rights: ["superAdmin", "callCenter"],
    },

    // Coupons
    {
        path: "coupons/new",
        component: NewCoupon,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "coupons/generate",
        component: GenerateCoupon,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "coupons",
        component: Coupons,
        rights: ["superAdmin", "callCenter"],
    },

    // Chat
    { path: "chat", component: ChannelList, rights: ["superAdmin", "doctor"] },
    {
        path: "chat/channel/:identifier",
        component: ChatPage,
        rights: ["superAdmin", "doctor"],
    },

    // Profile
    {
        path: "profile",
        component: Profile,
        rights: [
            "superAdmin",
            "callCenter",
            "doctor",
            "finance",
            "caseManager",
            "fullCaseManager",
        ],
    },

    // Calls
    {
        path: "hotline-calls",
        component: Calls,
        rights: ["superAdmin", "callCenter"],
    },
    { path: "duty-calls", component: Calls, rights: ["superAdmin", "doctor"] },

    // Stats
    { path: "stats", component: Stats, rights: ["superAdmin", "finance"] },
    {
        path: "statistics",
        component: Statistics,
        rights: ["superAdmin", "finance"],
    },

    // Institutions
    {
        path: "institutions/:id",
        component: InstitutionDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "institutions/new",
        component: InstitutionDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "institutions",
        component: Institutions,
        rights: ["superAdmin", "callCenter"],
    },

    // Appointments
    {
        path: "reservations",
        component: Reservations,
        rights: ["superAdmin", "callCenter", "doctor"],
    },

    // PrescriptionRedeems
    {
        path: "prescription-redeems",
        component: PrescriptionRedeems,
        rights: ["superAdmin", "pharmacist"],
    },
    {
        path: "prescription-redeems/:id",
        component: PrescriptionRedeemDetails,
        rights: ["superAdmin", "pharmacist"],
    },

    // Qualifications
    {
        path: "qualifications/:id",
        component: QualificationDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "qualifications/new",
        component: QualificationDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "qualifications",
        component: Qualifications,
        rights: ["superAdmin", "callCenter"],
    },

    // Duties
    {
        path: "duties/:id",
        component: DutyDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duties/new",
        component: DutyDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duties",
        component: Duties,
        rights: ["superAdmin", "callCenter"],
    },

    // Daily Summary
    {
        path: "daily-summary",
        component: DailySummary,
        rights: ["superAdmin", "callCenter", "caseManager", "fullCaseManager"],
    },

    // Case Manager
    {
        path: "case-manager/:id",
        component: CaseDetails,
        rights: [
            "superAdmin",
            "callCenter",
            "doctor",
            "caseManager",
            "fullCaseManager",
        ],
    },
    {
        path: "case-manager",
        component: CaseManager,
        rights: [
            "superAdmin",
            "callCenter",
            "doctor",
            "caseManager",
            "fullCaseManager",
        ],
    },

    // Duties
    {
        path: "duties/:id",
        component: DutyDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duties/new",
        component: DutyDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "duties",
        component: Duties,
        rights: ["superAdmin", "callCenter"],
    },

    // Employers
    {
        path: "employers/:id",
        component: EmployerDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "employers/new",
        component: EmployerDetails,
        rights: ["superAdmin", "callCenter"],
    },
    {
        path: "employers",
        component: Employers,
        rights: ["superAdmin", "callCenter"],
    },

    // Consilium
    {
        path: "consilium/new",
        component: NewConsilium,
        rights: ["superAdmin", "doctor"],
    },
    {
        path: "consilium/:identifier",
        component: ConsiliumPage,
        rights: ["superAdmin", "doctor"],
    },
    {
        path: "consilium",
        component: ConsiliumList,
        rights: ["superAdmin", "doctor"],
    },

    // Prescription
    {
        path: "prescription/:identifier",
        component: PrescriptionPage,
        rights: ["superAdmin", "doctor", "callCenter"],
    },
    {
        path: "prescription",
        component: PrescriptionList,
        rights: ["superAdmin", "doctor", "callCenter"],
    },

    // Documents
    { path: "documents", component: Documents, rights: ["superAdmin"] },
    // QA
    {
        path: "qa/:id",
        component: AnswerForm,
        rights: ["superAdmin", "callCenter"],
    },
    { path: "qa", component: QA, rights: ["superAdmin", "callCenter"] },
    // Reservation
    {
        path: "reservation",
        component: Reservation,
        rights: ["superAdmin", "callCenter", "doctor"],
    },
    {
        path: "reservation-summary",
        component: ReservationSummary,
        rights: ["superAdmin", "callCenter"],
    },

    // Ratings
    { path: "ratings", component: Ratings, rights: ["superAdmin"] },

    {
        path: "*",
        exact: true,
        rights: ["pharmacist"],
        component: () => <Navigate to="/prescription-redeems" />,
    },
    { path: "*", exact: true, component: () => <Navigate to="/users" /> },
];

const publicRoutes = [{ path: "login", component: Login2 }];

export { authProtectedRoutes, publicRoutes };
