import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
    AvCheckbox,
    AvCheckboxGroup,
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import { useLoadScript } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { connect } from "react-redux";
import FileUploader from "../../components/FileUploader/FileUploader";
import { endLoading, get, post } from "../../config/fetch";
import { responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Config } from "../../config/config";
import TextEditor from "../../components/TextEditor/TextEditor";
import * as actions from "../../store/ui/actions";

let originalTypes = [];
let originalAddress = null;

const libraries = ["places"];

const DutyDetails = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: Config.googleApiKey,
        libraries,
    });

    const { id } = useParams();
    const navigate = useNavigate();
    const url = window.location.href.split("/");
    const type = url[url.length - 1];

    const emptyDutyData = {
        name: "",
        address: "",
        phone: "",
        medio_id: "",
        lat: "",
        lng: "",
        city: "",
        zip_code: "",
    };

    const [types, setTypes] = useState([]);
    const [dutyTypes, setDutyTypes] = useState([]);

    const [dutyData, setDutyData] = useState(emptyDutyData);
    const [imageURL, setImageURL] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [isAd, setIsAd] = useState(null);
    const [description, setDescription] = useState(null);

    const setDutyDataByKey = (key, value) =>
        setDutyData({ ...dutyData, [key]: value });

    const handleEditorChange = (content) => setDescription(content);

    useEffect(() => {
        get("admin/get-duty-types")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        endLoading();
                        setTypes(response.data.dutyTypes);

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((errors) => {
                console.error(errors);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    useEffect(() => {
        if (id !== "new" && type !== "new") {
            get(`admin/duty?id=${id}`)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK": {
                            const fetchedDuty = response.data.duty;
                            fetchedDuty.is_ad = fetchedDuty.is_ad == "1";
                            setDutyData(fetchedDuty);
                            setImageURL(fetchedDuty.image);
                            setDutyTypes(response.data.dutyTypes);
                            setIsAd(fetchedDuty.is_ad);
                            originalTypes = response.data.dutyTypes;
                            originalAddress = fetchedDuty.address;
                            setDescription(fetchedDuty.description);
                            endLoading();

                            break;
                        }
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    const handleSubmit = () => {
        const newDuty = dutyData;
        newDuty.is_ad = isAd === true ? "1" : "0";

        const getAddressInfos = (results) => {
            const addressArr = results[0].address_components;
            addressArr.forEach((data) => {
                if (data.types.includes("locality")) {
                    formData.append("city", data.long_name);
                }
                if (data.types.includes("postal_code")) {
                    formData.append("zipCode", data.long_name);
                }
            });
        };

        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("id", id);
        formData.append("name", newDuty.name);
        formData.append("address", newDuty.address);
        formData.append("is_ad", newDuty.is_ad);
        formData.append("phone", newDuty.phone);
        formData.append("medio_id", newDuty.medio_id);
        formData.append("description", description === null ? "" : description);

        if (JSON.stringify(originalTypes) !== JSON.stringify(dutyTypes)) {
            formData.append("types", JSON.stringify(dutyTypes));
        }

        if (type === "new") {
            if (isLoaded) {
                getGeocode({ address: newDuty.address })
                    .then((results) => {
                        getAddressInfos(results);

                        return results;
                    })
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        formData.append("lat", lat);
                        formData.append("lng", lng);

                        post("admin/new-duty", formData)
                            .then((response) => {
                                switch (response.data.responseCode) {
                                    case "OK":
                                        responseMessage("Sikeres mentés!");
                                        navigate("/duties");
                                        endLoading();

                                        break;
                                    default:
                                        endLoading();
                                        responseMessage(
                                            ALERT_MESSAGES.errorTryAgain
                                        );

                                        break;
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                endLoading();
                                responseMessage(ALERT_MESSAGES.errorTryAgain);
                            });
                    })
                    .catch((error) => {
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                        console.error(error);
                    });
            }
        }
        if (type !== "new") {
            if (newDuty.address !== originalAddress) {
                if (isLoaded) {
                    getGeocode({ address: newDuty.address })
                        .then((results) => {
                            getAddressInfos(results);

                            return results;
                        })
                        .then((results) => getLatLng(results[0]))
                        .then(({ lat, lng }) => {
                            formData.append("lat", lat);
                            formData.append("lng", lng);

                            post("admin/update-duty", formData)
                                .then((response) => {
                                    switch (response.data.responseCode) {
                                        case "OK":
                                            responseMessage("Sikeres mentés!");
                                            endLoading();

                                            break;
                                        default:
                                            endLoading();
                                            responseMessage(
                                                ALERT_MESSAGES.errorTryAgain
                                            );

                                            break;
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                    endLoading();
                                    responseMessage(
                                        ALERT_MESSAGES.errorTryAgain
                                    );
                                });
                        })
                        .catch((error) => {
                            responseMessage(ALERT_MESSAGES.errorTryAgain);
                            console.error(error);
                        });
                }
            } else {
                formData.append("lat", dutyData.lat);
                formData.append("lng", dutyData.lng);
                formData.append("city", dutyData.city);
                formData.append("zipCode", dutyData.zip_code);

                post("admin/update-duty", formData)
                    .then((response) => {
                        switch (response.data.responseCode) {
                            case "OK":
                                responseMessage("Sikeres mentés!");
                                endLoading();

                                break;
                            default:
                                endLoading();
                                responseMessage(ALERT_MESSAGES.errorTryAgain);

                                break;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);
                    });
            }
        }
    };

    const deleteInstitution = () => {
        if (id === "new") {
            return;
        }
        post("admin/delete-duty", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        navigate("/duties");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törli az ügyeletet? A művelet nem visszavonható!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteInstitution,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    const handleSelectChange = (e) => {
        if (e === null) {
            return;
        }
        const modified = e.map((q) => ({ id: q.value, name: q.label }));
        setDutyTypes(modified);
    };

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                {type === "new"
                                    ? "Új ügyelet"
                                    : "Ügyelet adatok"}
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="institution-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Név
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az ügyelet nevét!"
                                                className="form-control"
                                                type="text"
                                                value={dutyData.name}
                                                onChange={(event) =>
                                                    setDutyDataByKey(
                                                        "name",
                                                        event.target.value
                                                    )
                                                }
                                                id="name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="full_address"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Cím
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="address"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                errorMessage="Adja meg az ügyelet teljes címét!"
                                                placeholder="Irányítószám, Város, Cím"
                                                className="form-control"
                                                type="text"
                                                value={dutyData.address}
                                                onChange={(event) =>
                                                    setDutyDataByKey(
                                                        "address",
                                                        event.target.value
                                                    )
                                                }
                                                id="address"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="types"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Ügyelet típusok
                                        </label>
                                        <Col
                                            sm={10}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            <CustomSelect
                                                value={dutyTypes.map((q) => ({
                                                    value: q.id,
                                                    label: q.name,
                                                }))}
                                                isMulti={true}
                                                placeholder="Válasszon típust..."
                                                options={types.map((q) => ({
                                                    value: q.id,
                                                    label: q.name,
                                                }))}
                                                onChange={(e) =>
                                                    handleSelectChange(e)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label
                                            htmlFor="phone"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Telefonszám
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="phone"
                                                className="form-control"
                                                type="text"
                                                value={dutyData.phone}
                                                onChange={(event) =>
                                                    setDutyDataByKey(
                                                        "phone",
                                                        event.target.value
                                                    )
                                                }
                                                id="phone"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-2">
                                            Hírdetés
                                        </label>
                                        <Col sm={10}>
                                            <AvCheckboxGroup name="is_ad">
                                                <AvCheckbox
                                                    checked={isAd}
                                                    onChange={(event) =>
                                                        setIsAd(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            </AvCheckboxGroup>
                                        </Col>
                                    </Row>
                                    {isAd && (
                                        <Row
                                            className="form-group"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            <label
                                                htmlFor="image"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Kép
                                            </label>
                                            <Col sm={10}>
                                                <FileUploader
                                                    handleFile={handleFile}
                                                />
                                                {imageURL ? (
                                                    <button
                                                        className="btn btn-danger"
                                                        type="button"
                                                        style={{
                                                            marginLeft: "1rem",
                                                        }}
                                                        onClick={
                                                            cancelImageUpload
                                                        }
                                                    >
                                                        Mégsem
                                                    </button>
                                                ) : null}
                                                {imageURL ? (
                                                    <div className="image-preview">
                                                        <img
                                                            className="image"
                                                            src={imageURL}
                                                        />
                                                    </div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className="form-group">
                                        <label
                                            htmlFor="medio_id"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Időpontfoglalási link (medio Id)
                                        </label>
                                        <Col sm={10}>
                                            <AvField
                                                name="medio_id"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                className="form-control"
                                                type="text"
                                                value={dutyData.medio_id}
                                                onChange={(event) =>
                                                    setDutyDataByKey(
                                                        "medio_id",
                                                        event.target.value
                                                    )
                                                }
                                                id="medio_id"
                                            />
                                        </Col>
                                    </Row>

                                    {((type !== "new" &&
                                        description !== null) ||
                                        type === "new") && (
                                        <Row className="form-group">
                                            <label
                                                htmlFor="description"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Leírás
                                            </label>
                                            <Col sm={10}>
                                                <TextEditor
                                                    value={description ?? ""}
                                                    onEditorChange={
                                                        handleEditorChange
                                                    }
                                                ></TextEditor>
                                            </Col>
                                        </Row>
                                    )}

                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                    {type !== "new" && (
                                        <button
                                            type="button"
                                            className="btn btn-danger float-right"
                                            onClick={handleDelete}
                                        >
                                            Ügyelet törlése
                                        </button>
                                    )}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(DutyDetails);
