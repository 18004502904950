import { useState } from "react";
import Tooltip from "reactstrap/lib/Tooltip";

const TooltipCell = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <div>
            <p style={{ cursor: "pointer" }}>
                <span href="#" id={props.id}>
                    {props.date}
                </span>
            </p>
            <Tooltip
                placement="left"
                isOpen={tooltipOpen}
                target={props.id}
                toggle={() => setTooltipOpen((prev) => !prev)}
            >
                {props.text}
            </Tooltip>
        </div>
    );
};

export default TooltipCell;
