import { baseApi } from "tdoc-common";
import type {
    CreateSubscriptionRequest,
    NextHealthSubscription,
    UpdateSubscriptionRequest,
} from "./model";

const api = baseApi
    .enhanceEndpoints({ addTagTypes: ["nhaSubscriptions"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getSubscriptions: build.query<
                NextHealthSubscription[],
                { userId: number }
            >({
                query: ({ userId }) => `users/${userId}/nhsubscriptions`,
                providesTags: ["nhaSubscriptions"],
            }),
            createSubscription: build.mutation<
                NextHealthSubscription,
                CreateSubscriptionRequest
            >({
                query: ({ userId, ...body }) => ({
                    url: `users/${userId}/nhsubscriptions`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["nhaSubscriptions"],
            }),
            updateSubscription: build.mutation<
                NextHealthSubscription,
                UpdateSubscriptionRequest
            >({
                query: ({ id, ...body }) => ({
                    url: `nhsubscriptions/${id}`,
                    method: "PATCH",
                    body,
                }),
                invalidatesTags: ["nhaSubscriptions"],
            }),
        }),
    });

export const {
    useGetSubscriptionsQuery,
    useCreateSubscriptionMutation,
    useUpdateSubscriptionMutation,
} = api;
