import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { endLoading, get, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";
import FormRow from "../../../components/FormRow/FormRow";
import * as actions from "../../../store/ui/actions";

const QualificationDetails = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const url = window.location.href.split("/");
    const type = url[url.length - 1];

    const emptyQualificationData = {
        name: null,
        url: null,
        longName: "",
        subheader: "",
        description: "",
        medio_outpatient_id: "",
        medio_video_id: "",
    };

    const [qualificationData, setQualificationData] = useState(
        emptyQualificationData
    );
    const [imageURL, setImageURL] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [description, setDescription] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [selectedLeaders, setSelectedLeaders] = useState(null);

    const setQualificationDataByKey = (key, value) =>
        setQualificationData({ ...qualificationData, [key]: value });

    useEffect(() => {
        if (id !== "new" && type !== "new") {
            get(`admin/get-qualification?id=${id}`)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK": {
                            const fetchedQualification =
                                response.data.qualification;
                            setQualificationData(fetchedQualification);
                            if (fetchedQualification.description === null) {
                                setDescription("");
                            } else {
                                setDescription(
                                    fetchedQualification.description
                                );
                            }
                            if (fetchedQualification.leaders.length > 0) {
                                const leaderArr =
                                    fetchedQualification.leaders.map((l) => ({
                                        value: l.id,
                                        label: `${l.title} ${l.last_name} ${l.first_name}`,
                                    }));
                                setSelectedLeaders(leaderArr);
                            }
                            setImageURL(fetchedQualification.image);
                            endLoading();

                            break;
                        }
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        get(`admin/doctors`)
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDoctors(response.data.doctors);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const handleFile = (file) => {
        setImageURL(URL.createObjectURL(file));
        setImageData(file);
    };

    const cancelImageUpload = () => setImageURL(null);

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("file", imageData);
        formData.append("id", id);
        formData.append("name", qualificationData.name);
        formData.append("url", qualificationData.url);
        formData.append("longName", qualificationData.longName);
        formData.append("subheader", qualificationData.subheader);
        formData.append("description", qualificationData.description);
        formData.append(
            "medioOutPatientId",
            qualificationData.medio_outpatient_id
        );
        formData.append("medioVideoId", qualificationData.medio_video_id);

        if (type === "new") {
            post("admin/create-qualification", formData)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("Sikeres mentés!");
                            navigate("/qualifications");
                            endLoading();

                            break;
                        case "URL_TAKEN":
                            endLoading();
                            responseMessage("A megadott URL már foglalt!");

                            break;
                        case "NAME_TAKEN":
                            endLoading();
                            responseMessage("A megadott név már foglalt!");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
        if (type !== "new") {
            formData.append("leaders", JSON.stringify(selectedLeaders));

            post("admin/update-qualification", formData)
                .then((response) => {
                    switch (response.data.responseCode) {
                        case "OK":
                            responseMessage("Sikeres mentés!");
                            endLoading();

                            break;
                        case "URL_TAKEN":
                            endLoading();
                            responseMessage("A megadott URL már foglalt!");

                            break;
                        case "NAME_TAKEN":
                            endLoading();
                            responseMessage("A megadott név már foglalt!");

                            break;
                        default:
                            endLoading();
                            responseMessage(ALERT_MESSAGES.errorTryAgain);

                            break;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    endLoading();
                    responseMessage(ALERT_MESSAGES.errorTryAgain);
                });
        }
    };

    const deleteQualification = () => {
        if (id === "new") {
            return;
        }
        post("admin/delete-qualification", { id })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres törlés!");
                        navigate("/qualifications");

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    const handleDelete = () =>
        props.toggleResponseModal(true, {
            title: "Megerősítés szükséges!",
            content:
                "Biztosan törli a szakterületet? Minden hozzárendelt intézménynél és orvosnál is törlődni fog!",
            confirmButton: true,
            confirmButtonText: "Törlés",
            confirmButtonCallback: deleteQualification,
            cancelButton: true,
            cancelButtonText: "Mégsem",
            cancelButtonCallback: null,
            disableBackdropClick: false,
        });

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">
                                {type === "new"
                                    ? "Új szakterület"
                                    : "Szakterület adatok"}
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="col-12">
                        <Card>
                            <CardBody id="institution-form">
                                <AvForm onValidSubmit={handleSubmit}>
                                    <FormRow
                                        type="text"
                                        name="name"
                                        label="Név"
                                        validate={true}
                                        errorMessage="Adja meg a szakterület nevét!"
                                        value={qualificationData.name}
                                        onChange={(e) =>
                                            setQualificationDataByKey(
                                                "name",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <FormRow
                                        type="text"
                                        name="url"
                                        label="URL"
                                        validate={true}
                                        errorMessage="Adja meg a szakterület URL címét!"
                                        value={qualificationData.url}
                                        onChange={(e) =>
                                            setQualificationDataByKey(
                                                "url",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <FormRow
                                        type="text"
                                        name="longName"
                                        label="Hosszú név"
                                        validate={false}
                                        errorMessage="Adja meg a szakterület teljes nevét!"
                                        value={qualificationData.longName}
                                        onChange={(e) =>
                                            setQualificationDataByKey(
                                                "longName",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <FormRow
                                        type="textarea"
                                        name="subheader"
                                        label="Alcím"
                                        validate={false}
                                        errorMessage="Adja meg a szakterület alcímét!"
                                        value={qualificationData.subheader}
                                        rows={6}
                                        onChange={(e) =>
                                            setQualificationDataByKey(
                                                "subheader",
                                                e.target.value
                                            )
                                        }
                                    />
                                    {doctors && type !== "new" && (
                                        <FormRow
                                            type="customselect"
                                            name="leadera"
                                            label="Vezetők"
                                            value={selectedLeaders}
                                            isMulti={true}
                                            placeholder="Vezető orvos hozzáadása"
                                            options={doctors.map((d) => ({
                                                value: d.doctor_id,
                                                label: `${d.title} ${d.last_name} ${d.first_name}`,
                                            }))}
                                            onChange={(e) =>
                                                setSelectedLeaders(e)
                                            }
                                            style={{ marginBottom: "1rem" }}
                                        />
                                    )}
                                    <FormRow
                                        type="image"
                                        name="image"
                                        label="Kép"
                                        handleFile={handleFile}
                                        imageURL={imageURL}
                                        onClick={cancelImageUpload}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                    {(description !== null ||
                                        type === "new") && (
                                        <FormRow
                                            type="texteditor"
                                            name="description"
                                            label="Leírás"
                                            value={
                                                qualificationData.description
                                            }
                                            onEditorChange={(content) =>
                                                setQualificationDataByKey(
                                                    "description",
                                                    content
                                                )
                                            }
                                            style={{ marginBottom: "1rem" }}
                                        />
                                    )}
                                    <FormRow
                                        type="text"
                                        name="medioOutpatientId"
                                        label="Medio Járóbeteg ID"
                                        validate={false}
                                        value={
                                            qualificationData.medio_outpatient_id
                                        }
                                        onChange={(e) =>
                                            setQualificationDataByKey(
                                                "medio_outpatient_id",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <FormRow
                                        type="text"
                                        name="medioVideoId"
                                        label="Medio Videó ID"
                                        validate={false}
                                        value={qualificationData.medio_video_id}
                                        onChange={(e) =>
                                            setQualificationDataByKey(
                                                "medio_video_id",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <hr></hr>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Mentés
                                    </button>
                                    {type !== "new" && (
                                        <button
                                            type="button"
                                            className="btn btn-danger float-right"
                                            onClick={handleDelete}
                                        >
                                            Szakterület törlése
                                        </button>
                                    )}
                                </AvForm>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleResponseModal: (show, responseModal) =>
        dispatch(actions.toggleResponseModal(show, responseModal)),
});

export default connect(null, mapDispatchToProps)(QualificationDetails);
