import { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import TooltipCell from "../../CaseDetails/TooltipCell/TooltipCell";
import { Icons } from "../../../assets/Theme";
import VipIcon from "../../../components/CaseManager/VipIcon/VipIcon";
import classes from "./CaseTable.module.css";

const CaseTable = (props) => {
    const [tableData, setTableData] = useState(null);
    const [open, setOpen] = useState(props.defaultOpen);

    const NameCell = (props) => (
        <div>
            <p style={{ marginBottom: 0 }}>{props.name}</p>
            {props.vip && <VipIcon />}
            {props.earlier && (
                <img
                    style={{ marginLeft: "10px" }}
                    src={Icons.earlierBlueIcon}
                />
            )}
        </div>
    );

    const fillTableData = (caseData) =>
        caseData.map((c) => {
            let name =
                c.first_name && c.last_name
                    ? `${c.last_name} ${c.first_name}`
                    : c.userLastName && c.userFirstName
                    ? `${c.userLastName} ${c.userFirstName}`
                    : "";
            if (!name && c.name) {
                name = c.name;
            }

            return {
                caseId: <Link to={"/case-manager/" + c.id}>{c.id}</Link>,
                // name: (c.name && !c.user_id) ?  <Link to={"/case-manager/" + c.id}>{c.name}</Link> :  <Link to={"/case-manager/" + c.id}>{`${c.userLastName} ${c.userFirstName}`}</Link>,
                // name: (c.first_name && c.last_name) ?   `${c.last_name} ${c.first_name}` : (c.userLastName && c.userFirstName) ? `${c.userLastName} ${c.userFirstName}` : '',
                name: (
                    <NameCell
                        name={name}
                        vip={c.vip == "1"}
                        earlier={c.take_earlier == "1"}
                    />
                ),
                birthDate: c.birthDate
                    ? c.birthDate.replace(/-/g, ".")
                    : c.userBirthDate
                    ? c.userBirthDate.replace(/-/g, ".")
                    : "",
                qualification: c.qualificationName ?? "",
                institution: c.institutionName ?? "",
                doctors: c.doctors
                    ?.map((d) => `${d.title} ${d.last_name} ${d.first_name}`)
                    .join(", "),
                caseManagers: c.case_managers
                    ?.map((cm) => `${cm.last_name} ${cm.first_name}`)
                    .join(", "),
                surgeryType: c.surgery_type ?? "",
                // tools: c.tools ?? '',
                anestDate: c.anestDate ? c.anestDate.replace(/-/g, ".") : "",
                surgeryDate: c.surgeryDate
                    ? c.surgeryDate.replace(/-/g, ".")
                    : "",
                pingDate: c.pingDate ? c.pingDate.replace(/-/g, ".") : "",
                createdAt: c.created_at.replace(/-/g, "."),
                creator: `${c.adminLastName} ${c.adminFirstName}`,
                status: c.statusName,
                surgeryStatus: c.surgeryStatusName,
                name2: name,
                email: c.email ?? c.userEmail,
                phone: c.phone ?? c.userPhone,
                examinationDescription: c.examination_description ?? "",
                examinationSize: c.examination_size ?? "",
                prosthesisPrice: c.prosthesis_price ?? "",
                description: c.description ?? "",
                doctorId: c.doctor_id ?? "",
                adminId: c.admin_id ?? "",
                institutionId: c.institution_id ?? "",
                surgeryPrice: c.surgery_price ?? "",
                userId: c.user_id ?? "",
                otherPing: c.other_ping_date ? (
                    <TooltipCell
                        id={`c${c.id}`}
                        date={c.other_ping_date.replace(/-/g, ".")}
                        text={c.other_ping_description}
                    />
                ) : (
                    ""
                ),

                // status: c.status === "0" ? 'Kész' :
                // <div style={{display: 'flex'}}>
                //   <p>Van vele feladat</p>
                //   <NotificationWarning style={{marginLeft: '0.5rem'}} />
                // </div>,
                // actions: <Link to={"/case-manager/" + c.id}>Részletek</Link>
            };
        });

    useEffect(() => {
        let rows = fillTableData(props.cases);
        let tableData = {
            columns: [
                // 1-12-ig rejtett fieldek, csak a keresés miatt vannak benne, CSS-el rejtem el
                {
                    field: "email",
                },
                {
                    field: "phone",
                },
                {
                    field: "examinationDescription",
                },
                {
                    field: "examinationSize",
                },
                {
                    field: "prosthesisPrice",
                },
                {
                    field: "description",
                },
                {
                    field: "doctorId",
                },
                {
                    field: "adminId",
                },
                {
                    field: "institutionId",
                },
                {
                    field: "surgeryPrice",
                },
                {
                    field: "userId",
                },
                {
                    field: "name2",
                },
                //
                {
                    label: "ID",
                    field: "caseId",
                },
                {
                    label: "Név",
                    field: "name",
                },
                {
                    label: "Születési dátum",
                    field: "birthDate",
                },
                {
                    label: "Szakterület",
                    field: "qualification",
                },
                {
                    label: "Helyszín",
                    field: "institution",
                },
                {
                    label: "Orvosok",
                    field: "doctors",
                },
                {
                    label: "Esetmenedzser",
                    field: "caseManagers",
                },
                {
                    label: "Műtéti típus",
                    field: "surgeryType",
                },
                // {
                //   label: "Eszközök",
                //   field: "tools",
                // },
                {
                    label: "Aneszt. időpontja",
                    field: "anestDate",
                },
                {
                    label: "Műtét időpontja",
                    field: "surgeryDate",
                },
                {
                    label: "Emlékeztető",
                    field: "pingDate",
                },
                {
                    label: "Egyéb teendő",
                    field: "otherPing",
                },
                // {
                //   label: "Létrehozva",
                //   field: "createdAt",
                // },
                // {
                //   label: "Létrehozó",
                //   field: "creator",
                // },
                {
                    label: "Eset Státusz",
                    field: "status",
                },
                {
                    label: "Műtét Státusz",
                    field: "surgeryStatus",
                },
                // {
                //   label: " ",
                //   field: "actions"
                // }
            ],
            rows,
        };

        setTableData(tableData);
    }, [props.cases]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card">
            <div className="card-body">
                {!props.hiddenTitle && (
                    <h5
                        className={classes.title}
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        {props.title}
                    </h5>
                )}
                {open && (
                    <>
                        {props.hiddenTitle && <hr />}

                        {tableData && (
                            <div className={classes.caseDataTable}>
                                <MDBDataTable
                                    responsive
                                    striped
                                    noBottomColumns
                                    bordered
                                    data={tableData}
                                    infoLabel={["", "-", "a(z)", "elemből"]}
                                    noRecordsFoundLabel="Nincs megjeleníthető elem"
                                    paginationLabel={["Előző", "Következő"]}
                                    searchLabel="Keresés"
                                    entriesLabel="Megjelenítendő elemek száma"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CaseTable;
