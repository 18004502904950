import { Col, Row } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import FileUploader from "../FileUploader/FileUploader";
import TextEditor from "../TextEditor/TextEditor";
import CustomSelect from "../CustomSelect/CustomSelect";
import DatePicker from "../DatePicker/DatePicker";

const FormRow = (props) => (
    <Row className="form-group" style={props.style}>
        <label htmlFor={props.name} className="col-sm-2 col-form-label">
            {props.label}
        </label>
        <Col sm={10}>
            {props.type === "text" && (
                <AvField
                    name={props.name}
                    validate={{ required: { value: props.validate } }}
                    errorMessage={props.errorMessage}
                    className="form-control"
                    type="text"
                    disabled={props.disabled ?? false}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    id={props.name}
                />
            )}
            {props.type === "select" && (
                <AvField
                    type="select"
                    name={props.name}
                    disabled={props.disabled ?? false}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e.target.value);
                        }
                    }}
                    className="form-control"
                >
                    {props.options.map(({ value, label }) => (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    ))}
                </AvField>
            )}
            {props.type === "number" && (
                <AvField
                    name={props.name}
                    disabled={props.disabled ?? false}
                    validate={{ required: { value: props.validate } }}
                    errorMessage={props.errorMessage}
                    className="form-control"
                    type="number"
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    id={props.name}
                />
            )}
            {props.type === "textarea" && (
                <AvField
                    name={props.name}
                    disabled={props.disabled ?? false}
                    validate={{ required: { value: props.validate } }}
                    errorMessage={props.errorMessage}
                    className="form-control"
                    type="textarea"
                    rows={props.rows ? props.rows : 3}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    id={props.name}
                />
            )}
            {props.type === "image" && (
                <>
                    <FileUploader handleFile={props.handleFile} />
                    {props.imageURL ? (
                        <button
                            className="btn btn-danger"
                            type="button"
                            style={{ marginLeft: "1rem" }}
                            onClick={props.onClick}
                        >
                            Mégsem
                        </button>
                    ) : null}

                    {props.imageURL ? (
                        <div className="image-preview">
                            <img className="image" src={props.imageURL} />
                        </div>
                    ) : null}
                </>
            )}
            {props.type === "texteditor" && (
                <TextEditor
                    value={props.value}
                    disabled={props.disabled ?? false}
                    onEditorChange={props.onEditorChange}
                ></TextEditor>
            )}
            {props.type === "customselect" && (
                <CustomSelect
                    value={props.value}
                    disabled={props.disabled ?? false}
                    isMulti={props.isMulti}
                    placeholder={props.placeholder}
                    options={props.options}
                    // onChange={props.onChange}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
            )}
            {props.type === "datepicker" && (
                <DatePicker
                    withTime={props.withTime}
                    value={props.value}
                    disableFuture={props.disableFuture}
                    disablePast={props.disablePast}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
            )}
        </Col>
    </Row>
);

export default FormRow;
