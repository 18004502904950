import { useState } from "react";
import DocumentTextEditor from "../../components/DocumentTextEditor/DocumentTextEditor";
import classes from "./Documents.module.css";

const DocumentEditor = (props) => {
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [content, setContent] = useState(props.content);

    return (
        <div className="card">
            <div className="card-body">
                <h5
                    className={classes.title}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {props.title}
                </h5>
                {open && (
                    <>
                        <hr></hr>
                        <p>
                            Utolsó módosítás:{" "}
                            {props.updated
                                ? props.updated.replace(/-/g, ".")
                                : " - "}
                        </p>
                        <DocumentTextEditor
                            disabled={!editMode}
                            value={content}
                            onEditorChange={(text) => setContent(text)}
                        />
                        <div style={{ marginTop: "1rem" }}>
                            {!editMode ? (
                                <button
                                    className="btn btn-warning"
                                    onClick={() => setEditMode(true)}
                                >
                                    Módosítás
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        props.onSave(content);
                                        setEditMode(false);
                                    }}
                                >
                                    Mentés
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DocumentEditor;
