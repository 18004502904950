import { formatDateTime } from "tdoc-common";
import type { NextHealthSubscription } from "./model";

interface Props {
    subscription: NextHealthSubscription;
}

const CaseNhaSubscription = ({
    subscription: { id, expiresAt, maxDeviceCount, maxRepresentativeCount },
}: Props) => (
    <div key={id}>
        Lejárat: {formatDateTime(expiresAt)}
        <br />
        Hozzáadható eszközök száma:{" "}
        {maxDeviceCount === null ? "∞" : maxDeviceCount}
        <br />
        Hozzáadható meghatalmazottak száma:{" "}
        {maxRepresentativeCount === null ? "∞" : maxRepresentativeCount}
        <br />
    </div>
);

export default CaseNhaSubscription;
