import { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import FormRow from "../../FormRow/FormRow";
import { endLoading, post } from "../../../config/fetch";
import { responseMessage } from "../../../utility";
import { ALERT_MESSAGES } from "../../../config/variables";

const CaseSettings = (props) => {
    const [selectedQualifications, setSelectedQualifications] = useState(null);
    const [selectedInstitutions, setSelectedInstitutions] = useState(null);

    useEffect(() => {
        const _selectedQualifications = [];
        const _selectedInstitutions = [];

        props.caseQualifications.forEach((element) => {
            const qualification = props.qualifications.find(
                (q) => element.qualification_id == q.id
            );
            _selectedQualifications.push({
                value: qualification.id,
                label: qualification.name,
            });
        });

        props.caseInstitutions.forEach((element) => {
            const institution = props.institutions.find(
                (i) => element.institution_id == i.id
            );
            _selectedInstitutions.push({
                value: institution.id,
                label: institution.name,
            });
        });
        setSelectedQualifications(_selectedQualifications);
        setSelectedInstitutions(_selectedInstitutions);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdate = () => {
        let qualifications = null;
        let institutions = null;

        if (selectedQualifications) {
            qualifications = JSON.stringify(selectedQualifications);
        }
        if (selectedInstitutions) {
            institutions = JSON.stringify(selectedInstitutions);
        }

        post("admin/update-case-access", {
            qualifications,
            institutions,
            adminId: props.adminId,
        })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        responseMessage("Sikeres mentés!");
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    };

    return (
        <div style={{ ...props.style }}>
            <AvForm onValidSubmit={handleUpdate}>
                <FormRow
                    type="customselect"
                    name="qualifications"
                    label="Szakterületek"
                    value={selectedQualifications}
                    isMulti={true}
                    placeholder="Válasszon szakterületet..."
                    options={props.qualifications.map((q) => ({
                        value: q.id,
                        label: q.name,
                    }))}
                    onChange={(e) => setSelectedQualifications(e)}
                    style={{ marginBottom: "1rem" }}
                />
                <FormRow
                    type="customselect"
                    name="institutions"
                    label="Intézmények"
                    value={selectedInstitutions}
                    isMulti={true}
                    placeholder="Válasszon intézményt..."
                    options={props.institutions.map((i) => ({
                        value: i.id,
                        label: i.name,
                    }))}
                    onChange={(e) => setSelectedInstitutions(e)}
                    style={{ marginBottom: "1rem" }}
                />
                <Button type="submit" color="primary">
                    Mentés
                </Button>
            </AvForm>
        </div>
    );
};

export default CaseSettings;
