import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { endLoading, get, post } from "../../config/fetch";
import { convertDate, responseMessage } from "../../utility";
import { ALERT_MESSAGES } from "../../config/variables";
import DocumentEditor from "./DocumentEditor";

const Documents = () => {
    const [documents, setDocuments] = useState(null);

    useEffect(() => {
        get("admin/get-documents")
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK":
                        setDocuments(response.data.documents);
                        endLoading();

                        break;
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });
    }, []);

    const onDocumentSave = (content, name) =>
        post("admin/save-document", { content, name })
            .then((response) => {
                switch (response.data.responseCode) {
                    case "OK": {
                        responseMessage("Sikeres mentés!");

                        let updatedDocument = documents.find(
                            (d) => d.document_name === name
                        );
                        let documentIndex = documents.findIndex(
                            (d) => d.document_name === name
                        );
                        let updatedDocuments = [...documents];
                        updatedDocument.updated_at = convertDate(
                            new Date(),
                            ".",
                            true
                        );
                        updatedDocuments[documentIndex] = updatedDocument;
                        setDocuments(updatedDocuments);

                        endLoading();

                        break;
                    }
                    default:
                        endLoading();
                        responseMessage(ALERT_MESSAGES.errorTryAgain);

                        break;
                }
            })
            .catch((error) => {
                console.error(error);
                endLoading();
                responseMessage(ALERT_MESSAGES.errorTryAgain);
            });

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4 className="font-size-18">Dokumentumok</h4>
                        </div>
                    </Col>
                </Row>
            </div>
            {documents?.map((d) => (
                <DocumentEditor
                    key={d.id}
                    title={d.title}
                    name={d.document_name}
                    content={d.content}
                    updated={d.updated_at}
                    onSave={(text) => onDocumentSave(text, d.document_name)}
                />
            ))}
        </>
    );
};

export default Documents;
