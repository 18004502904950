import { useState } from "react";
import { Card, CardHeader, Collapse } from "reactstrap";
import styles from "./CollapseBox.module.css";

const CollapseBox = ({
    children,
    classInput,
    title,
    isEditing,
    setIsEditing,
    buttonEdit = "Szerkesztés",
    buttonSave = "Lista nézet",
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Card
            tag="section"
            className={styles.scope + (classInput ? ` ${classInput}` : "")}
        >
            <CardHeader tag="header" className={isOpen ? " opened" : " closed"}>
                <h2 onClick={() => setIsOpen((state) => !state)}>
                    <span>{title}</span>
                </h2>

                {setIsEditing && buttonSave ? (
                    isEditing ? (
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(() => true);
                                setIsEditing((state) => !state);
                            }}
                        >
                            {buttonSave}
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(() => true);
                                setIsEditing((state) => !state);
                            }}
                        >
                            {buttonEdit}
                        </button>
                    )
                ) : (
                    <></>
                )}
            </CardHeader>

            <Collapse isOpen={isOpen} className="collapse-box">
                <div>{children}</div>
            </Collapse>
        </Card>
    );
};

export default CollapseBox;
